import React, { forwardRef } from 'react'
import classNames from 'classnames'
import './style.scss'

const FormLabel = forwardRef(function FormLabel(props, ref) {
    const {
        className,
        disabled,
        error,
        focused,
        filled,
        variant,
        required,
        ...other
    } = props
    return (
        <label
            className={classNames(
                'form-label',
                {
                    'form-label_disabled': disabled,
                    'form-label_error': error,
                    'form-label_filled': filled,
                    'form-label_focused': focused,
                    'form-label_required': required,
                    [`input-${variant}-label`]: variant,
                    [`input-${variant}-label_disabled`]: variant && disabled,
                    [`input-${variant}-label_error`]: variant && error,
                    [`input-${variant}-label_filled`]: variant && filled,
                    [`input-${variant}-label_focused`]: variant && focused,
                    [`input-${variant}-label_required`]: variant && required,
                },
                className
            )}
            ref={ref}
            {...other}
        />
    )
})

export default FormLabel
