import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { AreaField, FormInputBlock } from 'core/components';
import { IPulseSurveyDataForRHF } from 'entities/survey/pulseSurveyTypes';
import { updatePulseSurveyQuestionAction } from 'action/PulseSurvey';

interface IProps {
	question: string;
}

export const PulseSurveyTextField: FC<IProps> = ({ question }) => {
	const dispatch = useDispatch();
	const { register, unregister, setValue, errors } = useFormContext<IPulseSurveyDataForRHF>();

	useEffect(() => {
		register('question');
		return () => {
			unregister('question');
		};
	}, [register, unregister]);

	useEffect(() => {
		setValue('question', question);
	}, [setValue, question]);

	const onChange = (value: string) => {
		dispatch(updatePulseSurveyQuestionAction(value));
	};

	return (
		<FormInputBlock>
			<AreaField
				id="pulse-survey_form_question"
				error={!!errors.question}
				label="Вопрос"
				placeholder="Введите текст вопроса"
				name={'question'}
				maxLength={255}
				onChange={onChange}
				defaultValue={question}
			/>
		</FormInputBlock>
	);
};
