import { useEffect, useRef } from 'react';

export default function useTimeout(ms: number = 500) {
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		return () => {
			timeoutRef?.current && clearTimeout(timeoutRef?.current);
		};
	}, []);

	return (fnc: (...args: any[]) => void) => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(fnc, ms);
	};
}
