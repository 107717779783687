import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Icon, Loader } from '@maxi-innovation/ui-kit-frontend';
import { bodyFixPosition, bodyUnfixPosition } from 'core/utils';
import { congratulationShowed, getCongratulationCompany } from 'shared/api/congratulation';
import { NotificationHelper } from 'action/notificationHelper';
import { ICongratulationFromCompany } from 'shared/api/congratulation/types';
import { useIsMobile } from 'hooks/useIsMobile';
import { URL } from 'constant';

import './style.scss';

interface IProps {
	closeModal: () => void;
}

export const BirthdayCongratulation = ({ closeModal }: IProps) => {
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const modalRef = useRef(null);
	const confettiRef = useRef<HTMLDivElement>(null);
	const [congratulation, setCongratulation] = useState<ICongratulationFromCompany>();
	const COUNT = isMobile ? 100 : 300;

	const onClose = useCallback(async () => {
		const { responseCode, errorMessage } = await congratulationShowed();

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
		}

		closeModal();
	}, [dispatch, closeModal]);

	const keyHandler = useCallback(
		e => {
			if (e.key === 'Escape') {
				onClose();
			}
		},
		[onClose]
	);

	useEffect(() => {
		bodyFixPosition();
		window.addEventListener('keyup', keyHandler);

		return () => {
			bodyUnfixPosition();
			window.removeEventListener('keyup', keyHandler);
		};
	}, [keyHandler]);

	useEffect(() => {
		if (!confettiRef.current) return;

		var animationClasses = [
			'confetti__item--animation1',
			'confetti__item--animation2',
			'confetti__item--animation3',
			'confetti__item--animation4',
			'confetti__item--animation5',
			'confetti__item--animation6',
			'confetti__item--animation7',
			'confetti__item--animation8',
			'confetti__item--animation9',
		];

		var colourClasses = [
			'confetti__item--colour1',
			'confetti__item--colour2',
			'confetti__item--colour3',
			'confetti__item--colour4',
			'confetti__item--colour5',
			'confetti__item--colour6',
			'confetti__item--colour7',
			'confetti__item--colour8',
		];

		var viewClasses = [
			'confetti__item--view1',
			'confetti__item--view2',
			'confetti__item--view3',
			'confetti__item--view4',
			'confetti__item--view5',
			'confetti__item--view6',
			'confetti__item--view7',
			'confetti__item--view8',
			'confetti__item--view9',
		];

		for (let i = 0; i < COUNT; i++) {
			var newNode = document.createElement('div');
			var randomClass = animationClasses[Math.floor(Math.random() * animationClasses.length)];
			var randomColour = colourClasses[Math.floor(Math.random() * colourClasses.length)];
			var randomView = viewClasses[Math.floor(Math.random() * viewClasses.length)];
			var animationDelay =
				parseFloat(Math.min(0 + Math.random() * (5 - 0), 5).toFixed(2)) + 's';
			var left = Math.floor(Math.random() * (100 - 0 + 1)) + 0 + 'vw';

			newNode.className =
				'confetti__item ' + randomClass + ' ' + randomColour + ' ' + randomView;

			newNode.setAttribute(
				'style',
				'animation-delay: ' + animationDelay + '; left: ' + left + ';'
			);

			confettiRef.current.appendChild(newNode);
		}
	}, [COUNT]);

	useEffect(() => {
		(async () => {
			const { response, responseCode, errorMessage } = await getCongratulationCompany();

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				setCongratulation(response);
			}
		})();
	}, [dispatch]);

	return (
		<div className="birthbay-congratulation-modal" ref={modalRef}>
			<div className="confetti" ref={confettiRef} />

			<div className="birthbay-congratulation-modal__wrapper">
				<Icon
					iconName="X"
					className="birthbay-congratulation-modal__close"
					onClick={onClose}
				/>

				{!!congratulation ? (
					<div className="company-congratulation">
						<img
							src={URL + congratulation.poster.file.filePath}
							alt=""
							className="company-congratulation__img"
						/>
						<p className="company-congratulation__title">
							{congratulation.toUser.name}, с днём рождения!
						</p>
						<p className="company-congratulation__text">{congratulation.poster.text}</p>
						<p className="company-congratulation__signature">
							{congratulation.poster.signature}
						</p>
					</div>
				) : (
					<div className="company-congratulation-load">
						<Loader variant="black" size="M" />
					</div>
				)}
			</div>
		</div>
	);
};
