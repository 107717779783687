import {
	SET_APPROVEMENT_INFO,
	CLEAR_APPROVEMENT_INFO,
	SET_APPROVEMENT_HISTORY,
	CLEAR_APPROVEMENT_HISTORY,
} from 'constant';

const DefaultState = {
	approvementItem: null,
	history: null,
};

export default function requests(state = DefaultState, action) {
	switch (action.type) {
		case SET_APPROVEMENT_INFO:
			return {
				...state,
				approvementItem: action.approvementItem,
				errorMessage: action.errorMessage,
			};

		case CLEAR_APPROVEMENT_INFO:
			return {
				...state,
				approvementItem: null,
			};

		case SET_APPROVEMENT_HISTORY:
			return {
				...state,
				history: action.history,
			};

		case CLEAR_APPROVEMENT_HISTORY:
			return {
				...state,
				history: null,
			};

		default:
			return state;
	}
}
