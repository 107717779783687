import React from 'react'

export const RenderOtherItem = ({ id, name, parentBread, addToListHandler }) => {
    return (
        <div
            key={id}
            className="find-item__search-item"
            data-itemid={id}
            data-itemname={name}
			onClick={addToListHandler}
			onKeyDown={event => {
				if (event.key === 'Enter') addToListHandler(event)
			}}
			tabIndex="1"
        >
            <div className="find-item__search-item__info">
				<span className="find-item__search-item__name">{name}</span>
				{parentBread && (
					<span className="find-item__search-item__position">{parentBread}</span>
				)}
			</div>
        </div>
    )
}