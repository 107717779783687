import React, { forwardRef } from 'react'
import classNames from 'classnames'
import Tooltip from '../tooltip'
import * as Icon from 'react-feather'

import './style.scss'

const InfoTooltip = forwardRef(function InfoTooltip(props, ref) {
    const {
        className,
        iconProps: iconPropsProp = {},
        title,
        placement = 'top',
        ...other
    } = props

    const iconProps = {
        size: 22,
        ...iconPropsProp
    }

    return (
        <Tooltip title={title} placement={placement}>
            <span
                className={classNames('info-tooltip', className)}
                ref={ref}
                {...other}
            >
                <Icon.Info
                    {...iconProps}
                    className={classNames(
                        'info-tooltip__icon',
                        iconProps.className
                    )}
                />
            </span>
        </Tooltip>
    )
})

export default InfoTooltip
