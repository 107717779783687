import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import { TextField } from 'core/components';
import useForkRef from 'core/utils/useForkRef';
import useClickOutside from 'hooks/useClickOutside';

import { useDropList, useKeyboardNav, useKeyHandler } from './useDataList';

import './style.scss';

export const Datalist = ({
	onFocus,
	onBlur,
	onChange,
	options = [],
	placeholder = '',
	error = false,
	readOnly = false,
	maxLength = null,
	name = '',
	refRegister,
	seporator = ', ',
	defaultValue = '',
	label,
	helperText,
	multiselect = false,
	clearField = {},
	...other
}) => {
	const refInput = useRef(null);
	const dropdownRef = useRef(null);
	const wrapperRef = useRef(null);
	const handleInputRef = useForkRef(refInput, refRegister);

	const [value, setValue] = useState(defaultValue);
	const [count, setCount] = useState(0);

	const { getDropState, DropDown, DropUp } = useDropList(false);
	const { activationKeyboardNav, deactivationKeyboardNav } = useKeyboardNav(
		dropdownRef,
		refInput
	);

	useEffect(() => {
		const { clear, setClear } = clearField;
		if (clear) {
			setValue('');
			if (setClear) setClear(false);
		}
	}, [clearField]);

	const closeSelect = useCallback(() => {
		DropUp();
		if (onBlur) onBlur();
		deactivationKeyboardNav();
	}, [onBlur, DropUp, deactivationKeyboardNav]);

	useClickOutside(wrapperRef, closeSelect);
	useKeyHandler(refInput, closeSelect);

	useEffect(() => {
		if (maxLength) setCount(value.length);
		refInput.current.value = value;

		if (value) onChange(value);
	}, [value, setCount, onChange, maxLength]);

	const changeHandle = event => {
		if (maxLength && event.target.value.length > maxLength) return;
		setValue(event.target.value);
	};

	const focusHandle = () => {
		if (onFocus) onFocus();
		DropDown();
		activationKeyboardNav();
	};

	const optionSelectHandle = event => {
		const selectedValue = event.target.innerText;

		setValue(prev => {
			if (prev && multiselect) {
				const temp = prev + seporator + selectedValue;

				return temp.length > maxLength ? prev : temp;
			} else {
				return selectedValue;
			}
		});

		if (!multiselect) closeSelect();
	};

	const optionOnKeyDownHandle = event => {
		if (event.key === 'Enter') optionSelectHandle(event);
	};

	return (
		<>
			<div className="custom-select" ref={wrapperRef}>
				<div className="custom-select__header">
					<TextField
						label={label}
						placeholder={placeholder}
						inputRef={handleInputRef}
						error={error}
						name={name}
						maxLength={maxLength}
						autoComplete="off"
						fullWidth={true}
						onChange={changeHandle}
						readOnly={readOnly}
						onFocus={focusHandle}
						tabIndex="1"
						valueLength={count}
						{...other}
					/>
				</div>

				<div
					className={classNames('custom-select__drop', {
						'custom-select__drop--active': getDropState,
					})}
					ref={dropdownRef}
				>
					{options.map((item, key) => (
						<div
							className="custom-select__option"
							key={key}
							onClick={optionSelectHandle}
							onKeyDown={optionOnKeyDownHandle}
							tabIndex="1"
						>
							{item}
						</div>
					))}
				</div>
			</div>

			{helperText && <p className="custom-select-desc">{helperText}</p>}
		</>
	);
};
