import React, { FC } from 'react';
import edjsHTML from 'editorjs-html';
import Parser from 'html-react-parser';
import './style.scss';
import {
	delimiter,
	imageParser,
	tableParser,
	rawParser,
	attachesParser,
	warningParser,
	youtubeParser,
	paragraphParser,
	imageSimpleParser,
	headerParser,
} from './customParser';

interface IProps {
	content: string;
}

export const ViewEditor: FC<IProps> = ({ content }) => {
	const edjsParser = edjsHTML({
		image: imageParser,
		simpleImage: imageSimpleParser,
		delimiter: delimiter,
		table: tableParser,
		raw: rawParser,
		attaches: attachesParser,
		warning: warningParser,
		paragraph: paragraphParser,
		youtube: youtubeParser,
		header: headerParser,
	});

	const blockHTML = edjsParser.parse(JSON.parse(content));

	return (
		<div className="parse_editor__tag">
			{blockHTML.map((item: string, index: number) => (
				<div key={index} className="wrapper">
					{typeof item === 'string' ? Parser(item) : item}
				</div>
			))}
		</div>
	);
};
