import React, {
    forwardRef,
    useState,
    useCallback
} from 'react'
import classNames from 'classnames'
import FormControlContext from './FormControlContext'

import './style.scss'

const FormControl = forwardRef(function FormControl(props, ref) {
    const {
        children,
        className,
        component: Component = 'div',
        disabled = false,
        error = false,
        fullWidth = false,
        required = false,
        size,
        ...other
    } = props

    const [filled, setFilled] = useState(false)
    const [focused, setFocused] = useState(false)

    if (disabled && focused) {
        setFocused(false)
    }

    const onBlur = useCallback(() => {
        setFocused(false)
    }, [])

    const onFilled = useCallback(() => {
        setFilled(true)
    }, [])

    const onEmpty = useCallback(() => {
        setFilled(false)
    }, [])

    const onFocus = useCallback(() => {
        setFocused(true)
    }, [])

    const childContext = {
        disabled,
        error,
        filled,
        focused,
        fullWidth,
        onBlur,
        onEmpty,
        onFilled,
        onFocus,
        required,
    }

    return (
        <FormControlContext.Provider value={childContext}>
            <Component
                className={classNames(
                    'form-control',
                    { 'form-control_full-width': fullWidth },
                    className
                )}
                ref={ref}
                {...other}
            >
                {children}
            </Component>
        </FormControlContext.Provider>
    )
})

export default FormControl
