import { api } from './api';

export const getDocumentsList = themeId => {
	return api.get(`/wiki/documents/list`, {
		params: { themeId },
	});
};

//Создать нормативный документ
//name	string	Обязат.
//content string Обязат.
export const createDocument = data => {
	return api.post('/wiki/documents/add', { ...data });
};

//Получить нормативный документ
//id integer (int64) Обязат.
export const getDocument = (id, fromIndex) => {
	return api.get(`/wiki/documents/get`, {
		params: {
			id,
			...(fromIndex ? { fromIndex: fromIndex } : {}),
		},
	});
};

//Удалить нормативный документ
//id integer (int64) Обязат.
export const deleteDocument = id => {
	return api.post('/wiki/documents/delete', { id });
};

//Изменить нормативный документ
//id integer (int64) Обязат.
//name string Обязат.
//content string Обязат.
export const updateDocument = data => {
	return api.post('/wiki/documents/update', { ...data });
};

export const sortDocumentList = data => {
	return api.post('/wiki/documents/sort', { ...data });
};
