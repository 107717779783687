import { useState, useEffect } from 'react';

function randomInteger(min: number, max: number) {
	let rand = min + Math.random() * (max + 1 - min);
	return Math.floor(rand);
}

export const useDelayShow = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const delay = randomInteger(1000, 60000);
		const time = setTimeout(() => {
			setShow(true);
		}, delay);
		return () => {
			clearTimeout(time);
		};
	}, []);

	return show;
};
