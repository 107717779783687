import React, { FC, useEffect } from 'react';

import { IQuestionComponentProps } from '../types';
import { URL } from 'constant';
import { TextField } from 'core/components';

export const TextQuestion: FC<IQuestionComponentProps> = ({
	question,
	answeredHandler,
	error,
	defaultValue,
}) => {
	useEffect(() => {
		if (defaultValue?.textAnswer) answeredHandler(defaultValue.textAnswer);
	}, [defaultValue, answeredHandler]);

	const changeAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
		answeredHandler(event.currentTarget.value);
	};

	return (
		<div className="question">
			{question.image && (
				<img src={URL + question.image.filePath} alt="" className="question__preview" />
			)}

			<div className="question__title">{question.text}</div>

			<TextField
				// @ts-ignore
				label="Ваш ответ"
				placeholder="Введите ответ"
				error={error}
				name="field"
				onChange={changeAnswer}
				fullWidth={true}
				defaultValue={defaultValue?.textAnswer}
			/>

			{error && <div className="question-answers-error">Введите ответ</div>}
		</div>
	);
};
