import React from 'react';

export const RenderTagItem = ({ text, addToListHandler }) => {
	if (typeof text == 'string') {
		return (
			<div className="find-item__search-item" data-text={text} onClick={addToListHandler}>
				<span>{`${text}`}</span>
			</div>
		);
	} else {
		return null;
	}
};
