import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import './style.scss';
import { useCreateListYears } from './useCreateListYears';

export const SelectYear = ({
	selectedYear,
	setCurrentYear,
	setOpenSelectYear,
	changeList,
	setChangeList,
}) => {
	const [years, setYears] = useState([]);
	const { list, nextList, prevList } = useCreateListYears(selectedYear);

	const selectYearHandle = year => {
		if (setCurrentYear) {
			setCurrentYear(year);
			setOpenSelectYear(false);
		}
	};

	useEffect(() => {
		if (changeList === 'next') nextList();
		else if (changeList === 'prev') prevList();

		setChangeList('');
	}, [changeList, setChangeList, nextList, prevList]);

	useEffect(() => {
		setYears([...list.map(item => ({ year: item, selected: item === selectedYear }))]);
	}, [list, selectedYear]);

	return (
		<div className="select-year">
			{years.map((item, index) => (
				<div
					key={index}
					className={classNames('select-year__item', {
						selected: item.selected,
					})}
					onClick={() => selectYearHandle(item.year)}
				>
					{item.year}
				</div>
			))}
		</div>
	);
};
