const Roles = (props) => {
    if (props.isView) {
        return (
            props.children
        )
    } else {
        return (
            null
        )
    }
}

export default Roles