import { useCallback, useState, useEffect } from 'react';
import { getBannerDisplayFlag } from '../lib';

export const useShow = () => {
	const [isShow, setIsShow] = useState(false);

	useEffect(() => {
		const flag = getBannerDisplayFlag();
		if (!flag) {
			setIsShow(true);
		}
	}, []);

	const onClose = useCallback(() => {
		setIsShow(false);
	}, []);

	return {
		isShow,
		onClose,
	};
};
