import React from 'react';
import { Link } from 'react-router-dom';
import { Star } from 'react-feather';

import './style.scss';

export const FavoritesLink = () => {
	return (
		<Link to="/favorites?type=CONTACT" className="navigation-favorites">
			<Star className="navigation-favorites__icon" />
			<span className="navigation-favorites__text">Избранное</span>
		</Link>
	);
};
