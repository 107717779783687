import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import './style.scss'

import { Roles } from 'core/components'
import { hasRole } from 'core/utils'
import { PageTitle } from '../../atoms'

const PageHeader = ({
	title,
	linkPath,
	linkText,
	linkIcon,
	arrayRoles,
	location
}) => {
	const { state } = location
    return (
        <div className="page__header">
            <PageTitle title={title} />

            {
                arrayRoles && linkPath !== undefined &&
                    <Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_VACANCY_CLAIM')}>
                        <Link
							to={{
								pathname: linkPath,
								state: {
									...state,
									back: state && !!state.back ? state.back - 1 : -1
								}
							}}
							className="page__header-link"
						>
                            <svg role="img">
                                <use xlinkHref={process.env.PUBLIC_URL + `/img/sprite.svg#${linkIcon}`}></use>
                            </svg>

                            <span>{linkText}</span>
                        </Link>
                    </Roles>
            }
        </div>
    )
}

export default withRouter(PageHeader)