/**
  * Получить объект параметров поиска / фильтров 
  * 
  * @param {String} url URL строка
  * @param {String} [startParamsSymbol]  символ начала параметров
  * @param {String} [splitSymbol]  символ разделения параметров
  * @return {Object} объект параметров
  */

const getUrlParams = (url, startParamsSymbol = '?', splitSymbol = '&') => {
    if (url.indexOf(startParamsSymbol) !== -1) {
        url = url.slice(url.indexOf('?') + 1)
    }
    return url.split(splitSymbol).reduce(
        (objParams, param) => {
            let [key, value] = param.split('=')
            if (value.length > 0) {
                objParams[key] = value
            } else {
                objParams[key] = null
            }
            return objParams
        }, {}
    )
}

export default getUrlParams