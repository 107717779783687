import React, { useState } from 'react'
import { Button, InputText } from 'core/components'
import './style.scss'

export const DeleteModal = ({
	title,
	text,
	name: nameProp,
	onAccept,
	onCancel
}) => {
	const [name, setName] = useState('')
	const [valid, setValid] = useState(true)

	function acceptHandler(e) {
		if (!onAccept) return

		if (name === nameProp) {
			onAccept(e)
		} else {
			setValid(false)
		}
	}

	return (
		<div className="delete-modal">
			<div className="delete-modal-dialog">
				<div className="delete-modal-header">
					<p className="delete-modal__title">{title}</p>
				</div>
				<div className="delete-modal-content">
					<p className="delete-modal__text"
						dangerouslySetInnerHTML={{ __html: text }}
					/>
					<InputText
						value={name}
						placeholder={nameProp}
						type="text"
						valid={valid}
						onChange={event => {
							setValid(true)
							setName(event.currentTarget.value)
						}}
					/>
				</div>
				<div className="delete-modal-footer">
					<Button
						className="delete-modal__accept"
						type="primary"
						text="Подтвердить"
						handler={acceptHandler}
					/>
					<Button
						className="delete-modal__cancel"
						type="secondary"
						text="Отменить"
						handler={onCancel}
					/>
				</div>
			</div>
			<div
				className="delete-modal-backdrop"
				onClick={onCancel}
			/>
		</div>
	)
}

export default DeleteModal
