import { ColorPlugin } from './picker';
import { markerIcon, textIcon } from './icons';
import { getDefaultColorCache, handleCSSVariables } from './picker/utils/main';
import './index.scss';

export class Color {
  constructor({ config, api }) {
    this.api = api;
    this.config = config;
    this.clickedOnLeft = false;
    this.pluginType = this.config.type || 'text';
    this.parentTag = this.pluginType === 'marker' ? 'MARK' : 'SPAN';
    this.hasCustomPicker = this.config.customPicker || false;
    this.color = handleCSSVariables(
        getDefaultColorCache(this.config.defaultColor, this.pluginType)
    );
    this.picker = null;
    this.icon = null;

    this.button = null;

    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    };
  }

  static get isInline() {
    return true;
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.classList.add('colorPlugin');
    this.button.classList.add(this.iconClasses.base);
    this.button.appendChild(this.createLeftButton());
    this.button.appendChild(this.createRightButton(this));

    return this.button;
  }

  createLeftButton() {
    if (!this.icon) {
      this.icon = document.createElement('div');
      this.icon.id = 'color-left-btn';
      this.icon.appendChild(this.createButtonIcon());
      this.icon.addEventListener('click', () => this.clickedOnLeft = true);
    }

    return this.icon;
  }

  createButtonIcon() {
    const buttonIcon = document.createElement('div');
    buttonIcon.id = 'color-btn-text';
    const defaultIcon = this.pluginType === 'marker' ? markerIcon : textIcon;
    buttonIcon.innerHTML = this.config.icon || defaultIcon;
    return buttonIcon;
  }

  createRightButton(sharedScope) {
    if (!this.picker) {
      this.picker = new ColorPlugin({
        onColorPicked: function (value) {
          sharedScope.color = value;
        },
        hasCustomPicker: this.hasCustomPicker,
        defaultColor: this.config.defaultColor,
        colorCollections: this.config.colorCollections,
        type: this.pluginType
      });
    }

    return this.picker;
  }

  surround(range) {
    if (!range) {
      return
    }

    const legacySpanWrapper = this.api.selection.findParentTag("FONT");
    if (legacySpanWrapper) this.unwrap(legacySpanWrapper);

    const termWrapper = this.api.selection.findParentTag(this.parentTag);

    if (termWrapper) {
      this.unwrap(termWrapper);
    } else {
      this.wrap(range);
    }

    this.clickedOnLeft = false;
  }

  wrap(range) {
    const selectedText = range.extractContents();
    const newWrapper = document.createElement(this.parentTag);

    newWrapper.appendChild(selectedText);
    range.insertNode(newWrapper);

    if (this.pluginType === 'marker') {
      this.wrapMarker(newWrapper);
    } else {
      this.wrapTextColor(newWrapper);
    }

    this.api.selection.expandToTag(newWrapper);
  }

  wrapMarker(newWrapper) {
    newWrapper.style.backgroundColor = this.color;
    const colorWrapper = this.api.selection.findParentTag('SPAN');
    if (colorWrapper) newWrapper.style.color = colorWrapper.style.color;
  }

  wrapTextColor(newWrapper) {
    newWrapper.style.color = this.color;
  }

  unwrap(termWrapper) {
    this.api.selection.expandToTag(termWrapper)

    const sel = window.getSelection()
    const range = sel.getRangeAt(0)

    const unwrappedContent = range.extractContents()

    if (this.clickedOnLeft) {
      this.removeWrapper(termWrapper);
    } else {
      this.updateWrapper(termWrapper);
    }

    range.insertNode(unwrappedContent)
    sel.removeAllRanges()
    sel.addRange(range)
  }

  updateWrapper(termWrapper) {
    if (this.pluginType === 'marker') {
      termWrapper.style.backgroundColor = this.color;
    } else {
      termWrapper.style.color = this.color;
    }
  }

  removeWrapper(termWrapper) {
    termWrapper.parentNode.removeChild(termWrapper);
  }

  checkState() {
    const legacyWrapper = this.api.selection.findParentTag("FONT");
    const termTag = this.api.selection.findParentTag(this.parentTag);
    let isWrapped = legacyWrapper ? this.handleLegacyWrapper(legacyWrapper, termTag) : termTag;
    this.button.classList.toggle(this.iconClasses.active, !!isWrapped)

    return !!isWrapped;
  }

  handleLegacyWrapper(legacyWrapper, termTag) {
    return this.pluginType === 'marker' ? legacyWrapper : (termTag & legacyWrapper);
  }

  static get sanitize() {
    return {
      font: true,
      span: true,
      mark: true
    };
  }

  clear() {
    this.picker = null;
    this.icon = null;
  }
}