import {
	SET_MESSENGER_CATEGORY_LIST,
	MESSENGER_CATEGORY_CHANGE_NAME,
	MESSENGER_CATEGORY_CREATE,
	MESSENGER_CATEGORY_CHILDREN_CREATE,
	MOVE_UP_MESSENGER_CATEGORY,
	MOVE_DOWN_MESSENGER_CATEGORY,
	DELETE_MESSENGER_CATEGORY,
	MESSANGES_COUNT,
	MESSANGES_COUNT_DEC,
} from '../constant';

export const setMessengerCategorylistAction = response => {
	return {
		type: SET_MESSENGER_CATEGORY_LIST,
		response,
	};
};

export const messengerCategoryChangeNameAction = (id, name) => {
	return {
		type: MESSENGER_CATEGORY_CHANGE_NAME,
		id,
		name,
	};
};

export const createNewCategoryAction = (chatBotId, item) => {
	return {
		type: MESSENGER_CATEGORY_CREATE,
		chatBotId,
		item,
	};
};

export const createNewCategoryChildrenAction = (chatBotId, parentId, item) => {
	return {
		type: MESSENGER_CATEGORY_CHILDREN_CREATE,
		chatBotId,
		parentId,
		item,
	};
};

export const moveUpMessengerAction = (currentId, chatbotId) => {
	return {
		type: MOVE_UP_MESSENGER_CATEGORY,
		currentId,
		chatbotId,
	};
};

export const moveDownMessengerAction = (currentId, chatbotId) => {
	return {
		type: MOVE_DOWN_MESSENGER_CATEGORY,
		currentId,
		chatbotId,
	};
};

export const deleteMessengerCategoryAction = (deleteId, chatbotId) => {
	return {
		type: DELETE_MESSENGER_CATEGORY,
		deleteId,
		chatbotId,
	};
};

export const messangesCountAction = unreadCount => {
	return {
		type: MESSANGES_COUNT,
		unreadCount,
	};
};

export const messangesCountDecAction = () => {
	return {
		type: MESSANGES_COUNT_DEC,
	};
};
