import store from 'store';
import { sortArticles } from "action/guide";

const getItem = (element) => {
	return element.closest(".articles-list__item-wrapper")
}

const tooglePointerEvents = () => {
	const list = document.querySelector('.articles-list__items');
	if(list.classList.contains('articles-list__items_pointer-events')) {
		list.classList.remove('articles-list__items_pointer-events')
	} else {
		list.classList.add('articles-list__items_pointer-events')
	}
}

export const handleDragStart = (e) => {
	e.target.classList.add('articles-list__item-wrapper_over');
	e.dataTransfer.effectAllowed = 'move';
	e.dataTransfer.setData('index', e.target.dataset.index);
	tooglePointerEvents()
}

export const handleDragEnd = (e) => {
	const el = getItem(e.target);
	el.classList.remove('articles-list__item-wrapper_over');
}

export const handleDragEnter = (e) => {
	const el = getItem(e.target);
	el.classList.add('articles-list__item-wrapper_enter');
}

export const handleDragOver = (e) => {
	if (e.preventDefault) {
		e.preventDefault();
	}
	e.dataTransfer.dropEffect = 'move';
	return false
}

export const handleDragLeave = (e) => {
	const el = getItem(e.target);
	el.classList.remove('articles-list__item-wrapper_enter');
}

export const handlerDrop = (e) => {
	if (e.stopPropagation) {
		e.stopPropagation();
	}
	const el = getItem(e.target)
	el.classList.remove('articles-list__item-wrapper_enter');
	const dragIndex = +e.dataTransfer.getData('index')
	const dropIndex = +el.dataset.index
	var items = store.getState().guide.articlesList.slice()
	items[dropIndex] = 
            [items[dragIndex], items[dragIndex] = items[dropIndex]][0]
	const sortIdList = items.map(
		item => item.id
	)
	const data = {
		dragIndex,
		dropIndex,
		sortIdList
	}
	store.dispatch(sortArticles(data));
	tooglePointerEvents();
}
