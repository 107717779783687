import {
	MOBILE_MENU_VIEW,
	MOBILE_MENU_VIEW_CLOSE,
	SEARCH_PANEL_VIEW,
	SEARCH_PANEL_VIEW_CLOSE,
	MOBILE_GROUP_MENU,
	MOBILE_GROUP_MENU_CLOSE,
	VACANCIES_FILTERS_OPEN,
	VACANCIES_FILTERS_CLOSE,
	TOP_MENU_VIEW,
	TOOGLE_HEAD_MENU,
	CLOSE_HEAD_MENU,
	TOOGLE_HEAD_NOTIFICATIONS,
	TOOGLE_ADMIN_MENU,
	CLOSE_ADMIN_MENU,
} from 'constant';

const DefaultState = {
	menuView: false,
	groupMenuView: false,
	searchView: false,
	topMenuView: false,
	vacFiltersView: false,
	headerMenu: false,
	headerNotifications: false,
	adminMenuView: false,
};

export default function groupMenu(state = DefaultState, action) {
	switch (action.type) {
		case TOOGLE_HEAD_MENU:
			let headerMenuOpen = state;
			headerMenuOpen.headerMenu = !headerMenuOpen.headerMenu;
			return headerMenuOpen;

		case CLOSE_HEAD_MENU:
			let headerMenuClose = state;
			headerMenuClose.headerMenu = false;
			return headerMenuClose;

		case TOOGLE_HEAD_NOTIFICATIONS:
			return {
				...state,
				headerNotifications: action.status,
			};

		case MOBILE_MENU_VIEW:
			let menuViewState = state;
			menuViewState.menuView = !menuViewState.menuView;
			return menuViewState;

		case MOBILE_MENU_VIEW_CLOSE:
			let menuViewStateClose = state;
			menuViewStateClose.menuView = false;
			return menuViewStateClose;

		case SEARCH_PANEL_VIEW:
			let searchViewState = state;
			searchViewState.searchView = !searchViewState.searchView;
			return searchViewState;

		case SEARCH_PANEL_VIEW_CLOSE:
			let searchViewStateClose = state;
			searchViewStateClose.searchView = false;
			return searchViewStateClose;

		case MOBILE_GROUP_MENU:
			let groupMenuViewState = state;
			groupMenuViewState.groupMenuView = !groupMenuViewState.groupMenuView;
			return groupMenuViewState;

		case MOBILE_GROUP_MENU_CLOSE:
			let groupMenuViewStateClose = state;
			groupMenuViewStateClose.groupMenuView = !groupMenuViewStateClose.groupMenuView;
			return groupMenuViewStateClose;

		case VACANCIES_FILTERS_OPEN:
			let vacFiltersViewState = state;
			vacFiltersViewState.vacFiltersView = true;
			return vacFiltersViewState;

		case VACANCIES_FILTERS_CLOSE:
			let vacFiltersViewStateClose = state;
			vacFiltersViewStateClose.vacFiltersView = false;
			return vacFiltersViewStateClose;

		case TOP_MENU_VIEW:
			let topMenuViewState = state;
			if (action.status !== undefined) {
				topMenuViewState.topMenuView = action.status;
			} else {
				topMenuViewState.topMenuView = !topMenuViewState.topMenuView;
			}
			return topMenuViewState;

		case TOOGLE_ADMIN_MENU:
			let adminMenuToggle = state;
			adminMenuToggle.adminMenuView = !adminMenuToggle.adminMenuView;
			return adminMenuToggle;

		case CLOSE_ADMIN_MENU:
			let adminMenuClose = state;
			adminMenuClose.adminMenuView = false;
			return adminMenuClose;

		default:
			return state;
	}
}
