import React, { FC } from 'react';
import * as Icon from 'react-feather';

import './style.scss';

interface IProps {
	status: {
		description: string;
		iconName: string;
		color: string;
	};
	fontSize?: number;
}

export const RenderStatus: FC<IProps> = ({ status, fontSize = 13 }) => {
	// @ts-ignore
	const CurrentIcon = Icon[status.iconName];

	return (
		<div
			className="status"
			style={{ '--status-color': `${status.color}` } as React.CSSProperties}
		>
			{CurrentIcon && <CurrentIcon className="status__icon" />}
			<p className="status__text" style={{ fontSize }}>
				{status.description}
			</p>
		</div>
	);
};
