import { api } from 'rest/api';

function createError(error: any, defaultValue: any = null) {
	return {
		responseCode: 1,
		errorMessage: typeof error === 'object' ? 'Ошибка сети' : error,
		response: defaultValue,
	};
}

export const getWikiDocumentsListRest = async () => {
	try {
		const response = await api.get(`/wiki/documents/theme/list`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const createDocumentsThemeRest = async ({ title }: { title: string }) => {
	try {
		const response = await api.post(`/wiki/documents/theme/create`, { title });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const updateDocumentsThemeRest = async ({ title, id }: { title: string; id: number }) => {
	try {
		const response = await api.post(`/wiki/documents/theme/update`, { id, title });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getDocumentsThemeRest = async (id: number) => {
	try {
		const response = await api.get(`/wiki/documents/theme/get`, { params: { id } });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const incSortDocumentsThemeRest = async (id: number) => {
	try {
		const response = await api.post(`/wiki/documents/theme/inc-sort`, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const decSortDocumentsThemeRest = async (id: number) => {
	try {
		const response = await api.post(`/wiki/documents/theme/dec-sort `, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const deleteDocumentsThemeRest = async (id: number) => {
	try {
		const response = await api.post(`/wiki/documents/theme/delete `, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};
