import React from 'react';
import { useDispatch } from 'react-redux';

import { AsideMenuLink } from '../../atoms';

import { sortCategoryList } from 'rest/guide';
import { topCategoriesList } from 'action/guide';

import './style.scss';

export const AsideSections = ({ categoriesList }) => {
	const dispatch = useDispatch();

	function moveCategory(currentIndex, newIndex) {
		const newCategories = [...categoriesList];

		newCategories[currentIndex] = categoriesList[newIndex];
		newCategories[newIndex] = categoriesList[currentIndex];

		sortCategoryList({
			sortIdList: newCategories.map(category => category.id),
		}).then(() => {
			dispatch(topCategoriesList(newCategories));
		});
	}

	function toUpHandler(index) {
		if (index > 0) {
			moveCategory(index, index - 1);
		}
	}

	function toDownHandler(index) {
		if (index < categoriesList.length - 1) {
			moveCategory(index, index + 1);
		}
	}

	return (
		<div className="aside-menu">
			<p className="aside-menu__title">Разделы</p>

			{categoriesList.map((item, index) => (
				<div className="Guide--aside-menu__item-wrapper" key={item.id}>
					<AsideMenuLink
						{...item}
						categoriesList={categoriesList}
						canEdit={item.canEdit}
						toUp={() => toUpHandler(index)}
						toDown={() => toDownHandler(index)}
						top={true}
					/>
				</div>
			))}
		</div>
	);
};
