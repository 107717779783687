import {
	PULSE_SURVEY_UPDATE_START_DATE,
	PULSE_SURVEY_UPDATE_END_DATE,
	PULSE_SURVEY_UPDATE_ANONYMAUS,
	PULSE_SURVEY_UPDATE_USERS,
	PULSE_SURVEY_UPDATE_POSITION,
	PULSE_SURVEY_UPDATE_DEPARTMENTS,
	PULSE_SURVEY_UPDATE_QUESTION,
	PULSE_SURVEY_UPDATE_ANSWER_TYPE,
	SET_PULSE_SURVEY,
	CLEAR_PULSE_SURVEY,
} from 'constant';
import { IPulseSurveyData, TAnswerScaleType } from 'entities/survey/pulseSurveyTypes';
import { IDepartment, IPosition, IUser } from 'shared/ui/types';

export const updatePulseSurveyStartDateAction = (startDate?: number) => {
	return {
		type: PULSE_SURVEY_UPDATE_START_DATE,
		payload: {
			startDate,
		},
	};
};

export const updatePulseSurveyEndDateAction = (endDate?: number) => {
	return {
		type: PULSE_SURVEY_UPDATE_END_DATE,
		payload: {
			endDate,
		},
	};
};

export const updatePulseSurveyAnonymousAction = (anonymous: boolean) => {
	return {
		type: PULSE_SURVEY_UPDATE_ANONYMAUS,
		payload: {
			anonymous,
		},
	};
};

export const updatePulseSurveyUsersAction = (forUsers?: IUser[]) => {
	return {
		type: PULSE_SURVEY_UPDATE_USERS,
		payload: {
			forUsers,
		},
	};
};

export const updatePulseSurveyDepartmentsAction = (forDepartments?: IDepartment[]) => {
	return {
		type: PULSE_SURVEY_UPDATE_DEPARTMENTS,
		payload: {
			forDepartments,
		},
	};
};

export const updatePulseSurveyPositionAction = (forPositions?: IPosition[]) => {
	return {
		type: PULSE_SURVEY_UPDATE_POSITION,
		payload: {
			forPositions,
		},
	};
};

export const updatePulseSurveyQuestionAction = (question: string) => {
	return {
		type: PULSE_SURVEY_UPDATE_QUESTION,
		payload: {
			question,
		},
	};
};

export const updatePulseSurveyAnswerTypeAction = (answerScaleType: TAnswerScaleType) => {
	return {
		type: PULSE_SURVEY_UPDATE_ANSWER_TYPE,
		payload: {
			answerScaleType,
		},
	};
};

export const setPulseSurveyAction = (data: IPulseSurveyData) => {
	return {
		type: SET_PULSE_SURVEY,
		payload: {
			...data,
		},
	};
};

export const clearPulseSurveyAction = () => {
	return {
		type: CLEAR_PULSE_SURVEY,
	};
};
