import { api } from './api';

//Получить список типов избранного
export const getTypesOfFavorites = () => {
	return api.get('/favorite/type/list');
};

//Получить список избранного по типу
//type string Обязат.
export const getFavoritesByType = data => {
	return api.get('/favorite/list', {
		params: {
			...data,
		},
	});
};

//Получить список id избранного по типу
//type string Обязат.
export const getFavoritesIdsByType = type => {
	return api.get(`/favorite/ids-list?type=${type}`);
};

//Изменить состояние Избранного
//type string Обязат.
//objectId integer (int64) Обязат.
//favorite boolean Обязат.
export const updateFavoriteState = data => {
	return api.post('/favorite/update', {
		...data
	})
}

//Проверить в Избранном ли пользователь
//type string Обязат.
//objectId integer (int64) Обязат.
export const checkIsFavorite = data => {
	return api.get(`/favorite/check`, {
		params: {
			...data
		}
	})
}