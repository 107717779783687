import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Icon from 'react-feather';

import { bodyFixPosition, bodyUnfixPosition } from 'core/utils';

import './style.scss';

const ModalComponent = ({ width, onClose, children, className }) => {
	const modalRef = useRef(null);

	const keyHandler = useCallback(
		e => {
			if (e.key === 'Escape') {
				onClose();
			}
		},
		[onClose]
	);

	useEffect(() => {
		bodyFixPosition();
		window.addEventListener('keyup', keyHandler);

		return () => {
			bodyUnfixPosition();
			window.removeEventListener('keyup', keyHandler);
		};
	}, [keyHandler]);

	return (
		<div className="modal">
			<div className="modal__wrapper">
				<Icon.X className="modal__close" size="24" onClick={onClose} />
				<div
					className={
						width
							? `modal__view modal__view_${width} ${className}`
							: `modal__view ${className}`
					}
					ref={modalRef}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ModalComponent;

ModalComponent.propTypes = {
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	width: PropTypes.oneOf(['narrow']),
};
