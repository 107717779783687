import React from 'react'
import { URL } from 'constant'
import YouTube from 'react-youtube'

import { MIVideoPlayer } from 'core/video'

import './style.scss'

export const VideoBlock = ({ video }) => {

    const {
        fileType,
        processConvert,
        originalFileName,
        filePath,
        preview,
        id
    } = video
    
    function youtubeGetID(url) {
        var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        return (url.match(p)) ? RegExp.$1 : false;
    }

    return (
        <div className="question__item-video">
            {
                fileType === "FAQ_VIDEO" ? processConvert ? (
                    <div className="video__convert">
                        <span>{`Видео ${originalFileName} в обработке`}</span>
                    </div>
                ) : (
                    <MIVideoPlayer
                        url={`${URL}${filePath}`}
                        posterUrl={preview ? `${URL}${preview.filePath}` : ``}
                        key={id}
                    />
                ) : (
                    <YouTube
                        key={id}
                        title="video"
                        videoId={youtubeGetID(video.originalFileName)}
                        frameBorder="0"
                        allowFullScreen
                    />
                )
            }
        </div>
    )
}