import { api } from './api';
import showNotification from './showNotification';

//Получить список статей в группе
//groupId integer (int64)
//pageNumber integer (int32)
//pageSize integer (int32)
export const articlesFindByGroup = (id, page, pageSize) => {
	return api.get(`/articles/find-by-group?groupId=${id}&pageNumber=${page}&pageSize=${pageSize}`);
};

//Получить статью для просмотра
//groupId integer (int64) Обязат.
//articleId integer (int64) Обязат.
export const getArticle = data => {
	return api.get('/articles/get', {
		params: {
			...data,
		},
	});
};

//Создать статью в группе
//groupId integer (int64) Обязат.
//name string Обязат.
//previewPicture integer (int64)
//content string
//status string Обязат.
export const articleCreate = data => {
	return api.post('/articles/create', {
		...data,
	});
};

//Удалить статью
export const deleteArticle = id => {
	return api.post('/articles/delete', {
		id,
	});
};

//Редактировать статью
//id integer (int64) Обязат.
//name string Обязат.
//previewPicture integer (int64)
//content string
//status string Обязат.
export const articlesEdit = data => {
	return api.post('/articles/edit', {
		...data,
	});
};

//Получить список статей для публикации
export const getArticlesForPost = context => {
	api.get(
		`/articles/find-for-post?groupId=${context.props.idGroup || context.props.groupInfo.id}`
	)
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.setState({
					articles: [...response.data.response],
				});
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/articles/find-for-post ${error}`);
		});
};
