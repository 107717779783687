import { api } from 'rest/api';

import { IFileSave } from './types';

export const fileSaveRest = async (data: IFileSave) => {
	try {
		const response = await api.post(`/file/save`, data);
		return response.data;
	} catch (error) {
		return {
			responseCode: 1,
			errorMessage: error,
			response: undefined,
		};
	}
};
