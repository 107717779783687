import { useCallback, useState } from 'react';

export const useShowPersonalDataModal = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = useCallback(() => {
		setIsOpen(prev => !prev);
	}, []);

	return {
		isOpen,
		toggleOpen,
	};
};
