import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FinderFieldMultiple } from 'components/finderFieldMultiple';
import { ImportFromExcel } from 'shared/ui/ImportFromExcel';
import { IPulseSurveyDataForRHF } from 'entities/survey/pulseSurveyTypes';
import { IDepartment, IPosition, IUser } from 'shared/ui/types';
import {
	updatePulseSurveyDepartmentsAction,
	updatePulseSurveyPositionAction,
	updatePulseSurveyUsersAction,
} from 'action/PulseSurvey';
import { useFormContext } from 'react-hook-form';
import { Label } from 'shared/ui/Label';

interface IProps {
	fixViewComponent: () => void;
	forUsers: IUser[];
	forPositions: IPosition[];
	forDepartments: IDepartment[];
}

export const PulseSurveyView: FC<IProps> = ({
	fixViewComponent,
	forUsers,
	forPositions,
	forDepartments,
}) => {
	const dispatch = useDispatch();
	const { register, unregister, setValue, errors } = useFormContext<IPulseSurveyDataForRHF>();

	const [users, setUsers] = useState<IUser[]>();
	const [departments, setDepartments] = useState<IDepartment[]>();
	const [positions, setPositions] = useState<IPosition[]>();

	useEffect(() => {
		register('users');
		register('positions');
		register('departments');
		return () => {
			unregister('users');
			unregister('positions');
			unregister('departments');
		};
	}, [register, unregister]);

	useEffect(() => {
		const arr = forUsers.map(item => item.id);
		setValue('users', arr);
		setUsers(forUsers);
	}, [setValue, forUsers]);

	useEffect(() => {
		const arr = forPositions.map(item => item.id);
		setValue('positions', arr);
		setPositions(forPositions);
	}, [setValue, forPositions]);

	useEffect(() => {
		const arr = forDepartments.map(item => item.id);
		setValue('departments', arr);
		setDepartments(forDepartments);
	}, [setValue, forDepartments]);

	const selectUsers = useCallback(
		(list: IUser[]) => {
			dispatch(updatePulseSurveyUsersAction(list));
		},
		[dispatch]
	);

	const selectDepartments = useCallback(
		(list: IDepartment[]) => {
			dispatch(updatePulseSurveyDepartmentsAction(list));
		},
		[dispatch]
	);

	const selectPosition = useCallback(
		(list: IPosition[]) => {
			dispatch(updatePulseSurveyPositionAction(list));
		},
		[dispatch]
	);

	const getUsersFromExcel = useCallback(
		(users: IUser[]) => {
			dispatch(updatePulseSurveyUsersAction(users));
			fixViewComponent();
		},
		[dispatch, fixViewComponent]
	);

	return (
		<div className="pulse-survey-view">
			<div className="pulse-survey-view__wrap">
				<Label>Подразделение</Label>

				<FinderFieldMultiple
					id="pulse-survey_form_departments"
					error={!!errors.departments}
					name="departments"
					searchType="DEPARTMENT"
					placeholder="Выберите подразделение"
					onSelected={selectDepartments}
					defaultOptions={departments}
				/>
			</div>

			<div className="pulse-survey-view__wrap">
				<Label>Должность</Label>

				<FinderFieldMultiple
					id="pulse-survey_form_positions"
					error={!!errors.positions}
					name="position"
					searchType="USER_POSITION"
					placeholder="Выберите должность"
					onSelected={selectPosition}
					defaultOptions={positions}
					limit=""
				/>
			</div>

			<div className="pulse-survey-view__wrap">
				<Label>Пользователь</Label>

				<FinderFieldMultiple
					id="pulse-survey_form_users"
					error={!!errors.users}
					name="users"
					searchType="USER"
					placeholder="Выберите пользователя"
					onSelected={selectUsers}
					defaultOptions={users}
				/>
			</div>

			<ImportFromExcel
				id="pulse-survey_upload-from-excel"
				getUsers={getUsersFromExcel}
				label="Загрузить список участников пульс-опроса из Excel"
			/>
		</div>
	);
};
