import { URL_AUTH } from 'constant';
import { useState, useCallback } from 'react';
import { api } from 'rest/api';
import { useFormState } from './useFormState';

export const useRegistrationStart = () => {
	const { setUuid, setDeadline, setCodeView } = useFormState();

	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const getCode = useCallback(
		async (phoneNumber: string) => {
			setLoading(true);
			setError(false);
			setErrorMessage(null);
			try {
				const response = await api.post(
					'/auth/registration/start',
					{
						phoneNumber,
					},
					{
						baseURL: URL_AUTH,
					}
				);
				const { data } = response;
				if (data.responseCode === 0) {
					const {
						startRegistrationResult: { claimUuid, confirmDeadline },
					} = data.response;
					setUuid(claimUuid);
					setDeadline(confirmDeadline);
					setCodeView();
				} else {
					setError(true);
					setErrorMessage(data.errorMessage);
				}
			} catch (error) {
				setError(true);
				setErrorMessage(`${error}`);
			} finally {
				setLoading(false);
			}
		},
		[setUuid, setDeadline, setCodeView]
	);

	return {
		isLoading,
		isError,
		errorMessage,
		getCode,
	};
};
