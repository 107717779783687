import {
	MOBILE_MENU_VIEW,
	MOBILE_MENU_VIEW_CLOSE,
	SEARCH_PANEL_VIEW,
	TOP_MENU_VIEW,
	GLOBAL_SEARCH,
	GLOBAL_SEARCH_CLEAR,
	SEARCH_PANEL_VIEW_CLOSE,
	MOBILE_GROUP_MENU,
	MOBILE_GROUP_MENU_CLOSE,
	VACANCIES_FILTERS_OPEN,
	VACANCIES_FILTERS_CLOSE,
	TOOGLE_HEAD_MENU,
	CLOSE_HEAD_MENU,
	TOOGLE_HEAD_NOTIFICATIONS,
	TOOGLE_ADMIN_MENU,
	CLOSE_ADMIN_MENU,
} from '../constant';

export const openHeadMenu = () => {
	return {
		type: TOOGLE_HEAD_MENU,
	};
};

export const closeHeadMenu = () => {
	return {
		type: CLOSE_HEAD_MENU,
	};
};

export const toggleHeadNotifications = status => {
	return {
		type: TOOGLE_HEAD_NOTIFICATIONS,
		status,
	};
};

export const mainMenuToggle = () => {
	return {
		type: MOBILE_MENU_VIEW,
	};
};

export const mainMenuClose = () => {
	return {
		type: MOBILE_MENU_VIEW_CLOSE,
	};
};

export const mobileSearch = () => {
	return {
		type: SEARCH_PANEL_VIEW,
	};
};

export const mobileSearchClose = () => {
	return {
		type: SEARCH_PANEL_VIEW_CLOSE,
	};
};

export const topMenuView = status => {
	return {
		type: TOP_MENU_VIEW,
		status,
	};
};

export const globalSearchResult = result => {
	return {
		type: GLOBAL_SEARCH,
		result,
	};
};

export const globalSearchResultClear = () => {
	return {
		type: GLOBAL_SEARCH_CLEAR,
	};
};

export const groupMenuToggle = () => {
	return {
		type: MOBILE_GROUP_MENU,
	};
};

export const groupMenuClose = () => {
	return {
		type: MOBILE_GROUP_MENU_CLOSE,
	};
};

export const vacanciesFiltersOpen = () => {
	return {
		type: VACANCIES_FILTERS_OPEN,
	};
};

export const vacanciesFiltersClose = () => {
	return {
		type: VACANCIES_FILTERS_CLOSE,
	};
};

export const toggleAdminMenu = () => {
	return {
		type: TOOGLE_ADMIN_MENU,
	};
};

export const closeAdminMenu = () => {
	return {
		type: CLOSE_ADMIN_MENU,
	};
};
