import { api } from './api';

//Список всех категорий
export const categoriesList = () => {
	return api.get('/contacts/category/get-all-list');
};

//Список корневых категорий
export const topCategoriesList = () => {
	return api.get('/contacts/category/get-top');
};

//Получить контент категории
export const categoryInfo = (parentId, fromIndex) => {
	return api.get(`/contacts/category/get-children-and-contacts`, {
		params: {
			parentId,
			...(fromIndex ? { fromIndex: fromIndex } : {}),
		},
	});
};

//Получить список недавних контактов
export const recentContacts = () => {
	return api.get('/contacts/recent/get-contacts');
};

//Сортировка контактов
//id integer (int64)
//type string
export const changeSortContacts = data => {
	return api.post('/contacts/change-sort', {
		...data,
	});
};

//Служебные записи
//Создать служебную запись
//telephone string
//address string
//categoryId integer (int64)
//name string
export const createAnotherContact = data => {
	return api.post('/contacts/create-another', {
		...data,
	});
};

//Создать карточку-контакт (со ссылкой на сотрудника)
//telephone string
//address string
//position string
//categoryId integer (int64)
//userId integer (int64)
export const createAnotherUserContact = data => {
	return api.post('/contacts/create-another-with-user', {
		...data,
	});
};

//Обновить служебную запись
//contactId integer (int64)
//telephone string
//address string
//categoryId integer (int64)
//name string
export const updateAnotherContact = data => {
	return api.post('/contacts/update-another', {
		...data,
	});
};

//Удалить служебную запись
//contactId integer (int64)
export const deleteAnotherContact = data => {
	return api.post('/contacts/delete-another', {
		...data,
	});
};

//Получить id элемента структуры компании по id подразделения справочника
//id integer (int64) Обязат.
export const getStructureItem = (id, fromIndex) => {
	return api.get(`contacts/category/get-company-structure`, {
		params: {
			id,
			...(fromIndex ? { fromIndex: fromIndex } : {}),
		},
	});
};
