import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { GalleryItem } from './image';

export const Gallery = withRouter(
	({ match, location, id, images, isOpenGalleryHandler, isModal = false }) => {
		const [spreadImages, setSpreadImages] = useState([]);
		useEffect(() => {
			const result = [];
			let count = 0;
			let tempImage;

			for (let index = 0; index < images.length; index++) {
				const image = images[index];

				if (!result[count]) result.push([]);

				if (tempImage) {
					result[count].push(tempImage);
					tempImage = undefined;
				}

				if (images.length - index !== 2) {
					result[count].push(image);
				} else {
					tempImage = image;
				}

				if ((index + 1) % 3 === 0) {
					count++;
				}
			}

			setSpreadImages(result);
		}, [images]);

		return (
			<div className={isModal ? 'post-gallery post-gallery--one-in-row' : 'post-gallery'}>
				{!!spreadImages.length &&
					spreadImages.map((imgs, index) => (
						<div className="post-gallery-row" key={index}>
							{imgs.map(item => (
								<GalleryItem
									item={item}
									id={id}
									key={item.id}
									isOpenGalleryHandler={isOpenGalleryHandler}
									match={match}
									location={location}
								/>
							))}
						</div>
					))}
			</div>
		);
	}
);
