import { GLOBAL_SEARCH, GLOBAL_SEARCH_CLEAR } from '../constant'

const DefaultState = null

export default function globalSearch(state = DefaultState, action) {

    switch (action.type) {
        case GLOBAL_SEARCH:
            return action.result

        case GLOBAL_SEARCH_CLEAR:
            return null

        default:
            return state
    }

}