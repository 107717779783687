import React, { forwardRef, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'core/components';
import { convertNormalDate } from 'core/utils';

import './style.scss';

const Calendar = forwardRef(function Calendar(props, ref) {
	const [dayName] = useState(['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']);
	const [monthName] = useState([
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	]);
	const [year, setYear] = useState(new Date().getFullYear());
	const [month, setMonth] = useState(new Date().getMonth());
	const [getDay, setGetDay] = useState('');
	const [isView, setIsView] = useState(false);

	const calendarRef = useRef(null);

	const dateFieldProps = {
		autoComplete: 'off',
		fullWidth: true,
	};

	const calendarRenderHelper = (year, month) => {
		let day;
		if (year === null && month === null) {
			day = new Date();
		} else {
			day = new Date(year, month);
		}
		let firstNumberDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1).getDay();
		let lastDayOfMonth = new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate();

		return (
			<>
				<div className="calendar__month">
					<svg
						role="img"
						className="calendar__month-arrow calendar__month-arrow_prev"
						onClick={prevMonth}
					>
						<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
					</svg>
					<span>{getMonthName(day)}</span>
					<span>{year}</span>
					<svg
						role="img"
						className="calendar__month-arrow calendar__month-arrow_next"
						onClick={nextMonth}
					>
						<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
					</svg>
				</div>
				<div className="calendar__days">{renderCalendarDays()}</div>
				<div className="calendar__body">
					{renderCalendarBody(lastDayOfMonth, firstNumberDayOfMonth)}
				</div>
			</>
		);
	};

	const renderCalendarDays = () => {
		let days = [];
		dayName.forEach((item, index) => {
			days.push(
				<span key={index} className="day-item">
					{item}
				</span>
			);
		});
		return days;
	};

	const renderCalendarBody = (lastDayOfMonth, firstNumberDayOfMonth) => {
		let curYear = new Date().getFullYear();
		let curMonth = new Date().getMonth();
		let curDay = new Date().getDate();
		let days = [];
		if (firstNumberDayOfMonth === 0) {
			for (let i = 0; i <= 5; i++) {
				days.push(<span key={i} className="day-item"></span>);
			}
		} else {
			for (let i = 0; i < firstNumberDayOfMonth - 1; i++) {
				days.push(<span key={i} className="day-item"></span>);
			}
		}
		for (let i = 1; i <= lastDayOfMonth; i++) {
			let id = `${i > 9 ? i : '0' + i}.${
				month + 1 > 9 ? month + 1 : '0' + (month + 1)
			}.${year}`;
			let dateForFormat = `${year}-${month + 1 > 9 ? month + 1 : '0' + (month + 1)}-${
				i > 9 ? i : '0' + i
			}`;
			let curDate = `${curDay > 9 ? curDay : '0' + curDay}.${
				curMonth + 1 > 9 ? curMonth + 1 : '0' + (curMonth + 1)
			}.${curYear}`;
			days.push(
				<span
					key={i + 40}
					onClick={getDayHandler}
					data-date={id}
					data-dateforformat={dateForFormat}
					className={
						getDay === id
							? curDate === id
								? 'day-item day-item-cur active'
								: 'day-item active'
							: curDate === id
							? 'day-item day-item-cur'
							: 'day-item'
					}
				>
					{i}
				</span>
			);
		}
		return days;
	};

	const getMonthName = day => {
		let monthNumber = day.getMonth();
		return monthName.filter((item, index) => {
			return index === monthNumber;
		});
	};

	const nextMonth = e => {
		e.preventDefault();
		let nextMonth = new Date(year, month).getMonth() + 1;
		if (nextMonth > 11) {
			setMonth(0);
			setYear(year + 1 === new Date().getFullYear() ? new Date().getFullYear() : year + 1);
		} else {
			setMonth(nextMonth);
		}
	};

	const prevMonth = e => {
		e.preventDefault();
		let prevMonth = new Date(year, month).getMonth() - 1;
		if (prevMonth !== -1) {
			setMonth(prevMonth);
		} else {
			setMonth(11);
			setYear(year - 1 === new Date().getFullYear() ? new Date().getFullYear() : year - 1);
		}
	};

	const getDayHandler = e => {
		e.preventDefault();
		const { handler } = props;
		setGetDay(e.currentTarget.dataset.date);
		viewCalendar(e);
		if (handler) {
			props.handler(e.currentTarget.dataset.dateforformat);
		}
	};

	const inputHandler = e => {
		e.preventDefault();
		const { handler } = props;
		setGetDay(e.currentTarget.value);
		if (handler) {
			props.handler(e.currentTarget.value);
		}
	};

	const viewCalendar = e => {
		e.preventDefault();
		setIsView(!isView);
	};

	useEffect(() => {
		function handleClickOutside(e) {
			if (
				e.target &&
				calendarRef.current &&
				e.target.closest('.calendar__main') !== calendarRef.current
			)
				setIsView(false);
		}
		document.addEventListener('click', handleClickOutside, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [props]);

	useEffect(() => {
		if (props.dataCard) {
			setGetDay(convertNormalDate(props.dataCard));
		}
	}, [props.dataCard]);

	useEffect(() => {
		if (ref) {
			ref.current = setGetDay;
		}
	}, [ref]);

	return (
		<div className="calendar__main" ref={calendarRef}>
			<TextField
				label={props.label && props.label}
				inputRef={props.inputRef}
				name={props.name}
				id={props.name}
				error={props.error}
				value={getDay}
				onFocus={viewCalendar}
				onChange={
					!!props.disabled
						? e => {
								e.preventDefault();
						  }
						: inputHandler
				}
				{...dateFieldProps}
				placeholder={props.placeholder && props.placeholder}
				readOnly={props.readOnly}
			/>
			<div className={isView ? 'calendar' : 'calendar none'}>
				{calendarRenderHelper(year, month)}
			</div>
		</div>
	);
});

Calendar.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	handler: PropTypes.func,
};

Calendar.defaultProps = {
	required: false,
};

export default Calendar;
