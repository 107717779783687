import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Col } from '../index';

import './style.scss';

export const HeaderImg = ({ img, title, cssClass }) => {
	return (
		<div className={cssClass ? `page-upper ${cssClass}` : 'page-upper'}>
			<img src={process.env.PUBLIC_URL + `/img/${img}`} alt="" />

			<Grid>
				<Col>
					<p className="page-title">{title}</p>
				</Col>
			</Grid>
		</div>
	);
};

HeaderImg.propTypes = {
	img: PropTypes.string.isRequired,
	title: PropTypes.string,
};
