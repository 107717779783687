import {
	COMMENTS_EDIT_STATUS,
	HIDE_COMMENTS
} from '../constant'

const DefaultState = true

export default function commentsEditStatus(state = DefaultState, action) {
    switch (action.type) {
        case COMMENTS_EDIT_STATUS:
			return action.status

		case HIDE_COMMENTS:
			return true;

        default:
            return state
    }
}