import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setNotification } from '../../action/notification';

import './style.scss';

class Notification extends Component {
	render() {
		let { notification } = this.props;
		return (
			<div
				className={
					notification.isOpen
						? notification.status === 0
							? 'notification__msg notification__msg_success radius'
							: 'notification__msg notification__msg_error radius'
						: 'notification__msg'
				}
			>
				<span>{notification.text}</span>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		notification: state.notification,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setNotification: notification => {
			dispatch(setNotification(notification));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
