import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { IProps } from './types';

import './style.scss';

export const AreaField: FC<IProps> = ({
	id = '',
	label,
	register,
	name,
	defaultValue = '',
	error,
	helperText,
	maxLength,
	placeholder,
	onChange,
	disabled = false,
}) => {
	const areaFieldRef = useRef<HTMLTextAreaElement>();
	const [value, setValue] = useState('');
	const [length, setLength] = useState(0);

	const autoGrow = (element: HTMLTextAreaElement) => {
		element.style.height = 'auto';
		element.style.height = element.scrollHeight + 'px';
	};

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue);
			setLength(defaultValue.length);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (areaFieldRef.current) autoGrow(areaFieldRef.current);
	}, [value]);

	const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		let { value } = event.currentTarget;

		if (maxLength) {
			if (value.length > maxLength) {
				value = value.slice(0, maxLength);
			}

			setLength(value.length);
		}

		if (onChange) onChange(value);
		setValue(value);
	};

	return (
		<div className={classNames('area-field', { error })}>
			{label && <p className="area-field__label">{label}</p>}

			<div className="area-field__wrap">
				<textarea
					ref={(el: HTMLTextAreaElement) => {
						if (register) register(el);
						areaFieldRef.current = el;
					}}
					name={name}
					className="area-field__input"
					placeholder={placeholder}
					value={value}
					onChange={onChangeHandler}
					rows={1}
					disabled={disabled}
					id={id}
				/>

				<div className="area-field__border"></div>

				{maxLength && (
					<div className="area-field-counter">
						<span className="area-field-counter__current">{length}</span>
						<span className="area-field-counter__separator">/</span>
						<span className="area-field-counter__max">{maxLength}</span>
					</div>
				)}
			</div>

			{helperText && <div className="area-field__helper">{helperText}</div>}
		</div>
	);
};
