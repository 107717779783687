import {
    forwardRef,
    useState,
    useEffect,
    useLayoutEffect,
    isValidElement,
    cloneElement
} from 'react'
import ReactDOM from 'react-dom'
import useForkRef from 'core/utils/useForkRef'
import setRef from 'core/utils/setRef'

function getContainer(container) {
    container = typeof container === 'function' ? container() : container
    return ReactDOM.findDOMNode(container)
}

const useEnhancedEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect

const Portal = forwardRef(function Portal(props, ref) {
    const { children, container, disablePortal = false, onRendered } = props
    const [mountNode, setMountNode] = useState(null)
    const handleRef = useForkRef(isValidElement(children) ? children.ref : null, ref)

    useEnhancedEffect(() => {
        if (!disablePortal) {
            setMountNode(getContainer(container) || document.body)
        }
    }, [container, disablePortal])

    useEnhancedEffect(() => {
        if (mountNode && !disablePortal) {
            setRef(ref, mountNode)
            return () => {
                setRef(ref, null)
            }
        }

        return undefined
    }, [ref, mountNode, disablePortal])

    useEnhancedEffect(() => {
        if (onRendered && (mountNode || disablePortal)) {
          onRendered();
        }
      }, [onRendered, mountNode, disablePortal]);
    
      if (disablePortal) {
        if (isValidElement(children)) {
            return cloneElement(children, {
                ref: handleRef,
            });
        }
        return children;
    }
    
    return mountNode ? ReactDOM.createPortal(children, mountNode) : mountNode
})

export default Portal
