import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import reducer from './reducers/reducer'

const middlewares = [];
if (process.env.NODE_ENV === `development` && process.env.LOGGER === 'true') {
  const { logger } = require(`redux-logger`)
  middlewares.push(logger);
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk, ...middlewares)))

export default store
