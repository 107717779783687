import React from 'react'

import './style.scss'

export const KnowledgeTitle = ({
    title,
    children
}) => (
    <p className="knowledge-title">
        {title}
        {children && (
            <span className="knowledge-title-right-side">
                {children}
            </span>
        )}
    </p>
)