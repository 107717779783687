import { api } from './api';
import showNotification from './showNotification';

export const createPoll = answers => {
	const answersArr = Object.keys(answers);
	const newPoll = {
		answers: [],
	};
	answersArr.map(key => {
		return newPoll.answers.push(answers[key].value.trim());
	});
	return newPoll;
};

//Создание поста
export const sendPost = async (context, id) => {
	let answers;
	let newPoll;
	if (context.state.poll) {
		answers = context.state.poll.answers;
		newPoll = createPoll(answers);
		newPoll.question = context.state.poll.question.trim();
		newPoll.publicFlag = context.state.poll.publicFlag;
		newPoll.multiple = context.state.poll.multiple;

		context.props.reRender();
	}

	return api
		.post('/posts/create', {
			groupId: id,
			text: context.state.text,
			title: context.state.title,
			imageIds: context.state.imagesId,
			videoList: context.state.videoList,
			documentIds: context.state.filesId,
			articleId: context.state.articleAttach !== null ? context.state.articleAttach.id : null,
			createPollRequest: newPoll,
		})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				let pollsObj = {};
				let requestRes = response.data.response;
				if (requestRes.poll) {
					pollsObj[requestRes.poll.id] = requestRes.poll;
					requestRes.pollId = requestRes.poll.id;
				}
				context.props.addPollAction(pollsObj);
				context.props.addGroupPost(requestRes);
				context.props.clearFeedPosts();
				context.setState({
					text: '',
					images: [],
					imagesId: [],
					video: [],
					files: [],
					filesId: [],
					loading: false,
					videoModal: false,
					videoLink: '',
					validVideoLink: true,
					articles: null,
					articlesView: false,
					articleAttach: null,
					poll: null,
				});
				showNotification(context, true, 0, 'Вы опубликовали новую запись');
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
				context.setState({
					text: '',
					images: [],
					imagesId: [],
					video: [],
					files: [],
					filesId: [],
					loading: false,
					videoModal: false,
					videoLink: '',
					validVideoLink: true,
					articles: null,
					articlesView: false,
					articleAttach: null,
					poll: null,
				});
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `sendPost - ${error}`);
		});
};

export const sendDeferredPost = async (context, id, deferredPost) => {
	let answers;
	let newPoll;
	if (context.state.poll) {
		answers = context.state.poll.answers;
		newPoll = createPoll(answers);
		newPoll.question = context.state.poll.question.trim();
		newPoll.publicFlag = context.state.poll.publicFlag;
		newPoll.multiple = context.state.poll.multiple;

		context.props.reRender();
	}

	const createPost = await api.post('/posts/delayed/create', {
		groupId: id,
		text: context.state.text,
		title: context.state.title,
		imageIds: context.state.imagesId,
		videoList: context.state.videoList,
		documentIds: context.state.filesId,
		articleId: context.state.articleAttach !== null ? context.state.articleAttach.id : null,
		createPollRequest: newPoll,
		plannedPublishDate: context.state.time,
	});

	const { data } = createPost;
	if (data.responseCode === 0) {
		let pollsObj = {};
		let requestRes = data.response;
		if (requestRes.poll) {
			pollsObj[requestRes.poll.id] = requestRes.poll;
			requestRes.pollId = requestRes.poll.id;
		}
		context.props.addPollAction(pollsObj);
		deferredPost && context.props.createDeferredPost(id, requestRes);
		context.props.clearFeedPosts();
		context.setState({
			text: '',
			images: [],
			imagesId: [],
			video: [],
			files: [],
			filesId: [],
			loading: false,
			videoModal: false,
			videoLink: '',
			validVideoLink: true,
			articles: null,
			articlesView: false,
			articleAttach: null,
			poll: null,
			time: null,
		});
		showNotification(context, true, 0, 'Вы создали отложенную публикацию');
	} else {
		showNotification(context, true, 1, `${data.errorMessage}`);
	}
};

//Получить контент отдельного поста
export const getPost = (id, fromIndex) => {
	return api.get(`/posts/get`, {
		params: {
			id,
			...(fromIndex ? { fromIndex } : {}),
		},
	});
};

//Получить контент отдельного поста
export const addView = id => {
	return api.post('posts/views/add', {
		postId: +id,
	});
};

//Добавить просмотр к посту
export const viewsAdd = context => {
	api.post('posts/views/add', {
		postId: context.props.id,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				if (!context.props.spectator && !context.props.view) {
					context.props.postView(context.props.id);
				}
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `posts/views/add - ${error}`);
		});
};

//Получить один пост для редактирования
export const getPostFromEdit = (context, id = 1) => {
	api.get('/posts/get?id=' + id)
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.setState({
					title: response.data.response.title,
					textEdit: response.data.response.text,
					images: response.data.response.imageList,
					imagesId: response.data.response.imageList.map(item => {
						return item.id;
					}),
					files: response.data.response.documentList,
					filesId: response.data.response.documentList.map(item => {
						return item.id;
					}),
					video: response.data.response.videoList,
					videoList: response.data.response.videoList.map(item => item.id),
					article: response.data.response.article,
					pollId: response.data.response.poll ? response.data.response.poll.id : null,
					time: response.data.response.plannedPublishDate,
				});
			} else {
				context.setState({ post: null });
			}
		})
		.catch(function (error) {
			console.log('getPost', error);
		});
};

//Удалить пост
export const deletePost = context => {
	context.props.isEditHandler();
	api.post('/posts/delete', {
		id: context.props.id,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.deleteGroupPost(context.props.id);
				context.props.clearFeedPosts();
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/delete - ${error}`);
		});
};

//Восстановить пост
export const recoverPost = context => {
	api.post('/posts/recovery', {
		id: context.props.id,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.recoverGroupPost(response.data.response);
				showNotification(context, true, 0, 'Публикация восстановлена');
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/recovery - ${error}`);
		});
};

//Изменить пост
export const updatePost = (context, id) => {
	const { title, textEdit, images, imagesId, video, videoList, files, filesId, article, pollId } =
		context.state;
	const { updateGroupPost } = context.props;

	api.post('/posts/update', {
		id: id,
		text: textEdit,
		imageIds: imagesId,
		videoList: videoList,
		documentIds: filesId,
		articleId: article ? article.id : null,
		pollId: pollId ? pollId : null,
		title,
	})
		.then(res => {
			const { errorMessage, responseCode } = res.data;
			if (responseCode === 0) {
				updateGroupPost(id, textEdit, images, video, files, article, pollId, title);
				showNotification(context, true, 0, 'Все изменения сохранены');
			} else {
				showNotification(context, true, 1, errorMessage);
			}
		})
		.catch(error => showNotification(context, true, 1, `updatePost - ${error}`));
};

//Изменить отложенный пост
export const updateDeferredPost = (context, id, idGroup) => {
	const {
		title,
		textEdit,
		images,
		imagesId,
		video,
		videoList,
		files,
		filesId,
		article,
		pollId,
		time,
	} = context.state;
	const { editDeferredPost } = context.props;
	api.post('/posts/delayed/update', {
		id: id,
		text: textEdit,
		imageIds: imagesId,
		videoList: videoList,
		documentIds: filesId,
		articleId: article ? article.id : null,
		pollId: pollId ? pollId : null,
		title,
		plannedPublishDate: time,
	})
		.then(res => {
			const { errorMessage, responseCode } = res.data;
			if (responseCode === 0) {
				editDeferredPost(
					idGroup,
					id,
					textEdit,
					images,
					video,
					files,
					article,
					pollId,
					title,
					time
				);
				showNotification(context, true, 0, 'Все изменения сохранены');
			} else {
				showNotification(context, true, 1, errorMessage);
			}
		})
		.catch(error => showNotification(context, true, 1, `updatePost - ${error}`));
};

//Лайки поста
export const postsLikes = context => {
	api.post('/posts/likes/add-remove', {
		postId: context.props.id,
		like: !context.props.hasLike,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.postLike(context.props.id, !context.props.hasLike);
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/likes/add-remove - ${error}`);
		});
};

export const postsLikesPage = context => {
	api.post('/posts/likes/add-remove', {
		postId: context.props.postPage.id,
		like: !context.props.postPage.hasLike,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.postLike(context.props.postPage.id, !context.props.postPage.hasLike);
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/likes/add-remove - ${error}`);
		});
};

//Предложеный пост
export const sendOfferPost = (context, id) => {
	api.post('/posts/offer-post', {
		groupId: id,
		text: context.state.text,
		imageIds: context.state.imagesId,
		videoList: context.state.videoList,
		documentIds: context.state.filesId,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				showNotification(context, true, 0, 'Вы предложили новость');
				context.setState({
					text: '',
					images: [],
					imagesId: [],
					video: [],
					files: [],
					filesId: [],
					loading: false,
					videoModal: false,
					videoLink: '',
					validVideoLink: true,
				});
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
				context.setState({
					text: '',
					images: [],
					imagesId: [],
					video: [],
					files: [],
					filesId: [],
					loading: false,
					videoModal: false,
					videoLink: '',
					validVideoLink: true,
				});
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `sendPost - ${error}`);
		});
};

//Установка превью для нового видео
export const createPreviewVideo = ({
	groupId,
	videoFileId,
	previewBase64,
	successHandler,
	errorHandler,
}) => {
	api.post('/posts/create-preview-post-video', {
		groupId,
		videoFileId,
		previewBase64,
	})
		.then(response => {
			successHandler(response.data);
		})
		.catch(error => {
			errorHandler();
		});
};

//Установка превью для нового видео
export const editPreviewVideo = ({ postId, videoFileId, base64, successHandler, errorHandler }) => {
	api.post('/posts/edit-preview-post-video', {
		postId,
		videoFileId,
		base64,
	})
		.then(response => {
			successHandler(response.data);
		})
		.catch(error => {
			errorHandler();
		});
};

/**
 * @description Рест на создание ответа на опрос
 * @param { number } pollId - (int64)
 * @param { [] } answersArray - array[integer]
 * @param { function } setLoading - useState
 * @param { function } setUpdatePoll - useState
 * @param { function } setErrorUpdatePoll - useState
 */
export const createPollResponse = (
	pollId,
	answersArray,
	setLoading,
	setUpdatePoll,
	setErrorUpdatePoll
) => {
	api.post('/poll/answer', {
		pollId: +pollId,
		numbers: answersArray,
	})
		.then(response => {
			if (response.data.responseCode === 0) {
				setUpdatePoll({ pollId, answersArray });
			} else {
				setErrorUpdatePoll(response.data.errorMessage);
			}

			setLoading(false);
		})
		.catch(error => {
			console.error(`poll/answer - ${error}`);
		});
};

//Получить статистику по опросу
//id integer (int64)
export const getPollsStatistics = (setPoll, setError, id) => {
	api.get(`/poll/get?id=${id}`)
		.then(function (response) {
			if (response.data.responseCode === 0) {
				setPoll(response.data.response);
				setError(false);
			} else {
				setPoll(null);
				setError(true);
			}

			return true;
		})
		.catch(function (error) {
			console.error('/poll/get', error);
			return false;
		});
};

export const setFixed = async postId => {
	return api.post('/posts/set-fixed', { id: postId }).then(res => res.data);
};

export const getLikesList = async (id, limit) => {
	try {
		let response;
		if (limit) {
			response = await api.get(`/posts/likes/list?id=${id}&limit=${limit}`);
		} else {
			response = await api.get(`/posts/likes/list?id=${id}`);
		}
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const favoriteUpdateRest = async (objectId, favorite, type) => {
	try {
		const response = await api.post(`/favorite/update`, {
			type,
			objectId,
			favorite,
		});
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

//Добавить в избранное
export const postsFavorite = (context, id, favorite) => {
	api.post('/favorite/update', {
		type: 'POST',
		objectId: id,
		favorite: favorite,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.updateFavoriteStateAction(id, favorite);

				showNotification(
					context,
					true,
					0,
					!favorite ? 'Удалено из Избранного' : 'Добавлено в Избранное'
				);
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/likes/add-remove - ${error}`);
		});
};
