export default function formControlState({ props, states, formControl }) {
    return states.reduce((acc, state) => {
        acc[state] = props[state];
    
        if (formControl) {
            if (typeof props[state] === 'undefined') {
                acc[state] = formControl[state];
            }
        }
    
        return acc;
    }, {});
}