import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import {
	ArticleGroupCreate,
	ArticleGroupEdit,
	ArticleGroupView,
	GuideArticleCreate,
	GuideArticleView,
	GuideArticleEdit,
} from './pages';

export default class Router extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/group/:id/article/create" component={ArticleGroupCreate} />
				<Route
					exact
					path="/group/:id/article/edit/:idArticle"
					component={ArticleGroupEdit}
				/>
				<Route exact path="/group/:id/article/:idArticle" component={ArticleGroupView} />

				<Route
					exact
					path="/knowledge/guide/:id*/article/create"
					component={GuideArticleCreate}
				/>
				<Route
					exact
					path="/knowledge/guide/:id*/article/:articleId"
					component={GuideArticleView}
				/>
				<Route
					exact
					path="/knowledge/guide/:id*/article/:articleId/edit"
					component={GuideArticleEdit}
				/>
			</Switch>
		);
	}
}
