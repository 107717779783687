import React, { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationHelper } from 'action/notificationHelper';
import { getUsersFromExcel } from 'shared/api/other';
import { IUser } from '../types';

import './style.scss';

interface IProps {
	id?: string;
	label: string;
	getUsers: (users: IUser[]) => void;
}

export const ImportFromExcel = ({ getUsers, label, id }: IProps) => {
	const dispatch = useDispatch();

	const uploadExcel = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = (event.target as HTMLInputElement).files;

		if (files) {
			const reader = new FileReader();
			reader.onload = async () => {
				if (!reader.result) return;
				const data = {
					name: files[0].name,
					base64: reader.result.toString().replace(/^data:(.*,)?/, ''),
				};

				const { response, responseCode, errorMessage } = await getUsersFromExcel(data);

				if (responseCode) {
					dispatch(NotificationHelper(errorMessage, responseCode));
				} else {
					getUsers(response);
				}
			};
			reader.readAsDataURL(files[0]);
		}
	};

	return (
		<div className="import-from-excel-wrap">
			<label className="import-from-excel">
				<input
					id={id}
					type="file"
					className="import-from-excel__input"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					onChange={uploadExcel}
				/>
				<span className="import-from-excel__text">{label}</span>
			</label>
		</div>
	);
};
