import {
    GET_USER_STIMULATION_LIST
} from '../constant'

const DefaultState = []

export default function stimulation(state = DefaultState, action) {

    switch (action.type) {
        case GET_USER_STIMULATION_LIST:
            return [...action.stimulation]

            default:
                return state
    }

}