import React, { FC, useCallback } from 'react';

import { SurveyTextField } from '../fields';
import { IQuestionComponentProps } from '../types';
import { useDispatch } from 'react-redux';
import { updateQuestionTitleAction } from 'action/survey';

export const TextQuestion: FC<IQuestionComponentProps> = ({ questionSchema, questionIndex }) => {
	const dispatch = useDispatch();

	const onChangeTitleHandler = useCallback(
		(value: string) => {
			questionSchema.title = value;
			dispatch(updateQuestionTitleAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	return (
		<div className="survey-question-body">
			<SurveyTextField
				error={!questionSchema.title}
				name={`questions[${questionIndex}].text`}
				label="Вопрос"
				placeholder="Введите текст вопроса"
				onChange={onChangeTitleHandler}
				maxLength={1000}
				defaultValue={questionSchema.title}
			/>
		</div>
	);
};
