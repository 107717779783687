import React, { forwardRef, useState, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import useForkRef from 'core/utils/useForkRef';

import * as Icon from 'react-feather';
import FormLabel from '../formLabel';

import './style.scss';

const SelectField = forwardRef(function SelectField(props, ref) {
	const {
		className,
		defaultValue,
		label,
		getOptionLabel = option => option.label,
		options = [],
		onChange,
		value,
		error,
		name,
		id,
		...other
	} = props;

	const [opened, setOpened] = useState(false);
	const [currentOption, setCurrentOption] = useState(defaultValue);

	const rootRef = useRef(null);
	const handleRootRef = useForkRef(rootRef, ref);

	const handleOutsideClick = event => {
		const target = event.target;
		const root = rootRef.current;
		if (root && target !== root && !root.contains(target)) {
			setOpened(false);
		}
	};

	useEffect(() => {
		if (opened) {
			window.addEventListener('click', handleOutsideClick);
		}
		return () => {
			window.addEventListener('click', handleOutsideClick);
		};
	}, [opened]);

	useEffect(() => {
		if (value === 0) {
			setCurrentOption(options[0]);
		}
	}, [value, options]);

	const handleControlClick = useCallback(() => {
		setOpened(!opened);
	}, [opened]);

	const handleOptionClick = option => () => {
		setCurrentOption(option);
		onChange(option, name);
		setOpened(false);
	};

	return (
		<div
			className={classNames('select-field', { 'select-field_opened': opened }, className)}
			ref={handleRootRef}
			{...other}
		>
			<div className="select-field__container">
				{label && <FormLabel>{label}</FormLabel>}
				<div className="select-field__control" onClick={handleControlClick} id={id}>
					<span
						className="select-field__text"
						title={currentOption ? currentOption.label : ''}
					>
						{currentOption && getOptionLabel(currentOption)}
					</span>
					<div
						className={
							error
								? 'select-field__line select-field__line_error'
								: 'select-field__line'
						}
					/>
					<Icon.ChevronDown className="select-field__arrow-icon" size={14} />
				</div>
			</div>
			{opened && (
				<div className="select-field__dropdown">
					{options.map((option, index) => (
						<div
							className="select-field__option"
							onClick={handleOptionClick(option)}
							tabIndex="-1"
							key={index}
						>
							<span
								className="select-field__option-label"
								title={getOptionLabel(option)}
							>
								{getOptionLabel(option)}
							</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
});

export default SelectField;
