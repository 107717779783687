import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export const CustomScrollbars = ({ children }) => {
	return (
		<Scrollbars
			autoHide={true}
			hideTracksWhenNotNeeded={true}
			renderTrackHorizontal={props => (
				<div {...props} className="custom-scrollbar__track-horizontal" />
			)}
			renderTrackVertical={props => (
				<div {...props} className="custom-scrollbar__track-vertical" />
			)}
			renderThumbHorizontal={props => (
				<div {...props} className="custom-scrollbar__thumb-horizontal" />
			)}
			renderThumbVertical={props => (
				<div {...props} className="custom-scrollbar__thumb-vertical" />
			)}
			renderView={props => <div {...props} className="custom-scrollbar__view" />}
		>
			{children}
		</Scrollbars>
	);
};
