import { api } from 'rest/api';
import {
	IAnswerPulseSurveyRest,
	IAnswerSurveyQuestionRequest,
	ICreatePulseSurveyResponse,
	IFileSave,
	IGetPulseSurveyForUserResponse,
	IGetPulseSurveyListResponse,
	IGetPulseSurveyListRest,
	IGetPulseSurveyResponse,
} from './types';
import { IPulseSurveyDataForRHF, IPulseSurveyEdit } from 'entities/survey/pulseSurveyTypes';
import { IResponse } from 'shared/ui/types';

function createError(error: any, defaultValue: any = null) {
	return {
		responseCode: 1,
		errorMessage: error,
		response: defaultValue,
	};
}

export const fileSave = async (data: IFileSave) => {
	try {
		const response = await api.post(`/file/save`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const createSurveyRest = async (data: any) => {
	try {
		const response = await api.post(`/services/survey/create`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const updateSurveyRest = async (data: any) => {
	try {
		const response = await api.post(`/services/survey/update`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getSurveyForAdminRest = async (id: number) => {
	try {
		const response = await api.get(`/services/survey/get-for-admin`, { params: { id } });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getSurveyListRest = async () => {
	try {
		const response = await api.get(`/services/survey/get-list`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getSurveyListArchiveRest = async () => {
	try {
		const response = await api.get(`/services/survey/get-archive-list`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const toArchiveRest = async (id: number) => {
	try {
		const response = await api.post(`/services/survey/to-archive`, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getNextQuestionRest = async (surveyId: number, currentQuestionId?: number) => {
	try {
		const response = await api.get(`/services/survey/get-next-question`, {
			params: {
				surveyId,
				currentQuestionId,
			},
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getPrevQuestionRest = async (surveyId: number, currentQuestionId?: number) => {
	try {
		const response = await api.get(`/services/survey/get-previous-question`, {
			params: {
				surveyId,
				currentQuestionId,
			},
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const answerQuestionRest = async (data: IAnswerSurveyQuestionRequest) => {
	try {
		const response = await api.post(`/services/survey/answer`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const completeQuestionRest = async (id: number) => {
	try {
		const response = await api.post(`/services/survey/complete`, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getAnaliticsSurveyRest = async (surveyId: number) => {
	try {
		const response = await api.get(`/services/survey/analytics`, { params: { surveyId } });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const exportExcelRest = async (surveyId: number) => {
	try {
		const response = await api.get(`/services/survey/export-answers`, { params: { surveyId } });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const isAvailableSurveyRest = async () => {
	try {
		const response = await api.get(`/services/survey/is-available`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getSpecialSurveyIdRest = async () => {
	try {
		const response = await api.get(`/services/survey/get-special-survey`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const cleanResultSurveyRest = async (id: number) => {
	try {
		const response = await api.post(`/services/survey/clean-result`, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const createPulseSurveyRest = async (
	data: IPulseSurveyDataForRHF
): Promise<ICreatePulseSurveyResponse> => {
	try {
		const response = await api.post(`/services/survey/pulse/create`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const editPulseSurveyRest = async (
	data: IPulseSurveyEdit
): Promise<ICreatePulseSurveyResponse> => {
	try {
		const response = await api.post(`/services/survey/pulse/update`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getPulseSurveyListRest = async (
	data: IGetPulseSurveyListRest
): Promise<IGetPulseSurveyListResponse> => {
	try {
		const response = await api.get(`/services/survey/pulse/get-list`, {
			params: data,
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getPulseSurveyRest = async (id: string): Promise<IGetPulseSurveyResponse> => {
	try {
		const response = await api.get(`/services/survey/pulse/get-for-admin`, {
			params: { id },
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const finishPulseSurveyRest = async (id: number): Promise<IResponse> => {
	try {
		const response = await api.post(`/services/survey/pulse/finish`, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getPulseSurveyForUserRest = async (): Promise<IGetPulseSurveyForUserResponse> => {
	try {
		const response = await api.get(`/services/survey/pulse/get`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const answerPulseSurveyRest = async (data: IAnswerPulseSurveyRest): Promise<IResponse> => {
	try {
		const response = await api.post(`/services/survey/pulse/answer`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const deletePulseSurveyRest = async (id: number): Promise<IResponse> => {
	try {
		const response = await api.post(`/services/survey/pulse/delete`, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};
