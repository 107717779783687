import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

const ToggleButton = ({ label, status, handler, stateName, disabled }) => {
    return (
        <div className="MIToggle" onClick={!disabled ? handler : null} id={stateName}>
            <span className={`MIToggle__switch${status ? " MIToggle__switch_active" : ""}${disabled ? " MIToggle__switch_disabled" : ""}`}></span>
            <span className={`MIToggle__label${disabled ? " MIToggle__label_disabled" : ""}`}>{label}</span>
        </div>
    )
}

ToggleButton.propTypes = {
    label: PropTypes.string,
    status: PropTypes.bool,
    handler: PropTypes.func,
    stateName: PropTypes.string,
    disabled: PropTypes.bool
}

ToggleButton.defaultProps = {
    label: '',
    status: false,
    handler: e => e.preventDefault(),
    stateName: '',
    disabled: false
}

export default ToggleButton

