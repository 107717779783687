import React, { useState, useEffect, createContext, useContext } from 'react'

import { getLikesList as getCommentLikesList } from 'rest/comments'
import { getLikesList  as getPostLikesList} from 'rest/post'

import Modal from 'components/like/Modal'

const RestAdapters = {
    'post': getPostLikesList,
    'comment': getCommentLikesList
}

const LikePostContext = createContext(null)

const LikePostContextProvider = ({children}) => {

    const [isOpen, toggleIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [likeType, setLikeType] = useState(null)
    const [id, setId] = useState(null)

    const [users, setUsers] = useState([])

    const onCloseHandler = () => {
        toggleIsOpen(false)
        setLikeType(null)
        setId(null)
    }

    const setLikeTypeHandler = (type, id) => {
        setLikeType(type)
        setId(id)
    }

    useEffect(() => {
        async function getUsersHandelr(likeType, id) {
            const rest = RestAdapters[likeType]
            if(rest) {
                const response = await rest(id)
                if(response.responseCode === 0) {
                    setUsers(response.response.userList)
                    setIsLoading(false)
                }
            }
        }

        if(likeType && id) {
            toggleIsOpen(true)
            getUsersHandelr(likeType, id)
        } else {
            setId(null)
            setUsers([])
            setLikeType(null)
            setIsLoading(true)
        }
    }, [likeType, id])

    return (
        <LikePostContext.Provider value={{
            setLikeType: setLikeTypeHandler
        }}>
            {isOpen && <Modal 
                onClose={onCloseHandler}
                users={users}
                isLoading={isLoading}
            />}
            {children}
        </LikePostContext.Provider>
    )
}

export default LikePostContextProvider

export const useLikePostContext = () => {
    const context = useContext(LikePostContext)
    if (context === undefined) {
        throw new Error('useLikePostContext must be used within a LikePostContextProvider');
    }
    return context
}

