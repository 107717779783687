import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { URL, URL_AUTH } from '../constant';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { getExpiresCookie } from 'plugins/token';
import { getShortUserInfo } from './claims';
import { setShortUserInfo } from 'action/user';
import Store from '../store';

const headersData = {
	Accept: 'application/json',
	'Content-Type': 'application/json',

	...(process.env.NODE_ENV === 'development'
		? { 'Access-Control-Allow-Origin': 'maxitest', 'X-Dev-Mode': 'LOCAL' }
		: null),
};

export const api = axios.create({
	baseURL: URL,
	withCredentials: true,
	headers: headersData,
});

api.interceptors.request.use(
	config => {
		let headers = config.headers || {};
		const { accessToken } = parseCookies();
		if (accessToken) {
			headers = {
				...headers,
				Authorization: `Bearer ${accessToken}`,
			};
		}
		return {
			...config,
			headers,
		};
	},
	error => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	res => res,
	error => {
		if (error.response.status === 404) {
			window.location.replace(`/404`);
		}

		return Promise.reject(error);
	}
);

const reShortUserInfo = async () => {
	const { response, responseCode } = await getShortUserInfo();

	if (!responseCode) {
		Store.dispatch(setShortUserInfo(response));
	}
};

const refreshTokenLogic = async failedRequest => {
	const { accessToken } = parseCookies();
	if (accessToken) {
		try {
			const response = await axios.post(
				'/auth/refresh',
				{ accessToken: accessToken },
				{
					headers: {
						...headersData,
					},
					baseURL: URL_AUTH,
				}
			);
			if (response.status === 200) {
				if (response.data.responseCode === 0) {
					destroyCookie(undefined, 'accessToken');

					response.data.response &&
						setCookie(null, 'accessToken', response.data.response, {
							expires: getExpiresCookie(),
							path: '/',
						});
					failedRequest.response.config.headers['Authorization'] =
						'Bearer ' + response.data.response;

					reShortUserInfo();

					return Promise.resolve();
				} else {
					console.log('Удаляем куку и ведем на страницу /login: ', response.data);
					destroyCookie(undefined, 'accessToken');
					window.location.replace(`/login`);
				}
			} else {
				console.log('Статус отличается от 200: ', response.status);
				window.location.replace(`/offline.html`);
			}
		} catch (error) {
			console.log('Что-то пошло не так, сработал блок catch: ', error);
			window.location.replace(`/offline.html`);
		}
	} else {
		window.location.replace(`/login`);
	}
};

createAuthRefreshInterceptor(api, refreshTokenLogic, {
	statusCodes: [401, 403],
	interceptNetworkError: true,
});
