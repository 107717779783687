import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Bell } from 'react-feather';

import { Loader } from 'core/components';

import { getLastNotificationsInHeader } from 'action/notification';
import NotificationView from 'components/notificationView';
import { UserNotificationView } from 'core/components/notifications';

import './style.scss';

export const HeaderNtf = () => {
	const dispatch = useDispatch();
	const { shortUserInfo, notifications, isLoading } = useSelector(state => {
		return {
			shortUserInfo: state.shortUserInfo,
			notifications: state.userNotification.last,
			isLoading: state.userNotification.isLoading,
		};
	});

	const renderNotificationList = () => {
		if (isLoading) {
			return (
				<span className="notification__item notification__item_noitem">
					<Loader />
				</span>
			);
		} else if (!isLoading && !!notifications.length) {
			return notifications.map(notification => (
				<NotificationView key={notification.id} notification={notification} />
			));
		} else {
			return (
				<span className="notification__item notification__item_noitem">
					Новых уведомлений нет
				</span>
			);
		}
	};

	useEffect(() => {
		dispatch(getLastNotificationsInHeader());
	}, [dispatch]);

	return (
		<div>
			{renderNotificationList()}

			<div className="notification-buttons">
				<Link
					to={`/user/${shortUserInfo.id}/notification`}
					className="user-notification-all"
				>
					<Bell />
					Все уведомления
				</Link>
				{!!notifications.length && <UserNotificationView />}
			</div>
		</div>
	);
};
