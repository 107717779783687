import {
	SET_SEARCH_RESULT,
	SET_SEARCH_RESULT_CATEGORY,
	CLEAR_SEARCH_RESULT,
	CLEAR_SEARCH_RESULT_PAGE,
	PUSH_SEARCH_RESULT_CATEGORY,
} from 'constant';

import { globalSearch, globalSearchContacts, globalSearchWithPagination } from 'rest/search';

import { NotificationHelper } from './notificationHelper';

export const search = data => {
	return dispatch => {
		return globalSearch(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SET_SEARCH_RESULT,
						result: res.data.response,
					});
				} else {
					dispatch(
						NotificationHelper(
							`Ошибка поиска: ${res.data.errorMessage}`,
							res.data.responseCode
						)
					);
				}
			})
			.catch(err => {
				dispatch(NotificationHelper(`Ошибка поиска`, 1));
			});
	};
};

export const categorySearch = data => {
	return dispatch => {
		const rest =
			data.indexName === 'CONTACT' ? globalSearchContacts : globalSearchWithPagination;
		return rest(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SET_SEARCH_RESULT_CATEGORY,
						result: res.data.response,
					});
				} else {
					dispatch(
						NotificationHelper(
							`Ошибка поиска по категории: ${res.data.errorMessage}`,
							res.data.responseCode
						)
					);
				}
			})
			.catch(err => {
				dispatch(NotificationHelper(`Ошибка поиска по категории`, 1));
			});
	};
};

export const nextPageSearch = data => {
	return dispatch => {
		const rest =
			data.indexName === 'CONTACT' ? globalSearchContacts : globalSearchWithPagination;
		return rest(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: PUSH_SEARCH_RESULT_CATEGORY,
						result: res.data.response,
					});
				} else {
					dispatch(
						NotificationHelper(
							`Ошибка поиска по категории: ${res.data.errorMessage}`,
							res.data.responseCode
						)
					);
				}
			})
			.catch(err => {
				dispatch(NotificationHelper(`Ошибка поиска по категории`, 1));
			});
	};
};

export const clearSearch = () => {
	return {
		type: CLEAR_SEARCH_RESULT,
	};
};

export const clearSearchPage = () => {
	return {
		type: CLEAR_SEARCH_RESULT_PAGE,
	};
};
