import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { URL } from 'constant';

import { Avatar } from 'core/components';

import './style.scss';

const FormUserBlock = ({ id, avatarFile, surname = '', name, middlename = '', position }) => {
	return (
		<Link to={`/user/${id}`} className="form-user-block">
			<Avatar
				type="user"
				size="small"
				image={
					avatarFile !== null && !!avatarFile.filePath ? URL + avatarFile.filePath : null
				}
			/>

			<div className="form-user-block__text">
				<p className="form-user-block__name">{`${surname} ${name} ${middlename}`}</p>
				<span className="form-user-block__position">{`${position}`}</span>
			</div>
		</Link>
	);
};

FormUserBlock.propTypes = {
	id: PropTypes.number,
	avatarFile: PropTypes.object,
	surname: PropTypes.string,
	name: PropTypes.string,
	middlename: PropTypes.string,
	position: PropTypes.string,
};

FormUserBlock.defaultProps = {
	avatarFile: null,
};

export default FormUserBlock;
