import { RecoverPhoneForm } from 'feature/auth-recover';
import React from 'react';
import Helmet from 'react-helmet';

import '../style.scss';
import { useAuthCheck } from '../useAuthCheck';

const Recover = () => {
	useAuthCheck();
	return (
		<>
			<Helmet title="Восстановление пароля" />
			<div className="auth-page">
				<RecoverPhoneForm />
			</div>
		</>
	);
};

export default Recover;
