import React, { useRef, useCallback, useEffect } from 'react';
import * as Icon from 'react-feather';

import { Button } from 'core/components';
import { bodyFixPosition, bodyUnfixPosition } from 'core/utils';
import useClickOutside from 'hooks/useClickOutside';

import './style.scss';

export const EditArticleModal = ({ closeModal, text, title, answerHandler }) => {
	const modalRef = useRef(null);
	useClickOutside(modalRef, closeModal);

	const keyHandler = useCallback(
		e => {
			if (e.key === 'Escape') {
				closeModal();
			}
		},
		[closeModal]
	);

	useEffect(() => {
		bodyFixPosition();
		window.addEventListener('keyup', keyHandler);

		return () => {
			bodyUnfixPosition();
			window.removeEventListener('keyup', keyHandler);
		};
	}, [keyHandler]);

	const acceptNotifications = e => {
		e.preventDefault();
		answerHandler(true);
	};

	const dismissNotifications = e => {
		e.preventDefault();
		answerHandler(false);
	};

	return (
		<div className="guide-edit__modal">
			<Icon.X className="guide-edit__modal-close" size="32" onClick={closeModal} />

			<div
				className="guide-edit__modal-view"
				ref={modalRef}
				onClick={e => {
					e.stopPropagation();
				}}
			>
				<p className="guide-edit__modal-title">{title}</p>
				<span
					className="guide-edit__modal-text"
					dangerouslySetInnerHTML={{ __html: text }}
				></span>

				<div className="guide-edit__modal-btns">
					<Button
						type="primary"
						typeButton="button"
						text="Да"
						handler={acceptNotifications}
						image={null}
					/>

					<Button
						type="secondary"
						typeButton="button"
						text="Нет"
						handler={dismissNotifications}
						image={null}
					/>
				</div>
			</div>
		</div>
	);
};
