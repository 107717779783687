import { api } from './api';
import { VACANCIES_COUNT_ON_PAGE } from '../constant';

//Создание вакансии
//contactId	integer (int64)
//position string
//requirements string
//conditions string (необязат.)
//responsibility string
//wage string (необязат.)
//wage2 string (необязат.)
//cityId integer (int64)
//sector string
//level string
//hot boolean
//state string
export const createVacancy = data => {
	return api.post('/vacancies/create', {
		...data,
	});
};

//Получение списка городов, в которых есть вакансии
export const getVacanciesCitiesList = () => {
	return api.get('/vacancies/cities');
};

//Получение списка вакансий
export const getVacanciesList = vacanciesListParams => {
	let { cityId, sector, level, state, page, onlyMy } = vacanciesListParams;
	return api.get('/vacancies/list', {
		params: {
			cityId: +cityId,
			sector: sector,
			level: level,
			state: state,
			pageNumber: +page,
			pageSize: VACANCIES_COUNT_ON_PAGE,
			onlyMy,
		},
	});
};

//Получение отдельной вакансии
export const getVacancyInfo = id => {
	return api.get(`/vacancies/get?id=${id}`);
};

//Редактирование вакансии
export const editVacancy = async data => {
	try {
		const response = await api.post(`/vacancies/update`, data);
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

//Форма "Откликнуться самому"
export const vacanciesReplyInternalUser = data => {
	return api.post('/vacancies/reply-internal-user', {
		claimId: +data.claimId,
		telephone: data.telephone,
		email: data.email,
		info: data.info,
		resumeId: +data.resumeId,
	});
};

//Форма "Предложить кандидата"
export const vacanciesReply = data => {
	return api.post('/vacancies/reply', {
		claimId: +data.claimId,
		fullName: data.fullName,
		telephone: data.telephone,
		email: data.email,
		info: data.info,
		resumeId: +data.resumeId,
	});
};

export const getQuantityVacancy = async () => {
	try {
		const response = await api.get(`/vacancies/total`);
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};
