import {
	SET_FAQ_CAT_INFO,
	SET_FAQ_CATS_LIST,
	ADD_FAQ_QUESTION,
	UPDATE_FAQ_QUESTION,
	DELETE_FAQ_QUESTION,
	RECOVER_FAQ_QUESTION,
	SET_FAQ_QUESTIONS_LIST,
	SET_FAQ_UPDATE_CAT_PARAM,
	SET_CATEGORIES_LIST_FROM_FAQ,
	CLEAR_CATEGORIES_LIST_FROM_FAQ,
	SORT_FAQ_LIST,
} from 'constant';

const DefaultState = {
	categoryInfo: null,
	catsList: null,
	categoryQuestions: null,
	categoriesListFromFaq: null,
};

export default function faq(state = DefaultState, action) {
	switch (action.type) {
		case SET_FAQ_CAT_INFO:
			return {
				...state,
				categoryInfo: action.info,
			};

		case SET_FAQ_CATS_LIST:
			return {
				...state,
				catsList: action.list,
			};

		case ADD_FAQ_QUESTION:
			let addNewQuestions = state;
			addNewQuestions.categoryQuestions.questions = [
				action.question,
				...addNewQuestions.categoryQuestions.questions,
			];
			return addNewQuestions;

		case UPDATE_FAQ_QUESTION:
			let updateFaqQuestion = state;
			updateFaqQuestion.categoryQuestions.questions = updateFaqQuestion.categoryQuestions.questions.map(
				item => {
					if (+item.id === +action.question.id) {
						item = action.question;
					}
					return item;
				}
			);
			return updateFaqQuestion;

		case DELETE_FAQ_QUESTION:
			let deleteFaqQuestion = state;
			deleteFaqQuestion.categoryQuestions.questions = deleteFaqQuestion.categoryQuestions.questions.map(
				item => {
					if (+item.id === +action.id) {
						item.isDelete = true;
						return item;
					} else {
						return item;
					}
				}
			);
			return deleteFaqQuestion;

		case RECOVER_FAQ_QUESTION:
			let recoverFaqQuestion = state;
			recoverFaqQuestion.categoryQuestions.questions = recoverFaqQuestion.categoryQuestions.questions.map(
				item => {
					if (+item.id === +action.question.id) {
						item = action.question;
						return item;
					} else {
						return item;
					}
				}
			);
			return recoverFaqQuestion;

		case SET_FAQ_QUESTIONS_LIST:
			return {
				...state,
				categoryQuestions: action.info,
			};

		case SET_FAQ_UPDATE_CAT_PARAM:
			let updateFaqCatParam = state;
			updateFaqCatParam.categoryInfo.admins = updateFaqCatParam.categoryInfo.admins.filter(
				item => {
					return item.id !== +action.id;
				}
			);
			return updateFaqCatParam;

		case SET_CATEGORIES_LIST_FROM_FAQ:
			return {
				...state,
				categoriesListFromFaq: action.categories,
			};

		case CLEAR_CATEGORIES_LIST_FROM_FAQ:
			return {
				...state,
				categoriesListFromFaq: null,
			};

		case SORT_FAQ_LIST:
			const list = state.categoryQuestions.questions;
			const newList =
				action.dropIndex < action.dragIndex
					? [
							...list.filter((_item, index) => index < action.dropIndex),
							list[action.dragIndex],
							...list.filter(
								(_item, index) =>
									index >= action.dropIndex && index !== action.dragIndex
							),
					  ]
					: action.dropIndex > action.dragIndex
					? [
							...list.filter(
								(_item, index) =>
									index <= action.dropIndex && index !== action.dragIndex
							),
							list[action.dragIndex],
							...list.filter((_item, index) => index > action.dropIndex),
					  ]
					: list;
			return {
				...state,
				categoryQuestions: {
					...state.categoryQuestions,
					questions: newList,
				},
			};

		default:
			return state;
	}
}
