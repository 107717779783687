import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Editor } from 'components/editorjsText';
import { useParams } from 'react-router';

import { Select, Button, Datepicker, FormInputBlock } from 'core/components';

import Notification from 'modules/notification';
import { NotificationHelper } from 'action/notificationHelper';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { Text, HeaderWrapp } from '../../atoms/header';

import { createDocument } from 'rest/documents';
import { getWikiDocumentsListRest } from 'shared/api/documents';

const DocumentCreate = props => {
	const { themeId } = useParams();
	const [name, setName] = useState('');
	const [content, setContent] = useState('');
	const [startDate, setStartDate] = useState('');
	const [startDateValid, setStartDateValid] = useState(true);
	const [endDate, setEndDate] = useState('');
	const [options, setOptions] = useState([]);
	const [option, setOption] = useState(themeId);

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			const { response, responseCode, errorMessage } = await getWikiDocumentsListRest();

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				setOptions(response.map(item => ({ value: item.id, label: item.title })));
			}
		})();
	}, [dispatch]);

	const nameHandler = e => {
		e.currentTarget.classList.remove('validation-error');
		setName(e.currentTarget.value);
	};

	const toogleErrorClass = status => {
		const node = document.querySelector('.article-name');
		if (status) {
			node.classList.add('validation-error');
		} else {
			node.classList.remove('validation-error');
		}
	};

	const isValid = () => {
		const result = name.replace(/\s/g, '').length !== 0 && name.length <= 255;
		toogleErrorClass(!result);

		const dateValid = !!startDate;
		setStartDateValid(!!startDate);

		return result && dateValid;
	};

	const createDocumentHandler = async () => {
		if (isValid()) {
			if (content && content !== '') {
				const res = await createDocument({
					name,
					content,
					themeId: option,
					startDate,
					endDate,
				});

				if (res.data.responseCode === 0) {
					props.history.push(`/knowledge/documents/theme/${themeId}`);
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			} else {
				dispatch(NotificationHelper('Необходимо указать содержание статьи', 1));
			}
		}
	};

	const onChangeType = id => {
		setOption(id);
	};

	return (
		<div className="Article-create">
			<Helmet title="Создание документа" />

			<Notification />

			<Header>
				<HeaderWrapp>
					<Text type="bold">Создание документа</Text>
					<div className="Aheader-block">
						<Button
							type="primary"
							typeButton="button"
							text="Опубликовать"
							handler={createDocumentHandler}
							image={null}
						/>
						<Link
							to={`/knowledge/documents/theme/${themeId}`}
							className="Aheader-btn Aheader-btn_special"
						>
							Отменить
						</Link>
					</div>
				</HeaderWrapp>
			</Header>
			<Wrapp>
				<div className="article-form article-form_create">
					<input
						type="text"
						className="article-name"
						onChange={nameHandler}
						placeholder="Название документа"
						value={name}
					/>
				</div>

				<div className="article-form-date">
					<FormInputBlock>
						<label className="form-label">Дата начала</label>
						<Datepicker
							error={!startDateValid}
							onChange={data => setStartDate(data)}
							name="startDate"
							placeholder="Выберите дату"
						/>
					</FormInputBlock>

					<FormInputBlock>
						<label className="form-label">Дата окончания</label>
						<Datepicker
							onChange={data => setEndDate(data)}
							name="endDate"
							placeholder="Выберите дату"
						/>
					</FormInputBlock>
				</div>

				<FormInputBlock>
					<label className="form-label">Тема</label>
					<Select options={options} defaultValue={+themeId} onChange={onChangeType} />
				</FormInputBlock>

				<Editor getContent={setContent} />
			</Wrapp>
		</div>
	);
};

export default DocumentCreate;
