import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import './style.scss';

export const CategoriesList = withRouter(({ categoriesList, location }) => {
	if (categoriesList.length > 0) {
		const { state } = location;
		return (
			<div className="guide-main__categories-list">
				{categoriesList.map(item => (
					<Link
						to={{
							pathname: `/knowledge/guide/${item.id}`,
							state: {
								...state,
								back: -1,
							},
						}}
						className="categories-list__item"
						key={item.id}
					>
						{item.name}
						{item.hasChildren && (
							<svg role="img">
								<use
									xlinkHref={
										process.env.PUBLIC_URL + '/img/sprite.svg#bold-mini-arrow'
									}
								/>
							</svg>
						)}
					</Link>
				))}
			</div>
		);
	} else {
		return (
			<div className="guide-main__categories-list">
				<div className="categories-list__no-items">
					<svg role="img">
						<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#no-items'} />
					</svg>
					<p>Скоро здесь появятся разделы</p>
				</div>
			</div>
		);
	}
});
