import { TIME_NOTIFICATION } from '../constant'

const showNotification = (context, isOpen, status, text) => {
	const { setNotification } = context.props;
	if (setNotification) {
		setNotification({
			isOpen: isOpen,
			status: status,
			text: text
		})
		setTimeout(() => {
			setNotification({
				isOpen: false,
				status: 0,
				text: ''
			})
		}, TIME_NOTIFICATION);
	}
}

export default showNotification;