import React from 'react';
import { Col, Grid } from 'core/components';

import { Help, FavoritesLink } from './atoms';
import { NavigationMenu } from 'feature/navigation-menu';

import './style.scss';

const Navigation = () => {
	return (
		<div className="navigation">
			<Grid>
				<Col lg={9}>
					<NavigationMenu />
				</Col>
				<Col lg={3}>
					<div className="navigation__links">
						<FavoritesLink />
						<Help />
					</div>
				</Col>
			</Grid>
		</div>
	);
};

export default Navigation;
