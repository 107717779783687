import { api } from 'rest/api';

function createError(error, defaultValue = null) {
	return {
		responseCode: 1,
		errorMessage: error,
		response: defaultValue,
	};
}

export const getList = async type => {
	try {
		const response = await api.get(`/posters/list?type=${type}`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const add = async data => {
	try {
		const response = await api.post('/posters/add', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const update = async data => {
	try {
		const response = await api.post('/posters/update', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const remove = async data => {
	try {
		const response = await api.post('/posters/delete', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const postersApi = {
	getList,
	add,
	update,
	remove,
};
