import React from 'react';

import './style.scss'

export const ArticleLoader = () => {
	return (
		<div className="Article-loader">
			<span></span>
			<p></p>
		</div>
	)
}