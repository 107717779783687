import React, { forwardRef } from 'react'
import classNames from 'classnames'
import FormLabel from '../formLabel'
import FileUploader from '../fileUploader'
import MultipleFileUploader from '../multipleFileUploader'
import FormHelperText from '../formHelperText'
import FormErrorMessage from '../formErrorMessage'

import './style.scss'

const variantComponent = {
    file: FileUploader,
    multipleFile: MultipleFileUploader
}

const UploaderField = forwardRef(function UploaderField(props, ref) {
    const {
        accept,
        className,
        defaultValue,
        disabled = false,
        endAdornment,
        error = false,
        errorMessage,
        FormHelperTextProps,
        fullWidth = false,
        helperText,
        id,
        InputLabelProps,
        inputProps,
        InputProps,
        inputRef,
        label,
        multiple,
        onlyBase64,
        onBlur,
        onChange,
        onFocus,
        onProgress,
        placeholder,
        startAdornment,
        required = false,
        variant = 'file',
        fileType,
        ...other
    } = props

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;
    const UploaderComponent = variantComponent[variant]

    return (
        <div
            className={classNames(
                'uploader-field',
                {
                    'uploader-field_disabled': disabled,
                    'uploader-field_error': error,
                    'uploader-field_full-width': fullWidth,
                    'uploader-field_required': required
                },
                className
            )}
            ref={ref}
            {...other}
        >
            {label && (
                <FormLabel
                    htmlFor={id}
                    id={inputLabelId}
                    children={label}
                    {...InputLabelProps}
                />
            )}
            <div className="text-field-body">
                {startAdornment}
                <UploaderComponent
                    accept={accept}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    error={error}
                    fullWidth={fullWidth}
                    id={id}
                    inputRef={inputRef}
                    multiple={multiple}
                    onlyBase64={onlyBase64}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    onProgress={onProgress}
                    placeholder={placeholder}
                    inputProps={inputProps}
                    required={required}
                    fileType={fileType}
                    {...InputProps}
                />
                {endAdornment}
            </div>
            {helperText && (
                <FormHelperText
                    id={helperTextId}
                    {...FormHelperTextProps}
                    children={helperText}
                />
            )}
            {error && errorMessage && (
                <FormErrorMessage>
                    {errorMessage}
                </FormErrorMessage>
            )}
        </div>
    )
})

export default UploaderField
