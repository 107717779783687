import {
	GET_CLUB_CATEGORIES_LIST,
	ADD_CLUB_CATEGORIES_LIST,
	DELETE_CLUB_CATEGORIES_LIST,
	UPDATE_CLUB_CATEGORIES_LIST,
	GET_CLUB_PARTNERS_LIST,
	ADD_CLUB_PARTNERS_LIST,
	DELETE_CLUB_PARTNERS_LIST,
	UPDATE_CLUB_PARTNERS_LIST,
	SET_CLUB_CITIES_LIST,
	SET_CLUB_SELECTED_CITY,
	GET_CLUB_SEARCH_PARTNERS_LIST,
	SET_CLUB_FILTERS_CITIES_LIST,
	MAXICLUB_KPP_MODAL_STATE,
	MAXICLUB_KPP_INFO_CARD,
} from '../constant';

const DefaultState = {
	cities: [],
	categories: [],
	partners: [],
	searchedPartners: [],
	cityId: null,
	filterCities: [],
	modalState: false,
	infoCard: null,
};

export default function maxiclub(state = DefaultState, action) {
	switch (action.type) {
		case GET_CLUB_CATEGORIES_LIST:
			return {
				categories: [...action.categoriesList],
				partners: state.partners,
				searchedPartners: state.searchedPartners,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case ADD_CLUB_CATEGORIES_LIST:
			return {
				categories: [action.category, ...state.categories],
				partners: state.partners,
				searchedPartners: state.searchedPartners,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case DELETE_CLUB_CATEGORIES_LIST:
			let newCategoriesList = state.categories.filter(item => {
				return item.id !== +action.id;
			});
			return {
				categories: [...newCategoriesList],
				partners: state.partners,
				searchedPartners: state.searchedPartners,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case UPDATE_CLUB_CATEGORIES_LIST:
			let updateCategory = state.categories;
			let newCategory = updateCategory.map(item => {
				if (item.id === action.category.id) {
					item.name = action.category.name;
					item.image = action.category.image;
				}
				return item;
			});
			return {
				categories: [...newCategory],
				partners: state.partners,
				searchedPartners: state.searchedPartners,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case GET_CLUB_PARTNERS_LIST:
			return {
				categories: state.categories,
				partners: [...action.partnersList],
				searchedPartners: state.searchedPartners,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case GET_CLUB_SEARCH_PARTNERS_LIST:
			return {
				categories: state.categories,
				partners: state.partners,
				searchedPartners: [...action.partnersList],
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case ADD_CLUB_PARTNERS_LIST:
			return {
				categories: state.categories,
				partners: [action.partner, ...state.partners],
				searchedPartners: state.searchedPartners,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case DELETE_CLUB_PARTNERS_LIST:
			let newPartnersList = state.partners.filter(item => {
				return item.id !== +action.id;
			});
			return {
				categories: state.categories,
				partners: [...newPartnersList],
				searchedPartners: state.searchedPartners,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case UPDATE_CLUB_PARTNERS_LIST:
			let updatePartner = state.partners;
			let newPartner = updatePartner.map(item => {
				if (item.id === action.partner.id) {
					item.name = action.partner.name;
					item.image = action.partner.image;
					item.startActivity = action.partner.startActivity;
					item.endActivity = action.partner.endActivity;

					item.idCategory = action.partner.idCategory;
					item.idCity = action.partner.idCity;
					item.conditions = action.partner.conditions;
					item.discount = action.partner.discount;
					item.web = action.partner.web;
					item.adresses = action.partner.adresses;
					item.phones = action.partner.phones;
					item.info = action.partner.info;
				}
				return item;
			});
			return {
				partners: [...newPartner],
				searchedPartners: state.searchedPartners,
				categories: state.categories,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case SET_CLUB_CITIES_LIST:
			return {
				partners: state.partners,
				searchedPartners: state.searchedPartners,
				categories: state.categories,
				cities: [...action.city],
				cityId: state.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case SET_CLUB_SELECTED_CITY:
			return {
				partners: state.partners,
				searchedPartners: state.searchedPartners,
				categories: state.categories,
				cities: state.cities,
				cityId: action.cityId,
				filterCities: state.filterCities,
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case SET_CLUB_FILTERS_CITIES_LIST:
			return {
				partners: state.partners,
				searchedPartners: state.searchedPartners,
				categories: state.categories,
				cities: state.cities,
				cityId: state.cityId,
				filterCities: [...action.city],
				modalState: state.modalState,
				infoCard: state.infoCard,
			};

		case MAXICLUB_KPP_MODAL_STATE:
			return {
				...state,
				modalState: action.modalState,
			};

		case MAXICLUB_KPP_INFO_CARD:
			return {
				...state,
				infoCard: action.infoCard,
			};
		
		default:
			return state;
	}
}
