import React, { FC, useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Copy, Download, GitBranch, MoreVertical, Trash2 } from 'react-feather';

import { ScaleQuestion } from './ScaleQuestion';
import { SelectQuestion } from './SelectQuestion';
import { TextQuestion } from './TextQuestion';
import { IQuestionComponentProps, IQuestionProps, IQuestionsTypes } from '../types';
import { FileField, QuestionTypes } from '../fields';
import useClickOutside from 'hooks/useClickOutside';
import { changeQuestionIndexAction, deleteQuestionAction } from 'action/survey';
import { Conditions } from '../../Conditions';

const questionsTypes: IQuestionsTypes = {
	SINGLE: SelectQuestion,
	MULTIPLE: SelectQuestion,
	SCALE: ScaleQuestion,
	TEXT: TextQuestion,
};

export const Question: FC<IQuestionProps> = ({
	index,
	lastItem,
	firstItem,
	questionSchema,
	changeTypeHandler,
	copyQuestion,
}) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const [drop, setDrop] = useState(false);
	const [conditions, setConditions] = useState(false);
	const Component: FC<IQuestionComponentProps> = questionsTypes[questionSchema.type];

	const closeHandle = () => setDrop(false);
	useClickOutside(dropdownRef, closeHandle);

	useEffect(() => {
		if (questionSchema.conditions?.length) {
			setConditions(true);
		}
	}, [questionSchema]);

	const addCondition = () => {
		setConditions(true);
		setDrop(false);
	};

	const removeHandle = useCallback(() => {
		dispatch(deleteQuestionAction(questionSchema.questionNumber));
	}, [dispatch, questionSchema.questionNumber]);

	const moveQuestion = useCallback(
		(index: number) => {
			closeHandle();
			dispatch(changeQuestionIndexAction(index));
		},
		[dispatch]
	);

	const copyQuestionHandler = () => {
		closeHandle();
		copyQuestion(questionSchema);
	};

	return (
		<div className="survey-question">
			<div className="survey-question__header">
				<div className="survey-question__wrap">
					<p className="survey-question__title">
						Вопрос №{questionSchema.questionNumber}
					</p>

					<FileField
						defaultValue={questionSchema?.image}
						questionNumber={questionSchema.questionNumber}
						index={index}
					/>
				</div>

				<QuestionTypes changeType={changeTypeHandler} questionSchema={questionSchema} />

				<div className="survey-question-menu" ref={dropdownRef}>
					<MoreVertical
						className="survey-question-menu__icon"
						onClick={() => setDrop(prev => !prev)}
					/>

					{drop && (
						<div className="survey-question-menu__drop">
							{!firstItem && (
								<div className="survey-question-menu__item" onClick={addCondition}>
									<GitBranch className="survey-question-menu__svg" />
									<span className="survey-question-menu__text">Условие</span>
								</div>
							)}

							<div
								className="survey-question-menu__item"
								onClick={copyQuestionHandler}
							>
								<Copy className="survey-question-menu__svg" />
								<span className="survey-question-menu__text">Копировать</span>
							</div>

							{!lastItem && (
								<div
									className="survey-question-menu__item"
									onClick={() => moveQuestion(index)}
								>
									<Download className="survey-question-menu__svg" />
									<span className="survey-question-menu__text">
										Вставить ниже
									</span>
								</div>
							)}

							<div className="survey-question-menu__item" onClick={removeHandle}>
								<Trash2 className="survey-question-menu__svg" />
								<span className="survey-question-menu__text">Удалить</span>
							</div>
						</div>
					)}
				</div>
			</div>

			<Component questionSchema={questionSchema} questionIndex={index} />

			{conditions && (
				<Conditions
					deleteConditions={() => setConditions(false)}
					questionSchema={questionSchema}
				/>
			)}
		</div>
	);
};
