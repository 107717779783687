import React, { memo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { WeekDays } from '../config'

const WeekDay = memo(
    ({className, days = WeekDays}) => {
        return (
            <ul className={cn("common-calendar__week-day", {
                [className]: Boolean(className)
            })}>
                {
                    days.map(
                        day => (
                            <li 
                                key={day}
                                className="week-day__item"
                            >
                                    {day}
                            </li>
                        )
                    )
                }
            </ul>
        )
    }
) 

export default WeekDay

WeekDay.prototype = {
    className: PropTypes.string,
    days: PropTypes.array
}