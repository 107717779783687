import React from 'react';
import { connect } from 'react-redux';

import { FilterLabel, FilterRadio } from '../../atoms';

import { updateFilterParams } from 'action/vacancies';

import './style.scss';

const ProfessionSelect = props => {
	const chooseLevelHandler = e => {
		e.preventDefault();
		props.updateFilterParams({
			level: e.currentTarget.id,
			page: 0,
		});
	};

	return (
		<div className="vacancies-filters__radio-filter">
			<FilterLabel text="Выберите тип должности" />

			<div className="radio-group">
				<FilterRadio
					text="Менеджмент"
					onChangeHandler={chooseLevelHandler}
					id="CHIEF"
					isActive={
						props.vacanciesListParams !== null ? props.vacanciesListParams.level : null
					}
				/>
				<FilterRadio
					text="Специалисты"
					onChangeHandler={chooseLevelHandler}
					id="SPECIALIST"
					isActive={
						props.vacanciesListParams !== null ? props.vacanciesListParams.level : null
					}
				/>
				<FilterRadio
					text="Рабочие профессии"
					onChangeHandler={chooseLevelHandler}
					id="EXECUTOR"
					isActive={
						props.vacanciesListParams !== null ? props.vacanciesListParams.level : null
					}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		vacanciesListParams: state.vacancies.vacanciesListParams,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateFilterParams: params => {
			dispatch(updateFilterParams(params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
	ProfessionSelect
);
