import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { Roles } from 'core/components';
import { hasRole } from 'core/utils';

import './style.scss';

export const AsideMenuLink = withRouter(
	({ title, relativeSection, id, arrayRoles, location, isEdit }) => {
		const { state } = location;
		const back = state && !!state.back ? state.back - 1 : -1;
		return (
			<div className="aside-menu__item">
				{isEdit && arrayRoles && (
					<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_WIKI_FAQ_CATEGORIES')}>
						<Link
							to={{
								pathname: `/knowledge/${relativeSection}/${id}/edit`,
								state: {
									...state,
									back,
								},
							}}
							className="aside-menu__edit"
						>
							<svg role="img">
								<use
									xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#pen'}
								></use>
							</svg>
						</Link>
					</Roles>
				)}

				<NavLink
					to={{
						pathname: `/knowledge/${relativeSection}/${id}`,
						state: {
							...state,
							back,
						},
					}}
					className="aside-menu__link"
				>
					{title}
				</NavLink>
			</div>
		);
	}
);
