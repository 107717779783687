import {
	ADD_QUESTION,
	SET_QUESTIONS,
	DELETE_QUESTIONS,
	UPDATE_QUESTION_TITLE,
	UPDATE_QUESTION_MAX,
	UPDATE_QUESTION_MIN,
	ADD_QUESTION_ANSWER,
	UPDATE_QUESTION_ANSWER,
	DELETE_QUESTION_ANSWER,
	ADD_QUESTION_SCALE,
	DELETE_QUESTION,
	CHANGE_QUESTION_INDEX,
	ADD_QUESTION_CONDITION,
	UPDATE_QUESTION_CONDITION_ID,
	UPDATE_QUESTION_CONDITION_VALUE,
	DELETE_QUESTION_CONDITION,
	UPDATE_SURVEY_TITLE,
	UPDATE_SURVEY_DESCRIPTION,
	UPDATE_SURVEY_START_DATE,
	UPDATE_SURVEY_END_DATE,
	UPDATE_SURVEY_DEPARTMENS,
	UPDATE_SURVEY_USERS,
	UPDATE_SURVEY_PREVIEW,
	UPDATE_SURVEY_ANONYMOUS,
	UPDATE_CONDITION_TYPE,
	SET_SURVEY,
	CHANGE_QUESTION_TYPE,
	QUESTION_IMAGE,
	CHANGE_ANSWER_OTHER_TYPE,
	UPDATE_SURVEY_POSITIONS,
	COPY_SURVEY,
	UPDATE_SURVEY_SPECIAL,
	UPDATE_SURVEY_FINISH_TEXT,
	UPDATE_SURVEY_FINISH_BUTTON_NAME,
	UPDATE_SURVEY_FINISH_BUTTON_LINK,
} from 'constant';

export const updateSurveyTitleAction = title => {
	return {
		type: UPDATE_SURVEY_TITLE,
		title,
	};
};

export const updateSurveyDescriptionAction = description => {
	return {
		type: UPDATE_SURVEY_DESCRIPTION,
		description,
	};
};

export const updateSurveyStartDateAction = startDate => {
	return {
		type: UPDATE_SURVEY_START_DATE,
		startDate,
	};
};

export const updateSurveyEndDateAction = endDate => {
	return {
		type: UPDATE_SURVEY_END_DATE,
		endDate,
	};
};

export const updateSurveyUsersAction = forUsers => {
	return {
		type: UPDATE_SURVEY_USERS,
		forUsers,
	};
};

export const updateSurveyDepartmentsAction = forDepartments => {
	return {
		type: UPDATE_SURVEY_DEPARTMENS,
		forDepartments,
	};
};

export const updateSurveyPositionsAction = forPositions => {
	return {
		type: UPDATE_SURVEY_POSITIONS,
		forPositions,
	};
};

export const questionImageAction = (image, questionNumber) => {
	return {
		type: QUESTION_IMAGE,
		image,
		questionNumber,
	};
};

export const updateSurveyPreviewAction = cover => {
	return {
		type: UPDATE_SURVEY_PREVIEW,
		cover,
	};
};

export const updateSurveyAnonymousAction = anonymous => {
	return {
		type: UPDATE_SURVEY_ANONYMOUS,
		anonymous,
	};
};

export const updateSurveySpecialAction = special => {
	return {
		type: UPDATE_SURVEY_SPECIAL,
		special,
	};
};

export const updateSurveyFinishTextAction = text => {
	return {
		type: UPDATE_SURVEY_FINISH_TEXT,
		text,
	};
};

export const updateSurveyFinishButtonNameAction = text => {
	return {
		type: UPDATE_SURVEY_FINISH_BUTTON_NAME,
		text,
	};
};

export const updateSurveyFinishButtonLinkAction = text => {
	return {
		type: UPDATE_SURVEY_FINISH_BUTTON_LINK,
		text,
	};
};

export const setSurveyAction = survey => {
	return {
		type: SET_SURVEY,
		survey,
	};
};

export const addQuestionAction = question => {
	return {
		type: ADD_QUESTION,
		question,
	};
};

export const setQuestionsAction = questions => {
	return {
		type: SET_QUESTIONS,
		questions,
	};
};

export const updateQuestionTitleAction = question => {
	return {
		type: UPDATE_QUESTION_TITLE,
		question,
	};
};

export const updateQuestionMaxAction = question => {
	return {
		type: UPDATE_QUESTION_MAX,
		question,
	};
};

export const updateQuestionMinAction = question => {
	return {
		type: UPDATE_QUESTION_MIN,
		question,
	};
};

export const addQuestionAnswerAction = (questionNumber, newAnswer) => {
	return {
		type: ADD_QUESTION_ANSWER,
		questionNumber,
		newAnswer,
	};
};

export const updateQuestionAnswerAction = (questionNumber, answerId, value) => {
	return {
		type: UPDATE_QUESTION_ANSWER,
		questionNumber,
		answerId,
		value,
	};
};

export const deleteQuestionAnswerAction = (questionNumber, answerId) => {
	return {
		type: DELETE_QUESTION_ANSWER,
		answerId,
		questionNumber,
	};
};

export const addQuestionConditionAction = (questionNumber, condition) => {
	return {
		type: ADD_QUESTION_CONDITION,
		questionNumber,
		condition,
	};
};

export const updateQuestionConditionIdAction = (questionNumber, condition) => {
	return {
		type: UPDATE_QUESTION_CONDITION_ID,
		questionNumber,
		condition,
	};
};

export const updateQuestionConditionValueAction = (questionNumber, index, value) => {
	return {
		type: UPDATE_QUESTION_CONDITION_VALUE,
		questionNumber,
		index,
		value,
	};
};

export const updateConditionTypeAction = (questionNumber, index, type) => {
	return {
		type: UPDATE_CONDITION_TYPE,
		questionNumber,
		index,
		conditionType: type,
	};
};

export const deleteQuestionConditionAction = (questionNumber, deleteIndex) => {
	return {
		type: DELETE_QUESTION_CONDITION,
		questionNumber,
		deleteIndex,
	};
};

export const updateQuestionScaleAction = question => {
	return {
		type: ADD_QUESTION_SCALE,
		question,
	};
};

export const deleteQuestionAction = questionNumber => {
	return {
		type: DELETE_QUESTION,
		questionNumber,
	};
};

export const changeQuestionIndexAction = changeIndex => {
	return {
		type: CHANGE_QUESTION_INDEX,
		changeIndex,
	};
};

export const clearAllQuestions = () => {
	return {
		type: DELETE_QUESTIONS,
	};
};

export const changeQuestionTypeAction = (questionNumber, data) => {
	return {
		type: CHANGE_QUESTION_TYPE,
		questionNumber,
		data,
	};
};

export const changeAnswerOtherAction = (questionNumber, state) => {
	return {
		type: CHANGE_ANSWER_OTHER_TYPE,
		questionNumber,
		state,
	};
};

export const copySurvey = survey => {
	return {
		type: COPY_SURVEY,
		survey,
	};
};
