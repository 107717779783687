import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getDay from 'date-fns/getDay';

import Header from './Header';
import WeekDay from './WeekDay';
import Days from './Days';

import { MonthName } from './config';
import { SelectYear } from './SelectYear';

const Calendar = ({
	className,

	headerClassName,
	showMonthChangeButton = true,
	disabledPrevDays = false,
	selectCurrentDay = false,
	disableTo,
	disableFrom,

	monthName = MonthName,

	weekDayClassName,
	weekDay,

	disabledDays = [],
	availableDays = [],
	selectedDays = [],

	name = 'calendar',
	onChange,

	onMonthChange,
	onYearChange,
	testIds,
	canSwitchToYear,
	isOpenDatepicker,
}) => {
	const inputRef = useRef(null);

	const [currentMonth, setCurrentMonth] = useState(null);

	const [currentYear, setCurrentYear] = useState(getYear(new Date()));
	const [currentDay, setCurrentDay] = useState(null);
	const [isOpenSelectYear, setOpenSelectYear] = useState(false);
	const [changeList, setChangeList] = useState('');

	const [daysInMonth, setDaysInMonth] = useState(30);
	const [dayBeginingMonth, setDayBeginingMonth] = useState(0);

	useEffect(() => {
		const day = getDate(new Date());
		setCurrentDay(day);
	}, []);

	useEffect(() => {
		const month = getMonth(new Date());
		setCurrentMonth(month);
		if (onMonthChange) {
			onMonthChange(month);
		}
	}, [onMonthChange]);

	useEffect(() => {
		const date = new Date(currentYear, currentMonth);
		const daysInMonth = getDaysInMonth(date);
		const dayBegining = getDay(date);
		setDaysInMonth(daysInMonth);
		setDayBeginingMonth(dayBegining);

		if (onYearChange) onYearChange(currentYear);
	}, [currentMonth, currentYear, onYearChange]);

	useEffect(() => {
		if (!isOpenDatepicker) {
			setOpenSelectYear(false);
		}
	}, [isOpenDatepicker]);

	const sendMonthChangeHelper = month => {
		if (onMonthChange) {
			onMonthChange(month);
		}
	};

	const onNextMonthClick = () => {
		const nextMonth = currentMonth + 1;
		if (nextMonth <= 11) {
			setCurrentMonth(nextMonth);
			sendMonthChangeHelper(nextMonth);
		} else {
			setCurrentMonth(0);
			sendMonthChangeHelper(0);
		}
	};

	const onPrevMonthClick = () => {
		const prevMonth = currentMonth - 1;
		if (prevMonth >= 0) {
			setCurrentMonth(prevMonth);
			sendMonthChangeHelper(prevMonth);
		} else {
			setCurrentMonth(11);
			sendMonthChangeHelper(11);
		}
	};

	const onDayClick = date => {
		setValueInInput(date);
	};

	const setValueInInput = value => {
		const input = inputRef.current;
		input.value = value;
		const event = new Event('input', { bubbles: true });
		event.simulated = true;
		input.dispatchEvent(event);
	};

	const onNextYearClick = () => {
		setChangeList('next');
	};
	const onPrevYearClick = () => {
		setChangeList('prev');
	};

	return (
		<div
			className={cn('common-calendar', {
				[className]: Boolean(className),
			})}
		>
			<input
				id={testIds?.inputId}
				type="text"
				name={name}
				onInput={onChange}
				ref={inputRef}
				hidden
			/>

			{isOpenSelectYear ? (
				<>
					<Header
						month={monthName[currentMonth]}
						year={currentYear}
						className={headerClassName}
						onNextClick={onNextYearClick}
						onPrevClick={onPrevYearClick}
						setOpenSelectYear={setOpenSelectYear}
						canSwitchToYear={canSwitchToYear}
					/>
					<SelectYear
						setCurrentYear={setCurrentYear}
						selectedYear={currentYear}
						setOpenSelectYear={setOpenSelectYear}
						changeList={changeList}
						setChangeList={setChangeList}
					/>
				</>
			) : (
				<>
					<Header
						month={monthName[currentMonth]}
						year={currentYear}
						className={headerClassName}
						showMonthChangeButton={showMonthChangeButton}
						onNextClick={onNextMonthClick}
						onPrevClick={onPrevMonthClick}
						arrowId={testIds?.arrowId}
						setOpenSelectYear={setOpenSelectYear}
						canSwitchToYear={canSwitchToYear}
					/>
					<WeekDay className={weekDayClassName} days={weekDay} />
					<Days
						currentDay={currentDay}
						currentMonth={currentMonth}
						currentYear={currentYear}
						daysInMonth={daysInMonth}
						dayBeginingMonth={dayBeginingMonth}
						disabledDays={disabledDays}
						availableDays={availableDays}
						selectCurrentDay={selectCurrentDay}
						selectedDays={selectedDays}
						disabledPrevDays={disabledPrevDays}
						disableTo={disableTo}
						disableFrom={disableFrom}
						onDayClick={onDayClick}
						daysId={testIds?.daysId}
					/>
				</>
			)}
		</div>
	);
};

export default Calendar;

Calendar.prototype = {
	className: PropTypes.string,

	monthName: PropTypes.shape({
		key: PropTypes.string,
	}),

	headerClassName: PropTypes.string,
	showMonthChangeButton: PropTypes.bool,
	selectCurrentDay: PropTypes.bool,
	disabledPrevDays: PropTypes.bool,

	weekDayClassName: PropTypes.string,
	weekDay: PropTypes.array,

	disabledDays: PropTypes.array,
	availableDays: PropTypes.array,
	selectedDays: PropTypes.array,

	name: PropTypes.string,
	testIds: PropTypes.shape({
		inputId: PropTypes.string,
		daysId: PropTypes.string,
		arrowId: PropTypes.string,
	}),

	onChange: PropTypes.func,
	onMonthChange: PropTypes.func,
	onYearChange: PropTypes.func,
	isOpenDatepicker: PropTypes.bool,
};
