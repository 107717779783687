import {
	GET_CONTACTS_CATEGORIES,
	GET_CONTACTS_TOP_CATEGORIES,
	CONTACTS_EDIT_MODE,
	GET_RECENT_CONTACTS_LIST,
	GET_CONTACTS_CATEGORY_INFO,
	CLEAR_CONTACTS_CATEGORY_INFO,
	GET_FAVORITE_CONTACTS_LIST,
	GET_FAVORITE_CONTACTS_IDS_LIST,
	ADD_ANOTHER_CONTACT,
	UPDATE_ANOTHER_CONTACT,
	DELETE_ANOTHER_CONTACT,
	CHANGE_CONTACTS_SORT,
} from 'constant';

import { NotificationHelper } from './notificationHelper';

import {
	categoriesList,
	topCategoriesList,
	recentContacts,
	categoryInfo,
	createAnotherContact,
	createAnotherUserContact,
	updateAnotherContact,
	deleteAnotherContact,
	changeSortContacts,
} from 'rest/contacts';

import { getFavoritesByType, getFavoritesIdsByType, updateFavoriteState } from 'rest/favorites';

export const getCategoriesList = () => {
	return dispatch => {
		return categoriesList()
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_CONTACTS_CATEGORIES,
						list: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка категорий', 1));
			});
	};
};

export const getTopCategoriesList = () => {
	return dispatch => {
		return topCategoriesList()
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_CONTACTS_TOP_CATEGORIES,
						list: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка категорий', 1));
			});
	};
};

export const contactsEditModeToggle = () => {
	return {
		type: CONTACTS_EDIT_MODE,
	};
};

export const getRecentContactsList = () => {
	return dispatch => {
		return recentContacts()
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_RECENT_CONTACTS_LIST,
						list: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка недавних контактов', 1));
			});
	};
};

export const getCategoryInfo = (parentId, fromIndex) => {
	return dispatch => {
		return categoryInfo(parentId, fromIndex)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_CONTACTS_CATEGORY_INFO,
						info: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения контента категории', 1));
			});
	};
};

export const clearContactsCategoryInfo = () => {
	return {
		type: CLEAR_CONTACTS_CATEGORY_INFO,
	};
};

//Избранные
export const getFavoriteContactsList = () => {
	return dispatch => {
		return getFavoritesByType({ type: 'CONTACT' })
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_FAVORITE_CONTACTS_LIST,
						list: res.data.response.contacts,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка избранных контактов', 1));
			});
	};
};

export const getFavoriteContactsIdsList = () => {
	return dispatch => {
		return getFavoritesIdsByType('CONTACT')
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_FAVORITE_CONTACTS_IDS_LIST,
						list: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка избранных контактов', 1));
			});
	};
};

export const addOrRemoveFromFavoriteAction = (data, changeFavoriteStateHandler) => {
	return dispatch => {
		return updateFavoriteState(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					if (data.favorite) {
						dispatch(NotificationHelper('Добавлено в Избранное', 0));
						changeFavoriteStateHandler();
					} else {
						dispatch(NotificationHelper('Удалено из Избранного', 0));
						changeFavoriteStateHandler();
					}
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления/удаления из Избранного', 1));
			});
	};
};

//Служебные записи
export const addAnotherContact = (data, closeModal) => {
	return dispatch => {
		return createAnotherContact(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: ADD_ANOTHER_CONTACT,
						contact: res.data.response,
					});
					closeModal();
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления служебной записи', 1));
			});
	};
};

export const addAnotherUserContact = (data, closeModal) => {
	return dispatch => {
		return createAnotherUserContact(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: ADD_ANOTHER_CONTACT,
						contact: res.data.response,
					});
					closeModal();
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления служебной записи', 1));
			});
	};
};

export const editAnotherContact = (data, closeModal) => {
	return dispatch => {
		return updateAnotherContact(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: UPDATE_ANOTHER_CONTACT,
						info: res.data.response,
					});
					closeModal();
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка обновления служебной записи', 1));
			});
	};
};

export const removeAnotherContact = data => {
	return dispatch => {
		return deleteAnotherContact(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: DELETE_ANOTHER_CONTACT,
						id: data.contactId,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка удаления служебной записи', 1));
			});
	};
};

export const changeContactsSort = data => {
	return dispatch => {
		return changeSortContacts(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: CHANGE_CONTACTS_SORT,
						info: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка изменения сортировки контакта', 1));
			});
	};
};
