import { SET_APPLICATION, SET_APPLICATIONS } from '../constant';

export const addAplicationPermision = value => {
	return {
		type: SET_APPLICATION,
		value,
	};
};

export const addAplicationsPermision = value => {
	return {
		type: SET_APPLICATIONS,
		value,
	};
};
