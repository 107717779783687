import {
	GET_COMMENTS_LIST,
	DELETE_COMMENT,
	RECOVER_COMMENT,
	ADD_COMMENT,
	COMMENTS_COUNT,
	COMMENTS_EDIT_STATUS,
	UPDATE_COMMENT,
	HIDE_COMMENTS,
	ADD_OR_REMOVE_COMMENTS_LIKE,
} from '../constant';

export const getComments = (comments, id) => {
	return {
		type: GET_COMMENTS_LIST,
		comments: comments,
		id: id,
	};
};

export const getCommentsCount = (id, length) => {
	return {
		type: COMMENTS_COUNT,
		id: id,
		length: length,
	};
};

export const addComment = (postId, comment) => {
	return {
		type: ADD_COMMENT,
		postId: postId,
		comment: comment,
	};
};

export const deleteComment = (postId, commentId) => {
	return {
		type: DELETE_COMMENT,
		postId: postId,
		commentId: commentId,
	};
};

export const recoverCommentItem = (postId, commentId) => {
	return {
		type: RECOVER_COMMENT,
		postId,
		commentId,
	};
};

export const setCommentsEditStatus = status => {
	return {
		type: COMMENTS_EDIT_STATUS,
		status: status,
	};
};

export const updateCommentItem = (postId, id, comment) => {
	return {
		type: UPDATE_COMMENT,
		postId: postId,
		id: id,
		comment: comment,
	};
};

export const hideCommentsInPost = postId => {
	return {
		type: HIDE_COMMENTS,
		postId: postId,
	};
};

export const addRemoveCommentsLike = (postId, commentId, like) => {
	return {
		type: ADD_OR_REMOVE_COMMENTS_LIKE,
		postId: postId,
		id: commentId,
		like,
	};
};
