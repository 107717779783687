import { useState, useCallback } from 'react';
import { URL_AUTH } from 'constant';
import { api } from 'rest/api';
import { useFormState } from './useFormState';
import { useAuth } from 'feature/auth/model';
import { getRestPhone } from 'plugins/getRestPhone';

export const useRecoverNewPassword = () => {
	const { uuid: claimUuid, phone } = useFormState();

	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const { onAuth } = useAuth();

	const setNewPassword = useCallback(
		async (newPassword: string) => {
			setLoading(true);
			setError(false);
			setErrorMessage(null);
			try {
				const response = await api.post(
					'/auth/change-password/set-new-password',
					{
						newPassword,
						claimUuid,
					},
					{
						baseURL: URL_AUTH,
					}
				);
				const { data } = response;
				if (data.responseCode === 0) {
					const clearPhone = getRestPhone(phone);
					onAuth(clearPhone, newPassword);
				} else {
					setError(true);
					setErrorMessage(data.errorMessage);
				}
			} catch (error) {
				setError(true);
				setErrorMessage(`${error}`);
			} finally {
				setLoading(false);
			}
		},
		[claimUuid, phone, onAuth]
	);

	return {
		isLoading,
		isError,
		errorMessage,
		setNewPassword,
	};
};
