import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Archive, Plus } from 'react-feather';
import { NavLink, Link } from 'react-router-dom';

import { FixedSidebar } from 'components/fixedSidebar';
import { hasRole } from 'core/utils';
import { useIsMobile } from 'hooks/useIsMobile';

import './style.scss';

export const Sidebar: FC = () => {
	const isMobile = useIsMobile();
	const { roles } = useSelector((state: any) => ({
		roles: state.shortUserInfo.roles,
	}));

	const admin = useMemo(() => {
		return hasRole(roles, ['ROLE_SURVEY']);
	}, [roles]);

	const PulseSurveyAdmin = useMemo(() => {
		return hasRole(roles, ['ROLE_PULSE_SURVEY']);
	}, [roles]);

	return (
		<div className="survey-sidebar">
			<FixedSidebar>
				{!isMobile && (
					<Link
						to="/services"
						className="survey-back-to-home"
						id="survey_sidebar_back-to-services"
					>
						<svg role="img" className="survey-back-to-home__icon">
							<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
						</svg>
						<span className="survey-back-to-home__text">Назад к Сервисам</span>
					</Link>
				)}

				<Link
					to="/services/survey"
					className="survey-sidebar__link"
					id="survey_sidebar_to-survey-list"
				>
					<span className="survey-sidebar__text">Опросы</span>
				</Link>

				{!isMobile && admin && (
					<Link
						to="/services/survey/create"
						className="survey-sidebar__link"
						id="survey_sidebar_to-survey-create"
					>
						<Plus className="survey-sidebar__svg" strokeWidth={3} />
						<span className="survey-sidebar__create-link">создать опрос</span>
					</Link>
				)}

				{!isMobile && admin && (
					<Link
						to="/services/survey/archive"
						className="survey-sidebar__link"
						id="survey_sidebar_to-survey-archiver"
					>
						<Archive className="survey-sidebar__svg" strokeWidth={2} />
						<span className="survey-sidebar__create-link">Архив опросов</span>
					</Link>
				)}

				{!isMobile && PulseSurveyAdmin && (
					<NavLink
						to="/services/pulse-survey/list"
						className="survey-sidebar__link"
						id="survey_sidebar_to-pulse-survey"
					>
						<span className="survey-sidebar__text">Пульс-опросы</span>
					</NavLink>
				)}
			</FixedSidebar>
		</div>
	);
};
