import { SET_NOTIFICATION, TIME_NOTIFICATION } from '../constant'

export const NotificationHelper = (text, status) => {
    return dispatch => {
        setTimeout(
            () => {
                return dispatch(
                    {
                        type: SET_NOTIFICATION,
                        notification: {
                            isOpen: false,
                            status: 0,
                            text: ''
                        }
                    }
                )
            },
            TIME_NOTIFICATION
        )
        return dispatch(
            {
                type: SET_NOTIFICATION,
                notification: {
                    isOpen: true,
                    status: status,
                    text: text
                }
            }
        )
    }
}