import { URL_AUTH } from 'constant';
import { useState, useCallback } from 'react';
import { api } from 'rest/api';
import { useFormState } from './useFormState';

export const useRegistrationConfirm = () => {
	const { uuid: claimUuid, setNewPassView, setUserName } = useFormState();

	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const sendCode = useCallback(
		async (confirmationCode: string) => {
			setLoading(true);
			setError(false);
			setErrorMessage(null);
			try {
				const response = await api.post(
					'/auth/registration/confirm',
					{
						confirmationCode,
						claimUuid,
					},
					{
						baseURL: URL_AUTH,
					}
				);
				const { data } = response;
				if (data.responseCode === 0) {
					const { userName } = data.response;
					setUserName(userName);
					setNewPassView();
				} else {
					setError(true);
					setErrorMessage(data.errorMessage);
				}
			} catch (error) {
				setError(true);
				setErrorMessage(`${error}`);
			} finally {
				setLoading(false);
			}
		},
		[claimUuid, setNewPassView, setUserName]
	);

	return {
		isLoading,
		isError,
		errorMessage,
		sendCode,
	};
};
