import { useEffect, useState, useDebugValue } from 'react';
import isMobile from 'ismobilejs';

export function useIsMobile({ width = 992, customAgent = navigator.userAgent } = {}) {
	const [isMobileState, setIsMobile] = useState(
		window.innerWidth < width || isMobile(customAgent).phone || isMobile(customAgent).tablet
	);

	useEffect(() => {
		function handleResize() {
			if (
				window.innerWidth < width ||
				isMobile(customAgent).phone ||
				isMobile(customAgent).tablet
			) {
				if (!isMobileState) {
					setIsMobile(true);
				}
			} else if (isMobileState) {
				setIsMobile(false);
			}
		}

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	useDebugValue('useIsMobile');

	return isMobileState;
}
