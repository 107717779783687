import React, { useState } from 'react';
import { RefreshCw } from 'react-feather';

import { ModalComponent, Button } from 'core/components';

import './style.scss';

export const CacheModal = () => {
	const [isCacheModal, setCacheModal] = useState(false);

	const triggerCloseModal = () => setCacheModal(false);

	const reload = async () => {
		window.location.reload(true);
	};

	navigator.serviceWorker.addEventListener('controllerchange', () => {
		setCacheModal(true);
	});

	return (
		isCacheModal && (
			<ModalComponent onClose={triggerCloseModal} className="cache-modal">
				<div className="cache-modal__wrapper">
					<p className="cache-modal__title">Приветствуем!</p>
					<p className="cache-modal__text">
						Мы выпустили обновленную <br />
						версию maxiportal.ru
					</p>
					<Button
						handler={reload}
						className="refresh-cache-button"
						type="primary"
						typeButton="button"
					>
						<RefreshCw className="refresh-cache-button__icon" />
						<span className="refresh-cache-button__text">ОБНОВИТЬ</span>
					</Button>
				</div>
			</ModalComponent>
		)
	);
};
