import {
	SET_CLAIM_ITEM_INFO,
	CLEAR_CLAIM_ITEM_INFO,
	GET_CLAIM_IN_ADMIN,
	CLEAR_CLAIM_IN_ADMIN,
} from '../constant';

const DefaultState = {
	adminClaims: [],
	claimItem: null,
	history: null,
	errorMessage: '',
};

export default function claims(state = DefaultState, action) {
	switch (action.type) {
		case SET_CLAIM_ITEM_INFO:
			return {
				...state,
				claimItem: action.claimItem,
				errorMessage: action.errorMessage,
			};

		case CLEAR_CLAIM_ITEM_INFO:
			return {
				...state,
				claimItem: null,
			};

		case GET_CLAIM_IN_ADMIN:
			return {
				...state,
				adminClaims: [...state.adminClaims, ...action.claims],
			};

		case CLEAR_CLAIM_IN_ADMIN:
			return {
				...state,
				adminClaims: [],
			};

		default:
			return state;
	}
}
