import {
    SET_ARTICLE,
    FETCH_ARTICLE,
	FETCH_ARTICLE_SUCCESS,
    TOOGLE_LAST_PAGE_IN_ARTICLE,
    ADD_PUBLISH_ARTICLE,
    ADD_DRAFT_ARTICLE,
    CLEAR_ARTICLE,
    SET_ARTICLE_VIEW,
    CLEAR_ARTICLE_VIEW,
    ERROR_ARTICLE_VIEW,
    SET_ARTICLE_EDIT,
    CLEAR_ARTICLE_EDIT,
    DELETE_ARTICLE,
    EDIT_ARTICLE,
    ARTICLE_DRAFT_TO_PUBLISH
} from "../constant"

const DefaultState = {
    publish: [],
    draft: [],
    view: null,
    edit: null,
    viewError: false,
    isLoading: false,
	isLastPage: false,
	page: 0,
}

export default function article(state = DefaultState, action) {
    switch (action.type) {
        case SET_ARTICLE:
            return {
                ...state,
                publish: [...state.publish, ...action.article.publish],
                draft: action.article.draft,
				page: action.page
            }

        case FETCH_ARTICLE:
			return {
				...state,
				isLoading: true
			}

		case FETCH_ARTICLE_SUCCESS:
			return {
				...state,
				isLoading: false
			}

		case TOOGLE_LAST_PAGE_IN_ARTICLE:
			return {
				...state,
				isLastPage: true
			}
            
        case CLEAR_ARTICLE:
            return {
				...state,
				publish: [],
				draft: [],
				isLastPage: false,
				page: 0
            }

        case ADD_PUBLISH_ARTICLE:
            let addPublishArticleState = state
            if (addPublishArticleState.publish) {
                addPublishArticleState.publish = [
                    action.article,
                    ...addPublishArticleState.publish
                ]
            } else {
                addPublishArticleState.publish = [action.article]
            }
            return addPublishArticleState

        case ADD_DRAFT_ARTICLE:
            let addDraftArticleState = state
            if (addDraftArticleState.draft) {
                addDraftArticleState.draft = [
                    action.article,
                    ...addDraftArticleState.draft
                ]
            } else {
                addDraftArticleState.draft = [action.article]
            }
            return addDraftArticleState

        case SET_ARTICLE_VIEW:
            let setArticleStateView = state
            setArticleStateView.view = action.article
            return setArticleStateView

        case CLEAR_ARTICLE_VIEW:
            let clearArticleStateView = state
            clearArticleStateView.view = null
            clearArticleStateView.viewError = false
            return clearArticleStateView

        case SET_ARTICLE_EDIT:
            return {
                ...state,
                edit: action.article
            }

        case CLEAR_ARTICLE_EDIT:
            return {
                ...state,
                edit: null
            }

        case ERROR_ARTICLE_VIEW:
            let errorArticleStateView = state
            errorArticleStateView.viewError = true
            return errorArticleStateView

        case DELETE_ARTICLE:
            return {
                ...state,
                publish: state.publish ? deleteHelper(state.publish, action.id) : state.publish,
                draft: state.draft ? deleteHelper(state.draft, action.id) : state.draft
            }

        case EDIT_ARTICLE:
            const data = {
                id: action.id,
                filePath: action.filePath,
                name: action.name
            }
            return {
                ...state,
                publish: state.publish ? editHelper(state.publish, data) : state.publish,
                draft: state.draft ? editHelper(state.draft, data) : state.draft
            }

        case ARTICLE_DRAFT_TO_PUBLISH:
            let article = null
            if (state.draft) {
                article = state.draft.filter(
                    item => {return +item.id === +action.id}
                )
            }
            if (article) {
                return {
                    ...state,
                    publish: [...article, ...state.publish],
                    draft: state.draft.filter(
                        item => {return +item.id !== +action.id}
                    )
                }
            } else return state
        default:
            return state
    }
}

const deleteHelper = (arr, id) => {
    return arr.filter(item => {
        return +item.id !== +id
    })
}

const editHelper = (arr, data) => {
    return arr.map(item => {
        if (+item.id === +data.id) {
            let previewPicture = null
            if (data.filePath) {
                previewPicture = {
                    filePath: data.filePath
                }
            } else if (item.previewPicture) {
                previewPicture = item.previewPicture
            }
            item = {
                ...item,
                name: data.name,
                previewPicture
            }
        }
        return item
    })
}
