import { api } from './api'
import showNotification from './showNotification'

//Добавить стикер 
export const addStickers = (context) => {
    api.post('/stickers/add/', {
        stickerFileIds: context.state.stickerFileIds
    })
        .then(function (res) {
			const { errorMessage, response, responseCode } = res.data
            if (responseCode === 0) {
                showNotification(context, true, 0, `Стикеры добавлены`)
                context.props.addStickerItem(response)
                context.setState({
                    stickerUpload: [],
                    photosAdd: [],
                    stickerFileIds: []
                })
            } else {
                showNotification(context, true, 1, errorMessage)
            }
        })
        .catch(function (error) {
            showNotification(context, true, 1, `/stickers/add/ - ${error}`)
        });
}

//Получить список стикеров
export const stickersList = (context) => {
    api.get('/stickers/list')
        .then(function (response) {
            if (response.data.responseCode === 0) {
                context.props.getStickersList(response.data.response)
            } else {
                showNotification(context, true, 1, response.data.errorMessage)
            }
        })
        .catch(function (error) {
            showNotification(context, true, 1, `/stickers/list - ${error}`)
        })
}

//Удалить стикер
export const deleteSticker = (context, id) => {
    api.post('/stickers/delete/', {
        id: id,
    })
        .then(function (response) {
            if (response.data.responseCode === 0) {
                showNotification(context, true, 0, `Стикер удалён`)
                context.props.deleteStickerItem(id)
            } else {
                showNotification(context, true, 1, response.data.errorMessage)
            }
        })
        .catch(function (error) {
            showNotification(context, true, 1, `/stickers/delete/ - ${error}`)
        });
}