export const useGetIndexName = (name: string) => {
	const removeGetParam = () => {
		const location: any = document.location;
		const url = new URL(location);
		const searchParams = url.searchParams;
		searchParams.delete(name);
		window.history.replaceState('', document.title, url.toString());
	};

	const getParams = () => {
		// @ts-ignore
		const url = new URL(document.location);
		const params = url.searchParams;

		const param = params.get(name);

		removeGetParam();
		if (param === 'undefined') return undefined;
		return param;
	};

	return getParams();
};
