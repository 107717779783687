import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

const RadioButton = ({ label, status, handler }) => {
    return (
        <div onClick={handler} className="MIRadio">
            <span className={status ? "MIRadio__circle MIRadio__circle_active" : "MIRadio__circle"}></span>
            <span className="MIRadio__label">{label}</span>
        </div>
    )
}

RadioButton.propTypes = {
    label: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    handler: PropTypes.func.isRequired
}

RadioButton.defaultProps = {
    label: 'Радио кнопка',
    status: false,
    handler: e => e.preventDefault()
}

export default RadioButton