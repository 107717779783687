import {
	SET_SEARCH_RESULT,
	SET_SEARCH_RESULT_CATEGORY,
	CLEAR_SEARCH_RESULT,
	CLEAR_SEARCH_RESULT_PAGE,
	PUSH_SEARCH_RESULT_CATEGORY,
} from '../constant';

const DefaultState = {
	quick: null,
	full: null,
};

export default function search(state = DefaultState, action) {
	switch (action.type) {
		case SET_SEARCH_RESULT:
			const quickState = state;
			quickState.quick = action.result;
			return quickState;

		case SET_SEARCH_RESULT_CATEGORY:
			const fullState = state;
			fullState.full = action.result;
			return fullState;

		case PUSH_SEARCH_RESULT_CATEGORY:
			const newState = state;
			newState.full.objects = [...newState.full.objects, ...action.result.objects];
			return newState;

		case CLEAR_SEARCH_RESULT:
			return {
				quick: null,
				full: state.full,
			};

		case CLEAR_SEARCH_RESULT_PAGE:
			return {
				quick: null,
				full: null,
			};

		default:
			return state;
	}
}
