import React, { FC, ReactNode, cloneElement, ReactElement, useMemo } from 'react';
import cn from 'classnames';
import { URL } from 'constant';
import { ViewEditor } from 'components/editorjsText/editorHtml';

import { useShowEmiliaImage } from 'emilia/features/showImage';
import { useBanner, useDelayShow } from '../../model';
import { Image } from '../image';
import { Message } from '../message';

interface IComposeBannerProps {
	closeComponent: ReactNode;
}

export const ComposeBanner: FC<IComposeBannerProps> = ({ closeComponent }) => {
	const { isShow, onClose } = useShowEmiliaImage();
	const delayShow = useDelayShow();

	const banner = useBanner();

	const Close = useMemo(
		() => cloneElement(closeComponent as ReactElement, { onClose }),
		[closeComponent, onClose]
	);

	if (isShow && banner) {
		return (
			<div
				className={cn('emilia__compose-banner', {
					'emilia__compose-banner_show': delayShow,
				})}
			>
				<Message closeComponent={Close}>
					{banner.phrase && <ViewEditor content={banner.phrase} />}
				</Message>
				<Image src={`${URL}${banner.emiliaImage.image.filePath}`} />
			</div>
		);
	}

	return null;
};
