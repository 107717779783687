import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class Button extends Component {
	render() {
		const {
			type,
			typeButton = 'button',
			text,
			handler,
			image = null,
			idName,
			className,
			children,
			...other
		} = this.props;

		let rootClassName = 'main-btn';
		if (type) rootClassName += ` main-btn_${type}`;
		if (className) rootClassName += ` ${className}`;

		return (
			<button
				className={rootClassName}
				data-unhover={image}
				onClick={handler}
				id={idName}
				type={typeButton}
				{...other}
			>
				{image && (
					<svg role="img">
						<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#' + image} />
					</svg>
				)}
				<span>{text ? text : children}</span>
			</button>
		);
	}
}

Button.propTypes = {
	type: PropTypes.oneOf(['primary', 'secondary', 'special', 'secondary_special', 'done'])
		.isRequired,
	typeButton: PropTypes.oneOf(['submit', 'reset', 'button']),
	text: PropTypes.string,
	handler: PropTypes.func,
	image: PropTypes.string,
	idName: PropTypes.string,
	children: PropTypes.node,
};

export default Button;
