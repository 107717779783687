import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Star, X } from 'react-feather';
import classNames from 'classnames';

import {
	ArticleItem,
	ClubItem,
	ContactItem,
	DocsItem,
	GlossaryItem,
	GuideItem,
	PostItem,
	ServiceCategoryItem,
	UserItem,
} from '../../molecules';
import ShortFaqItem from 'modules/search/molecules/shortFaqItem';
import { getSearchHistoryList, searchHistoryDeleteRest } from 'rest/search';
import { NotificationHelper } from 'action/notificationHelper';
import useClickOutside from 'hooks/useClickOutside';
import { favoriteUpdateRest } from 'rest/post';

import './style.scss';
import Tooltip from 'core/components/tooltip';
import { getStatusesList } from 'rest/user';
import { Loader } from 'core/components';

const EntityComponents = new Map([
	['WIKI_GLOSSARY', GlossaryItem],
	['WIKI_DOCUMENT', DocsItem],
	['WIKI_FAQ', ShortFaqItem],
	['CONTACT', UserItem],
	['CATEGORY_CONTACT', ContactItem],
	['MAXI-CLUB-PARTNER', ClubItem],
	['WIKI_GUIDE', GuideItem],
	['USER_NOT_FIRE_NOT_CONTACT', UserItem],
	['ARTICLE', ArticleItem],
	['POST', PostItem],
	['SERVICE_CATEGORY', ServiceCategoryItem],
]);

export const SearchHistory = ({ closeHandle }) => {
	const historyRef = useRef(null);
	const dispatch = useDispatch();
	const [historyItems, setHistoryItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [statuses, setStatuses] = useState(null);
	useClickOutside(historyRef, closeHandle);

	useEffect(() => {
		(async () => {
			const { response, responseCode } = await getStatusesList();
			if (!responseCode) setStatuses(response);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			const { response, responseCode, errorMessage } = await getSearchHistoryList();

			if (responseCode) dispatch(NotificationHelper(errorMessage, responseCode));
			else {
				setHistoryItems(response);
			}
		})();
	}, [dispatch]);

	const renderItem = item => {
		const ResultEntity = EntityComponents.get(item.indexName);

		if (ResultEntity) {
			return (
				<ResultEntity
					{...item.object}
					statuses={statuses}
					indexName={item.indexName}
					closeHandle={closeHandle}
				/>
			);
		}
		return null;
	};

	const clickFavoriteHandle = async (entityId, favorite, type) => {
		const { responseCode, errorMessage } = await favoriteUpdateRest(entityId, !favorite, type);

		if (responseCode) dispatch(NotificationHelper(errorMessage, responseCode));
		else {
			dispatch(
				NotificationHelper(
					!favorite ? 'Добавлено в избранное' : 'Удалено из избранного',
					responseCode
				)
			);
			setHistoryItems(prev => {
				return prev.map(item => {
					if (item.object.id === entityId) item.favorite = !favorite;
					return item;
				});
			});
		}
	};

	const deleteHandle = async (entityId, indexName) => {
		setLoading(true);
		if (loading) return;

		const { response, responseCode, errorMessage } = await searchHistoryDeleteRest(
			entityId,
			indexName
		);

		if (responseCode) dispatch(NotificationHelper(errorMessage, responseCode));
		else {
			dispatch(NotificationHelper('Удалено из истории', responseCode));
			setHistoryItems(prev => {
				if (response)
					return [...prev.filter(item => item.object.id !== entityId), response];

				return prev.filter(item => item.object.id !== entityId);
			});
		}
		setLoading(false);
	};

	const renderResult = () => {
		return !!historyItems.length ? (
			historyItems.map(item => (
				<div className="search-history__item" key={item.object.id}>
					{typeof item.favorite === 'boolean' && (
						<Tooltip
							title={item.favorite ? 'Удалить из избранного' : 'Добавить в избранное'}
							placement="top"
						>
							<Star
								className={classNames('search-history__star', {
									filled: item.favorite,
								})}
								onClick={() =>
									clickFavoriteHandle(
										item.object.id,
										item.favorite,
										item.indexName
									)
								}
							/>
						</Tooltip>
					)}
					<div className="search-history__wrap">{renderItem(item)}</div>

					<X
						className="search-history__x"
						onClick={() => deleteHandle(item.object.id, item.indexName)}
					/>
				</div>
			))
		) : (
			<p className="search-history__empty">История переходов на результаты поиска пуста</p>
		);
	};

	return (
		<div className="search-history" ref={historyRef}>
			{typeof historyItems === 'undefined' ? <Loader /> : renderResult()}
		</div>
	);
};
