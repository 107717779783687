import React from 'react';
import Select from 'react-select';
import { selectStyles } from 'core/utils';
import './style.scss';

const FindSection = props => {
	return (
		<div className="find-section">
			<span className="find-section__label">Выберите раздел</span>
			<div className="find-section-body">
				<Select
					className="find-section__control"
					placeholder="Разделы"
					isSearchable={false}
					isClearable={false}
					onKeyDown={e => {
						e.preventDefault();
					}}
					styles={selectStyles}
					{...props}
				/>
			</div>
		</div>
	);
};

export default FindSection;
