import {
	SET_RETAIL_SHIFTS_LIST,
	ADD_RETAIL_SHIFTS_LIST,
	RETAIL_SHIFTS_CHANGE_STATUS,
	RETAIL_SHIFTS_CLEAR_LIST,
} from '../constant';

const DefaultState = [];

export default function retailShifts(state = DefaultState, action) {
	switch (action.type) {
		case ADD_RETAIL_SHIFTS_LIST:
			return [...state, ...action.list];

		case SET_RETAIL_SHIFTS_LIST:
			return action.list;

		case RETAIL_SHIFTS_CHANGE_STATUS:
			const newState = state.map(item => {
				if (item.id === action.data.id) {
					item.status = action.data.status;
				}
				return item;
			});
			return newState;

		case RETAIL_SHIFTS_CLEAR_LIST:
			return [];

		default:
			return state;
	}
}
