import React, { FC, useEffect, useState } from 'react';

import { FinderField } from 'components/finderField/feature/Field';
import { IContact, IProps } from '../model/types';
import { NavLink } from 'react-router-dom';
import { X } from 'react-feather';

export const FinderFieldMultiple: FC<IProps> = ({
	label,
	placeholder,
	description,
	name,
	searchType,
	id,
	inputRef,
	border,
	max,
	onSelected: selectedToParent,
	defaultOptions,
	addItemHandler,
	removeItemHandler,
	clearSelected = false,
	error,
	disabled = false,
	withEmail = false,
	limit = '10',
}) => {
	const [list, setList] = useState<IContact[] | undefined>();

	useEffect(() => {
		if (clearSelected) setList(undefined);
	}, [clearSelected]);

	useEffect(() => {
		if (defaultOptions?.length && list === undefined) {
			const temp: any = [];

			for (let i = 0; i < defaultOptions.length; i++) {
				const el = defaultOptions[i];

				if (temp.length === 0) temp.push(el);

				if (temp.some((item: any) => el.id !== item.id)) temp.push(el);
			}

			setList(temp);
		}
	}, [defaultOptions, list]);

	useEffect(() => {
		if (selectedToParent && list) selectedToParent(list);
	}, [selectedToParent, list]);

	const onSelected = (data: IContact) => {
		let available = false;

		if (max && list && list.length + 1 > max) return;

		if (list) {
			for (let index = 0; index < list.length; index++) {
				if (+list[index].id === +data.id) {
					available = true;
				}
			}
		}

		if (available) return;

		if (addItemHandler) addItemHandler(data);

		setList(prev => {
			if (prev) {
				return [...prev, data];
			} else {
				return [data];
			}
		});
	};

	const deleteItem = (deleteItem: IContact) => {
		if (!list) return;
		const newList = list.filter(item => item.id !== deleteItem.id);
		if (removeItemHandler) removeItemHandler(deleteItem);
		setList(newList);
	};

	return (
		<div className="finder-field-multiple">
			<FinderField
				id={id}
				name={name}
				label={label}
				placeholder={placeholder}
				onSelected={onSelected}
				searchType={searchType}
				inputRef={inputRef}
				multiple
				border={border}
				description={description}
				error={error}
				disabled={disabled}
				limit={limit}
			/>

			<div className="finder-field-multiple__list">
				{list &&
					list.map(item => (
						<div className="finder-field-multiple__item" key={item.id}>
							{['CONTACT', 'USER'].includes(searchType) ? (
								<NavLink
									to={`/user/${item.id}`}
									className="finder-field-multiple__name link"
								>
									{`${item.surname ? item.surname : ''} ${item.name} ${
										item.middlename ? item.middlename : ''
									} ${withEmail && !item.email ? '(Без E-mail)' : ''}`}
								</NavLink>
							) : (
								<span className="finder-field-multiple__name">{item.name}</span>
							)}

							<X
								onClick={() => deleteItem(item)}
								className="finder-field-multiple__icon"
							/>
						</div>
					))}
			</div>
		</div>
	);
};
