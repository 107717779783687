import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { ArrowLeft, ArrowRight } from 'react-feather';

const Header = ({
	month,
	year,

	className,
	arrowId,

	showMonthChangeButton = true,
	onNextClick,
	onPrevClick,

	canSwitchToMonth = false,
	onSwithToMonthClick,

	canSwitchToYear = false,
	setOpenSelectYear,
}) => {
	const onNextClickHandler = e => {
		e.preventDefault();
		if (onNextClick) {
			onNextClick();
		}
	};

	const onPrevClickHandler = e => {
		e.preventDefault();
		if (onPrevClick) {
			onPrevClick();
		}
	};

	const onSwithToMonthlickHandler = e => {
		e.preventDefault();
		if (canSwitchToMonth && onSwithToMonthClick) {
			onSwithToMonthClick();
		}
	};

	const onSwithToYearClickHandler = e => {
		e.preventDefault();
		if (canSwitchToYear && !!setOpenSelectYear) {
			setOpenSelectYear(prev => !prev);
		}
	};

	return (
		<header
			className={cn('common-calendar__header', {
				[className]: Boolean(className),
			})}
		>
			{showMonthChangeButton && (
				<div
					id={arrowId && arrowId + '-prev'}
					className="header__btn"
					onClick={onPrevClickHandler}
				>
					<ArrowLeft />
				</div>
			)}

			<div className="header__btn header__btn--text" onClick={onSwithToMonthlickHandler}>
				{month}
			</div>

			<div className="header__btn header__btn--text" onClick={onSwithToYearClickHandler}>
				{year}
			</div>

			{showMonthChangeButton && (
				<div
					id={arrowId && arrowId + '-next'}
					className="header__btn"
					onClick={onNextClickHandler}
				>
					<ArrowRight />
				</div>
			)}
		</header>
	);
};

export default Header;

Header.prototype = {
	month: PropTypes.string.isRequired,
	year: PropTypes.string.isRequired,

	className: PropTypes.string,
	showMonthChangeButton: PropTypes.bool,
	onNextClick: PropTypes.func,
	onPrevClick: PropTypes.func,

	canSwitchToMonth: PropTypes.bool,
	onSwithToMonthClick: PropTypes.func,

	canSwitchToYear: PropTypes.bool,
	setOpenSelectYear: PropTypes.func,
};
