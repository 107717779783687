import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'

import './style.scss'

export const MoreBtn = withRouter(({ title, link, location }) => {
    return (
		<Link
			to={{
				pathname: link,
				state: {
					...location.state,
					back: -1
				}
			}}
			className="more-btn"
		>
            <span className="more-btn__text">{title}</span>

            <svg role="img" className="more-btn__img">
                <use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
            </svg>
        </Link>
    )
})

MoreBtn.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
}

MoreBtn.defaultProps = {
    title: 'Подробнее'
}