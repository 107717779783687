import { MAXI_PROFESSIONAL_AWARDS, MAXI_PROFESSIONAL_NO_ACTIVE } from '../constant';

const DefaultState = {
	awards: [],
	noActive: [],
};

export default function maxiProfessional(state = DefaultState, action) {
	switch (action.type) {
		case MAXI_PROFESSIONAL_AWARDS: {
			return {
				...state,
				awards: action.awards,
			};
		}
		case MAXI_PROFESSIONAL_NO_ACTIVE: {
			return {
				...state,
				noActive: action.awards,
			};
		}

		default:
			return state;
	}
}
