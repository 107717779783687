import * as Dom from './dom';
import Caret from './caret';
import { IconListBulleted, IconListNumbered } from './icon';
import './style.scss';

export class NestedList {
	static get isReadOnlySupported() {
		return true;
	}

	static get enableLineBreaks() {
		return true;
	}

	static get toolbox() {
		return {
			icon: IconListNumbered,
			title: 'List',
		};
	}

	constructor({ data, config, api, readOnly }) {
		this.nodes = {
			wrapper: null,
		};
		this.api = api;
		this.readOnly = readOnly;
		this.config = config;
		this.defaultListStyle = this.config.defaultStyle === 'ordered' ? 'ordered' : 'unordered';
		const initialData = {
			style: this.defaultListStyle,
			items: [],
		};
		this.data = data && Object.keys(data).length ? data : initialData;
		this.caret = new Caret();
	}

	render() {
		this.nodes.wrapper = this.makeListWrapper(this.data.style, [this.CSS.baseBlock]);
		if (this.data.items.length) {
			this.appendItems(this.data.items, this.nodes.wrapper);
		} else {
			this.appendItems(
				[
					{
						content: '',
						items: [],
					},
				],
				this.nodes.wrapper
			);
		}

		if (!this.readOnly) {
			this.nodes.wrapper.addEventListener(
				'keydown',
				event => {
					switch (event.key) {
						case 'Enter':
							this.enterPressed(event);
							break;
						case 'Backspace':
							this.backspace(event);
							break;
						case 'Tab':
							if (event.shiftKey) {
								this.shiftTab(event);
							} else {
								this.addTab(event);
							}
							break;
						// no default
					}
				},
				false
			);
		}
		return this.nodes.wrapper;
	}

	renderSettings() {
		const tunes = [
			{
				name: 'unordered',
				label: this.api.i18n.t('Маркированный список'),
				icon: IconListBulleted,
			},
			{
				name: 'ordered',
				label: this.api.i18n.t('Нумерованный список'),
				icon: IconListNumbered,
			},
		];

		return tunes.map(tune => ({
			name: tune.name,
			icon: tune.icon,
			label: tune.label,
			isActive: this.data.style === tune.name,
			closeOnActivate: true,
			onActivate: () => {
				this.listStyle = tune.name;
			},
		}));
	}

	static get pasteConfig() {
		return {
			tags: ['OL', 'UL', 'LI'],
		};
	}

	onPaste(event) {
		const list = event.detail.data;
		this.data = this.pasteHandler(list);
		const oldView = this.nodes.wrapper;
		if (oldView) {
			oldView.parentNode.replaceChild(this.render(), oldView);
		}
	}

	pasteHandler(element) {
		const { tagName: tag } = element;
		let style;
		let tagToSearch;

		switch (tag) {
			case 'OL':
				style = 'ordered';
				tagToSearch = 'ol';
				break;
			case 'UL':
			case 'LI':
				style = 'unordered';
				tagToSearch = 'ul';
			// no default
		}

		const data = {
			style,
			items: [],
		};

		const getPastedItems = parent => {
			const children = Array.from(parent.querySelectorAll(`:scope > li`));
			return children.map(child => {
				const subItemsWrapper = child.querySelector(`:scope > ${tagToSearch}`);
				const subItems = subItemsWrapper ? getPastedItems(subItemsWrapper) : [];
				const content = child?.firstChild?.textContent || '';
				return {
					content,
					items: subItems,
				};
			});
		};

		data.items = getPastedItems(element);
		return data;
	}

	appendItems(items, parentItem) {
		items.forEach(item => {
			const itemEl = this.createItem(item.content, item.items);
			parentItem.appendChild(itemEl);
		});
	}

	createItem(content, items = []) {
		const itemWrapper = Dom.make('li', this.CSS.item);
		const itemBody = Dom.make('div', this.CSS.itemBody);
		const itemContent = Dom.make('div', this.CSS.itemContent, {
			innerHTML: content,
			contentEditable: !this.readOnly,
		});
		itemBody.appendChild(itemContent);
		itemWrapper.appendChild(itemBody);
		if (items && items.length > 0) {
			this.addChildrenList(itemWrapper, items);
		}
		return itemWrapper;
	}

	save() {
		const getItems = parent => {
			const children = Array.from(parent.querySelectorAll(`:scope > .${this.CSS.item}`));
			return children.map(el => {
				const subItemsWrapper = el.querySelector(`.${this.CSS.itemChildren}`);
				const content = this.getItemContent(el);
				const subItems = subItemsWrapper ? getItems(subItemsWrapper) : [];
				return {
					content,
					items: subItems,
				};
			});
		};

		return {
			style: this.data.style,
			items: getItems(this.nodes.wrapper),
		};
	}

	addChildrenList(parentItem, items) {
		const itemBody = parentItem.querySelector(`.${this.CSS.itemBody}`);
		const sublistWrapper = this.makeListWrapper(undefined, [this.CSS.itemChildren]);
		this.appendItems(items, sublistWrapper);
		itemBody.appendChild(sublistWrapper);
	}

	makeListWrapper(style = this.listStyle, classes = []) {
		const tag = style === 'ordered' ? 'ol' : 'ul';
		const styleClass =
			style === 'ordered' ? this.CSS.wrapperOrdered : this.CSS.wrapperUnordered;
		classes.push(styleClass);
		return Dom.make(tag, [this.CSS.wrapper, ...classes]);
	}

	get CSS() {
		return {
			baseBlock: this.api.styles.block,
			wrapper: 'cdx-nested-list',
			wrapperOrdered: 'cdx-nested-list--ordered',
			wrapperUnordered: 'cdx-nested-list--unordered',
			item: 'cdx-nested-list__item',
			itemBody: 'cdx-nested-list__item-body',
			itemContent: 'cdx-nested-list__item-content',
			itemChildren: 'cdx-nested-list__item-children',
			settingsWrapper: 'cdx-nested-list__settings',
			settingsButton: this.api.styles.settingsButton,
			settingsButtonActive: this.api.styles.settingsButtonActive,
		};
	}

	get listStyle() {
		return this.data.style || this.defaultListStyle;
	}

	set listStyle(style) {
		const lists = Array.from(this.nodes.wrapper.querySelectorAll(`.${this.CSS.wrapper}`));
		lists.push(this.nodes.wrapper);
		lists.forEach(list => {
			list.classList.toggle(this.CSS.wrapperUnordered, style === 'unordered');
			list.classList.toggle(this.CSS.wrapperOrdered, style === 'ordered');
		});
		this.data.style = style;
	}

	get currentItem() {
		let currentNode = window.getSelection().anchorNode;
		if (currentNode.nodeType !== Node.ELEMENT_NODE) {
			currentNode = currentNode.parentNode;
		}
		return currentNode.closest(`.${this.CSS.item}`);
	}

	enterPressed(event) {
		const currentItem = this.currentItem;
		event.stopPropagation();
		event.preventDefault();
		const isEmpty = this.getItemContent(currentItem).trim().length === 0;
		const isFirstLevelItem = currentItem.parentNode === this.nodes.wrapper;
		const isLastItem = currentItem.nextElementSibling === null;
		if (isFirstLevelItem && isLastItem && isEmpty) {
			this.getOutOfList()
			return;
		} else if (isLastItem && isEmpty) {
			this.unshiftItem();
			return;
		}
		const endingFragment = Caret.extractFragmentFromCaretPositionTillTheEnd();
		const endingHTML = Dom.fragmentToString(endingFragment);
		const itemChildren = currentItem.querySelector(`.${this.CSS.itemChildren}`);
		const itemEl = this.createItem(endingHTML, undefined);

		const childrenExist =
			itemChildren &&
			Array.from(itemChildren.querySelectorAll(`.${this.CSS.item}`)).length > 0;
		if (childrenExist) {
			itemChildren.prepend(itemEl);
		} else {
			currentItem.after(itemEl);
		}
		this.focusItem(itemEl);
	}

	unshiftItem() {
		const currentItem = this.currentItem;
		const parentItem = currentItem.parentNode.closest(`.${this.CSS.item}`);

		if (!parentItem) {
			return;
		}

		this.caret.save();
		parentItem.after(currentItem);
		this.caret.restore();

		const prevParentChildrenList = parentItem.querySelector(`.${this.CSS.itemChildren}`);
		const isPrevParentChildrenEmpty = prevParentChildrenList.children.length === 0;

		if (isPrevParentChildrenEmpty) {
			prevParentChildrenList.remove();
		}
	}

	getItemContent(item) {
		const contentNode = item.querySelector(`.${this.CSS.itemContent}`);
		if (Dom.isEmpty(contentNode)) {
			return '';
		}
		return contentNode.innerHTML;
	}

	focusItem(item, atStart = true) {
		const itemContent = item.querySelector(`.${this.CSS.itemContent}`);
		Caret.focus(itemContent, atStart);
	}

	getOutOfList() {
		this.currentItem.remove();
		this.api.blocks.insert();
		this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex());
	}

	backspace(event) {
		if (!Caret.isAtStart()) {
			return;
		}
		event.preventDefault();
		const currentItem = this.currentItem;
		const previousItem = currentItem.previousSibling;
		const parentItem = currentItem.parentNode.closest(`.${this.CSS.item}`);

		if (!previousItem && !parentItem) {
			return;
		}
		event.stopPropagation();

		let targetItem;

		if (previousItem) {
			const childrenOfPreviousItem = previousItem.querySelectorAll(`.${this.CSS.item}`);
			targetItem = Array.from(childrenOfPreviousItem).pop() || previousItem;
		} else {
			targetItem = parentItem;
		}

		const endingFragment = Caret.extractFragmentFromCaretPositionTillTheEnd();
		const endingHTML = Dom.fragmentToString(endingFragment);
		const targetItemContent = targetItem.querySelector(`.${this.CSS.itemContent}`);

		Caret.focus(targetItemContent, false);
		this.caret.save();
		targetItemContent.insertAdjacentHTML('beforeend', endingHTML);

		let currentItemSublistItems = currentItem.querySelectorAll(
			`.${this.CSS.itemChildren} > .${this.CSS.item}`
		);

		currentItemSublistItems = Array.from(currentItemSublistItems);
		currentItemSublistItems = currentItemSublistItems.filter(
			node => node.parentNode.closest(`.${this.CSS.item}`) === currentItem
		);
		currentItemSublistItems.reverse().forEach(item => {
			if (!previousItem) {
				currentItem.after(item);
			} else {
				targetItem.after(item);
			}
		});
		currentItem.remove();
		this.caret.restore();
	}

	addTab(event) {
		event.stopPropagation();
		event.preventDefault();
		const currentItem = this.currentItem;
		const prevItem = currentItem.previousSibling;
		const isFirstChild = !prevItem;
		if (isFirstChild) {
			return;
		}
		const prevItemChildrenList = prevItem.querySelector(`.${this.CSS.itemChildren}`);
		this.caret.save();
		if (prevItemChildrenList) {
			prevItemChildrenList.appendChild(currentItem);
		} else {
			const sublistWrapper = this.makeListWrapper(undefined, [this.CSS.itemChildren]);
			const prevItemBody = prevItem.querySelector(`.${this.CSS.itemBody}`);

			sublistWrapper.appendChild(currentItem);
			prevItemBody.appendChild(sublistWrapper);
		}
		this.caret.restore();
	}

	shiftTab(event) {
		event.stopPropagation();
		event.preventDefault();
		this.unshiftItem();
	}

	static joinRecursive(data) {
		return data.items.map(item => `${item.content} ${NestedList.joinRecursive(item)}`).join('');
	}

	static get conversionConfig() {
		return {
			export: data => {
				return NestedList.joinRecursive(data);
			},
			import: content => {
				return {
					items: [
						{
							content,
							items: [],
						},
					],
					style: 'unordered',
				};
			},
		};
	}
}
