import React, { forwardRef } from 'react';
import classNames from 'classnames';
import InputBase from '../inputBase';

import './style.scss';

const OutlinedInput = forwardRef(function OutlinedInput(props, ref) {
	const { className, fullWidth = false, type = 'text', defaultValue = '', ...other } = props;

	const renderSuffix = state => (
		<div
			className={classNames('outlined-input__line', {
				'outlined-input__line_disabled': state.disabled,
				'outlined-input__line_error': state.error,
				'outlined-input__line_filled': state.filled,
				'outlined-input__line_focused': state.focused,
			})}
		/>
	);

	return (
		<InputBase
			renderSuffix={renderSuffix}
			className={classNames('outlined-input', className)}
			fullWidth={fullWidth}
			type={type}
			ref={ref}
			defaultValue={defaultValue}
			{...other}
		/>
	);
});

export default OutlinedInput;
