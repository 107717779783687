import { STATEMENTS_COPY_CLAIM } from 'constant';

export default function copyStatements(state = null, action) {
	switch (action.type) {
		case STATEMENTS_COPY_CLAIM:
			return action.claim;

		default:
			return state;
	}
}
