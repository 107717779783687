import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Icon, TypeButton } from '@maxi-innovation/ui-kit-frontend';
import { TableHead, TableBody, TableRow, TableCell, Table } from 'core/components';
import { NotificationHelper } from 'action/notificationHelper';
import { deletePulseSurveyRest, getPulseSurveyListRest } from 'shared/api/survey';
import { IPulseSurvey } from 'entities/survey/pulseSurveyTypes';
import { IData, IFiltersData } from './types';
import { convertNormalDateWithTime } from 'core/utils';
import { RenderFor } from './renderFor';
import { Filters } from './Filters';
import { setPulseSurveyAction } from 'action/PulseSurvey';

import './style.scss';
import { ModalConfirmDelete } from 'shared/ui/ConfirmModal';

const pageSize = 25;

export const PulseSurveyTable = () => {
	const dispatch = useDispatch();
	const [list, setList] = useState<IPulseSurvey[]>();
	const [isLoading, setIsLoading] = useState(false);
	const [isLastPage, setIsLastPage] = useState(false);
	const [deleteItem, setDeleteItem] = useState<IPulseSurvey>();
	const [data, setData] = useState<IData>({
		pageNumber: 0,
		pageSize: pageSize,
		endPeriod: undefined,
		startPeriod: undefined,
	});

	const currentTime = useMemo(() => {
		return new Date().getTime();
	}, []);

	useEffect(() => {
		(async () => {
			const { response, responseCode, errorMessage } = await getPulseSurveyListRest(data);

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				if (data.pageNumber === 0) setList([...response]);
				else setList(prev => [...(prev ? prev : []), ...response]);

				setIsLastPage(response.length < pageSize);
			}
		})();
	}, [dispatch, setList, data]);

	const loadingHandle = () => {
		setIsLoading(true);
		setTimeout(() => setIsLoading(false), 2000);
	};

	const showMore = () => {
		loadingHandle();
		setData((prev: IData) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
	};

	const renderFor = (data: IPulseSurvey) => {
		interface ICombine {
			name: string;
			link?: string;
		}
		const combine: ICombine[] = [];
		data.forPositions.forEach(item => {
			combine.push({
				name: item.name,
			});
		});
		data.forDepartments.forEach(item => {
			combine.push({
				name: item.name,
			});
		});
		data.forUsers.forEach(item => {
			combine.push({
				name: `${item.surname} ${item.name}${item.middlename ? ` ${item.middlename}` : ''}`,
				link: `/user/${item.id}`,
			});
		});

		return <RenderFor combine={combine} key={data.id} />;
	};

	const filterHandle = (data: IFiltersData) => {
		loadingHandle();

		setData({
			pageNumber: 0,
			pageSize: pageSize,
			...data,
		});
	};

	const clearFilters = () => {
		loadingHandle();
		setData({
			pageNumber: 0,
			pageSize: pageSize,
			endPeriod: undefined,
			startPeriod: undefined,
		});
	};

	const onCopy = (data: IPulseSurvey) => {
		dispatch(setPulseSurveyAction(data));
	};

	const deletePulseSurvey = async (id: number, name: string) => {
		const { responseCode, errorMessage } = await deletePulseSurveyRest(id);

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
		} else {
			setList(prev => {
				return prev?.filter(item => item.id !== id);
			});
			dispatch(NotificationHelper(`Пульс-опрос «${name}» удалён`, responseCode));
			setDeleteItem(undefined);
		}
	};

	return (
		<div>
			<Filters filterHandle={filterHandle} clearFiltersHandle={clearFilters} />

			{list !== null &&
				(list?.length ? (
					<>
						<div className={classNames('pulse-survey-table', { loading: isLoading })}>
							{/* @ts-ignore */}
							<Table>
								<TableHead>
									<TableRow>
										<TableCell variant="head">Старт пульс-опроса</TableCell>
										<TableCell variant="head">Окончание пульс-опроса</TableCell>
										<TableCell variant="head">Вопрос</TableCell>
										<TableCell variant="head">Участники</TableCell>
										<TableCell variant="head"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{list.map(item => (
										<TableRow key={item.id}>
											<TableCell>
												<Link
													to={`/services/pulse-survey/item/${item.id}`}
													className="inline-link"
													id="pulse-survey_table_open-modal"
												>
													{convertNormalDateWithTime(item.startDate)}
												</Link>
											</TableCell>

											<TableCell>
												{convertNormalDateWithTime(item.endDate)}
											</TableCell>

											<TableCell>{item.question}</TableCell>

											<TableCell>{renderFor(item)}</TableCell>

											<TableCell>
												<div className="pulse-survey-table__control">
													{item.startDate > currentTime && (
														<NavLink
															to={`/services/pulse-survey/edit/${item.id}`}
															id="pulse-survey_table_edit"
														>
															<Icon
																iconName="Edit2"
																color="mc74"
																w={24}
																h={24}
																className="pulse-survey-table__icon"
															/>
														</NavLink>
													)}

													{item.startDate > currentTime && (
														<Icon
															onClick={() => setDeleteItem(item)}
															iconName="Trash"
															color="mc74"
															w={24}
															h={24}
															className="pulse-survey-table__icon"
															id="pulse-survey_table_delete"
														/>
													)}

													<NavLink
														to={`/services/pulse-survey/create`}
														id="pulse-survey_table_copy"
														onClick={() => onCopy(item)}
													>
														<Icon
															iconName="Copy"
															color="mc74"
															w={24}
															h={24}
															className="pulse-survey-table__icon"
														/>
													</NavLink>
												</div>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>

						{!isLastPage && (
							<Button
								variant={TypeButton.PRIMARY}
								type="button"
								onClick={showMore}
								id="pulse-survey_table_show-more"
							>
								Показать ещё
							</Button>
						)}

						{!!deleteItem && (
							<ModalConfirmDelete
								title="Удаление пульс-опроса"
								text={`Вы действительно хотите удалить пульс-опрос «${deleteItem.question}»?`}
								closeModal={() => setDeleteItem(undefined)}
								callback={() =>
									deletePulseSurvey(deleteItem.id, deleteItem.question)
								}
							/>
						)}
					</>
				) : (
					<div className="pulse-survey-stub">
						Пульс-опросы за выбранный период не найдены
					</div>
				))}
		</div>
	);
};
