import {
    GET_STICKERS_LIST,
    ADD_STICKERS,
    DELETE_STICKERS
} from '../constant'

const DefaultState = []

export default function stickers(state = DefaultState, action) {

    switch (action.type) {
        case GET_STICKERS_LIST:
            return [...action.list]

        case ADD_STICKERS:
            let addStickers = state
            return [...action.stickers, ...addStickers]

        case DELETE_STICKERS:
            let deleteStickers = state
            deleteStickers = deleteStickers.filter(
                (item) => {
                    return +item.id !== +action.id
                }
            )
            return deleteStickers

        default:
            return state
    }
}