import {
	SET_ALBUM_DATA,
	UPDATE_ALBUM_DATA,
	CLEAR_ALBUM_DATA,
	SET_PHOTOS_LIST,
	CLEAR_PHOTOS_LIST,
	ADD_PHOTO_TO_LIST,
	REMOVE_PHOTO_FROM_LIST,
	UPDATE_PHOTO_IN_ALBUM,
} from 'constant';

const DefaultState = {
	albumData: null,
	photosList: [],
};

export default function gallery(state = DefaultState, action) {
	switch (action.type) {
		case SET_ALBUM_DATA:
			return {
				...state,
				albumData: action.data,
			};

		case UPDATE_ALBUM_DATA:
			return {
				...state,
				albumData: action.data,
			};

		case CLEAR_ALBUM_DATA:
			return {
				...state,
				albumData: null,
			};

		case SET_PHOTOS_LIST:
			return {
				...state,
				photosList: action.list,
			};

		case CLEAR_PHOTOS_LIST:
			return {
				...state,
				photosList: [],
			};

		case ADD_PHOTO_TO_LIST:
			return {
				...state,
				photosList: [action.photo, ...state.photosList],
			};

		case REMOVE_PHOTO_FROM_LIST:
			let newPhotosList = state;
			newPhotosList.photosList = newPhotosList.photosList.filter(item => {
				return +item.id !== action.id;
			});
			return newPhotosList;

		case UPDATE_PHOTO_IN_ALBUM:
			let updatingPhotosList = state;
			let updatingPhoto = updatingPhotosList.photosList.find(
				item => +item.id === +action.photo.id
			);
			updatingPhoto.description = action.photo.description;
			return updatingPhotosList;

		default:
			return state;
	}
}
