import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TextField, Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';

import { useAuth } from 'feature/auth/model';
import { getRestPhone } from 'plugins/getRestPhone';
import { PasswordTextField } from 'shared/ui/PasswordTextField';

const validationSchema = yup.object().shape({
	username: yup
		.string()
		.required()
		.test('phone-format', 'Неверный формат номера телефона', value => {
			return value.length === 16;
		})
		.test('phone-format-number', 'Неверный формат номера телефона', value => {
			const phone = value.replace(/[^\d]/g, '').substr(1);
			return phone.length === 10;
		}),
	password: yup.string().required('Введите пароль'),
});

export const Form = () => {
	const { isLoading, errorMessage, onAuth } = useAuth();

	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const onSubmit = handleSubmit(async data => {
		const { username, password } = data;
		const clearUserName = getRestPhone(username);
		onAuth(clearUserName, password);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#entry-red-logo'} />
			</svg>
			{!!errorMessage && <span id="auth-form-error" className="auth-form-error-message">{errorMessage}</span>}
			<div className="auth-control-block">
				<TextField
					variant="default"
					ref={register}
					label="Телефон"
					name="username"
					id="auth-control-phone"
					error={errors?.username}
					errorMessage={errors?.username?.message}
					mask={{
						mask: '+{7} #00 000-00-00',
						definitions: {
							'#': /[01234569]/,
						},
						lazy: false,
						placeholderChar: '_',
					}}
				/>
			</div>
			<div className="auth-control-block auth-login-block">
			<PasswordTextField
						ref={register}
						name="password"
						className="auth-control_password"
						label="Пароль"
						id="auth-control-password"
						isError={!!errors?.password}
						//@ts-ignore
						errorMessage={errors?.password?.message}
					/>
			</div>
			<Button
				variant={TypeButton.PRIMARY}
				type="submit"
				image={null}
				className="auth-action-btn"
				disabled={isLoading}
				loading={isLoading}
				id="auth-btn"
			>
				Вход
			</Button>
			<div className="auth-form-footer">
				<Link to="/register" className="entryPage__link red-text" id="register-page">
					Регистрация
				</Link>
				<Link to="/recover" className="entryPage__link red-text" id="recover-password-page">
					Восстановление пароля
				</Link>
			</div>
		</form>
	);
};
