import React, { forwardRef } from 'react'
import classNames from 'classnames'
import './style.scss'

const FormCaption = forwardRef(function FormCaption(props, ref) {
    const {
        children,
        className,
        component: Component = 'p',
        disabled,
        error,
        filled,
        focused,
        margin,
        required,
        variant,
        ...other
    } = props

    return (
        <Component
            className={classNames(
                'form-caption',
                {
                    'form-caption_disabled': disabled,
                    'form-caption_error': error,
                    'form-caption_filled': filled,
                    'form-caption_focused': focused,
                    'form-caption_required': required,
                },
                className
            )}
            ref={ref}
            {...other}
        >
            {children === ' ' ? (
                <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
            ) : (
                children
            )}
        </Component>
    )
})

export default FormCaption
