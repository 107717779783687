import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { User, Star } from 'react-feather';

import store from 'store';
import { clearPosts } from 'action/feed';
import {
	mobileSearch,
	mobileSearchClose,
	mainMenuToggle,
	mainMenuClose,
	groupMenuClose,
	globalSearchResultClear,
	vacanciesFiltersClose,
} from 'action/ui';
import './style.scss';
import { MessagesLink } from 'modules/header/atoms';

class BottomMenu extends Component {
	render() {
		const { user, location } = this.props;
		const { id, countNotifications } = user;
		return (
			<Fragment>
				<div className="bottomMenu__nav">
					<NavLink
						strict
						to={`/main`}
						className="bottomMenu__item"
						data-view="feed"
						onClick={() => {
							this.panelsViewToDefault();
							if (location.pathname === '/main') {
								store.dispatch(clearPosts());
							}
						}}
					>
						<svg role="img">
							<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#home'}></use>
						</svg>
					</NavLink>
					<NavLink
						strict
						to={`/search`}
						className="bottomMenu__item"
						data-view="feed"
						onClick={this.panelsViewToDefault}
					>
						<svg role="img">
							<use
								xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#magnifier'}
							></use>
						</svg>
					</NavLink>
					<NavLink
						strict
						to={`/user/${id}`}
						className="bottomMenu__item"
						data-view="notification"
						onClick={this.panelsViewToDefault}
					>
						<User className="bottomMenu__item_packSvg user-notifications" />
						{countNotifications > 0 && (
							<span className="bottomMenu__item-ntfctns">{countNotifications}</span>
						)}
					</NavLink>
					<MessagesLink />
					<NavLink
						strict
						to={`/favorites?type=CONTACT`}
						className="bottomMenu__item"
						data-view="favorites"
						onClick={this.panelsViewToDefault}
					>
						<Star className="bottomMenu__item_packSvg user-favorites" />
					</NavLink>
					<NavLink
						strict
						to={`/menu`}
						className="bottomMenu__item"
						data-view="activities"
						onClick={this.panelsViewToDefault}
					>
						<svg role="img">
							<use
								xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#burger'}
							></use>
						</svg>
					</NavLink>
				</div>
			</Fragment>
		);
	}

	activeLinkrenderHelper = parameter => {
		if (parameter) {
			document.getElementById('wrapp').classList.remove('no-scroll');
			if (document.getElementsByClassName('bottomMenu__item active').length > 0) {
				document
					.getElementsByClassName('bottomMenu__item active')[0]
					.classList.remove('not-active');
			}
		} else {
			document.getElementById('wrapp').classList.add('no-scroll');
			if (document.getElementsByClassName('bottomMenu__item active').length > 0) {
				document
					.getElementsByClassName('bottomMenu__item active')[0]
					.classList.add('not-active');
			}
		}
	};

	panelsViewToDefault = () => {
		if (this.props.searchView) {
			this.props.mobileSearchClose();
			document.getElementById('wrapp').classList.remove('no-scroll');
			document
				.getElementsByClassName('bottomMenu__item active')[0]
				.classList.remove('not-active');
		}
		if (this.props.menuView) {
			this.props.mainMenuClose();
			document.getElementById('wrapp').classList.remove('no-scroll');
			document
				.getElementsByClassName('bottomMenu__item active')[0]
				.classList.remove('not-active');
		}
		if (this.props.vacFiltersView) {
			this.props.vacanciesFiltersClose();
			document.getElementById('wrapp').classList.remove('no-scroll');
		}
		if (this.props.groupMenuView) {
			this.props.groupMenuClose();
			document.getElementById('wrapp').classList.remove('no-scroll');
		}
	};
}

const mapStateToProps = state => {
	return {
		user: state.shortUserInfo,
		searchView: state.ui.searchView,
		menuView: state.ui.menuView,
		groupMenuView: state.ui.groupMenuView,
		vacFiltersView: state.ui.vacFiltersView,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		mobileSearch: () => {
			dispatch(mobileSearch());
		},
		mobileSearchClose: () => {
			dispatch(mobileSearchClose());
		},
		mainMenuToggle: () => {
			dispatch(mainMenuToggle());
		},
		mainMenuClose: () => {
			dispatch(mainMenuClose());
		},
		groupMenuClose: () => {
			dispatch(groupMenuClose());
		},
		globalSearchResultClear: () => {
			dispatch(globalSearchResultClear());
		},
		vacanciesFiltersClose: () => {
			dispatch(vacanciesFiltersClose());
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(BottomMenu)
);
