import React from 'react';

import {
	arrTypePost,
	arrTypeGroup,
	arrTypeThanks,
	arrTypeActivity,
	arrTypeTrip,
	arrTypeBusinessCard,
	arrTypeCongratulation,
	arrTypePass,
	arrTypeStatement,
	arrTypeIT,
	arrTypeITToOwner,
	arrTypeEducation,
	arrTypeDoctor,
	arrTypeDoctorForAdmin,
	arrTypeRetailShifts,
	arrTypeMaxiClubKpp,
	arrTypeHotLine,
	arrTypeVacationToOwner,
	arrTypeVacationToApprover,
	arrTypeCourierToApprover,
	arrTypeCourierToOwner,
	arrTypeWorkplace,
	arrTypeWorkplaceToApprover,
	arrTypeInventoryMove,
	arrTypeInventoryInfo,
} from './types';

export const UserAddon = ({ variables }) => {
	return variables.user.objectId ? (
		<a className="user-notification__link" href={`/user/${variables.user.objectId}`}>
			{variables.user.text}
		</a>
	) : (
		variables.user.text
	);
};

export const PostAddon = ({ variables }) => {
	const link = variables.group
		? `/group/${variables.group.objectId}/${variables.post.objectId}`
		: `/news/${variables.post.objectId}`;

	return (
		<a className="user-notification__link" href={link}>
			{variables.post.text}
		</a>
	);
};

export const GroupAddon = ({ variables, type }) => {
	return ['GROUP_CREATE_MODERATION', 'GROUP_CREATE_REJECT'].includes(type) ? (
		variables.group.text
	) : (
		<a className="user-notification__link" href={`/group/${variables.group.objectId}`}>
			{variables.group.text}
		</a>
	);
};

const getLink = (variables, type, shortUserInfo) => {
	let link = '';

	// Формирование ссылок для заявок
	// POSTS
	if (arrTypePost.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//GROUPS
	else if (arrTypeGroup.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//THANKS
	else if (arrTypeThanks.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//ACTIVITIES
	else if (arrTypeActivity.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//TRIPS
	else if (arrTypeTrip.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//BUSINESS CARD
	else if (arrTypeBusinessCard.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	//CONGRATULATIONS
	else if (arrTypeCongratulation.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//PASSES
	else if (arrTypePass.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//STATEMENTS
	else if (arrTypeStatement.includes(type))
		if (type === 'STATEMENT_COMPLETED') {
			link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
		} else {
			link = `/services/statements/list#${variables.claim.objectId}`;
		}
		//IT
	else if (arrTypeITToOwner.includes(type))
			link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	else if (arrTypeIT.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	//EDUCATION
	else if (arrTypeEducation.includes(type))
		if (type === 'EDUCATION_CREATE') {
			link = `/education/list#${variables.claim.objectId}`;
		} else {
			link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
		}
	//DOCTOR
	else if (arrTypeDoctorForAdmin.includes(type))
		link = `/services/doctor/all#${variables.claim.objectId}`;
	else if (arrTypeDoctor.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	//RETAIL SHIFTS
	else if (arrTypeRetailShifts.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	//MaxiCardKPP
	else if (arrTypeMaxiClubKpp.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	//COURIER
	else if (arrTypeCourierToApprover.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	else if (arrTypeCourierToOwner.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	//HOT LINE
	else if (arrTypeHotLine.includes(type))
		if (type === 'GROUP_MESSAGE_NEW_FOR_ADMIN') {
			link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
		} else {
			link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
		}
	// VACATION
	else if (arrTypeVacationToOwner.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	else if (arrTypeVacationToApprover.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	// WORKPLACE
	else if (arrTypeWorkplace.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	else if (arrTypeWorkplaceToApprover.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	// INVENTORY
	else if (arrTypeInventoryInfo.includes(type))
		link = `/user/${shortUserInfo.id}/claims/${variables.claim.objectId}`;
	else if (arrTypeInventoryMove.includes(type))
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	// GOAL
	else if (type === 'DEVELOPMENT_GOAL_MODERATION')
		link = `/user/${shortUserInfo.id}/approvement-requests/${variables.claim.objectId}`;
	else link = '';

	return link;
};

export const ClaimAddon = ({ variables, type, shortUserInfo }) => {
	const link = getLink(variables, type, shortUserInfo);

	return link ? (
		<a className="user-notification__link" href={link}>
			{variables.claim.text}
		</a>
	) : (
		variables.claim.text
	);
};

export const CongratulationAddon = ({ variables, type, shortUserInfo }) => {
	let userId;

	if (type === 'CONGRATULATION_LIKE_CREATE') {
		userId = variables.user.objectId;
	} else {
		userId = shortUserInfo.id;
	}

	return (
		<a
			className="user-notification__link"
			href={`/user/${userId}/congratulation/${variables.congratulation.objectId}`}
		>
			{variables.congratulation.text}
		</a>
	);
};

export const ThanksAddon = ({ variables, type, shortUserInfo }) => {
	let userId;

	if (type === 'THANKS_LIKE_CREATE') {
		userId = variables.user.objectId;
	} else {
		userId = shortUserInfo.id;
	}

	return (
		<a
			className="user-notification__link"
			href={`/user/${userId}/${variables.thanks.objectId}`}
		>
			{variables.thanks.text}
		</a>
	);
};

export const ActivityAddon = ({ variables }) => {
	return (
		<a className="user-notification__link" href={`/activities/${variables.activity.objectId}`}>
			{variables.activity.text}
		</a>
	);
};

export const ActivityMemberAddon = ({ variables }) => {
	return (
		<a className="user-notification__link" href={`/user/${variables.activity_member.objectId}`}>
			{variables.activity_member.text}
		</a>
	);
};

export const AchievementAddon = ({ variables, shortUserInfo }) => {
	return (
		<a
			className="user-notification__link"
			href={`/user/${shortUserInfo.id}/achievement/${variables.achievement?.objectId}`}
		>
			достижение
		</a>
	);
};

export const DevelopmentGoalAddon = ({ variables, type }) => {
	return ['DEVELOPMENT_GOAL_COMPLETED', 'DEVELOPMENT_GOAL_CANCELED'].includes(type) ? (
		<a
			className="user-notification__link"
			href={`/ltr/user/${variables.user.objectId}#${variables.goal.objectId}`}
		>
			{variables.goal.text}
		</a>
	) : (
		<a
			className="user-notification__link"
			href={`/ltr${variables.goal.objectId ? `#${variables.goal.objectId}` : ''}`}
		>
			{variables.goal.text}
		</a>
	);
};

export const SurveyAddon = ({ variables }) => {
	return (
		<a
			className="user-notification__link"
			href={`/services/survey/take/${variables.survey.objectId}`}
		>
			{variables.survey.text}
		</a>
	);
};

export const MaxiClubAddon = ({ variables }) => {
	return (
		<a className="user-notification__link" href="/club/maxi-club-kpp?openModal=true">
			{variables.create_claim.text}
		</a>
	);
};
