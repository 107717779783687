import React, { FC, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';

import { SingleQuestion } from './Single';
import { MultipleQuestion } from './Multiple';
import { ScaleQuestion } from './Scale';
import { TextQuestion } from './Text';
import {
	IQuestionsTypes,
	IQuestionComponentProps,
	IQuestion,
	IAnswered,
	IDefaultValue,
} from '../types';
import { NotificationHelper } from 'action/notificationHelper';
import { answerQuestionRest } from 'shared/api/survey';

const questionsTypes: IQuestionsTypes = {
	SINGLE: SingleQuestion,
	MULTIPLE: MultipleQuestion,
	SCALE: ScaleQuestion,
	TEXT: TextQuestion,
};

interface IProps {
	question: IQuestion;
	surveyId: number;
	firstQuestionId: number;
	goToNextQuestion: (questionId: number) => void;
	goToPrevQuestion: (questionId: number) => void;
	defaultValue?: IDefaultValue;
}

export const TakeQuestion: FC<IProps> = ({
	question,
	surveyId,
	goToNextQuestion,
	goToPrevQuestion,
	defaultValue,
	firstQuestionId,
}) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [answered, setAnswered] = useState<IAnswered[] | string | undefined>();

	const answeredHandler = useCallback((data: IAnswered[] | string | undefined) => {
		setAnswered(data);
		setError(false);
	}, []);

	const renderHelper = (question: IQuestion) => {
		if (!question) return;
		const Component: FC<IQuestionComponentProps> = questionsTypes[question.answerType];

		return (
			<Component
				question={question}
				answeredHandler={answeredHandler}
				error={error}
				defaultValue={defaultValue}
			/>
		);
	};

	const nextStepHandler = async () => {
		if (!answered) {
			setError(true);
			return;
		}

		setIsLoading(true);

		const { responseCode, errorMessage } = await answerQuestionRest({
			surveyId,
			questionId: question.id,
			...(typeof answered === 'string'
				? { textAnswer: answered }
				: {
						selectedAnswerId: answered.map(item => item.id),
						textAnswer: answered.find(item => item.ownAnswer)?.text,
				  }),
		});

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
			setIsLoading(false);
		} else {
			goToNextQuestion(question.id);
		}
	};

	return (
		<div className="take-question">
			<div className="take-question__wrap">{!!question && renderHelper(question)}</div>

			<div className="take-question__wrap">
				<div className="question-controll">
					{firstQuestionId !== question.id && (
						<Button
							variant={TypeButton.QUATERNARY}
							onClick={() => goToPrevQuestion(question.id)}
						>
							назад
						</Button>
					)}

					<Button
						variant={TypeButton.PRIMARY}
						className="question-controll__button"
						onClick={nextStepHandler}
						disabled={isLoading}
					>
						Далее
					</Button>
				</div>
			</div>
		</div>
	);
};
