import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { notificationAllViewAction } from 'action/notification';

import './style.scss';

export const UserNotificationView = ({ className }) => {
	const dispatch = useDispatch();
	const readAllNotifications = () => {
		dispatch(notificationAllViewAction());
	};

	return (
		<button
			className={classNames('read-all', {
				[className]: !!className,
			})}
			onClick={readAllNotifications}
		>
			<svg role="img" className="read-all__icon">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#status-satisfy'}></use>
			</svg>
			<span className="read-all__text">Прочитать все</span>
		</button>
	);
};
