import {
	GET_CONTACTS_CATEGORIES,
	GET_CONTACTS_TOP_CATEGORIES,
	CONTACTS_EDIT_MODE,
	GET_RECENT_CONTACTS_LIST,
	GET_CONTACTS_CATEGORY_INFO,
	CLEAR_CONTACTS_CATEGORY_INFO,
	GET_FAVORITE_CONTACTS_LIST,
	GET_FAVORITE_CONTACTS_IDS_LIST,
	ADD_ANOTHER_CONTACT,
	UPDATE_ANOTHER_CONTACT,
	DELETE_ANOTHER_CONTACT,
	CHANGE_CONTACTS_SORT
} from 'constant';

const DefaultState = {
	categoriesList: null,
	topCategories: null,
	editModeOn: false,
	recentContacts: null,
	categoryInfo: null,
	favoritesList: null,
    favoritesIdsList: null
};

export default function contacts(state = DefaultState, action) {

	switch (action.type) {
		case GET_CONTACTS_CATEGORIES:
			return {
				...state,
				categoriesList: action.list
			}

		case GET_CONTACTS_TOP_CATEGORIES:
			return {
				...state,
				topCategories: action.list
			}

		case CONTACTS_EDIT_MODE:
			return {
				...state,
				editModeOn: !state.editModeOn
			}

		case GET_RECENT_CONTACTS_LIST:
			return {
				...state,
				recentContacts: action.list
			}

		case GET_CONTACTS_CATEGORY_INFO:
			return {
				...state,
				categoryInfo: action.info
			}
		
		case CLEAR_CONTACTS_CATEGORY_INFO:
			return {
				...state,
				categoryInfo: null
			}

		case GET_FAVORITE_CONTACTS_LIST:
            return {
                ...state,
                favoritesList: action.list
            }

        case GET_FAVORITE_CONTACTS_IDS_LIST:
            return {
                ...state,
                favoritesIdsList: action.list
			}
			
		case ADD_ANOTHER_CONTACT:
			let addCategoryInfo = state
			addCategoryInfo.categoryInfo.contacts = [...addCategoryInfo.categoryInfo.contacts, action.contact]
			return addCategoryInfo

		case DELETE_ANOTHER_CONTACT:
			let deleteCategoryInfo = state
			deleteCategoryInfo.categoryInfo.contacts = deleteCategoryInfo.categoryInfo.contacts.filter(
				item => {
					return +item.id !== +action.id
				}
			)
			return deleteCategoryInfo

		case UPDATE_ANOTHER_CONTACT:
			let updateCategoryInfo = state
			updateCategoryInfo.categoryInfo.contacts = updateCategoryInfo.categoryInfo.contacts.map(
				item => {
					if (+item.id === +action.info.id) {
						item = action.info
						return item
					} else {
						return item
					}
				}
			)
			return updateCategoryInfo

		case CHANGE_CONTACTS_SORT:
			let changeSortInCategoryInfo = state
			changeSortInCategoryInfo.categoryInfo.contacts = changeSortInCategoryInfo.categoryInfo.contacts.map(
				item => {
					if (+item.id === +action.info.id) {
						item.sort = +action.info.sort
						return item
					} else {
						return item
					}
				}
			)
			return changeSortInCategoryInfo

		default:
			return state
	}

}