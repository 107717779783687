import { api } from 'rest/api';
import {
	IFileSave,
	IGetUSersFromExcelResponse,
	IGetUSersFromExcelRest,
	IStatementsMessageCreateRequest,
	IservicesCategoriesCreateReact,
	IservicesCategoriesUpdateReact,
} from './types';

function createError(error: any, defaultValue: any = null) {
	return {
		responseCode: 1,
		errorMessage: typeof error === 'object' ? 'Ошибка сети' : error,
		response: defaultValue,
	};
}

//проверка прав на создание заявки по типу
export const checkPermissionServiceRest = async (type: string) => {
	try {
		const response = await api.get('/services/permission/check', {
			params: {
				userClaimType: type,
			},
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

//получение информации о пользователе
export const getFullUserInfo = async (id: number) => {
	try {
		const response = await api.get('/user/full-user-info', {
			params: {
				id,
			},
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

//получение технического сообщения
export const getMessageRest = async (categoryId: number, type: 'INFO' | 'FAQ') => {
	try {
		const response = await api.get('/services/technical-messages/get-by-category', {
			params: { categoryId, type },
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

//редактирование технического сообщения
export const updateMessageRest = async (id: string | number, message: string) => {
	try {
		const response = await api.post('/services/technical-messages/update', { id, message });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

//создание технического сообщения
export const createMessageRest = async (data: IStatementsMessageCreateRequest) => {
	try {
		const response = await api.post('/services/technical-messages/create', data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

//удаление технического сообщения
export const deleteMessageRest = async (id: number) => {
	try {
		const response = await api.post('/services/technical-messages/delete', { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const fileSave = async (data: IFileSave) => {
	try {
		const response = await api.post(`/file/save`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const servicesCategoriesCreateReact = async (data: IservicesCategoriesCreateReact) => {
	try {
		const response = await api.post(`/services/categories/create`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const serviceCategoryGetTreeReact = async (type: string) => {
	try {
		const response = await api.get(`/services/categories/tree-service-category`, {
			params: {
				serviceCategoryType: type,
			},
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const servicesCategoriesUpdateReact = async (data: IservicesCategoriesUpdateReact) => {
	try {
		const response = await api.post(`/services/categories/update`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const servicesCategoriesDeleteReact = async (id: number) => {
	try {
		const response = await api.post(`/services/categories/delete`, { id });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getCitiesList = async () => {
	try {
		const response = await api.get(`/cities/list`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getFAQList = async (id: number) => {
	try {
		const response = await api.get('/services/categories/list-faq', { params: { id } });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const deleteFAQList = async (data: { id: number; wikiFAQId: number }) => {
	try {
		const response = await api.post('/services/categories/delete-faq', data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getServiceIdRest = async (type: string) => {
	try {
		const response = await api.get('/services/categories/get-by-type', { params: { type } });
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getUsersFromExcel = async (
	data: IGetUSersFromExcelRest
): Promise<IGetUSersFromExcelResponse> => {
	try {
		const response = await api.post('/user/list-from-excel', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};
