export const scrollToogleClass = (element, scrollValue, className) => {
    if (window.pageYOffset >= scrollValue) {
        document.querySelector(element).classList.add(className)
    } else {
        document.querySelector(element).classList.remove(className)
    }
}

export const scrollToogleFunction = (scrollValue, callBackValueGreater, callBackValueLess) => {
    if (window.pageYOffset >= scrollValue) {
        if (callBackValueGreater) { callBackValueGreater() }
    } else {
        if (callBackValueLess) { callBackValueLess() }
    }
}