import React, { FC } from 'react';
import { X } from 'react-feather';
import { useClose } from '../../model';

interface ICloseProps {
	onClose?: () => void;
}

export const Close: FC<ICloseProps> = ({ onClose }) => {
	const onCloseClick = useClose();

	const onCloseHandler = () => {
		if (onClose) {
			onClose();
		}
		onCloseClick();
	};

	return (
		<button className="emilia__close-image" onClick={onCloseHandler}>
			<X className="close-image__icon" />
		</button>
	);
};
