import React, { forwardRef } from 'react';
import './style.scss';

const Table = forwardRef(function Table(props, ref) {
	return (
		<div className="mpui-table-wrap">
			<table className="mpui-table" {...props} ref={ref}></table>
		</div>
	);
});

export default Table;
