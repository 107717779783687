import { SET_DOCUMENTS_LIST, CLEAR_DOCUMENTS_LIST, SORT_DOCUMENT_LIST } from 'constant';

export const DefaultState = {
	list: null,
	listError: false,
	themeTitle: '',
};

export default function documents(state = DefaultState, action) {
	switch (action.type) {
		case SET_DOCUMENTS_LIST:
			return {
				...state,
				list: action.list,
				themeTitle: action.themeTitle,
			};

		case CLEAR_DOCUMENTS_LIST:
			return {
				...state,
				list: null,
				themeTitle: '',
			};

		case SORT_DOCUMENT_LIST:
			const { list } = state;
			const newList =
				action.dropIndex < action.dragIndex
					? [
							...list.filter((_item, index) => index < action.dropIndex),
							list[action.dragIndex],
							...list.filter(
								(_item, index) =>
									index >= action.dropIndex && index !== action.dragIndex
							),
					  ]
					: action.dropIndex > action.dragIndex
					? [
							...list.filter(
								(_item, index) =>
									index <= action.dropIndex && index !== action.dragIndex
							),
							list[action.dragIndex],
							...list.filter((_item, index) => index > action.dropIndex),
					  ]
					: list;
			return {
				...state,
				list: newList,
			};

		default:
			return state;
	}
}
