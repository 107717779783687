import { SET_LTR_LIST, UPDATE_LTR_LIST, ADD_LTR_LIST } from '../constant';

const DefaultState = null;

export default function ltr(state = DefaultState, action) {
	switch (action.type) {
		case SET_LTR_LIST:
			return action.data;

		case ADD_LTR_LIST:
			return [action.data, ...state];

		case UPDATE_LTR_LIST:
			return state.map(item => {
				if (item.id === action.data.id) return action.data;
				else return item;
			});

		default:
			return state;
	}
}
