import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const DayItem = ({
	className,

	day,
	month,
	year,

	disableTo,
	disableFrom,

	isCurrent = false,
	isAvailable = false,
	isSelected = false,
	isDisabled = false,

	onDayClick,
	daysId,
}) => {
	const [disabledDays, setDisabled] = useState(false);

	useEffect(() => {
		if (disableTo) {
			const date = new Date(year, month, day);
			setDisabled(disableTo > date.getTime());
		}
	}, [year, month, day, disableTo]);

	useEffect(() => {
		if (disableFrom) {
			const date = new Date(year, month, day);
			setDisabled(disableFrom < date.getTime());
		}
	}, [year, month, day, disableFrom]);

	const onDayClickHandler = e => {
		e.preventDefault();
		if (onDayClick && !isDisabled && !disabledDays) {
			const date = new Date(year, month, day);
			onDayClick(date.getTime());
		}
	};

	return (
		<div
			id={daysId && daysId + '-' + day}
			onClick={onDayClickHandler}
			className={cn('common-calendar__day', {
				'common-calendar__day_current': isCurrent,
				'common-calendar__day_available': isAvailable,
				'common-calendar__day_selected': isSelected,
				'common-calendar__day_disabled': isDisabled || disabledDays,
				[className]: Boolean(className),
			})}
		>
			{day}
		</div>
	);
};

export default DayItem;

DayItem.prototype = {
	className: PropTypes.string,

	day: PropTypes.number.isRequired,
	month: PropTypes.number.isRequired,
	year: PropTypes.number.isRequired,

	isCurrent: PropTypes.bool,
	isAvailable: PropTypes.bool,
	isSelected: PropTypes.bool,
	isDisabled: PropTypes.bool,
	onDayClick: PropTypes.func,
};
