import { WELCOME_MESSAGE } from '../constant';

const DefaultState = false;

export default function welcomeMessage(state = DefaultState, action) {
	switch (action.type) {
		case WELCOME_MESSAGE:
			return action.show;

		default:
			return state;
	}
}
