import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Avatar = props => {
	const { image, type, size } = props;
	let result = null;
	let styleName = 'avatar';
	if (size === 'large') {
		styleName += ' avatar_large';
	} else if (size === 'small') {
		styleName += ' avatar_small';
	}

	if (image === null && type === 'user') {
		result = (
			<svg role="img" className={styleName}>
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#no-avatar'}></use>
			</svg>
		);
	} else if (image === null && type === 'group') {
		result = (
			<svg role="img" className={styleName}>
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#group-nologo'}></use>
			</svg>
		);
	} else if (image === null && type === 'gift') {
		result = (
			<svg role="img" className={styleName} style={{ fill: 'transparent' }}>
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#gift'}></use>
			</svg>
		);
	} else if (image !== null) {
		result = <img src={image} alt="" className={styleName} />;
	}

	return result;
};

Avatar.propTypes = {
	image: PropTypes.string,
	type: PropTypes.oneOf(['user', 'group', 'gift']).isRequired,
	size: PropTypes.oneOf(['large', 'small']),
};

Avatar.defaultProps = {
	image: null,
	type: 'user',
	size: 'small',
};

export default Avatar;
