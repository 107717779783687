import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider } from '@maxi-innovation/ui-kit-frontend';
import 'intersection-observer';

import { ROUTER_PATH } from './constant';

import Store from './store';
import App from './modules/App';
import LikePostContextProvider from 'contexts/LikePostContextProvider';
import './index.scss';

import BrowserDetect from './plugins/browserDetect';

BrowserDetect.init();
//@ts-ignore
if (BrowserDetect.browser !== 'Explorer') {
	ReactDOM.render(
		<Provider store={Store}>
			<BrowserRouter basename={ROUTER_PATH}>
				<QueryParamProvider ReactRouterRoute={Route}>
					<ThemeProvider>
						<LikePostContextProvider>
							<App />
						</LikePostContextProvider>
					</ThemeProvider>
				</QueryParamProvider>
			</BrowserRouter>
		</Provider>,
		document.getElementById('root')
	);
} else {
	//@ts-ignore
	let node = document.getElementById('browser-detect').cloneNode(true);
	let container = document.getElementById('root');
	//@ts-ignore
	container.appendChild(node);
}
