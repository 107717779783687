import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import './style.scss'

export const CreateBtnIcon = ({ linkTo }) => {
    return (
        <NavLink to={linkTo} className="create-btn-icon">
            <svg role="img">
                <use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#plus'}></use>
            </svg>
            <span>Создать раздел</span>
        </NavLink>
    )
}

CreateBtnIcon.propTypes = {
    linkTo: PropTypes.string.isRequired
}