import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './style.scss';
import { getChildCategoriesList, sortCategoryList } from 'rest/guide';
import { useIsMobile } from 'hooks/useIsMobile';
import { NotificationHelper } from 'action/notificationHelper';

export const AsideMenuLink = withRouter(
	({
		hasChildren,
		id,
		name,
		categoriesList,
		canEdit,
		parent,
		toUp,
		toDown,
		location,
		top = false,
	}) => {
		const dispatch = useDispatch();
		const isMobile = useIsMobile();
		const [isOpen, toogleOpen] = useState(false);
		const [childCategories, setChildCategories] = useState([]);

		useEffect(() => {
			if (isOpen) {
				(async () => {
					const { response, responseCode, errorMessage } = await getChildCategoriesList(
						id
					);

					if (!responseCode) {
						setChildCategories(response);
					} else {
						dispatch(NotificationHelper(errorMessage, 1));
					}
				})();
			} else {
				setChildCategories([]);
			}
		}, [isOpen, categoriesList, id, dispatch]);

		const openChildrenDropdown = () => {
			toogleOpen(!isOpen);
		};

		function moveChildCategory(currentIndex, newIndex) {
			const newChildCategories = [...childCategories];

			newChildCategories[currentIndex] = childCategories[newIndex];
			newChildCategories[newIndex] = childCategories[currentIndex];

			setChildCategories(newChildCategories);
			sortCategoryList({
				sortIdList: newChildCategories.map(category => category.id),
			});
		}

		function toUpHandler(index) {
			if (index > 0) {
				moveChildCategory(index, index - 1);
			}
		}

		function toDownHandler(index) {
			if (index < childCategories.length - 1) {
				moveChildCategory(index, index + 1);
			}
		}

		const { state } = location;

		return (
			<Fragment>
				<div className="Guide--aside-menu__item">
					{hasChildren && (
						<svg
							role="img"
							className={
								isOpen
									? 'Guide--aside-menu__item-icon opened'
									: 'Guide--aside-menu__item-icon'
							}
							onClick={openChildrenDropdown}
						>
							<use
								xlinkHref={
									process.env.PUBLIC_URL + '/img/sprite.svg#bold-mini-arrow'
								}
							/>
						</svg>
					)}

					{!isMobile && canEdit && (
						<NavLink
							exact
							to={{
								pathname: top
									? `/knowledge/guide/${id}/edit`
									: `/knowledge/guide/${id}/edit-child`,
								state: {
									...state,
									back: state && !!state.back ? state.back - 1 : -1,
								},
							}}
							className="Guide--aside-menu__edit"
						>
							<svg role="img">
								<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#pen'} />
							</svg>
						</NavLink>
					)}

					<NavLink
						exact
						to={parent ? `/knowledge/guide/${id}` : `/knowledge/guide/${id}`}
						className="Guide--aside-menu__link"
						onClick={openChildrenDropdown}
						title={name}
						children={name}
					/>

					{!isMobile && canEdit && (
						<div className="Guide--aside-menu__item-sort">
							<svg
								role="img"
								onClick={toUp}
								className="Guide--aside-menu__item-sort_arrow Guide--aside-menu__item-sort_arrow-up"
							>
								<use
									xlinkHref={
										process.env.PUBLIC_URL + '/img/sprite.svg#mini-arrow'
									}
								></use>
							</svg>
							<svg
								role="img"
								onClick={toDown}
								className="Guide--aside-menu__item-sort_arrow Guide--aside-menu__item-sort_arrow-down"
							>
								<use
									xlinkHref={
										process.env.PUBLIC_URL + '/img/sprite.svg#mini-arrow'
									}
								></use>
							</svg>
						</div>
					)}
				</div>
				{childCategories.map((item, index) => (
					<div className="Guide--aside-menu__item-wrapper" key={item.id}>
						<AsideMenuLink
							{...item}
							categoriesList={categoriesList}
							canEdit={!isMobile && item.canEdit}
							parent={item.parent}
							toUp={() => toUpHandler(index)}
							toDown={() => toDownHandler(index)}
						/>
					</div>
				))}
			</Fragment>
		);
	}
);

AsideMenuLink.propTypes = {
	hasChildren: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
};
