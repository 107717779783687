import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import VMasker from 'vanilla-masker';

import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';

import useClickOutside from 'hooks/useClickOutside';
import Calendar from 'core/calendar';

import './style.scss';

export const Datepicker = ({
	error,
	clearDate,
	setClearDate,
	initialValue = null,
	name,
	refRegister,
	inputRef,
	onChange,
	onInput,
	placeholder,
	maskPattern = '99.99.9999',
	borders = false,
	id,
	disabled,
	disabledPrevDays,
	disableTo,
	disableFrom,
}) => {
	const seporator = maskPattern.match(/\D/)[0];
	const unixToNormalFormatDate = useCallback(
		date => {
			if (!date) return;

			let day = getDate(date);
			if (day < 10) day = `0${day}`;
			let month = getMonth(date) + 1;
			if (month < 10) month = `0${month}`;

			return day + seporator + month + seporator + getYear(date);
		},
		[seporator]
	);

	const refInput = inputRef ? inputRef : React.createRef();
	const datepickerRef = useRef(null);
	const [isOpen, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [unixDate, setUnixDate] = useState(null);
	const [selectedDays, setSelectedDays] = useState([]);

	const classes = {
		datepicker: classNames('datepicker', {
			error: error,
			open: isOpen,
		}),
		input: classNames('datepicker-field__input', {
			'border-botom': !borders,
			'border-full': borders,
		}),
	};

	useEffect(() => {
		if (!clearDate) return;

		if (typeof clearDate === 'number') {
			setInputValue(unixToNormalFormatDate(clearDate));
			setUnixDate(clearDate);
			setSelectedDays([clearDate]);
		} else if (typeof clearDate === 'boolean') {
			setInputValue('');
			setUnixDate(null);
			setSelectedDays([]);
		}

		if (setClearDate) setClearDate(false);
	}, [clearDate, setClearDate, unixToNormalFormatDate]);

	useEffect(() => {
		if (initialValue) {
			setInputValue(unixToNormalFormatDate(initialValue));
			setUnixDate(initialValue);
			setSelectedDays([initialValue]);
		}
	}, [initialValue, unixToNormalFormatDate]);

	useEffect(() => {
		if (onChange) onChange(unixDate);
	}, [unixDate, onChange]);

	useEffect(() => {
		if (refInput?.current) VMasker(refInput.current).maskPattern(maskPattern);
	}, [refInput, maskPattern]);

	const toggleDropdown = () => setOpen(prev => !prev);
	const closeDropdown = () => setOpen(false);
	useClickOutside(datepickerRef, closeDropdown);

	const changeInputhandler = event => {
		const date = event.target.value;
		setInputValue(date);

		// ожидаю дату в формате ДД.ММ.ГГГГ (разделитель любой)
		const tempArr = date.split(seporator);
		const formatedDate = tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0];

		// проверяю формат ГГГГ-ММ-ДД
		if (formatedDate.match(/^(\d{4})-(0\d|1[0-2])-(0\d|[12]\d|3[01])$/)) {
			const unixDate = new Date(formatedDate).getTime();
			setUnixDate(unixDate);
			setSelectedDays([unixDate]);
		} else {
			setUnixDate(null);
			setSelectedDays([]);
		}
	};

	const changeDate = event => {
		const date = +event.target.value;
		setSelectedDays([date]);
		setUnixDate(date);

		const formatedDate = unixToNormalFormatDate(date);

		setInputValue(formatedDate);
		closeDropdown();
	};

	const onInputHandle = event => onInput && onInput(event);

	return (
		<div className={classes.datepicker} ref={datepickerRef}>
			<div className="datepicker-field" onClick={toggleDropdown}>
				<input
					type="text"
					className={classes.input}
					value={inputValue}
					name={name}
					ref={e => {
						if (refRegister) refRegister(e, {}); // ожидаю функцию регистрации от react-hook-form
						refInput.current = e;
					}}
					onChange={changeInputhandler}
					onInput={onInputHandle}
					placeholder={placeholder}
					autoComplete="off"
					id={id}
					disabled={disabled}
				/>
				{!borders && <div className="datepicker-field__border-animation" />}
			</div>

			<div className="datepicker-drop">
				<Calendar
					onChange={changeDate}
					selectedDays={selectedDays}
					canSwitchToYear={true}
					isOpenDatepicker={isOpen}
					disabledPrevDays={disabledPrevDays}
					disableTo={disableTo}
					disableFrom={disableFrom}
				/>
			</div>
		</div>
	);
};
