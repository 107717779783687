import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export class Textarea extends Component {
	constructor(props) {
		super(props);

		this.refText = props.textareaRef ? props.textareaRef : React.createRef();
	}

	render() {
		let { id, className, handler, placeholder } = this.props;
		return (
			<div
				id={id}
				className={`MI-textarea ${className}`}
				contentEditable="true"
				suppressContentEditableWarning="true"
				placeholder={placeholder}
				onInput={handler}
				onBlur={handler}
				onPaste={this.pasteHandler}
				ref={this.refText}
			></div>
		);
	}

	componentDidMount() {
		this.refText.current.innerHTML = this.props.value;
	}

	componentDidUpdate() {
		this.refText.current.innerHTML = this.props.value;
	}

	shouldComponentUpdate() {
		return false;
	}

	pasteHandler = e => {
		e.preventDefault();
		const text = (e.originalEvent || e).clipboardData.getData('text/plain');
		window.document.execCommand('insertText', false, text);
	};
}

Textarea.propTypes = {
	id: PropTypes.string,
	handler: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.string.isRequired,
	className: PropTypes.string,
};

Textarea.defaultProps = {
	id: '',
	handler: e => {
		console.log(e);
	},
	placeholder: 'Что у вас нового?',
	value: '',
	className: '',
};
