class KeyboardNav {
	_list;
	_controlElement;
	_focusIndex = -1;
	_onChangeFocusIndex;

	constructor(list, controlElement, forFindItemBlock = false, onChangeFocusIndex) {
		this._handleKeyDown = this._handleKeyDown.bind(this);

		this._forFindItemBlock = forFindItemBlock;
		this._list = list;
		this._controlElement = controlElement;
		this._onChangeFocusIndex = onChangeFocusIndex;
	}

	getList() {
		return this._list;
	}

	setList(list) {
		this.moveFocus(-1);
		this._list = list;
	}

	getControlElement() {
		return this._controlElement;
	}

	setControlElement(controlElement) {
		this._controlElement = controlElement;
	}

	getFocusIndex() {
		return this._focusIndex;
	}

	setFocusIndex(focusIndex) {
		const prevFocusIndex = this._focusIndex;
		const prevItem = this._list[prevFocusIndex];
		const nextItem = this._list[focusIndex];

		this._focusIndex = focusIndex;

		if (this._onChangeFocusIndex) {
			this._onChangeFocusIndex({
				prevItem,
				nextItem,
			});
		}
	}

	activateNav() {
		window.addEventListener('keydown', this._handleKeyDown);
	}

	deactivateNav() {
		window.removeEventListener('keydown', this._handleKeyDown);
	}

	moveFocus(nextIndex) {
		const nextItem = this._list[nextIndex];
		if (nextItem) {
			nextItem.focus();
			this.setFocusIndex(nextIndex);
		} else {
			if (this._controlElement) {
				if (!this._forFindItemBlock) this._controlElement.focus();
				this.setFocusIndex(-1);
			} else {
				this.setFocusIndex(0);
			}
		}
	}

	_handleKeyDown(event) {
		const key = event.key;

		if (key === 'ArrowDown') {
			event.preventDefault();
			this.moveFocus(this._focusIndex + 1);
		} else if (key === 'ArrowUp') {
			event.preventDefault();
			this.moveFocus(this._focusIndex - 1);
		} else if (key === 'Enter') {
			if (!this._forFindItemBlock) this.setFocusIndex(-1);
		}
	}
}

export default KeyboardNav;
