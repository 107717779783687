import React, { Component } from 'react'

export class InputNameBlock extends Component {

	state = {
		currentName: ''
	}

	render() {
		return (
			<div className="MIInput__text">
				<input
					placeholder="Введите название раздела"
					id="name"
					name="name"
					type="text"
					value={this.state.currentName}
					autoComplete="off"
					onChange={this.handler}
				/>
				<span className="MIInput__bar"></span>
			</div>
		)
	}

	componentDidMount() {
        let { name } = this.props
        if (name) {
            this.setState({
				currentName: name
			})
        }
	}
	
	componentDidUpdate(prevProps) {
		let { name } = this.props
        if (prevProps.name !== this.props.name) {
			this.setState({
				currentName: name
			})
        }
	}
	
	handler = (e) => {
		e.preventDefault()
		this.setState({
			currentName: e.currentTarget.value
		})
	}
}