import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FormInputBlock, InputsDateTime } from 'core/components';
import { IPulseSurveyDataForRHF } from 'entities/survey/pulseSurveyTypes';
import {
	updatePulseSurveyEndDateAction,
	updatePulseSurveyStartDateAction,
} from 'action/PulseSurvey';
import { useFormContext } from 'react-hook-form';

interface IProps {
	endDate: number | undefined;
	startDate: number | undefined;
}

const millisecondsInDay = 86400000;

export const DateFields: FC<IProps> = ({ endDate, startDate }) => {
	const dispatch = useDispatch();
	const { register, unregister, setValue, errors } = useFormContext<IPulseSurveyDataForRHF>();

	useEffect(() => {
		register('startDate');
		register('endDate');
		return () => {
			unregister('startDate');
			unregister('endDate');
		};
	}, [register, unregister]);

	useEffect(() => {
		setValue('startDate', startDate);
	}, [setValue, startDate]);

	useEffect(() => {
		setValue('endDate', endDate);
	}, [setValue, endDate]);

	const dateChange = useCallback(
		(time: string, date: number, name: string) => {
			if (time && date) {
				const timeArr = time.split(':');
				const unixDate = Number(date) + (+timeArr[0] * 3600 + +timeArr[1] * 60) * 1000;

				if (name === 'startDate') {
					dispatch(updatePulseSurveyStartDateAction(unixDate));
				} else {
					dispatch(updatePulseSurveyEndDateAction(unixDate));
				}
			}
		},
		[dispatch]
	);

	const onInputHandle = useCallback(
		(name: string) => {
			if (name === 'startDate') {
				dispatch(updatePulseSurveyStartDateAction(undefined));
			} else {
				dispatch(updatePulseSurveyEndDateAction(undefined));
			}
		},
		[dispatch]
	);

	return (
		<FormInputBlock>
			<div className="pulse-survey-form-date">
				<div className="pulse-survey-form-date__item">
					<InputsDateTime
						id="pulse-survey_form_start-date"
						timePickerId="pulse-survey_form_start-time"
						error={!!errors.startDate}
						title="Дата и время начала пульс-опроса"
						onChange={dateChange}
						onInput={onInputHandle}
						name={'startDate'}
						disabledPrevDays={true}
						defaultDate={startDate}
						disableFrom={endDate}
					/>
				</div>

				<div className="pulse-survey-form-date__item">
					<InputsDateTime
						id="pulse-survey_form_end-date"
						timePickerId="pulse-survey_form_end-time"
						error={!!errors.endDate}
						title="Дата и время окончания пульс-опроса"
						onChange={dateChange}
						onInput={onInputHandle}
						name={'endDate'}
						disabledPrevDays={true}
						disableTo={startDate ? startDate - millisecondsInDay : undefined}
						defaultDate={endDate}
					/>
				</div>
			</div>
		</FormInputBlock>
	);
};
