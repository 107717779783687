import {
	SET_ADMINS_PASSES_LIST,
	FETCH_ADMINS_PASSES_LIST,
	FETCH_ADMINS_PASSES_LIST_SUCCESS,
	TOOGLE_LAST_PAGE_IN_ADMINS_PASSES_LIST,
	CLEAR_ADMINS_PASSES_LIST,
	SET_PASSES_INFO,
	CLEAR_PASSES_INFO,
	CHANGE_PASSES_CLAIM_STATUS,
} from 'constant';

const DefaultState = {
	passesClaims: [],
	isLoading: false,
	isLastPage: false,
	page: 0,
	passesClaim: null,
	history: null,
};

export default function passes(state = DefaultState, action) {
	switch (action.type) {
		case FETCH_ADMINS_PASSES_LIST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_ADMINS_PASSES_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
			};

		case SET_ADMINS_PASSES_LIST:
			return {
				...state,
				passesClaims: [...state.passesClaims, ...action.passesClaims],
				page: action.page,
			};

		case TOOGLE_LAST_PAGE_IN_ADMINS_PASSES_LIST:
			return {
				...state,
				isLastPage: true,
			};

		case CLEAR_ADMINS_PASSES_LIST:
			return {
				...state,
				passesClaims: [],
				isLastPage: false,
				page: 0,
			};

		case SET_PASSES_INFO:
			return {
				...state,
				passesClaim: action.passesClaim,
			};

		case CLEAR_PASSES_INFO:
			return {
				...state,
				passesClaim: null,
			};

		case CHANGE_PASSES_CLAIM_STATUS:
			let changeStatus = state;
			let passesClaim = changeStatus.passesClaims.find(item => +action.claimId === +item.id);
			passesClaim.status = action.status;
			return changeStatus;

		default:
			return state;
	}
}
