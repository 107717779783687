import {
    GET_VACANCIES_LIST,
    VACANCY_INFO,
    VACANCIES_LIST_PARAMS,
    VACANCIES_LIST_PARAMS_UPDATE,
    VACANCIES_CITIES_LIST
} from '../constant'

const DefaultState = {
    vacanciesListParams: null,
    vacanciesList: null,
    vacancy: null,
    vacanciesCities: null
};

export default function vacancies(state = DefaultState, action) {

    switch (action.type) {
        case GET_VACANCIES_LIST:
            return {...state, ...{vacanciesList: action.vacancies}}

        case VACANCY_INFO:
            return {...state, ...{vacancy: action.vacancy}}

        case VACANCIES_LIST_PARAMS:
            let setParamsList = state
            setParamsList.vacanciesListParams = action.params
            return setParamsList

        case VACANCIES_LIST_PARAMS_UPDATE:
            let newVacanciesParams = state
            newVacanciesParams.vacanciesListParams = {...newVacanciesParams.vacanciesListParams, ...action.params}
            return newVacanciesParams

        case VACANCIES_CITIES_LIST:
            let getCitiesList = state
            getCitiesList.vacanciesCities = action.citiesList
            return getCitiesList
            
        default:
            return state
    }

}