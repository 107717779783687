import {
    SET_GROUP_LIST,
    SORT_GROUP,
    SET_GROUP_USER_LIST
} from '../constant';

const DefaultState = {
    all: [],
    user: []
};

export default function groupList(state = DefaultState, action) {

    switch (action.type) {
        case SET_GROUP_LIST:
            let allGroup = state
            allGroup.all = [...action.groupList]
            return allGroup

        case SET_GROUP_USER_LIST:
            let userGroup = state
            userGroup.user = [...action.groupList]
            return userGroup

        case SORT_GROUP:
            let sortGroup = state
            sortGroup.all = sortGroup.all.map(
                (item) => {
                    if (item.id === action.group.id) {
                        item = action.group
                    }
                    return item
                }
            ).sort(
                (a, b) => {
                    if (b.sort !== a.sort) {
                        return b.sort - a.sort
                    } else {
                        if (a.name < b.name) {
                            return -1
                        } else if (a.name > b.name) {
                            return 1
                        } else return 0
                    }
                }
            )
            sortGroup.user = sortGroup.user.map(
                (item) => {
                    if (item.id === action.group.id) {
                        item = action.group
                    }
                    return item
                }
            ).sort(
                (a, b) => {
                    if (b.sort !== a.sort) {
                        return b.sort - a.sort
                    } else {
                        if (a.name < b.name) {
                            return -1
                        } else if (a.name > b.name) {
                            return 1
                        } else return 0
                    }
                }
            )
            return sortGroup

        default:
            return state
    }

}