import { api } from 'rest/api';
import {
	CreateEmiliaPhraseRequest,
	CreateImageData,
	GetEmiliaImageIdRequest,
	GetEmiliaPhraseIdRequest,
	UpdateEmiliaImageRequest,
	UpdateEmiliaPhraseRequest,
} from './types';

function createError(error: any) {
	return {
		responseCode: 1,
		errorMessage: error,
		response: null,
	};
}

//Images
export const getImageList = async () => {
	try {
		const response = await api.get('/emilia/image/list');
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const createImage = async (data: CreateImageData) => {
	try {
		const response = await api.post('/emilia/image/add', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const editImage = async (data: UpdateEmiliaImageRequest) => {
	try {
		const response = await api.post('/emilia/image/edit', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const deleteImage = async (id: number) => {
	try {
		const response = await api.post('/emilia/image/delete', {
			id,
		});
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

//Phrase
export const getPhraseList = async (data: GetEmiliaImageIdRequest) => {
	const { id } = data;
	try {
		const response = await api.get(`/emilia/phrase/list?id=${id}`);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const addPhrase = async (data: CreateEmiliaPhraseRequest) => {
	try {
		const response = await api.post('/emilia/phrase/add', { ...data });
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getPhraseById = async (data: GetEmiliaPhraseIdRequest) => {
	try {
		const { id } = data;
		const response = await api.get(`/emilia/phrase/get?id=${id}`);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const editPhrase = async (data: UpdateEmiliaPhraseRequest) => {
	try {
		const response = await api.post(`/emilia/phrase/edit`, {
			...data,
		});
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const deletePhrase = async (data: GetEmiliaPhraseIdRequest) => {
	try {
		const response = await api.post('/emilia/phrase/delete', { ...data });
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

//Banner
export const getBanner = async () => {
	try {
		const response = await api.get('/emilia/banner');
		return response.data;
	} catch (error) {
		return createError(error);
	}
};
