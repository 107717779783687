import { VACATION_COPY_CLAIM } from 'constant';

export default function vacation(state = null, action) {
	switch (action.type) {
		case VACATION_COPY_CLAIM:
			return action.claim;

		default:
			return state;
	}
}
