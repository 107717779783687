export const secondsToTime = (time) => {
    let fulltime = ''
    let h = Math.floor(time / (60 * 60))
    let dm = time % (60 * 60)
    let m = Math.floor(dm / 60)
    let ds = dm % 60
    let s = Math.round(ds)
    if (s === 60) {
        s = 0
        m = m + 1
    }
    if (s < 10) {
        s = '0' + s
    }
    if (m === 60) {
        m = 0
        h = h + 1
    }
    if (m < 10) {
        m = '0' + m
    }
    if (h === 0) {
        fulltime = m + ':' + s
    } else {
        fulltime = h + ':' + m + ':' + s
    }
    return fulltime
}