import { api } from './api'

//Предложить исправление по справочнику
//message string
//sourceURL string
export const suggestContactsCorrection = (data) => {
	return api.post('/suggest-correction/contact', {
		...data
	})
}

//Предложить исправление по Гиду
//message string
//sourceURL string
//wikiGuideCategoryId integer (int64)
export const suggestGuideCorrection = (data) => {
	return api.post('/suggest-correction/wiki/guide', {
		...data
	})
}