import {
	GUIDE_EDIT_MODE,
	GUIDE_EDIT_MODE_ON,
	GET_TOP_CATEGORIES_LIST,
	GET_CHILD_CATEGORIES_LIST,
	ADD_GUIDE_CATEGORY,
	SET_GUIDE_CATEGORY_INFO,
	CLEAR_GUIDE_CATEGORY_INFO,
	UPDATE_GUIDE_CATEGORY_INFO,
	DELETE_GUIDE_CATEGORY,
	GET_GUIDE_CATEGORY_CHILDREN,
	CLEAR_GUIDE_CHILDREN_LIST,
	GET_CATEGORY_ARTICLES_LIST,
	GET_GUIDE_ARTICLE_INFO,
	CLEAR_GUIDE_ARTICLE_INFO,
	UPDATE_GUIDE_ARTICLE_INFO,
	SHOW_ACCESS_LIST,
	SORT_ARTICLE_LIST,
	CLEAR_GUIDE_ARTICLE_LIST,
	SET_PARENT_CATEGORY,
} from 'constant';

const DefaultState = {
	editModeOn: false,
	categoriesList: null,
	category: null,
	childrenList: null,
	articlesList: null,
	article: null,
	showAccessList: false,
	parentCategory: null,
};

export default function guide(state = DefaultState, action) {
	switch (action.type) {
		case GUIDE_EDIT_MODE:
			return {
				...state,
				editModeOn: !state.editModeOn,
			};

		case GUIDE_EDIT_MODE_ON:
			return {
				...state,
				editModeOn: true,
			};

		case SET_PARENT_CATEGORY:
			return {
				...state,
				parentCategory: action.category,
			};

		case GET_TOP_CATEGORIES_LIST:
			return {
				...state,
				categoriesList: action.list,
			};

		case GET_CHILD_CATEGORIES_LIST:
			return {
				...state,
				categoriesList: state.categoriesList.map(item => {
					if (+item.id === +action.parentId) {
						item.childrenList = action.category;
						return item;
					} else {
						return item;
					}
				}),
			};

		case ADD_GUIDE_CATEGORY:
			return {
				...state,
				categoriesList: [action.category, ...state.categoriesList],
			};

		case SET_GUIDE_CATEGORY_INFO:
			return {
				...state,
				category: action.category,
			};

		case CLEAR_GUIDE_CATEGORY_INFO:
			return {
				...state,
				category: null,
			};

		case UPDATE_GUIDE_CATEGORY_INFO:
			return {
				...state,
				categoriesList: state.categoriesList.map(item => {
					if (+item.id === +action.category.id) {
						item = action.category;
						return item;
					} else {
						return item;
					}
				}),
				category: action.category,
			};

		case DELETE_GUIDE_CATEGORY:
			return {
				...state,
				categoriesList: state.categoriesList.filter(item => {
					return +item.id !== +action.id;
				}),
			};

		case GET_GUIDE_CATEGORY_CHILDREN:
			return {
				...state,
				childrenList: action.childrenList,
			};

		case CLEAR_GUIDE_CHILDREN_LIST:
			return {
				...state,
				childrenList: null,
			};

		case GET_CATEGORY_ARTICLES_LIST:
			return {
				...state,
				articlesList: action.articlesList,
			};

		case SORT_ARTICLE_LIST:
			const newList = state.articlesList;
			newList[action.dropIndex] = [
				newList[action.dragIndex],
				(newList[action.dragIndex] = newList[action.dropIndex]),
			][0];
			return {
				...state,
				articlesList: newList,
			};

		case GET_GUIDE_ARTICLE_INFO:
			return {
				...state,
				article: action.article,
			};

		case CLEAR_GUIDE_ARTICLE_INFO:
			return {
				...state,
				article: null,
			};

		case CLEAR_GUIDE_ARTICLE_LIST:
			return {
				...state,
				articlesList: null,
			};

		case UPDATE_GUIDE_ARTICLE_INFO:
			return {
				...state,
				article: action.article,
			};

		case SHOW_ACCESS_LIST:
			return {
				...state,
				showAccessList: action.status,
			};

		default:
			return state;
	}
}
