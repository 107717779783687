import React from 'react';

import { BlockTitle, BlockResultLink } from '../../atoms';

import { GlossaryItem } from '../glossaryItem';
import { UserItem } from '../userItem';
import { ContactItem } from '../contactsItem';
import ShortFaqItem from '../shortFaqItem';
import { ClubItem } from '../clubItem';
import { GuideItem } from '../guideItem';
import { DocsItem } from '../docsItem';
import { ArticleItem } from '../articleItem';
import { PostItem } from '../postItem';
import { ServiceCategoryItem } from '../serviceCategoryItem';

import './style.scss';

const EntityName = {
	WIKI_GLOSSARY: 'Глоссарий',
	WIKI_DOCUMENT: 'Нормативные документы',
	WIKI_FAQ: 'FAQ',
	CONTACT: 'Контакты',
	CATEGORY_CONTACT: 'Подразделения',
	'MAXI-CLUB-PARTNER': 'МаксиКлуб',
	WIKI_GUIDE: 'Гид по компании',
	USER_NOT_FIRE_NOT_CONTACT: 'Прочие контакты',
	ARTICLE: 'Статьи',
	POST: 'Публикации',
	SERVICE_CATEGORY: 'ИТ-услуги',
};

const EntityComponents = new Map([
	['WIKI_GLOSSARY', GlossaryItem],
	['WIKI_DOCUMENT', DocsItem],
	['WIKI_FAQ', ShortFaqItem],
	['CONTACT', UserItem],
	['CATEGORY_CONTACT', ContactItem],
	['MAXI-CLUB-PARTNER', ClubItem],
	['WIKI_GUIDE', GuideItem],
	['USER_NOT_FIRE_NOT_CONTACT', UserItem],
	['ARTICLE', ArticleItem],
	['POST', PostItem],
	['SERVICE_CATEGORY', ServiceCategoryItem],
]);

export const Block = ({ item, userStatuses }) => {
	return (
		<div className="search-block">
			<div className="block-header">
				<BlockTitle>{EntityName[item.indexName]}</BlockTitle>
				<BlockResultLink total={item.total} type={item.indexName} />
			</div>
			<div className="block-content">
				{item.objects.map(res => {
					const ResultEntity = EntityComponents.get(item.indexName);

					if (ResultEntity) {
						return (
							<ResultEntity
								key={res.id}
								{...res}
								statuses={userStatuses}
								indexName={item.indexName}
							/>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
};
