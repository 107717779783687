import { SET_ROLES_LIST, ADD_NEW_ADMIN, DELETE_ADMIN } from '../constant'

const DefaultState = [];

export default function setRolesList(state = DefaultState, action) {

    switch (action.type) {
        case SET_ROLES_LIST:
            return [...action.rolesList]

        case ADD_NEW_ADMIN:
            return [...state, action.user]
        
        case DELETE_ADMIN:
            let newAdminsList = state;
            newAdminsList.filter(
                (item) => {
                    return +item.id !== +action.id
                }
            )
            return [...newAdminsList]

        default:
            return state
    }

}