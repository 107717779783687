import { SET_RME_ID, SET_FAQ_HELP_ID } from 'constant';

import { getOptions } from 'rest/options';

export const setRmeId = name => {
	return dispatch => {
		return getOptions(name)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SET_RME_ID,
						id: +res.data.response.value,
					});
				} else {
					console.error(res.data.errorMessage);
				}
			})
			.catch(() => {
				console.error('Ошибка получения id РМЕ');
			});
	};
};

export const setFaqHelpId = name => {
	return dispatch => {
		return getOptions(name)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SET_FAQ_HELP_ID,
						id: +res.data.response.value,
					});
				} else {
					console.error(res.data.errorMessage);
				}
			})
			.catch(() => {
				console.error('Ошибка получения id раздела FAQ "Помощь"');
			});
	};
};
