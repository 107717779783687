import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Clock } from 'react-feather';
import classNames from 'classnames';

import { search, clearSearch } from 'action/search';

import './style.scss';

const InputSearch = ({ focus = false, inputRef, isMobile, toggleHistory }) => {
	const dispatch = useDispatch();
	const [focused, setFocus] = useState(false);

	const searchHandler = value => {
		const data = {
			query: value.replace(/^\s+|\s+$/g, '').replace(/[\\%#^&]/g, ''),
		};
		dispatch(search(data));
	};

	const clearSearchHandler = () => {
		dispatch(clearSearch());
	};

	const onChange = e => {
		debouncedCallback(e.currentTarget.value);
	};

	const [debouncedCallback] = useDebouncedCallback(value => {
		if (value.trim().length > 2) {
			searchHandler(value);
		} else {
			clearSearchHandler();
		}
	}, 400);

	return (
		<div className="search-input">
			<label
				className={classNames('search-icon', {
					focused,
				})}
				htmlFor="search-input"
			>
				<svg role="img">
					<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#magnifier-old'} />
				</svg>
			</label>

			<input
				type="text"
				id="search-input"
				placeholder={
					!isMobile ? 'Поиск по справочнику, новостям, документам...' : 'Поиск по порталу'
				}
				onChange={onChange}
				autoFocus={focus}
				autoComplete="off"
				ref={inputRef}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
			/>

			<Clock className="search-history-icon" onClick={toggleHistory} />
		</div>
	);
};

export default InputSearch;
