import {
	SET_ACHIEVEMENTS_LIST,
	ADD_ACHIEVEMENTS_ITEM,
	UPDATE_ACHIEVEMENTS_ITEM,
	SET_ARCHIVE_ACHIEVEMENTS_LIST,
} from '../constant';

const defaultValue = {
	list: [],
	archive: [],
};

export default function achievements(state = defaultValue, action) {
	switch (action.type) {
		case SET_ACHIEVEMENTS_LIST:
			return {
				...state,
				list: action.list,
			};

		case SET_ARCHIVE_ACHIEVEMENTS_LIST:
			return {
				...state,
				archive: action.list,
			};

		case ADD_ACHIEVEMENTS_ITEM:
			const newList = [...state.list];
			newList.push(action.newItem);

			return {
				...state,
				list: newList,
			};

		case UPDATE_ACHIEVEMENTS_ITEM:
			let changeItem = null;
			const updateState = {
				list: state.list
					.map(item => {
						if (item.id === action.updateItem.id) {
							item = action.updateItem;
						}
						return item;
					})
					.filter(item => {
						if (!item.archive) return item;
						else {
							changeItem = item;
							return null;
						}
					}),
				archive: state.archive
					.map(item => {
						if (item.id === action.updateItem.id) {
							item = action.updateItem;
						}
						return item;
					})
					.filter(item => {
						if (!!item.archive) return item;
						else {
							changeItem = item;
							return null;
						}
					}),
			};

			if (changeItem !== null) {
				if (changeItem.archive) {
					updateState.archive.push(changeItem);
				} else {
					updateState.list.push(changeItem);
				}
			}

			return updateState;

		default:
			return state;
	}
}
