import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse } from 'react-collapse';
import { Link } from 'react-router-dom';

import { NotificationHelper } from 'action/notificationHelper';
import { getClaimsHistory, getClaimsHistoryForVacation } from 'rest/claims';
import { convertNormalDate, getReadableTime } from 'core/utils';

import './style.scss';

export const ClaimsHistory = ({ claimId, forVacation = false }) => {
	const dispatch = useDispatch();

	const [history, setHistory] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isHistoryOpened, setHistoryOpened] = useState(false);

	useEffect(() => {
		(async () => {
			setLoading(true);
			const { response, responseCode, errorMessage } = !forVacation
				? await getClaimsHistory(claimId)
				: await getClaimsHistoryForVacation(claimId);

			if (responseCode === 0) setHistory(response);
			else dispatch(NotificationHelper(errorMessage, 1));
			setLoading(false);
		})();
	}, [claimId, dispatch, forVacation]);

	const openingHandler = () => setHistoryOpened(!isHistoryOpened);

	return !loading ? (
		history.length ? (
			<div className="claim-history-block">
				<div className="claim-history-block__item">
					<span className="claim-history-block__date">
						{`${convertNormalDate(history[0].date)} в ${getReadableTime(
							history[0].date
						)}`}
					</span>

					<span className="claim-history-block__status">
						<Link to={`/user/${history[0].user.id}`} target="_blank">
							{history[0].user.surname} {history[0].user.name}{' '}
							{history[0].user.middlename ?? ''}:{' '}
						</Link>
						{history[0].typeDescription}
					</span>

					{history[0].comment && (
						<span className="claim-history-block__comment">{history[0].comment}</span>
					)}
				</div>

				{!!history.slice(1, history.length).length && (
					<>
						<Collapse isOpened={isHistoryOpened}>
							{history.slice(1, history.length).map((item, index) => {
								return (
									<div className="claim-history-block__item" key={index}>
										<span className="claim-history-block__date">
											{`${convertNormalDate(item.date)} в ${getReadableTime(
												item.date
											)}`}
										</span>

										<span className="claim-history-block__status">
											<Link to={`/user/${item.user.id}`}>
												{item.user.surname} {item.user.name}{' '}
												{item.user.middlename ?? ''}:{' '}
											</Link>
											{item.typeDescription}
										</span>

										{item.comment && (
											<span className="claim-history-block__comment">
												{item.comment}
											</span>
										)}
									</div>
								);
							})}
						</Collapse>

						<button
							onClick={openingHandler}
							type="button"
							className="claim-history-block__button inline-link"
						>
							{isHistoryOpened ? 'Скрыть историю заявки' : 'Показать историю заявки'}
						</button>
					</>
				)}
			</div>
		) : null
	) : (
		<div className="claim-history-loading-block"></div>
	);
};
