import {
    GET_SEARCHING_RIGHTS_ITEMS,
    CLEAR_SEARCHING_RIGHTS_ITEMS
} from '../constant'

const DefaultState = null

export default function searchRightsItem(state = DefaultState, action) {

    switch (action.type) {
        case GET_SEARCHING_RIGHTS_ITEMS:
            return [...action.list]

        case CLEAR_SEARCHING_RIGHTS_ITEMS:
            return null
            
        default:
            return state
    }

}