import { api } from './api';

//Получить заявку по id
//id integer (int32)
export const getCurrentUserClaimItem = id => {
	return api.get(`/user-claims/get?id=${id}`);
};

export const getShortUserInfo = async id => {
	try {
		const resp = await api.get(`/user/short-cur-user-info`);
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

//Получить историю заявки
//id integer (int64)
//pageNumber integer (int32)
//pageSize integer (int32)
export const getClaimsHistory = async id => {
	try {
		const resp = await api.get(
			`/user-claims-v2/list-history?claimId=${id}&pageNumber=0&pageSize=100`,
			{
				id,
			}
		);
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};
export const getClaimsHistoryForVacation = async id => {
	try {
		const resp = await api.get(
			`/user-claims/status/list-history?claimId=${id}&pageNumber=0&pageSize=100`,
			{
				id,
			}
		);
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

//Получить список всех статусов заявок
//type string
export const getClaimsStatuses = type => {
	return api.get(`/user-claims/list-statuses?type=${type}`);
};

//Получить список типов заявок
export const getClaimsTypes = () => {
	return api.get('/user-claims/list-types-for-user');
};

//Получить список заявок текущего юзера
//type string
//status string
//beginPeriod integer (int64)
//endPeriod	integer (int64)
//pageNumber integer (int32) Обязат.
//pageSize integer (int32) Обязат.
export const getListForCurUser = data => {
	return api.get(`/user-claims-v2/list-for-cur-user`, {
		params: {
			...data,
		},
	});
};
