import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { api } from 'rest/api';
import { NotificationHelper } from 'action/notificationHelper';

import { Button } from 'core/components';

import Notification from 'modules/notification';

import { ArticleLoader } from '../../organisms/loader';
import { Header } from '../../organisms/header';
import { DeleteModal } from '../../organisms/deleteModal';
import { Wrapp } from '../../atoms/mainWrapp';
import { Text, HeaderWrapp } from '../../atoms/header';

import { FilesBlock } from 'modules/guide/molecules';
import { FindSection } from 'modules/guide/molecules';
import { EditArticleModal } from 'modules/guide/organisms';

import { editGuideArticle, addGuideArticleToArchive } from 'action/guide';

import { getChildrenCategoryList } from 'rest/guide';

import { Editor } from 'components/editorjsText';

import { FinderFieldMultiple } from 'components/finderFieldMultiple';
import { useGetIndexName } from 'hooks/useGetIndexName';
import FindItemBlock from 'core/components/findItemBlock';
import '../style.scss';

export const GuideArticleEdit = props => {
	const fromIndex = useGetIndexName('fromIndex');
	const [name, setName] = useState('');
	const [article, setArticle] = useState(null);
	const [newContent, setNewContent] = useState('');
	const [articleFilesIds, setArticleFilesIds] = useState([]);
	const [rolesList, setRolesList] = useState([]);
	const [tagsList, setTagsList] = useState([]);
	const [category, setCategory] = useState(null);
	const [categories, setCategories] = useState([]);
	const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);
	const [isModalEditOpened, setIsModalEditOpened] = useState(false);

	const dispatch = useDispatch();

	const fetchArticle = useCallback(async () => {
		const articleId = props.match.params.articleId.split('?')[0];

		const { redirect } = props.history.push;
		const parentId = props.match.params.id;
		const res = await api.get(`/wiki/guide/get`, {
			params: {
				id: articleId,
				...(fromIndex ? { fromIndex: fromIndex } : {}),
			},
		});
		if (res.data.responseCode === 0) {
			let newContent = {
				...res.data.response,
			};
			setArticle(newContent);
			setName(newContent.wiki.name);
		} else {
			if (
				!res.data.errorMessage.indexOf(
					`Не найдена запись гида с идентификатором ${articleId}`
				)
			) {
				setArticle(-1);
			} else {
				dispatch(NotificationHelper(res.data.errorMessage, 1));
				redirect(`/knowledge/guide/${parentId}`);
			}
		}
	}, [
		dispatch,
		props.match.params.articleId,
		props.history.push,
		props.match.params.id,
		fromIndex,
	]);

	useEffect(() => {
		fetchArticle();
		window.scrollTo(0, 0);
	}, [fetchArticle]);

	useEffect(() => {
		let idsArray = props.match.params.id.split('/');
		let rootCategoryId;
		if (idsArray) {
			rootCategoryId = idsArray[0];
		}

		(async () => {
			const { response, responseCode } = await getChildrenCategoryList(rootCategoryId);

			if (responseCode === 0) {
				setCategories(
					response.map(category => ({
						value: category.categories,
						label: category.name,
					}))
				);
			}
		})();
	}, [props.match.params.id, dispatch]);

	useEffect(() => {
		if (article) {
			const category = categories.find(category => category.label === article.category.name);
			setCategory(category);
		}
	}, [article, categories]);

	const rolesArrayHandler = useCallback(roles => {
		const rolesIds = [];
		roles.forEach(item => {
			rolesIds.push(+item.id);
		});
		setRolesList(rolesIds);
	}, []);

	const tagsArrayHandler = list => {
		setTagsList(list);
	};

	const filesArrayHandler = files => {
		const filesIds = [];
		files.forEach(item => {
			filesIds.push(+item.id);
		});
		setArticleFilesIds(filesIds);
	};

	const categoryHandler = category => {
		setCategory(category);
	};

	const nameHandler = e => {
		e.currentTarget.classList.remove('validation-error');
		setName(e.currentTarget.value);
	};

	const toggleEditModalHanler = () => {
		if (rolesList.length > 0) {
			setIsModalEditOpened(!isModalEditOpened);
		} else {
			editHandler(false);
		}
	};

	const editHandler = flag => {
		const id = props.match.params.articleId.split('?')[0];
		if (newContent) {
			const { history } = props;
			const idsArray = category.value.split('/');
			const categoryId = idsArray[idsArray.length - 1];
			const parentId = category.value;
			dispatch(
				editGuideArticle(
					{
						id,
						name,
						categoryId,
						roleIds: rolesList,
						fileIds: articleFilesIds,
						tags: tagsList,
						url: window.location.href.replace('/edit', ''),
						flagToEmail: flag,
						content: newContent,
					},
					history.push,
					parentId
				)
			);
		} else {
			dispatch(NotificationHelper('Необходимо указать содержание и название статьи', 1));
		}
	};

	const addToArchiveHandler = e => {
		e.preventDefault();
		setIsModalDeleteOpened(false);

		const { history, match } = props;
		const { id, articleId } = match.params;
		const { push } = history;

		dispatch(addGuideArticleToArchive(articleId, `/knowledge/guide/${id}`, push));
	};

	const toggleDeleteModalOpenedHandler = e => {
		e.preventDefault();
		setIsModalDeleteOpened(!isModalDeleteOpened);
	};

	if (article) {
		return (
			<>
				{isModalEditOpened && (
					<EditArticleModal
						isOpened={isModalEditOpened}
						closeModal={toggleEditModalHanler}
						title="Изменение документа"
						text={`Вы внесли изменения в документ <b>${article.wiki.name}</b>. Уведомить об этом пользователей?`}
						answerHandler={editHandler}
					/>
				)}
				<Helmet title={`Редактирование документа "${article.wiki.name}"`} />

				<Notification />

				<div className="Article-create">
					{isModalDeleteOpened && (
						<DeleteModal
							title="Удаление документа"
							name={article.wiki.name}
							text={`Документ <b>${article.wiki.name}</b> будет удален(а) навсегда. Если Вы уверены, что хотите удалить документ навсегда, введите название <b>${article.wiki.name}</b> в поле ниже.`}
							onAccept={addToArchiveHandler}
							onCancel={toggleDeleteModalOpenedHandler}
						/>
					)}
					<Header>
						<HeaderWrapp>
							<Text type="bold">Редактирование документа</Text>
							<div className="Aheader-block">
								<Button
									type="primary"
									typeButton="button"
									text="Обновить"
									handler={toggleEditModalHanler}
								/>

								<Button
									type="secondary"
									typeButton="button"
									text="Удалить"
									handler={toggleDeleteModalOpenedHandler}
								/>
								<Link
									to={`/knowledge/guide/${props.match.params.id}/article/${article.id}`}
									className="Aheader-btn Aheader-btn_special"
								>
									Отменить
								</Link>
							</div>
						</HeaderWrapp>
					</Header>
					<Wrapp>
						<FindSection
							value={category}
							options={categories}
							onChange={categoryHandler}
						/>
						<div className="article-form article-form_create">
							<input
								type="text"
								className="article-name"
								onChange={nameHandler}
								defaultValue={name}
								placeholder="Название документа"
							/>
						</div>
						<Editor content={article.content} getContent={setNewContent} />
						<FilesBlock filesList={article.files} arrayHandler={filesArrayHandler} />

						<div className="article-create__find-item role">
							<FinderFieldMultiple
								searchType="WIKI_ROLE"
								label="Роли"
								placeholder="Добавить роль"
								onSelected={rolesArrayHandler}
								defaultOptions={article.roles}
								description="Выберите роли пользователей, которых нужно уведомлять об изменениях в документе"
							/>
						</div>

						<div className="article-create__find-item">
							<FindItemBlock
								type="tag"
								label="Теги"
								placeholder="Добавить тег"
								arrayHandler={tagsArrayHandler}
								currentItemsList={article.tags}
								hint="Выберите существующие или введите свои теги для поиска"
							/>
						</div>
					</Wrapp>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className="Article-create">
					<Header>
						<HeaderWrapp></HeaderWrapp>
					</Header>
					<Wrapp>
						<ArticleLoader />
					</Wrapp>
				</div>
			</>
		);
	}
};
