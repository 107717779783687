import React from 'react'

import './style.scss'

const MainLoader = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 819 403.6" enableBackground="new 0 0 819 403.6" width="200" height="200">
            <path className="st0" id="st0" d="M3 201.6v199h812.4v-65H649.8v-60.5h62.7V132.8c-8.7-1.9-14.3.4-19.5 8.2-42.8 63.8-86.1 127.2-129.2 190.8-2.3 3.4-4.6 4.5-8.6 4.5-38-.2-76-.2-114 0-4.7 0-7.5-1.6-10-5.3-10.1-15.4-20.4-30.6-30.7-45.9-33.3-49.6-66.7-99.2-100.1-148.7-1.1-1.6-2.7-3.5-4.2-3.7-4.1-.6-8.3-.2-12.6-.2v143.3h62.5v59.1H105.5v-59.7h71v-73.4" />
            <path className="st1" id="st1" d="M176.5 201.6v-70.3h-63.1c-6.4 0-7-.5-7-6.9V76.8c0-8.2.1-8.3 8.1-8.3 83.7.3 167.3.6 250.9.8 4.2 0 6.6 1.3 8.8 4.8 40.3 63.1 80.6 126.1 121 189l3.2 5c1.4-2.1 2.6-3.8 3.6-5.4 40.3-62.9 80.6-125.9 120.8-189 2.3-3.7 4.7-5.2 9.2-5.2h184V3H3v198.6" />
        </svg>

    )
}

export default MainLoader