import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { updateFilterParams } from 'action/vacancies';

import { selectStyles } from 'core/utils';

import { FilterLabel } from '../../atoms';

import './style.scss';

class CitySelect extends Component {
	render() {
		let { vacanciesCities, vacanciesListParams } = this.props;
		let cityOptions = [];
		let defaultOption = [{ value: 1, label: 'Вологда' }];

		if (vacanciesCities !== null) {
			vacanciesCities.forEach(item => {
				cityOptions.push({ value: item.id, label: item.name });
			});

			cityOptions.sort(function (a, b) {
				return a.value - b.value;
			});
		}

		if (vacanciesListParams !== null) {
			defaultOption = cityOptions.filter(item => {
				return +vacanciesListParams.cityId === +item.value;
			});
		}

		return (
			<div className="vacancies-filters__select-filter">
				<FilterLabel text="Выберите город" />

				{vacanciesListParams !== null && (
					<Select
						id="cityId"
						className="validation-input"
						placeholder="Выберите город"
						value={defaultOption}
						options={cityOptions}
						onChange={this.changeCity}
						isSearchable={false}
						isClearable={false}
						onKeyDown={e => {
							e.preventDefault();
						}}
						styles={selectStyles}
					/>
				)}
			</div>
		);
	}

	changeCity = e => {
		this.props.updateFilterParams({
			cityId: e.value,
			page: 0,
		});
	};
}

const mapStateToProps = state => {
	return {
		vacanciesListParams: state.vacancies.vacanciesListParams,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateFilterParams: params => {
			dispatch(updateFilterParams(params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(CitySelect);
