import {
    GET_PHOTO_ALBUM_LIST,
    GET_PHOTO_ALBUM_PHOTO_LIST,
    ADD_PHOTO_ALBUM_PHOTO,
    DELETE_PHOTO_ALBUM_PHOTO,
    UPDATE_PHOTO_ALBUM_DESCRIPTION
} from '../constant'

const DefaultState = {
    albumsList: [],
    album: []
}

export default function albums(state = DefaultState, action) {

    switch (action.type) {
        case GET_PHOTO_ALBUM_LIST:
            let getAlbumsList = state
            getAlbumsList.albumsList = [...action.albums]
            return getAlbumsList

        case GET_PHOTO_ALBUM_PHOTO_LIST:
            let getAlbumsPhotoList = state
            getAlbumsPhotoList.album = [...action.album]
            return getAlbumsPhotoList

        case ADD_PHOTO_ALBUM_PHOTO:
            let albumsPhotoAddPhoto = state
            albumsPhotoAddPhoto.album = [...albumsPhotoAddPhoto.album, action.photo]
            return albumsPhotoAddPhoto

        case DELETE_PHOTO_ALBUM_PHOTO:
            let albumsPhotoDeletePhoto = state
            albumsPhotoDeletePhoto.album = albumsPhotoDeletePhoto.album.filter(
                (item) => {
                    return +item.id !== +action.id
                }
            )
            return albumsPhotoDeletePhoto

        case UPDATE_PHOTO_ALBUM_DESCRIPTION:
            let albumPhotoUpdateDescription = state
            albumPhotoUpdateDescription.album = albumPhotoUpdateDescription.album.map(
                (item) => {
                    if (+item.id === +action.id) {
                        item.description = action.description
                        return item
                    } else {
                        return item
                    }
                }
            )
            return albumPhotoUpdateDescription

        default:
            return state
    }
}