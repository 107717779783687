import { useContext } from 'react';
import { FormStateContext } from './formStateContext';

export const useFormState = () => {
	const context = useContext(FormStateContext);
	if (!context) {
		throw new Error('useFormState должен использоваться в FormStateContext');
	}
	return context;
};
