import {
    SET_GLOSSARY_LIST,
    CLEAR_GLOSSARY_LIST,
    ADD_GLOSSARY_ITEM,
    PUBLISH_GLOSSARY_ITEM,
    DELETE_GLOSSARY_ITEM
} from '../constant'

const DefaultState = null

const glossary = (state = DefaultState, action) => {
    switch (action.type) {

        case SET_GLOSSARY_LIST:
            return action.list

        case CLEAR_GLOSSARY_LIST:
            return null

        case ADD_GLOSSARY_ITEM:
            return [...state, action.item]

        case PUBLISH_GLOSSARY_ITEM:
            return state.map(
                item => {
                    if (item.id === action.id) {
                        item.wiki.status = "PUBLISHED"
                    }
                    return item
                }
            )

        case DELETE_GLOSSARY_ITEM:
            return state.filter(
                item => {
                    return item.id !== action.id
                }
            )

        default:
            return state
    }
}

export default glossary