import {
	ADD_POLL,
	UPDATE_POLL
} from 'constant'

const DefaultState = {};

export default function polls(state = DefaultState, action) {

	switch (action.type) {
		case ADD_POLL:
			return {
				...state,
				...action.polls
			}

		case UPDATE_POLL:
			let updatePoll = {}

			action.answersArray.forEach(item => {
				const filteredState = state[`${action.id}`].answerResponses.filter(it => +item === +it.idx);

				filteredState.forEach(el => {
					el.quantity = el.quantity + 1;
					el.isResponse = !el.isResponse;
				})
			})

			updatePoll[`${action.id}`] = {
				...state[`${action.id}`],
				totalCount: state[`${action.id}`].totalCount + 1
			}

			return {
				...state,
				...updatePoll
			}
			
		default:
			return state
	}

}
