import {
	SET_FAVORITE_POSTS,
	POST_FAVORITE,
	POST_LIKE,
	ADD_OR_REMOVE_COMMENTS_LIKE,
	GET_COMMENTS_LIST,
	ADD_COMMENT,
	COMMENTS_COUNT_ADD,
	COMMENTS_COUNT_REMOVE,
	HIDE_COMMENTS,
	DELETE_COMMENT,
	RECOVER_COMMENT,
	UPDATE_COMMENT,
	POST_VIEW,
	VOTE_IN_POLL,
} from 'constant';

const initialState = {
	list: [],
};

export default function favoritesPosts(state = initialState, action) {
	switch (action.type) {
		case SET_FAVORITE_POSTS:
			return {
				...state,
				list: [...action.favoritesPosts],
			};

		case POST_FAVORITE:
			return {
				...state,
				list: state.list.map(item => {
					if (+item.id === +action.id) {
						item.favorite = action.favorite;
					}
					return item;
				}),
			};

		case POST_LIKE:
			return {
				...state,
				list: state.list.map(item => {
					if (+item.id === +action.id) {
						item.hasLike = action.hasLike;
						if (action.hasLike) {
							item.likeCount++;
						} else {
							item.likeCount--;
						}
						return item;
					} else return item;
				}),
			};

		case POST_VIEW:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.id) {
						post.view = true;
						post.viewsCount = post.viewsCount + 1;
					}
					return post;
				}),
			};

		case VOTE_IN_POLL:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.postId) {
						post.poll.response = +action.answerId;
						post.poll[`quantity${+action.answerId}`] =
							+post.poll[`quantity${+action.answerId}`] + 1;
					}
					return post;
				}),
			};

		case ADD_OR_REMOVE_COMMENTS_LIKE:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.postId) {
						post.comments = post.comments.map(comment => {
							if (+comment.id === +action.id && comment.hasLike !== action.like) {
								comment.hasLike = action.like;
								comment.likeCount = comment.hasLike
									? (comment.likeCount += 1)
									: (comment.likeCount -= 1);
							}
							return comment;
						});
					}
					return post;
				}),
			};

		case GET_COMMENTS_LIST:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.id) {
						post.comments = [...action.comments];
					}
					return post;
				}),
			};

		case ADD_COMMENT:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.postId) {
						post.comments = [...post.comments, action.comment];
					}
					return post;
				}),
			};

		case COMMENTS_COUNT_ADD:
			return {
				...state,
				list: state.list.map(post => {
					if (post.id === action.id) {
						post.commentCount++;
						return post;
					} else {
						return post;
					}
				}),
			};

		case COMMENTS_COUNT_REMOVE:
			return {
				...state,
				list: state.list.map(post => {
					if (post.id === action.id) {
						post.commentCount--;
						return post;
					} else {
						return post;
					}
				}),
			};

		case HIDE_COMMENTS:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.postId) {
						let hidden = post.comments.splice(-3);
						post.comments = hidden;
					}
					return post;
				}),
			};

		case DELETE_COMMENT:
			return {
				...state,
				list: state.list.map(item => {
					if (+item.id === +action.postId) {
						item.comments = item.comments.filter(comment => {
							if (+comment.id === +action.commentId) {
								comment.isDelete = true;
								return comment;
							} else {
								return comment;
							}
						});
					}
					return item;
				}),
			};

		case RECOVER_COMMENT:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.postId) {
						post.comments = post.comments.filter(comment => {
							if (+comment.id === +action.commentId) {
								comment.isDelete = false;
								return comment;
							} else {
								return comment;
							}
						});
					}
					return post;
				}),
			};

		case UPDATE_COMMENT:
			return {
				...state,
				list: state.list.map(post => {
					if (+post.id === +action.postId) {
						post.comments = post.comments.map(comment => {
							if (+comment.id === +action.id) {
								comment.note = action.comment.note;
								comment.changeDate = action.comment.changeDate;
							}
							return comment;
						});
					}
					return post;
				}),
			};

		default:
			return state;
	}
}
