import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { FormInputBlock, Select } from 'core/components';
import { selectStyles } from 'core/utils';
import { TAnswerScaleType, IPulseSurveyDataForRHF } from 'entities/survey/pulseSurveyTypes';
import { Label } from 'shared/ui/Label';
import { updatePulseSurveyAnswerTypeAction } from 'action/PulseSurvey';

const types: {
	label: string;
	value: TAnswerScaleType;
}[] = [
	{
		value: 'NUMBER',
		label: 'Шкала',
	},
	{
		value: 'SMILE',
		label: 'Смайлы',
	},
];

interface IProps {
	answerScaleType: TAnswerScaleType;
}

export const AnswerType: FC<IProps> = ({ answerScaleType }) => {
	const dispatch = useDispatch();
	const { register, unregister, setValue } = useFormContext<IPulseSurveyDataForRHF>();

	useEffect(() => {
		register('answerScaleType');
		return () => {
			unregister('answerScaleType');
		};
	}, [register, unregister]);

	useEffect(() => {
		setValue('answerScaleType', answerScaleType);
	}, [setValue, answerScaleType]);

	const changeTypeHandle = (value: TAnswerScaleType) => {
		dispatch(updatePulseSurveyAnswerTypeAction(value));
	};

	return (
		<FormInputBlock>
			<Label>Тип ответа</Label>
			<Select
				id="pulse-survey_form_answer-type"
				placeholder="Выберите тип ответа"
				options={types}
				onChange={changeTypeHandle}
				styles={selectStyles}
				value={answerScaleType}
				defaultValue={answerScaleType}
			/>
		</FormInputBlock>
	);
};
