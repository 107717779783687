import {
	SET_NOTIFICATIONS_IN_PROFILE,
	FETCH_NOTIFICATIONS_IN_PROFILE,
	FETCH_NOTIFICATIONS_IN_PROFILE_SUCCESS,
	TOOGLE_LAST_PAGE_IN_NOTIFICATIONS_IN_PROFILE,
	CLEAR_NOTIFICATIONS_IN_PROFILE,
	SET_LAST_NOTIFICATIONS,
	CLEAR_LAST_NOTIFICATIONS,
	SET_IS_LOADING_NOTIFICATIONS,
	VIEW_NOTIFICATION,
	VIEW_ALL_NOTIFICATION,
	ACCEPT_NOTIFICATION,
	REJECT_NOTIFICATION,
	SET_NOTIFICATION,
} from 'constant';

import { NotificationHelper } from './notificationHelper';

import {
	getCurrentUserNotifications,
	notificationView,
	userClaimAccept,
	userClaimReject,
} from 'rest/notifications';

export const getNotificationsInProfile = (pageSize = 20) => {
	return (dispatch, getState) => {
		const { page } = getState().userNotification;
		dispatch({
			type: FETCH_NOTIFICATIONS_IN_PROFILE,
		});
		return getCurrentUserNotifications(page, pageSize)
			.then(res => {
				if (res.data.responseCode === 0) {
					if (res.data.response < pageSize) {
						dispatch({
							type: TOOGLE_LAST_PAGE_IN_NOTIFICATIONS_IN_PROFILE,
						});
					}
					dispatch({
						type: SET_NOTIFICATIONS_IN_PROFILE,
						all: res.data.response,
						page: page + 1,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка всех уведомлений', 1));
			})
			.finally(() => {
				dispatch({
					type: FETCH_NOTIFICATIONS_IN_PROFILE_SUCCESS,
				});
			});
	};
};

export const clearNotificationsInProfile = () => {
	return {
		type: CLEAR_NOTIFICATIONS_IN_PROFILE,
	};
};

export const getLastNotificationsInHeader = () => {
	return dispatch => {
		dispatch({
			type: SET_IS_LOADING_NOTIFICATIONS,
			isLoading: true,
		});
		return getCurrentUserNotifications(0, 5, true)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SET_LAST_NOTIFICATIONS,
						last: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка уведомлений в шапке', 1));
			})
			.finally(() => {
				dispatch({
					type: SET_IS_LOADING_NOTIFICATIONS,
					isLoading: false,
				});
			});
	};
};

export const clearLastNotifications = () => {
	return {
		type: CLEAR_LAST_NOTIFICATIONS,
	};
};

export const notificationViewAction = (id, link) => {
	return dispatch => {
		return notificationView(id)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: VIEW_NOTIFICATION,
						id,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}

				if (link) {
					window.location = link;
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка просмотра уведомления', 1));
			});
	};
};

export const notificationAllViewAction = () => {
	return dispatch => {
		return notificationView()
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: VIEW_ALL_NOTIFICATION,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка просмотра уведомлений', 1));
			});
	};
};

export const acceptNotification = (claimId, comment, ntfId) => {
	return dispatch => {
		return userClaimAccept(claimId, comment)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: ACCEPT_NOTIFICATION,
						ntfId,
					});
					dispatch({
						type: VIEW_NOTIFICATION,
						id: ntfId,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка принятия в уведомлении', 1));
			});
	};
};

export const rejectNotification = (claimId, comment, ntfId) => {
	return dispatch => {
		return userClaimReject(claimId, comment)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: REJECT_NOTIFICATION,
						ntfId,
					});
					dispatch({
						type: VIEW_NOTIFICATION,
						id: ntfId,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка отклонения в уведомлении', 1));
			});
	};
};

export const setNotification = notification => {
	return {
		type: SET_NOTIFICATION,
		notification,
	};
};
