import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'react-feather';

import './style.scss';

const WelcomMessage = ({ closeMessage }) => {
	const [showClass, setShowClass] = useState('welcome-message');

	useEffect(() => {
		setTimeout(() => {
			setShowClass('welcome-message active');
			const wrapp = document.querySelector('#wrapp');
			wrapp.classList.add('no-scroll');
		}, 0);
	}, []);

	const onClickBody = event => {
		event.preventDefault();

		const wrapp = document.querySelector('#wrapp');
		if (wrapp.classList.contains('no-scroll')) {
			wrapp.classList.remove('no-scroll');
		}
	};

	const closeMessageHandler = event => {
		closeMessage(event);

		const wrapp = document.querySelector('#wrapp');
		if (wrapp.classList.contains('no-scroll')) {
			wrapp.classList.remove('no-scroll');
		}
	};

	return (
		<div className={showClass}>
			<div className="welcome-message_body" onClick={onClickBody}>
				<X
					className="welcome-home-page-close-modal"
					size="32"
					onClick={closeMessageHandler}
				/>
				<div className="welcome-home-page">
					<div className="welcome-home-page__wrap">
						<img
							src={process.env.PUBLIC_URL + 'img/emilia.png'}
							alt=""
							className="welcome-home-page__emilia"
						/>
						<div className="welcome-home-page__right">
							<p className="welcome-home-page__text">
								Привет!
								<br />
								Меня зовут Эмилия. Рада видеть тебя на Максипортале!
								<br /> Давай я расскажу, что здесь есть интересного и полезного для
								тебя
								<svg role="img" className="welcome-home-page__icon">
									<use
										xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#emoji'}
									></use>
								</svg>
								<br />
								Итак, на портале ты можешь...{' '}
								<Link to="/knowledge/guide/20/article/672" className="color-red">
									перейти к обзору
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WelcomMessage;
