import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TypeButton, Icon } from '@maxi-innovation/ui-kit-frontend';

import { Condition } from './Condition';
import { addQuestionConditionAction, deleteQuestionConditionAction } from 'action/survey';
import { IQuestions } from '../Form/types';

import './style.scss';

interface IProps {
	deleteConditions: () => void;
	questionSchema: IQuestions;
}

interface ISelector {
	survey: {
		questions: IQuestions[];
	};
}

export const Conditions: FC<IProps> = ({ deleteConditions, questionSchema }) => {
	const dispatch = useDispatch();

	const { questions } = useSelector((state: ISelector) => ({
		questions: state.survey.questions,
	}));

	const getIndexCondition = useCallback(() => {
		const arr: number[] = [];
		questions.forEach(item => {
			if (!item.conditions) return;

			item.conditions.forEach(condition => {
				arr.push(condition.index);
			});
		});

		return arr.length ? Math.max(...arr) + 1 : 0;
	}, [questions]);

	useEffect(() => {
		if (!!questionSchema.conditions?.length) {
			return;
		}

		dispatch(
			addQuestionConditionAction(questionSchema.questionNumber, {
				id: undefined,
				index: getIndexCondition(),
				type: 'E',
				value: undefined,
				questionNumber: undefined,
				questionType: undefined,
			})
		);
	}, [dispatch, questionSchema, getIndexCondition]);

	const addCondition = () => {
		dispatch(
			addQuestionConditionAction(questionSchema.questionNumber, {
				id: undefined,
				index: getIndexCondition(),
				type: 'E',
				value: undefined,
				questionNumber: undefined,
				questionType: undefined,
			})
		);
	};

	const deleteCondition = (deleteIndex: number) => {
		dispatch(deleteQuestionConditionAction(questionSchema.questionNumber, deleteIndex));
		if (questionSchema.conditions?.length === 1) deleteConditions();
	};

	return (
		<div className="survey-condition">
			<p className="survey-condition__seporator no-margin-top">Условие показа вопроса</p>

			<div className="survey-condition__wrap">
				{!!questionSchema?.conditions &&
					questionSchema.conditions.map((item, index) => (
						<div key={index}>
							<Condition
								id={item.id}
								itemNumber={item.index}
								currentCondition={item}
								questions={questions}
								questionSchema={questionSchema}
								deleteCondition={deleteCondition}
							/>

							{questionSchema.conditions &&
								/* @ts-ignore */
								item.index !== questionSchema.conditions.at(-1).index && (
									<p className="survey-condition__seporator">или</p>
								)}
						</div>
					))}
			</div>

			<div className="survey-condition__wrap">
				<Button
					variant={TypeButton.LINKCAPS}
					className="survey-add-condition"
					onClick={addCondition}
				>
					<Icon iconName="Plus" />
					Добавить условие
				</Button>
			</div>
		</div>
	);
};
