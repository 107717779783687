import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Mail } from 'react-feather';

import { getDefaultChatRest, getUnreadCountRest } from 'shared/api/messenger';
import { useIsMobile } from 'hooks/useIsMobile';
import { messangesCountAction } from 'action/messenger';

import './style.scss';

export const MessagesLink = () => {
	const dispatch = useDispatch();
	const [defaultChatBot, setDefaultChatBot] = useState<{ id: number }>();
	const unreadCount = useSelector(
		(state: { messenger: { unreadCount: number } }) => state.messenger.unreadCount
	);
	const isMobile = useIsMobile();

	useEffect(() => {
		(async () => {
			const { response, responseCode } = await getUnreadCountRest();

			if (!responseCode) {
				dispatch(messangesCountAction(response.unreadCount));
			}
		})();
		(async () => {
			const { response, responseCode } = await getDefaultChatRest();

			if (!responseCode) {
				setDefaultChatBot(response);
			}
		})();
	}, [dispatch]);

	return (
		<NavLink
			to={
				isMobile
					? `/messenger/${!!defaultChatBot && defaultChatBot.id}`
					: `/messenger/chats/${!!defaultChatBot && defaultChatBot.id}`
			}
			className="header-messages"
		>
			<span className="header-messages__wrap">
				<Mail className="header-messages__icon" />
				{unreadCount > 0 && <span className="header-messages__count">{unreadCount}</span>}
			</span>
		</NavLink>
	);
};
