import { ADD_MIRAPOLIS_LINK } from '../constant';

const defaultValues = '';

export default function mirapolisLink(state = defaultValues, action) {
	switch (action.type) {
		case ADD_MIRAPOLIS_LINK:
			return action.value;

		default:
			return state;
	}
}
