import React, { FC } from 'react';

import { AreaField, FormInputBlock } from 'core/components';

interface IProps {
	name?: string;
	label: string;
	placeholder: string;
	desc?: string;
	defaultValue?: string;
	maxLength?: number;
	onChange?: (value: string) => void;
	error?: boolean;
	disabled?: boolean;
}

export const SurveyTextField: FC<IProps> = ({
	name,
	label,
	placeholder,
	desc,
	defaultValue,
	maxLength,
	onChange,
	error,
	disabled,
}) => {
	return (
		<FormInputBlock>
			<AreaField
				label={label}
				placeholder={placeholder}
				error={error}
				helperText={desc}
				defaultValue={defaultValue}
				name={name ? name : 'field'}
				maxLength={maxLength}
				onChange={onChange}
				disabled={disabled}
			/>
		</FormInputBlock>
	);
};
