import { NotificationHelper } from './notificationHelper'

import {
	suggestContactsCorrection,
	suggestGuideCorrection
} from 'rest/suggestCorrection'

export const suggestContactsCorrectionAction = (data, showThanksModal) => {
	return dispatch => {
		return suggestContactsCorrection(data).then(
			res => {
				if (res.data.responseCode === 0) {
					showThanksModal()
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1))
				}
			}
		).catch(
			() => {
				dispatch(NotificationHelper('Ошибка отправки сообщения', 1))
			}
		)
	}
}

export const suggestGuideCorrectionAction = (data, showThanksModal) => {
	return dispatch => {
		return suggestGuideCorrection(data).then(
			res => {
				if (res.data.responseCode === 0) {
					showThanksModal()
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1))
				}
			}
		).catch(
			() => {
				dispatch(NotificationHelper('Ошибка отправки сообщения', 1))
			}
		)
	}
}