import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bell } from 'react-feather';

import { toggleHeadNotifications } from 'action/ui';

import './style.scss';

export const HeaderNotifications = ({ countNotifications }) => {
	const ui = useSelector(state => state.ui);

	const dispatch = useDispatch();

	const openHandler = () => {
		dispatch(toggleHeadNotifications(!ui.headerNotifications));
	};

	return (
		<div className={ui.headerNotifications ? 'header-ntfctns active' : 'header-ntfctns'}>
			<Bell className="header-ntfctns__icon" onClick={openHandler} />
			{countNotifications > 0 && (
				<span className="header-ntfctns__count">{countNotifications}</span>
			)}
		</div>
	);
};
