import React from 'react';

import { Roles, Loader } from 'core/components';
import { hasRole } from 'core/utils';
import { BackToBase } from 'modules/university/atoms';
import { CreateBtnIcon } from '../../atoms';
import { AsideSections } from '../../molecules';

export const GuideAside = ({ arrayRoles, categoriesList }) => {
	return (
		<div className="guide-aside">
			{categoriesList ? (
				<>
					<BackToBase />

					{categoriesList !== null && <AsideSections categoriesList={categoriesList} />}

					{arrayRoles && (
						<Roles isView={hasRole(arrayRoles, 'ROLE_UPDATE_WIKI_GUIDE_TOP_CATEGORY')}>
							<CreateBtnIcon linkTo="/knowledge/guide/create" />
						</Roles>
					)}
				</>
			) : (
				<Loader />
			)}
		</div>
	);
};
