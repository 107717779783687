import { parseCookies } from 'nookies';

export function getAccessToken() {
	const { accessToken } = parseCookies();
	if (accessToken) {
		return accessToken;
	}
	return null;
}


export function getExpiresCookie() {
	let cookie_date = new Date();
	cookie_date.setMonth(cookie_date.getMonth() + 2);
	
	return cookie_date;
}