import React from 'react';

interface IProps {
	htmlFor?: string;
	children: JSX.Element | string;
}

export const Label = ({ htmlFor, children }: IProps) => {
	return (
		<label className="my-label" htmlFor={htmlFor}>
			{children}
		</label>
	);
};
