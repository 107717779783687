import React, { FC, useState, useEffect } from 'react';

import { IQuestionComponentProps, IAnswered } from '../types';
import { URL } from 'constant';
import { Check } from 'react-feather';
import { TextField } from 'core/components';

export const MultipleQuestion: FC<IQuestionComponentProps> = ({
	question,
	answeredHandler,
	error,
	defaultValue,
}) => {
	const [answered, setAnswered] = useState<IAnswered[]>([]);
	const [ownAnswer, setOwnAnswer] = useState(false);
	const [ownAnswerError, setOwnAnswerError] = useState(false);
	const [defaultText, setDefaultText] = useState('');

	useEffect(() => {
		if (defaultValue?.selectedAnswer) {
			setAnswered(defaultValue.selectedAnswer);
			if (defaultValue?.textAnswer) setDefaultText(defaultValue.textAnswer);

			answeredHandler(
				defaultValue?.selectedAnswer.map(item => {
					// @ts-ignore
					if (item.ownAnswer) item.text = defaultValue.textAnswer;
					return item;
				})
			);
		}
	}, [defaultValue, answeredHandler]);

	useEffect(() => {
		const ownAnswer = !!answered.find(item => item.ownAnswer);
		if (!ownAnswer) setDefaultText('');

		answeredHandler(
			answered.length
				? answered.map(item => {
						if (item.ownAnswer) {
							item.type = 'OTHER';
						}
						return item;
				  })
				: undefined
		);

		setOwnAnswer(ownAnswer);
	}, [answered, answeredHandler]);

	const changeAnswer = (event: React.ChangeEvent<HTMLInputElement>, data: IAnswered) => {
		const checked = event.currentTarget.checked;

		if (checked) {
			setAnswered(prev => {
				return [...prev, data.ownAnswer ? { ...data, text: '' } : data];
			});
		} else {
			setAnswered(prev => [...prev.filter(item => item.id !== data.id)]);
		}
	};

	const ownAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!answered) return;

		const { value } = event.currentTarget;
		setOwnAnswerError(!value);

		answeredHandler([
			...answered.map(item => {
				if (item.ownAnswer) {
					item.text = value;
					item.type = 'OTHER';
				}
				return item;
			}),
		]);
	};

	return (
		<div className="question">
			{question.image && (
				<img src={URL + question.image.filePath} alt="" className="question__preview" />
			)}

			<div className="question__title">{question.text}</div>

			<div className="question-answers">
				{question.possibleAnswers.map(item => (
					<label className="question-answers__item" key={item.id}>
						<div className="question-answers__wrap">
							<input
								type="checkbox"
								name="question-answer"
								className="question-answers__input"
								onChange={event => changeAnswer(event, item)}
								checked={!!answered.find(answer => answer.id === item.id)}
							/>
							<div className="question-answers__square">
								<Check className="question-answers__square-icon" />
							</div>
							<p className="question-answers__text">
								{item?.type === 'OTHER' ? 'Другое' : item.text}
							</p>
						</div>

						{item.ownAnswer && ownAnswer && (
							<TextField
								// @ts-ignore
								placeholder="Введите текст"
								error={ownAnswerError}
								name="own-answer"
								onChange={ownAnswerChange}
								fullWidth={true}
								autoComplete="off"
								defaultValue={defaultText}
							/>
						)}
					</label>
				))}
			</div>

			{error && <div className="question-answers-error">Выберите вариант ответа</div>}
		</div>
	);
};
