import { GET_SEARCHING_RIGHTS_ITEMS, CLEAR_SEARCHING_RIGHTS_ITEMS } from '../constant';

import { NotificationHelper } from './notificationHelper';

import { searchUsersRightsItem, searchTagsRightsItem } from 'rest/searchRightsItem';

import { globalSearch } from 'rest/search';

export const searchItemsList = data => {
	return dispatch => {
		return globalSearch(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_SEARCHING_RIGHTS_ITEMS,
						list: res.data.response,
					});
				} else {
					dispatch(NotificationHelper('Ошибка получения списка', 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка', 1));
			});
	};
};

export const searchUsersRightsList = query => {
	return dispatch => {
		return searchUsersRightsItem(query)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_SEARCHING_RIGHTS_ITEMS,
						list: res.data.response[0].objects,
					});
				} else {
					dispatch(NotificationHelper('Ошибка получения списка', 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка', 1));
			});
	};
};

export const searchTagsRightsList = data => {
	return dispatch => {
		return searchTagsRightsItem(data.query)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_SEARCHING_RIGHTS_ITEMS,
						list: res.data.response.values,
					});
				} else {
					dispatch(NotificationHelper('Ошибка получения списка', res.data.responseCode));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка', 1));
			});
	};
};

export const clearRightsList = () => {
	return dispatch => {
		dispatch({
			type: CLEAR_SEARCHING_RIGHTS_ITEMS,
		});
	};
};
