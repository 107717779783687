import {
	GET_ROLE_BY_POSITION_LIST,
	ADD_ROLE_BY_POSITION,
	UPDATE_ROLE_BY_POSITION,
	DELETE_ROLE_BY_POSITION
} from 'constant'

const DefaultState = {
	roles: null
};

export default function rolesByPositions(state = DefaultState, action) {

	switch (action.type) {
		case GET_ROLE_BY_POSITION_LIST:
			return {
				...state,
				roles: action.roles
			}

		case ADD_ROLE_BY_POSITION:
			return {
				...state,
				roles: [action.role, ...state.roles]
			}

		case UPDATE_ROLE_BY_POSITION:
			return {
				...state,
				roles: state.roles.map(
					item => {
						if (+item.id === +action.info.id) {
							item = action.info
						}
						return item
					}
				)
			}

		case DELETE_ROLE_BY_POSITION:
			return {
				...state,
				roles: state.roles.filter(
					item => {
						return +item.id !== +action.id
					}
				)
			}
			
		default:
			return state
	}

}