import { api } from './api';

export const addToken = async (token) => {
	try {
		const response = await api.post('/web-push/token/add', {token})
		if(response.data.responseCode === 0) {
			console.log('Device token is saved')
		} else {
			console.log('Device token is not saved')
		}
	} catch (err) {
		console.log('Device token is not saved. Error ', err)
	}
}

export const deleteToken = async (token) => {
	try {
		return await api.post('/web-push/token/delete', {token})
	} catch (err) {
		console.log('Device token is not remove. Error ', err)
	}
}