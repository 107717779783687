import { api } from './api';
import showNotification from './showNotification';

//Создание комментария
export const addCommentRest = (context, comment, stickerId, callback) => {
	api.post('/posts/comments/create', {
		postId: context.props.id,
		note: stickerId !== null ? '' : comment,
		stickerId: stickerId,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.addComment(+context.props.id, response.data.response);
				context.props.addCommentCount(+context.props.id);

				if (context.state.post) {
					const { post, postCommentsCount } = context.state;
					context.setState({
						postCommentsCount: postCommentsCount ? postCommentsCount + 1 : 1,
						post: {
							...post,
							comments: [...post.comments, response.data.response],
						},
					});
				}
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}

			callback();
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/comments/create - ${error}`);

			if (callback) callback();
		});
};

//Получение списка комментариев к посту
export const getCommentsList = context => {
	api.get(`/posts/comments/list/?postId=${context.props.id}`)
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.getComments(response.data.response, +context.props.id);
				context.props.getCommentsCount(+context.props.id, response.data.response.length);
			}
			context.setState({
				loading: false,
			});
		})
		.catch(function (error) {
			console.error('getPost', error);
		});
};

//Удаление комментария
export const removeComment = (context, id) => {
	api.post('/posts/comments/delete', {
		id: id,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				showNotification(context, true, 0, 'Комментарий удален');
				context.props.deleteComment(+context.props.id, id);
				context.props.removeCommentCount(+context.props.id);

				if (context.state.post) {
					const { post, postCommentsCount } = context.state;
					const newCount = postCommentsCount - 1;
					context.setState({
						postCommentsCount: newCount < 1 ? null : newCount,
						post: {
							...post,
							comments: post.comments.filter(comment => +comment.id !== +id),
						},
					});
				}
			} else {
				showNotification(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/comments/delete - ${error}`);
		});
};

//Восстановление комментария
export const recoverComment = (context, id) => {
	api.post('/posts/comments/recovery', {
		id: id,
	})
		.then(res => {
			const { errorMessage, responseCode } = res.data;
			if (responseCode === 0) {
				context.props.recoverCommentItem(context.props.id, id);
				context.props.addCommentCount(+context.props.id);
				showNotification(context, true, 0, 'Комментарий восстановлен');
			} else {
				showNotification(context, true, 1, errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/comments/recovery/ - ${error}`);
		});
};

//Редактирование комментария
export const updateComment = (context, id, note, notifyUserIds) => {
	api.post('/posts/comments/edit', {
		id: id,
		note: note,
		notifyUserIds: notifyUserIds,
	})
		.then(res => {
			const { errorMessage, response, responseCode } = res.data;
			if (responseCode === 0) {
				context.props.updateCommentItem(context.props.id, id, response);
				if (context.state.post) {
					const { post } = context.state;
					context.setState({
						post: {
							...post,
							comments: post.comments.map(comment => {
								if (+comment.id === +id) {
									comment.note = response.note;
									comment.changeDate = response.changeDate;
								}
								return comment;
							}),
						},
					});
				}
				showNotification(context, true, 0, 'Комментарий обновлен');
			} else {
				showNotification(context, true, 1, errorMessage);
			}
		})
		.catch(function (error) {
			showNotification(context, true, 1, `/posts/comments/edit/ - ${error}`);
		});
};

//Установить/снять лайк комментария
export const addOrRemoveCommentsLike = (context, id, like) => {
	api.post('/posts/comments/likes/add-remove', {
		postCommentId: id,
		like,
	})
		.then(res => {
			const { errorMessage, responseCode } = res.data;
			if (responseCode === 0) {
				context.props.addRemoveCommentsLike(context.props.id, id, like);
			} else {
				console.error(context, true, 1, errorMessage);
			}
		})
		.catch(function (error) {
			console.error(context, true, 1, `/posts/comments/likes/add-remove - ${error}`);
		});
};

export const postsPageCommentsLikes = (context, commentId, like) => {
	api.post('/posts/comments/likes/add-remove', {
		postCommentId: +commentId,
		like,
	})
		.then(function (response) {
			if (response.data.responseCode === 0) {
				context.props.addRemoveCommentsLike(context.props.postPage.id, commentId, like);
			} else {
				console.error(context, true, 1, response.data.errorMessage);
			}
		})
		.catch(function (error) {
			console.error(context, true, 1, `/posts/likes/add-remove - ${error}`);
		});
};

export const getLikesList = async (id, limit) => {
	try {
		let response;
		if (limit) {
			response = await api.get(`/posts/comments/likes/list?id=${id}&limit=${limit}`);
		} else {
			response = await api.get(`/posts/comments/likes/list?id=${id}`);
		}
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};
