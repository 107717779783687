const hasRole = (rolesArray, role) => {
    let roles = [];
    if (typeof role === "string") {
        roles.push(role);
    } else {
        roles = [...role];
    }
    if (Array.isArray(rolesArray)) {
        let hasRole = false;
        roles.every(item => {
            hasRole = rolesArray.includes(item);
            if (hasRole) {
                return false;
            } else {
                return true;
            }
        });
        return hasRole;
    } else return false;
};
export default hasRole;
