import React from 'react';
import ReactGA from 'react-ga';
import { NavLink, withRouter } from 'react-router-dom';

import './style.scss';

export const LinkItem = withRouter(({ name, link, ga, onClick, location }) => {
	const gaClick = () => {
		if (ga !== null) {
			ReactGA.event({
				category: ga.eventCategory,
				action: ga.eventAction,
				label: ga.eventLabel,
			});
		}
	};

	if (link === null) {
		return <span className="navigation-link">{name}</span>;
	} else {
		return (
			<NavLink
				to={link}
				className="navigation-link"
				onClick={event => {
					gaClick();
					if (onClick && link === location.pathname) {
						onClick(event);
					}
				}}
			>
				{name}
			</NavLink>
		);
	}
});
