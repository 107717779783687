import {
	MENTORING_SET_LIST,
	MENTORING_PUSH_TO_LIST,
	MENTORING_REMOVE_ITEM,
	MENTORING_UPDATE_ITEM,
	ADD_MENTOR_TO_LIST,
	MENTORS_SET_LIST,
	DELETE_MENTOR,
} from 'constant';

const initialState = {
	list: [],
	mentors: [],
};

export default function mentoring(state = initialState, action) {
	switch (action.type) {
		case MENTORING_SET_LIST:
			return {
				...state,
				list: [...action.pagesList],
			};

		case MENTORING_PUSH_TO_LIST:
			return {
				...state,
				list: [...state.list, action.newPage],
			};

		case MENTORING_REMOVE_ITEM:
			const newList = state.list.filter(item => item.id !== action.pageId);
			return {
				...state,
				list: [...newList],
			};

		case MENTORING_UPDATE_ITEM:
			const updateList = state.list.map(item => {
				if (item.id === action.updateItem.id) item = action.updateItem;
				return item;
			});
			return {
				...state,
				list: [...updateList],
			};

		case MENTORS_SET_LIST:
			const sortMentors = action.mentors.sort(
				(a, b) => a.surname.localeCompare(b.surname) || a.name.localeCompare(b.name)
			);

			return {
				...state,
				mentors: [...sortMentors],
			};

		case ADD_MENTOR_TO_LIST:
			const newMentors = [...state.mentors, action.mentor];
			newMentors.sort(
				(a, b) => a.surname.localeCompare(b.surname) || a.name.localeCompare(b.name)
			);

			return {
				...state,
				mentors: [...newMentors],
			};

		case DELETE_MENTOR:
			const newMentorsList = state.mentors.filter(item => item.id !== action.mentorId);

			return {
				...state,
				mentors: [...newMentorsList],
			};

		default:
			return state;
	}
}
