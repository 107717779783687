import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useClickOutside from 'hooks/useClickOutside';

import { HeaderLink } from '../../atoms';
import { HeaderNav } from '../../molecules';
import { HeaderNtf } from '../index';

import { toggleHeadNotifications } from 'action/ui';

export const NotificationsMenu = () => {
	const { ui } = useSelector(state => {
		return {
			ui: state.ui,
		};
	});
	const menuRef = useRef(null);
	const dispatch = useDispatch();

	useClickOutside(menuRef, () => {
		ui.headerNotifications && dispatch(toggleHeadNotifications(false));
	});

	if (ui.headerNotifications) {
		return (
			<div className="header-menu" ref={menuRef}>
				<HeaderNav>
					<HeaderLink url={null} className="active">
						Уведомления
					</HeaderLink>
				</HeaderNav>

				<HeaderNtf />
			</div>
		);
	} else return null;
};
