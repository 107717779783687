import React, { forwardRef } from 'react'
import classNames from 'classnames'
import './style.scss'

const FormHelperText = forwardRef(function FormHelperText(props, ref) {
    const {
        children,
        className,
        component: Component = 'p',
        disabled,
        error,
        filled,
        focused,
        margin,
        required,
        variant,
        ...other
    } = props

    return (
        <Component
            className={classNames(
                'form-helper-text',
                {
                    'form-helper-text_disabled': disabled,
                    'form-helper-text_error': error,
                    'form-helper-text_filled': filled,
                    'form-helper-text_focused': focused,
                    'form-helper-text_required': required,
                },
                className
            )}
            ref={ref}
            {...other}
        >
            {children === ' ' ? (
                <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
            ) : (
                children
            )}
        </Component>
    )
})

export default FormHelperText
