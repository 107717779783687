// Фиксация body
export const bodyFixPosition = () => {
	let scrollPosition = window.pageYOffset || document.documentElement.scrollTop; // Получаем позицию прокрутки

	document.body.style.position = 'fixed';
	document.body.style.overflow = 'hidden';
	document.body.style.top = `-${scrollPosition}px`;
	document.body.style.height = `calc(100% + ${scrollPosition}px)`;
};

// Расфиксация body
export const bodyUnfixPosition = () => {
	// Получаем значение позиции прокрутки из CSS, что мы установили первой функцией в body, в виде числа без px, но с точкой, если значение дробное
	let scrollCSSPosition = document.body.style.top.replace(/[^0-9.]/g, '');

	document.body.style.position = '';
	document.body.style.top = '';
	document.body.style.overflow = 'initial';
	document.body.style['-webkit-overflow-scrolling'] = 'touch';
	document.body.style.height = '';

	window.scroll(0, scrollCSSPosition);
};
