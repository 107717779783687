import React from 'react';
import Helmet from 'react-helmet';

import { AuthForm } from 'feature/auth';

import './style.scss';
import { useAuthCheck } from './useAuthCheck';

const Auth = () => {
	useAuthCheck();

	return (
		<>
			<Helmet title="Вход" />
			<div className="auth-page">
				<AuthForm />
			</div>
		</>
	);
};

export default Auth;
