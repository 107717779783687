import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HelpCircle } from 'react-feather';

import './style.scss';

export const Help = () => {
	const faqHelpId = useSelector(state => state.options?.faqHelpId);

	if (faqHelpId) {
		return (
			<Link to={`/knowledge/faq/${faqHelpId}`} className="navigation-help">
				<HelpCircle className="help-icon" />
				<span className="help-text">Помощь</span>
			</Link>
		);
	} else return null;
};
