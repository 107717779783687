import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

interface ICombine {
	name: string;
	link?: string;
}

interface IProps {
	combine: ICombine[];
}

export const RenderFor = ({ combine }: IProps) => {
	const [showMore, setShowMore] = useState(false);

	const callback = (item: ICombine, index: number, arr: ICombine[]) => {
		return item.link ? (
			<NavLink
				to={item.link}
				className="pulse-survey-table-users__item"
				key={index}
				id="pulse-survey_table_user-link"
			>
				{item.name}
				{arr.length - 1 !== index && ', '}
			</NavLink>
		) : (
			<span key={index}>
				{item.name}
				{arr.length - 1 !== index && ', '}
			</span>
		);
	};

	return (
		<div className="pulse-survey-table-users">
			{combine.slice(0, 5).map(callback)}
			{showMore && combine.slice(5).map(callback)}

			{combine.length > 5 && (
				<p
					className="pulse-survey-show-more"
					onClick={() => setShowMore(prev => !prev)}
					id="pulse-survey_table_toggle"
				>
					{showMore ? 'Скрыть' : 'Показать ещё'}
				</p>
			)}
		</div>
	);
};
