import {
    GET_STICKERS_LIST,
    ADD_STICKERS,
    DELETE_STICKERS
} from '../constant'

export const getStickersList = (list) => {
    return {
        type: GET_STICKERS_LIST,
        list: list
    }
}

export const addStickerItem = (stickers) => {
    return {
        type: ADD_STICKERS,
        stickers: stickers
    }
}

export const deleteStickerItem = (id) => {
    return {
        type: DELETE_STICKERS,
        id: id
    }
}