import React, { FC, useEffect, useState } from 'react';

import { IQuestionComponentProps, IAnswered } from '../types';
import { URL } from 'constant';
import { TextField } from 'core/components';

export const SingleQuestion: FC<IQuestionComponentProps> = ({
	question,
	answeredHandler,
	error,
	defaultValue,
}) => {
	const [selectedAnswer, setSelectedAnswer] = useState<IAnswered>();
	const [ownAnswer, setOwnAnswer] = useState(false);
	const [ownAnswerError, setOwnAnswerError] = useState(false);

	useEffect(() => {
		if (defaultValue?.selectedAnswer) {
			answeredHandler(
				defaultValue?.selectedAnswer.map(item => {
					// @ts-ignore
					if (item.ownAnswer) item.text = defaultValue.textAnswer;
					return item;
				})
			);

			setSelectedAnswer(defaultValue.selectedAnswer[0]);

			setOwnAnswer(defaultValue.selectedAnswer[0].ownAnswer);
		}
	}, [defaultValue, answeredHandler]);

	const changeAnswer = (data: IAnswered) => {
		answeredHandler([data.ownAnswer ? { ...data, text: '' } : data]);
		setSelectedAnswer(data.ownAnswer ? { ...data, text: '' } : data);
		setOwnAnswer(data.ownAnswer);
	};

	const ownAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!selectedAnswer) return;

		const { value } = event.currentTarget;
		setOwnAnswerError(!value);

		answeredHandler([
			{
				id: selectedAnswer.id,
				text: value,
				ownAnswer: true,
			},
		]);
	};

	return (
		<div className="question">
			{question.image && (
				<img src={URL + question.image.filePath} alt="" className="question__preview" />
			)}

			<div className="question__title">{question.text}</div>

			<div className="question-answers">
				{question.possibleAnswers.map(item => (
					<label className="question-answers__item" key={item.id}>
						<div className="question-answers__wrap">
							<input
								type="radio"
								name="question-answer"
								className="question-answers__input"
								onChange={() => changeAnswer(item)}
								checked={item.id === selectedAnswer?.id}
							/>
							<div className="question-answers__circle"></div>
							<p className="question-answers__text">{item.text}</p>
						</div>

						{item.ownAnswer && ownAnswer && (
							<TextField
								// @ts-ignore
								placeholder="Введите текст"
								error={ownAnswerError}
								name="own-answer"
								onChange={ownAnswerChange}
								fullWidth={true}
								autoComplete="off"
								defaultValue={defaultValue?.textAnswer}
							/>
						)}
					</label>
				))}
			</div>

			{error && <div className="question-answers-error">Выберите вариант ответа</div>}
		</div>
	);
};
