import React, { Component } from 'react'

export class Image extends Component {

    render() {
        let { src } = this.props
        return (
            <img data-src={src} ref={(el) => { this.image = el }} className="MIImage" alt="" />
        )
    }

    componentDidMount() {
        if ("IntersectionObserver" in window) {
            let image = this.image
            let imageObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        let image = entry.target;
                        image.src = image.dataset.src;
                        imageObserver.unobserve(image);
                    }
                });
            });
            imageObserver.observe(image)
        } else {
            let lazyloadThrottleTimeout;
            let image = this.image
            function lazyload() {
                if (lazyloadThrottleTimeout) {
                    clearTimeout(lazyloadThrottleTimeout);
                }

                lazyloadThrottleTimeout = setTimeout(function () {
                    let scrollTop = window.pageYOffset;
                    if (image.offsetTop < (window.innerHeight + scrollTop)) {
                        image.src = image.dataset.src
                    }
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }, 20);
            }

            document.addEventListener("scroll", lazyload);
            window.addEventListener("resize", lazyload);
            window.addEventListener("orientationChange", lazyload);
        }
    }
}