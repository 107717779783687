import React, {
    forwardRef,
    useEffect,
    useRef,
    cloneElement
} from 'react'
import { Transition } from 'react-transition-group'
import useForkRef from 'core/utils/useForkRef'
import {
    create,
    reflow,
    getTransitionProps,
    getAutoHeightDuration
} from 'core/utils/transitions'

function getScale(value) {
    return `scale(${value}, ${value ** 2})`
}

const styles = {
    entering: {
        opacity: 1,
        transform: getScale(1),
    },
    entered: {
        opacity: 1,
        transform: 'none',
    },
}

const TransitionGrow = forwardRef(function TransitionGrow(props, ref) {
    const {
        children,
        in: inProp,
        onEnter,
        onExit,
        style,
        timeout = 'auto',
        ...other
    } = props
    const timer = useRef()
    const autoTimeout = useRef()
    const handleRef = useForkRef(children.ref, ref)

    const handleEnter = (node, isAppearing) => {
        reflow(node)
    
        const { duration: transitionDuration, delay } = getTransitionProps(
            { style, timeout },
            { mode: 'enter' }
        )
    
        let duration
        if (timeout === 'auto') {
            duration = getAutoHeightDuration(node.clientHeight)
            autoTimeout.current = duration
        } else {
            duration = transitionDuration
        }
    
        node.style.transition = [
            create('opacity', {
                duration,
                delay
            }),
            create('transform', {
                duration: duration * 0.666,
                delay
            })
        ].join(',')
    
        if (onEnter)
            onEnter(node, isAppearing)
    }

    const handleExit = node => {
        const { duration: transitionDuration, delay } = getTransitionProps(
            { style, timeout },
            { mode: 'exit' }
        )
    
        let duration
        if (timeout === 'auto') {
            duration = getAutoHeightDuration(node.clientHeight)
            autoTimeout.current = duration
        } else {
            duration = transitionDuration
        }
    
        node.style.transition = [
            create('opacity', {
                duration,
                delay
            }),
            create('transform', {
                duration: duration * 0.666,
                delay: delay || duration * 0.333
            })
        ].join(',')
    
        node.style.opacity = '0'
        node.style.transform = getScale(0.75)
    
        if (onExit) {
            onExit(node)
        }
    }

    const addEndListener = (_, next) => {
        if (timeout === 'auto') {
            timer.current = setTimeout(next, autoTimeout.current || 0)
        }
    }
    
    useEffect(() => {
        return () => {
            clearTimeout(timer.current)
        }
    }, [])

    return (
        <Transition
            appear
            in={inProp}
            onEnter={handleEnter}
            onExit={handleExit}
            addEndListener={addEndListener}
            timeout={timeout === 'auto' ? null : timeout}
            {...other}
        >
            {(state, childProps) => cloneElement(children, {
                style: {
                    opacity: 0,
                    transform: getScale(0.75),
                    visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
                    ...styles[state],
                    ...style,
                    ...children.props.style,
                },
                ref: handleRef,
                ...childProps
            })}
        </Transition>
    )
})

export default TransitionGrow
