import { api } from 'rest/api';
import { ISearchData, ISearchPost } from './types';
import { createError } from 'shared/api';

export const getSearchResult = async (data: ISearchData) => {
	let searchString = `/search-v2/find?`;
	if (data.query) {
		searchString += `query=${data.query}`;
	}
	if (data.indexName) {
		searchString += `&indexName=${data.indexName}`;
	}
	if (data.limit) {
		searchString += `&limit=${data.limit}`;
	}
	if (data.withoutMe) {
		searchString += `&withoutMe=${data.withoutMe}`;
	}
	if (data.quick) {
		searchString += `&quick=${data.quick}`;
	}

	try {
		const response = await api.get(encodeURI(searchString));
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getSearchResultForPost = async (data: ISearchPost) => {
	try {
		const response = await api.get('/posts/find', {
			params: data,
		});
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getSearchResultTag = async (data: ISearchData) => {
	try {
		const response = await api.get('/tags/find-by-value', {
			params: { value: data.query },
		});
		return response.data;
	} catch (error) {
		return createError(error);
	}
};
