import { PulseSurveyList } from 'pages/survey/PulseSurveyList';
import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

const HomePage = lazy(() => import('../homePage'));
const NewContacts = lazy(() => import('../contacts'));
const UserPage = lazy(() => import('../user'));
const GroupPage = lazy(() => import('../group'));
const PostPage = lazy(() => import('../feed'));
const ThanksPage = lazy(() => import('../thanks'));
const VacanciesPage = lazy(() => import('../vacancies'));
const ClubPage = lazy(() => import('../club'));
const ClubPageKPP = lazy(() => import('../../pages/club'));
const AppointmentsPage = lazy(() => import('../appointments'));
const HistoryPage = lazy(() => import('../history'));
const Knowledge = lazy(() => import('../knowledge'));
const Guide = lazy(() => import('../guide'));
const WalkFamePage = lazy(() => import('../walkfame'));
const Activities = lazy(() => import('../activities'));
const Services = lazy(() => import('../services'));
const Passes = lazy(() => import('../passes'));
const NewItServices = lazy(() => import('../it-services_new'));
const University = lazy(() => import('../university'));
const Competencies = lazy(() => import('../competencies'));
const Trips = lazy(() => import('../trips'));
const Calendar = lazy(() => import('../calendar'));
const Education = lazy(() => import('../education'));
const BusinessCard = lazy(() => import('../businessCard'));
const Birthday = lazy(() => import('../birthday'));
const StrategyPage = lazy(() => import('../../pages/strategy'));
const Favorites = lazy(() => import('../favorites'));
const Gallery = lazy(() => import('../gallery'));
const Updates = lazy(() => import('../updates'));
const NormativeDocument = lazy(() => import('../normativeDocument'));

const Admin = lazy(() => import('../admin'));
const NoPage = lazy(() => import('../noPage'));
const ForbiddenPage = lazy(() => import('../forbiddenPage'));

const Camera = lazy(() => import('../../core/camera'));

const Search = lazy(() => import('../search/pages/main'));
const SearchResult = lazy(() => import('../search/pages/result'));

const MobileMenu = lazy(() => import('../bottomMenu/menu'));

const Structure = lazy(() => import('../structure'));
const StructurePage = lazy(() => import('../../pages/structure'));

const MirapolisMobile = lazy(() => import('../mirapolis-mobile-hub'));

const Articles = lazy(() => import('../new-article'));

const MentoringPage = lazy(() => import('../../pages/mentoring'));
const RetailShiftsPage = lazy(() => import('../../pages/retailShifts'));
const RetailShiftsPageEdit = lazy(() => import('../../pages/retailShifts/Edit'));

const HotLinePage = lazy(() => import('../../pages/hotLine'));
const CorporateValuesPage = lazy(() => import('../../pages/corporateValues'));
const AchievementsPage = lazy(() => import('../../pages/achievements'));
const QuizPage = lazy(() => import('../../pages/quiz'));
const CropperPage = lazy(() => import('../../pages/cropper'));
const VacationPage = lazy(() => import('../../pages/vacation'));

const PageStatements = lazy(() => import('../../pages/statements'));

const CourierPage = lazy(() => import('../../pages/courier'));
const PageLTR = lazy(() => import('../../pages/LTR'));
const PageSurvey = lazy(() => import('../../pages/survey'));
const SurveyChildsPage = lazy(() => import('../../pages/survey/SurveyChildsPage'));
const WorkplacePage = lazy(() => import('../../pages/workplace'));
const InventoryPage = lazy(() => import('../../pages/inventory'));

const DocumentCreate = lazy(() => import('../new-article/pages/documentCreate'));
const DocumentEdit = lazy(() => import('../new-article/pages/documentEdit'));
const DocumentView = lazy(() => import('../new-article/pages/documentView'));
const MessengerPage = lazy(() => import('../../pages/messenger'));
const PageDMS = lazy(() => import('../../pages/DMS'));
const PageMaxiProfessional = lazy(() => import('../../pages/maxiProfessional'));
const RepairServicesPage = lazy(() => import('../../pages/repairServices'));

const Router = () => {
	return (
		<Switch>
			<Route exact path="/" component={HomePage} />
			<Route exact strict path="/main" component={HomePage} />
			<Route exact strict path="/main/thanks/:thankId" component={HomePage} />
			<Route exact path="/main/news/:idPost" component={HomePage} />
			<Route exact path="/main/news/:idPost/:idPoll" component={HomePage} />

			<Route exact path="/group/:id/article/create" component={Articles} />
			<Route exact path="/group/:id/article/edit/:idArticle" component={Articles} />
			<Route exact path="/group/:id/article/:idArticle" component={Articles} />

			<Route exact path="/knowledge/guide/:id*/article/create" component={Articles} />
			<Route exact path="/knowledge/guide/:id*/article/:articleId" component={Articles} />
			<Route
				exact
				path="/knowledge/guide/:id*/article/:articleId/edit"
				component={Articles}
			/>

			<Route exact strict path="/media" component={Camera} />

			<Route exact path="/contacts" component={NewContacts} />
			<Route exact path="/contacts/:id*" component={NewContacts} />

			<Route exact strict path="/user/:id" component={UserPage} />
			<Route exact path="/user/:id/congratulation/:congratulationId" component={UserPage} />
			<Route exact path="/user/:id/achievement/:achievementId" component={UserPage} />
			<Route exact path="/user/:id/club-card" component={UserPage} />
			<Route exact path="/user/:id/notification" component={UserPage} />
			<Route exact path="/user/:id/claims" component={UserPage} />
			<Route exact path="/user/:id/claims/:claimId" component={UserPage} />
			<Route exact path="/user/:id/:thankId" component={UserPage} replace />
			<Route exact path="/user/:id/approvement-requests" component={UserPage} />
			<Route
				exact
				path="/user/:id/:approvement-requests/:approvementId"
				component={UserPage}
			/>

			<Route exact path="/group" component={GroupPage} />
			<Route exact path="/group/:id" component={GroupPage} />
			<Route exact path="/group-create" component={GroupPage} />
			<Route exact path="/group/:id/members" component={GroupPage} />
			<Route exact path="/group/:id/edit" component={GroupPage} />
			<Route exact path="/group/:id/albums" component={GroupPage} />
			<Route exact path="/group/:id/albums/create" component={GroupPage} />
			<Route exact path="/group/:id/albums/:albumId" component={GroupPage} />
			<Route exact path="/group/:id/albums/:albumId/:photoId" component={GroupPage} />
			<Route exact path="/group/:id/article" component={GroupPage} />
			<Route exact path="/group/:id/:idPost" component={GroupPage} />
			<Route exact path="/group/:id/:idPost/:idPoll" component={GroupPage} />

			<Route exact path="/" component={HomePage} />
			<Route exact path="/news" component={PostPage} />
			<Route exact path="/news/thanks/:thankId" component={PostPage} />
			<Route exact path="/news/:idPost" component={PostPage} />
			<Route exact path="/news/:idPost/:idPoll" component={PostPage} />

			<Route exact path="/thanks" component={ThanksPage} />
			<Route exact path="/thanks/:id" component={ThanksPage} />
			<Route exact path="/vacancies" component={VacanciesPage} />
			<Route exact path="/vacancies/create" component={VacanciesPage} />
			<Route exact path="/vacancies/rules" component={VacanciesPage} />
			<Route exact path="/vacancies/:id" component={VacanciesPage} />
			<Route exact path="/vacancies/:id/edit" component={VacanciesPage} />

			<Route exact path="/club" component={ClubPage} />
			<Route exact path="/club/card" component={ClubPage} />
			<Route exact path="/club/partners" component={ClubPage} />
			<Route exact path="/club/partners/:idCity" component={ClubPage} />
			<Route exact path="/club/partners/:idCity/:idCat" component={ClubPage} />
			<Route exact path="/club/partner/:id" component={ClubPage} />
			<Route exact path="/club/admin/categories" component={ClubPage} />
			<Route exact path="/club/admin/partner" component={ClubPage} />
			<Route exact path="/club/admin/partner/create" component={ClubPage} />
			<Route exact path="/club/admin/partner/edit/:id" component={ClubPage} />
			<Route exact path="/club/:idCity/search/:text" component={ClubPage} />
			<Route exact path="/club/admin/maxi-club-kpp" component={ClubPageKPP} />
			<Route path="/club/maxi-club-kpp" component={ClubPageKPP} />

			<Route path="/admin" component={Admin} />

			<Route exact path="/appointments" component={AppointmentsPage} />
			<Route exact path="/appointments/:id" component={AppointmentsPage} />

			<Route exact path="/history" component={HistoryPage} />
			<Route exact path="/corporate-values" component={CorporateValuesPage} />

			<Route exact path="/knowledge/glossary" component={Knowledge} />
			<Route exact path="/knowledge/glossary/add" component={Knowledge} />

			<Route exact path="/knowledge/guide" component={Guide} />
			<Route exact path="/knowledge/guide/create" component={Guide} />
			<Route exact path="/knowledge/guide/:id*/create-child" component={Guide} />
			<Route exact path="/knowledge/guide/:id*/edit" component={Guide} />
			<Route exact path="/knowledge/guide/:id*/edit-child" component={Guide} />
			<Route exact path="/knowledge/guide/:id*" component={Guide} />

			<Route exact path="/birthday*" component={Birthday} />

			<Route exact path="/strategy" component={StrategyPage} />
			<Route exact path="/strategy/:id" component={StrategyPage} />

			<Route path="/maxiProfessional" component={PageMaxiProfessional} />

			<Route exact path="/forbidden" component={ForbiddenPage} />

			<Route exact path="/search" component={Search} />
			<Route exact path="/search/:entity/:query" component={SearchResult} />

			<Route exact path="/menu" component={MobileMenu} />

			<Route exact path="/walkfame" component={WalkFamePage} />
			<Route exact path="/walkfame/create" component={WalkFamePage} />
			<Route exact path="/walkfame/all" component={WalkFamePage} />
			<Route exact path="/walkfame/:id" component={WalkFamePage} />
			<Route exact path="/walkfame/edit/:id" component={WalkFamePage} />
			<Route exact path="/walkfame/:id/edit/:userId" component={WalkFamePage} />
			<Route exact path="/walkfame/:id/album" component={WalkFamePage} />
			<Route exact path="/walkfame/:id/:userId" component={WalkFamePage} />

			<Route exact path="/activities" component={Activities} />
			<Route exact path="/activities/create" component={Activities} />
			<Route exact path="/activities/:id" component={Activities} />
			<Route exact path="/activities/:id/users" component={Activities} />
			<Route exact path="/activities/:id/participants" component={Activities} />
			<Route exact path="/activities/:id/edit" component={Activities} />

			<Route exact path="/services" component={Services} />
			<Route exact path="/services/carFix" component={Services} />
			<Route exact path="/services/doctor" component={Services} />
			<Route exact path="/services/doctor/edit" component={Services} />
			<Route exact path="/services/doctor/all" component={Services} />
			<Route exact path="/services/doctor/waiting-list" component={Services} />

			<Route path="/services/courier" component={CourierPage} />

			<Route path="/services/vacation" component={VacationPage} />

			<Route path="/services/workplace" component={WorkplacePage} />

			<Route path="/services/inventory" component={InventoryPage} />

			<Route path="/services/hot-line" component={HotLinePage} />

			<Route exact path="/services/statements/:id" component={PageStatements} />

			<Route exact path="/services/trips/:id" component={Trips} />
			<Route exact path="/services/trips/:id/trip/:tripId" component={Trips} />

			<Route exact path="/services/retail-shifts" component={RetailShiftsPage} />
			<Route exact path="/services/retail-shifts/edit" component={RetailShiftsPageEdit} />
			<Route exact path="/services/retail-shifts/:id" component={RetailShiftsPage} />

			<Route exact path="/services/passes/:id/admin" component={Passes} />
			<Route exact path="/services/passes/:id/:categoryId" component={Passes} />

			<Route exact path="/v2/services/:id" component={NewItServices} />
			<Route
				exact
				path="/v2/services/:id/:categoryId*/ticket/:ticketId"
				component={NewItServices}
			/>
			<Route exact path="/v2/services/:id/:categoryId*" component={NewItServices} />

			<Route path="/education" component={Education} />

			<Route exact path="/university" component={University} />
			<Route exact path="/university/literature" component={University} />
			<Route exact path="/knowledge/faq" component={University} />
			<Route exact path="/knowledge/faq/:id" component={University} />
			<Route exact path="/knowledge/faq/:id/add" component={University} />
			<Route exact path="/knowledge/faq/create" component={University} />
			<Route exact path="/knowledge/faq/:id/edit" component={University} />

			<Route exact path="/calendar/:id" component={Calendar} />

			<Route exact path="/competencies" component={Competencies} />

			<Route exact path="/structure" component={Structure} />
			<Route exact path="/new-structure" component={StructurePage} />

			<Route exact path="/services/business-card*" component={BusinessCard} />

			<Route path="/favorites" component={Favorites} />

			<Route exact path="/updates" component={Updates} />

			<Route exact path="/gallery" component={Gallery} />
			<Route exact path="/gallery/photos" component={Gallery} />
			<Route exact path="/gallery/photos/create" component={Gallery} />
			<Route exact path="/gallery/photos/:albumId/edit" component={Gallery} />
			<Route exact path="/gallery/photos/:albumId" component={Gallery} />
			<Route exact path="/gallery/videos/create" component={Gallery} />
			<Route exact path="/gallery/videos" component={Gallery} />
			<Route exact path="/gallery/videos/:videoId/edit" component={Gallery} />

			<Route exact path="/404" component={NoPage} />

			<Route exact path="/mirapolis-mobile" component={MirapolisMobile} />

			<Route path="/mentoring" component={MentoringPage} />

			<Route path="/achievements" component={AchievementsPage} />

			<Route path="/quiz" component={QuizPage} />
			<Route path="/cropper" component={CropperPage} />

			<Route path="/ltr" component={PageLTR} />

			<Route exact path="/services/pulse-survey/list" component={PulseSurveyList} />
			<Route path="/services/pulse-survey" component={PageSurvey} />
			<Route path="/services/survey" component={PageSurvey} />
			<Route path="/special-survey" component={SurveyChildsPage} />

			<Route exact path="/knowledge/documents/create/:themeId" component={DocumentCreate} />
			<Route exact path="/knowledge/documents/edit/:themeId/:id" component={DocumentEdit} />
			<Route exact path="/knowledge/documents/view/:themeId/:id" component={DocumentView} />
			<Route path="/knowledge/documents" component={NormativeDocument} />

			<Route path="/messenger" component={MessengerPage} />

			<Route path="/VHI" component={PageDMS} />

			<Route path="/services/repair" component={RepairServicesPage} />

			<Route component={NoPage} />
		</Switch>
	);
};

export default Router;
