import { createEffect, createStore, createEvent, forward } from 'effector';
import { EmiliaBannerResponse } from 'emilia/shared/api/types';
import { getBanner as getBannerApi } from 'emilia/shared/api';

//Get
const getBanner = createEvent();

const getBannerFx = createEffect(() => {
	return getBannerApi();
});

forward({
	from: getBanner,
	to: getBannerFx,
});

export const $banner = createStore<EmiliaBannerResponse | null>(null).on(
	getBannerFx.doneData,
	(_, data) => {
		if (data.responseCode === 0) {
			const { response } = data;
			return response;
		}
		return null;
	}
);

export const events = {
	getBanner,
};

export const effects = {
	getBannerFx,
};
