import { CONGRATULATION_MODAL_STATE } from '../constant';

const defaultValue = {
	modalState: false,
	iconType: '',
	data: {},
};

export default function congratulationModalState(state = defaultValue, action) {
	switch (action.type) {
		case CONGRATULATION_MODAL_STATE:
			return {
				state,
				...action.congratulationModalState,
			};

		default:
			return state;
	}
}
