import {
	REQUEST_FEED_POSTS,
	RECEIVE_FEED_POSTS,
	RECEIVE_NEXT_FEED_POSTS,
	RECEIVE_PREV_FEED_POSTS,
	CLEAR_FEED_POSTS,
	REQUEST_FEED_POSTS_FAILD,
	SET_FEED_CURRENT_PAGE,
	SET_FEED_LAST_PAGE,
	POST_LIKE,
	POST_VIEW,
	POST_FAVORITE,
	SET_GROUP_POSTS,
	SET_FEED_IS_LOADING_POSTS,
	PAGE_SIZE,
	GET_COMMENTS_LIST,
	COMMENTS_COUNT_ADD,
	COMMENTS_COUNT_REMOVE,
	HIDE_COMMENTS,
	DELETE_COMMENT,
	RECOVER_COMMENT,
	ADD_COMMENT,
	UPDATE_COMMENT,
	VOTE_IN_POLL,
	SET_FEED_LAST_POST_ID,
	ADD_OR_REMOVE_COMMENTS_LIKE,
} from 'constant';

const initialState = {
	currentPage: 0,
	isLoadingPosts: false,
	lastPage: false,
	posts: [],
	windowTop: 0,
	lastPostId: null,
};

export default function feed(state = initialState, action) {
	switch (action.type) {
		case REQUEST_FEED_POSTS:
			return {
				...state,
				isLoadingPosts: true,
			};

		case RECEIVE_FEED_POSTS:
			return {
				...state,
				isLoadingPosts: false,
				posts: [...state.posts, ...action.payload],
			};

		case RECEIVE_NEXT_FEED_POSTS:
			return {
				...state,
				isLoadingPosts: false,
				posts: [
					...state.posts.slice(Math.max(state.posts.length - PAGE_SIZE, 0)),
					...action.payload,
				],
			};

		case RECEIVE_PREV_FEED_POSTS:
			return {
				...state,
				isLoadingPosts: false,
				posts: [...action.payload, ...state.posts.slice(0, PAGE_SIZE)],
			};

		case CLEAR_FEED_POSTS:
			return initialState;

		case GET_COMMENTS_LIST:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.id) {
						post.comments = [...action.comments];
					}
					return post;
				}),
			};

		case ADD_COMMENT:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.postId) {
						post.comments = [...post.comments, action.comment];
					}
					return post;
				}),
			};

		case COMMENTS_COUNT_ADD:
			return {
				...state,
				posts: state.posts.map(post => {
					if (post.id === action.id) {
						post.commentCount++;
						return post;
					} else {
						return post;
					}
				}),
			};

		case COMMENTS_COUNT_REMOVE:
			return {
				...state,
				posts: state.posts.map(post => {
					if (post.id === action.id) {
						post.commentCount--;
						return post;
					} else {
						return post;
					}
				}),
			};

		case HIDE_COMMENTS:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.postId) {
						let hidden = post.comments.splice(-3);
						post.comments = hidden;
					}
					return post;
				}),
			};

		case DELETE_COMMENT:
			return {
				...state,
				posts: state.posts.map(item => {
					if (+item.id === +action.postId) {
						item.comments = item.comments.filter(comment => {
							if (+comment.id === +action.commentId) {
								comment.isDelete = true;
								return comment;
							} else {
								return comment;
							}
						});
					}
					return item;
				}),
			};

		case RECOVER_COMMENT:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.postId) {
						post.comments = post.comments.filter(comment => {
							if (+comment.id === +action.commentId) {
								comment.isDelete = false;
								return comment;
							} else {
								return comment;
							}
						});
					}
					return post;
				}),
			};

		case UPDATE_COMMENT:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.postId) {
						post.comments = post.comments.map(comment => {
							if (+comment.id === +action.id) {
								comment.note = action.comment.note;
								comment.changeDate = action.comment.changeDate;
							}
							return comment;
						});
					}
					return post;
				}),
			};

		case ADD_OR_REMOVE_COMMENTS_LIKE:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.postId) {
						post.comments = post.comments.map(comment => {
							if (+comment.id === +action.id && comment.hasLike !== action.like) {
								comment.hasLike = action.like;
								comment.likeCount = comment.hasLike
									? (comment.likeCount += 1)
									: (comment.likeCount -= 1);
							}
							return comment;
						});
					}
					return post;
				}),
			};

		case REQUEST_FEED_POSTS_FAILD:
			return {
				...state,
				isLoadingPosts: false,
			};

		case SET_FEED_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.page,
			};

		case SET_FEED_LAST_PAGE:
			return {
				...state,
				isLoadingPosts: false,
				lastPage: action.lastPage,
			};

		case POST_FAVORITE:
			return {
				...state,
				posts: state.posts.map(item => {
					if (+item.id === +action.id) {
						item.favorite = action.favorite;
					}
					return item;
				}),
			};

		case POST_LIKE:
			return {
				...state,
				posts: state.posts.map(item => {
					if (+item.id === +action.id) {
						item.hasLike = action.hasLike;
						if (action.hasLike) {
							item.likeCount++;
						} else {
							item.likeCount--;
						}
						return item;
					} else return item;
				}),
			};

		case POST_VIEW:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.id) {
						post.view = true;
						post.viewsCount = post.viewsCount + 1;
					}
					return post;
				}),
			};

		case SET_GROUP_POSTS:
			return {
				...state,
				isLoadingPosts: false,
			};

		case SET_FEED_IS_LOADING_POSTS:
			return {
				...state,
				isLoadingPosts: action.isLoadingPosts,
			};

		case VOTE_IN_POLL:
			return {
				...state,
				posts: state.posts.map(post => {
					if (+post.id === +action.postId) {
						post.poll.response = +action.answerId;
						post.poll[`quantity${+action.answerId}`] =
							+post.poll[`quantity${+action.answerId}`] + 1;
					}
					return post;
				}),
			};

		case SET_FEED_LAST_POST_ID:
			return {
				...state,
				lastPostId: action.lastPostId,
			};

		default:
			return state;
	}
}
