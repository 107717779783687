import {
	SET_TRIPS_ITEM_STEP,
	SET_TRIPS_DISABLED_STEPS
} from 'constant'

const DefaultState = {
	tripsStep: 'first',
	disabledSteps: []
};

export default function trips(state = DefaultState, action) {

	switch (action.type) {
		case SET_TRIPS_ITEM_STEP:
			return {
				...state,
				tripsStep: action.step
			}

		case SET_TRIPS_DISABLED_STEPS:
			return {
				...state,
				disabledSteps: [...action.disabledSteps]
			}
			
		default:
			return state
	}

}