import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { events, $banner } from './store';

export const useBanner = () => {
	const banner = useStore($banner);

	useEffect(() => {
		events.getBanner();
	}, []);

	return banner;
};
