import { ALL_CITIES_LIST } from '../constant'

const DefaultState = []

export default function cities(state = DefaultState, action) {

    switch (action.type) {
        case ALL_CITIES_LIST:
            let citiesList = state
            citiesList = [...action.citiesList]
            return citiesList

        default:
            return state
    }
    
}