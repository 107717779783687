import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { api } from 'rest/api';
import parallelLimit from 'core/utils/parallelLimit';
import { setNotification } from 'action/notification';

import './style.scss';

class UploaderBase extends Component {
	static defaultProps = {
		defaultValue: [],
		fullWidth: false,
		inputProps: {},
		limit: 4,
		multiple: false,
		onlyBase64: false,
		maxLength: false,
		currentCount: false,
	};

	state = {
		files: [],
		focused: false,
		hovered: false,
	};

	componentDidMount() {
		const { defaultValue, limit, onlyBase64 } = this.props;
		const { files } = this.state;

		if (defaultValue) {
			parallelLimit(
				[...defaultValue].map(data => {
					const file = {
						data,
						index: files.length,
						originalFileName: data.originalFileName,
						progress: 0,
					};
					this.updateFile(file);

					return async () => {
						if (onlyBase64 && !data.base64) {
							const res = await api.get(data.filePath, {
								responseType: 'blob',
							});
							const base64 = await this.toBase64(res.data);
							file.base64 = base64;
						}

						this.updateFile({
							...file,
							progress: 100,
						});
					};
				}),
				limit
			);
		}
	}

	componentDidUpdate() {
		const { disabled, onBlur } = this.props;
		const { focused } = this.state;

		if (disabled && focused) {
			this.setState({
				focused: false,
			});
			if (onBlur) onBlur();
		}

		if (this.props.clearFilesTrigger) {
			this.props.setClearFilesTrigger(false);
			this.clearFiles();
		}
	}

	handleChange = event => {
		const { limit, multiple, maxLength, currentCount } = this.props;
		const { files } = this.state;

		const length = currentCount !== false ? currentCount : files.length;

		const input = event.target;
		const inputFiles =
			multiple && maxLength
				? [...input.files].slice(0, maxLength - length)
				: [...input.files];

		if (multiple) input.value = null;

		parallelLimit(
			inputFiles.map(file => this.handleUpload(file, input)),
			limit
		);
	};

	handleClick = event => {
		const { onClick } = this.props;
		if (event.currentTarget === event.target) event.currentTarget.focus();
		if (onClick) onClick(event);
	};

	handleFocus = event => {
		const { disabled, onFocus } = this.props;

		if (disabled) {
			event.stopPropagation();
			return;
		}
		if (onFocus) {
			onFocus(event);
		}

		this.setState({
			focused: true,
		});
	};

	handleBlur = event => {
		const { onBlur } = this.props;

		if (onBlur) onBlur(event);

		this.setState({
			focused: false,
		});
	};

	handleEnter = event => {
		const { onMouseEnter } = this.props;

		if (onMouseEnter) {
			onMouseEnter(event);
		}

		this.setState({
			hovered: true,
		});
	};

	handleLeave = event => {
		const { onMouseLeave } = this.props;

		if (onMouseLeave) {
			onMouseLeave(event);
		}

		this.setState({
			hovered: false,
		});
	};

	handleUpload = (instance, input) => {
		const { multiple, onlyBase64, fileType } = this.props;

		const { files } = this.state;

		const file = {
			data: {},
			index: multiple ? files.length : 0,
			instance,
			originalFileName: instance.name,
			progress: 0,
		};

		if (multiple) this.updateFile(file);
		return async () => {
			const base64 = await this.toBase64(file.instance);
			file.base64 = base64;

			if (onlyBase64) {
				file.progress = 100;
				this.updateFile(file);
			} else {
				file.progress = 30;
				api.post('/file/save', {
					originalFileName: file.originalFileName,
					fileType: fileType,
					base64File: file.base64,
				})
					.then(res => res.data)
					.then(data => {
						if (data.responseCode === 0) {
							file.data = data.response;
							file.progress = 100;
							this.updateFile(file);
						} else {
							input.value = null;
							this.props.setnotification({
								isOpen: true,
								status: 1,
								text: data.errorMessage,
							});
							setTimeout(() => {
								this.props.setnotification({
									isOpen: false,
									status: 0,
									text: '',
								});
							}, 3000);
						}
					});
			}
		};
	};

	updateFile = file => {
		const { onProgress } = this.props;
		const { files } = this.state;

		files[file.index] = file;
		this.setState({ files });

		if (onProgress) {
			onProgress(files);
		}
	};

	clearFiles = () => {
		this.setState({ files: [] });
	};

	deleteFile = index => {
		const { onProgress } = this.props;
		const { files } = this.state;
		const updateFiles = [...files].filter((file, fileIndex) => fileIndex !== index);
		this.setState(
			{
				files: updateFiles,
			},
			() => {
				onProgress(this.state.files);
			}
		);
	};

	async toBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = data => {
				const result = data.currentTarget.result;
				const base64File = result.slice(result.indexOf(',') + 1);
				resolve(base64File);
			};
			reader.onerror = () => reject();
			reader.readAsDataURL(file);
		});
	}

	render() {
		const {
			'aria-describedby': ariaDescribedby,
			accept,
			className,
			children,
			currentCount,
			defaultValue,
			disabled,
			endAdornment,
			error,
			fullWidth,
			id,
			inputProps,
			inputRef,
			multiple,
			maxLength,
			name,
			onlyBase64,
			onBlur,
			onClick,
			onFocus,
			onLoad,
			startAdornment,
			renderSuffix,
			required,
			fileType,
			clearFilesTrigger,
			setClearFilesTrigger,
			...other
		} = this.props;
		const { files, focused, hovered } = this.state;

		return (
			<div
				className={classNames(
					'uploader-base',
					{
						'uploader-base_disabled': disabled,
						'uploader-base_error': error,
						'uploader-base_full-width': fullWidth,
						'uploader-base_focused': focused,
						'uploader-base_hovered': hovered,
					},
					className
				)}
				onClick={this.handleClick}
				{...other}
			>
				<input
					aria-invalid={error}
					aria-describedby={ariaDescribedby}
					accept={accept}
					disabled={disabled}
					id={id}
					multiple={multiple}
					type="file"
					{...inputProps}
					className={classNames(
						'uploader-base-control',
						{
							'uploader-base-control_disabled': disabled,
							'uploader-base-control_error': error,
							'uploader-base-control_focused': focused,
							'uploader-base-control_required': required,
						},
						inputProps.className
					)}
					onBlur={this.handleBlur}
					onFocus={this.handleFocus}
					onChange={this.handleChange}
					onMouseEnter={this.handleEnter}
					onMouseLeave={this.handleLeave}
					ref={inputRef}
				/>
				<div className="uploader-base-content">
					{renderSuffix &&
						renderSuffix({
							disabled,
							error,
							files,
							focused,
							hovered,
							required,
							deleteFile: this.deleteFile,
						})}
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setnotification: ntf => {
			dispatch(setNotification(ntf));
		},
	};
};

export default connect(null, mapDispatchToProps, null, { pure: false })(UploaderBase);
