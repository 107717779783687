import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';

import Item from '../DayItem';
import EmptyItem from '../EmptyDayItem';

import renderEmptyItems from '../helpers/renderEmptyItems';
import createDayArray from '../helpers/createDayArray';

const Days = ({
	className,

	currentDay,
	currentMonth,
	currentYear,

	daysInMonth,
	dayBeginingMonth,
	selectCurrentDay,

	disabledDays = [],
	availableDays = [],
	selectedDays = [],

	onDayClick,

	disabledPrevDays,
	disableTo,
	disableFrom,
	daysId,
}) => {
	const [days, setDays] = useState([]);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);

	const [available, setAvailable] = useState([]);
	const [selected, setSelected] = useState([]);
	const [disabled, setDisabled] = useState([]);

	const onDayClickHandler = date => {
		if (onDayClick) {
			onDayClick(date);
		}
	};

	useEffect(() => {
		const days = createDayArray(daysInMonth);
		setDays(days);
	}, [daysInMonth]);

	useEffect(() => {
		const month = getMonth(new Date());
		setMonth(month);

		const year = getYear(new Date());
		setYear(year);
	}, []);

	useEffect(() => {
		const available = availableDays.map(item => {
			const date = new Date(+item);
			const day = getDate(date);
			const month = getMonth(date);
			return `${day}${month}`;
		});
		setAvailable(available);
	}, [availableDays]);

	useEffect(() => {
		if (selectedDays.length) {
			const selected = selectedDays.map(item => {
				const date = new Date(+item);
				const day = getDate(date);
				const month = getMonth(date);
				return `${day}${month}`;
			});
			setSelected(selected);
		} else {
			setSelected([]);
		}
	}, [selectedDays]);

	const getMillisecondsToday = () => {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const diff = now - today;

		return diff;
	};

	const getDisabled = item => {
		if (!disabledPrevDays) return false;

		const now = new Date();
		if (currentYear === getYear(now)) {
			if (currentMonth < getMonth(now)) return true;
			else if (currentMonth === getMonth(now) && currentDay > item) return true;
		}
	};

	useEffect(() => {
		if (disabledPrevDays) {
			const currentDayTime = new Date().getTime();
			const disabled = [];
			for (let i = 0; i < availableDays.length; i++) {
				const item = availableDays[i];
				const date = new Date(+item);
				const dateTime = date.getTime();

				const subtrahend = selectCurrentDay ? getMillisecondsToday() : 0;

				if (currentDayTime - subtrahend > dateTime) {
					const day = getDate(date);
					const month = getMonth(date);
					const result = `${day}${month}`;
					disabled.push(result);
				}
			}
			setDisabled(disabled);
		}
	}, [availableDays, disabledPrevDays, selectCurrentDay]);

	return (
		<div
			className={cn('common-calendar__days', {
				[className]: Boolean(className),
			})}
		>
			{/* Если месяц начинается в вс - сдвигаем первый день на 6 ячеек */}
			{renderEmptyItems(dayBeginingMonth > 0 ? dayBeginingMonth - 1 : 6, EmptyItem)}
			{days.map(item => (
				<Item
					key={item}
					day={item}
					month={currentMonth}
					year={currentYear}
					isCurrent={
						item === currentDay && month === currentMonth && year === currentYear
					}
					isAvailable={available.includes(`${item}${currentMonth}`)}
					isSelected={selected.includes(`${item}${currentMonth}`)}
					isDisabled={disabled.includes(`${item}${currentMonth}`) || getDisabled(item)}
					disableTo={disableTo}
					disableFrom={disableFrom}
					onDayClick={onDayClickHandler}
					daysId={daysId}
				/>
			))}
		</div>
	);
};

export default Days;

Days.prototype = {
	className: PropTypes.string,

	currentDay: PropTypes.number,
	currentMonth: PropTypes.number,
	currentYear: PropTypes.number,

	daysInMonth: PropTypes.number.isRequired,
	dayBeginingMonth: PropTypes.number.isRequired,

	disabledDays: PropTypes.array,
	availableDays: PropTypes.array,
	selectedDays: PropTypes.array,

	onDayClick: PropTypes.func,

	disabledPrevDays: PropTypes.bool,
	selectCurrentDay: PropTypes.bool,
};
