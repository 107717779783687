import { WELCOME_MESSAGE } from '../constant';

export const showWelcomeMessage = (show) => {
    return {
        type: WELCOME_MESSAGE,
        show: show
    }
}


