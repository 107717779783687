import React from 'react'
import { URL } from 'constant'

import './style.scss'

export const FileItem = ({ file, deleteHandler, index }) => {
	return (
		<div className="article__add-file">
			<a target="_blank" rel="noopener noreferrer" href={URL + file.filePath} className="article__add-file-link">
				<svg role="img">
					<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#file'}></use>
				</svg>

				<div className="file__info">
					<span>{file.originalFileName}</span>
					<span>{`${(file.fileSize / 1024 / 1024).toFixed(2)} МБ`}</span>
				</div>
			</a>

			{
				deleteHandler &&
					<b>
						<svg
							role="img"
							data-id={file.id}
							data-index={index}
							onClick={deleteHandler}
						>
							<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#cross'}></use>
						</svg>
					</b>
			}
		</div>
	)
}