import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormInputBlock, InputsDateTime } from 'core/components';
import { updateSurveyEndDateAction, updateSurveyStartDateAction } from 'action/survey';
import { ISurvey } from '../types';

interface ISelector {
	survey: ISurvey;
}

const millisecondsInDay = 86400000;

export const DateFields: FC = () => {
	const dispatch = useDispatch();
	const { endDate, startDate } = useSelector((state: ISelector) => ({
		endDate: state.survey.endDate,
		startDate: state.survey.startDate,
	}));

	const dateChange = useCallback(
		(time: string, date: number, name: string) => {
			if (time && date) {
				const timeArr = time.split(':');
				const unixDate = Number(date) + (+timeArr[0] * 3600 + +timeArr[1] * 60) * 1000;

				if (name === 'startDate') {
					dispatch(updateSurveyStartDateAction(unixDate));
				} else {
					dispatch(updateSurveyEndDateAction(unixDate));
				}
			}
		},
		[dispatch]
	);

	const onInputHandle = useCallback(
		(name: string) => {
			if (name === 'startDate') {
				dispatch(updateSurveyStartDateAction(undefined));
			} else {
				dispatch(updateSurveyEndDateAction(undefined));
			}
		},
		[dispatch]
	);

	return (
		<FormInputBlock>
			<div className="create-survey-date">
				<div className="create-survey-date__item">
					<InputsDateTime
						error={!startDate}
						title="Дата начала опроса"
						onChange={dateChange}
						onInput={onInputHandle}
						name={'startDate'}
						disabledPrevDays={true}
						defaultDate={startDate}
						disableFrom={endDate}
					/>
				</div>

				<div className="create-survey-date__item">
					<InputsDateTime
						error={!endDate}
						title="Дата окончания опроса"
						onChange={dateChange}
						onInput={onInputHandle}
						name={'endDate'}
						disabledPrevDays={true}
						disableTo={startDate - millisecondsInDay}
						defaultDate={endDate}
					/>
				</div>
			</div>
		</FormInputBlock>
	);
};
