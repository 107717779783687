import React from 'react'

import './style.scss'

const FormInputBlock = ({ children }) => {
    return (
        <div className="input-block">
            {children}
        </div>
    )
}

export default FormInputBlock