import {
	PULSE_SURVEY_UPDATE_START_DATE,
	PULSE_SURVEY_UPDATE_END_DATE,
	PULSE_SURVEY_UPDATE_ANONYMAUS,
	PULSE_SURVEY_UPDATE_USERS,
	PULSE_SURVEY_UPDATE_POSITION,
	PULSE_SURVEY_UPDATE_DEPARTMENTS,
	PULSE_SURVEY_UPDATE_QUESTION,
	PULSE_SURVEY_UPDATE_ANSWER_TYPE,
	CLEAR_PULSE_SURVEY,
	SET_PULSE_SURVEY,
} from 'constant';

import { IPulseSurveyData } from 'entities/survey/pulseSurveyTypes';

const DefaultState: IPulseSurveyData = {
	question: '',
	startDate: undefined,
	endDate: undefined,
	anonymous: false,
	forUsers: [],
	forPositions: [],
	forDepartments: [],
	answerScaleType: 'NUMBER',
};

interface IAction {
	type: string;
	payload: IPulseSurveyData;
}

export default function pulseSurvey(state = DefaultState, action: IAction) {
	switch (action.type) {
		case CLEAR_PULSE_SURVEY:
			return DefaultState;
		case SET_PULSE_SURVEY:
			return {
				...state,
				...action.payload,
			};
		case PULSE_SURVEY_UPDATE_START_DATE:
			return {
				...state,
				startDate: action.payload.startDate,
			};

		case PULSE_SURVEY_UPDATE_END_DATE:
			return {
				...state,
				endDate: action.payload.endDate,
			};

		case PULSE_SURVEY_UPDATE_QUESTION:
			return {
				...state,
				question: action.payload.question,
			};

		case PULSE_SURVEY_UPDATE_ANSWER_TYPE:
			return {
				...state,
				answerScaleType: action.payload.answerScaleType,
			};

		case PULSE_SURVEY_UPDATE_ANONYMAUS:
			return {
				...state,
				anonymous: action.payload.anonymous,
			};

		case PULSE_SURVEY_UPDATE_USERS:
			return {
				...state,
				forUsers: action.payload.forUsers,
			};

		case PULSE_SURVEY_UPDATE_POSITION:
			return {
				...state,
				forPositions: action.payload.forPositions,
			};

		case PULSE_SURVEY_UPDATE_DEPARTMENTS:
			return {
				...state,
				forDepartments: action.payload.forDepartments,
			};

		default:
			return state;
	}
}
