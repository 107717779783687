import {
	GET_POST_PAGE_CONTENT,
	CLEAR_POST_PAGE_CONTENT,
	POST_LIKE,
	POST_VIEW,
	POST_FAVORITE,
	COMMENTS_COUNT_ADD,
	COMMENTS_COUNT_REMOVE,
	ADD_COMMENT,
	DELETE_COMMENT,
	RECOVER_COMMENT,
	UPDATE_COMMENT,
	ADD_OR_REMOVE_COMMENTS_LIKE,
} from 'constant';

const DefaultState = null;

export default function postPage(state = DefaultState, action) {
	switch (action.type) {
		case GET_POST_PAGE_CONTENT:
			return action.post;

		case CLEAR_POST_PAGE_CONTENT:
			return null;

		case POST_FAVORITE:
			let post = state;
			if (post && post.id === +action.id) {
				post.favorite = action.favorite;
			}
			return post;

		case POST_LIKE:
			let postLikes = state;
			if (postLikes && +postLikes.id === +action.id) {
				postLikes.hasLike = action.hasLike;
				if (action.hasLike) {
					postLikes.likeCount++;
				} else {
					postLikes.likeCount--;
				}
			}
			return postLikes;

		case POST_VIEW:
			let postViews = state;
			if (postViews && +postViews.id === +action.id && !postViews.view) {
				postViews.view = true;
				postViews.viewsCount = postViews.viewsCount + 1;
			}
			return postViews;

		case COMMENTS_COUNT_ADD:
			let commentsCountAdd = state;
			if (commentsCountAdd && +commentsCountAdd.id === +action.id) {
				commentsCountAdd.commentCount++;
			}
			return commentsCountAdd;

		case COMMENTS_COUNT_REMOVE:
			let commentsCountRemove = state;
			if (commentsCountRemove && +commentsCountRemove.id === +action.id) {
				commentsCountRemove.commentCount--;
			}
			return commentsCountRemove;

		case ADD_COMMENT:
			let addComment = state;
			if (addComment && +addComment.id === +action.postId) {
				addComment.comments = [...addComment.comments, action.comment];
			}
			return addComment;

		case DELETE_COMMENT:
			let deleteComment = state;
			if (deleteComment && +deleteComment.id === +action.postId) {
				deleteComment.comments = deleteComment.comments.filter(comment => {
					if (+comment.id === +action.commentId) {
						comment.isDelete = true;
						return comment;
					} else {
						return comment;
					}
				});
			}
			return deleteComment;

		case RECOVER_COMMENT:
			let recoverComment = state;
			if (recoverComment && +recoverComment.id === +action.postId) {
				recoverComment.comments = recoverComment.comments.filter(comment => {
					if (+comment.id === +action.commentId) {
						comment.isDelete = false;
						return comment;
					} else {
						return comment;
					}
				});
			}
			return recoverComment;

		case UPDATE_COMMENT:
			let updateComment = state;
			if (updateComment && +updateComment.id === +action.postId) {
				updateComment.comments = updateComment.comments.map(comment => {
					if (+comment.id === +action.id) {
						comment.note = action.comment.note;
						comment.changeDate = action.comment.changeDate;
					}
					return comment;
				});
			}
			return updateComment;

		case ADD_OR_REMOVE_COMMENTS_LIKE:
			let addOrRemoveCommentsLike = state;
			if (addOrRemoveCommentsLike && +addOrRemoveCommentsLike.id === +action.postId) {
				addOrRemoveCommentsLike.comments = addOrRemoveCommentsLike.comments.map(comment => {
					if (+comment.id === +action.id && comment.hasLike !== action.like) {
						comment.hasLike = action.like;
						comment.likeCount = comment.hasLike
							? (comment.likeCount += 1)
							: (comment.likeCount -= 1);
					}
					return comment;
				});
			}
			return addOrRemoveCommentsLike;

		default:
			return state;
	}
}
