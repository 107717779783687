import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { Editor } from 'components/editorjsText';

import { Button } from 'core/components';

import Notification from 'modules/notification';
import { NotificationHelper } from 'action/notificationHelper';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { Text, HeaderWrapp } from '../../atoms/header';

import { articleCreate } from 'rest/article';

import { CoverUploader } from '../coverUploader';

import './style.scss';

export const ArticleGroupCreate = props => {
	const [newContent, setNewContent] = useState(null);
	const [name, setName] = useState('');
	const [coverId, setCoverId] = useState(null);

	const dispatch = useDispatch();

	const { id } = props.match.params;

	const toogleErrorClass = status => {
		const node = document.querySelector('.article-name');
		if (status) {
			node.classList.add('validation-error');
		} else {
			node.classList.remove('validation-error');
		}
	};

	const isValid = () => {
		const result = name.replace(/\s/g, '').length !== 0 && name.length <= 255;
		toogleErrorClass(!result);
		return result;
	};

	const createArticleHandler = async status => {
		if (isValid()) {
			if (newContent) {
				const res = await articleCreate({
					name,
					groupId: id,
					previewPicture: coverId,
					content: newContent,
					status,
				});
				if (res.data.responseCode === 0) {
					props.history.push(`/group/${id}/article/${res.data.response.id}`);
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			} else {
				dispatch(NotificationHelper('Необходимо указать содержание статьи', 1));
			}
		}
	};

	const publishArticleHandler = e => {
		e.preventDefault();
		createArticleHandler('PUBLISH');
	};

	const draftArticleHandler = e => {
		e.preventDefault();
		createArticleHandler('DRAFT');
	};

	const nameHandler = e => {
		e.currentTarget.classList.remove('validation-error');
		setName(e.currentTarget.value);
	};

	const setCoverIdHandler = coverId => {
		setCoverId(coverId);
	};

	return (
		<div className="Article-create">
			<Notification />

			<Header>
				<HeaderWrapp>
					<Helmet title="Создание статьи" />
					<Text type="bold">Добавление статьи в группу</Text>
					<div className="Aheader-block">
						<Button
							type="primary"
							typeButton="button"
							text="Опубликовать"
							handler={publishArticleHandler}
							image={null}
						/>
						<Button
							type="secondary"
							typeButton="button"
							text="В черновик"
							handler={draftArticleHandler}
							image={null}
						/>
						<Link
							to={`/group/${id}/article`}
							className="Aheader-btn Aheader-btn_special"
						>
							Отменить
						</Link>
					</div>
				</HeaderWrapp>
			</Header>
			<Wrapp>
				<div className="article-form article-form_create">
					<CoverUploader setCoverId={setCoverIdHandler} />
					<input
						type="text"
						className="article-name"
						onChange={nameHandler}
						placeholder="Заголовок статьи"
						value={name}
					/>
				</div>
				<Editor getContent={setNewContent} />
			</Wrapp>
		</div>
	);
};
