import React, { forwardRef } from 'react'
import classNames from 'classnames'
import './style.scss'

const FormErrorMessage = forwardRef(function FormErrorMessage(props, ref) {
    const {
        children,
        className,
        component: Component = 'p',
        ...other
    } = props
    return (
        <Component
            className={classNames('form-error-message', className)}
            ref={ref}
            {...other}
        >
            {children === ' ' ? (
                <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
            ) : (
                children
            )}
        </Component>
    )
})

export default FormErrorMessage
