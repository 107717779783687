import Header from '@editorjs/header';
import Table from '@editorjs/table';
import Embed from '@editorjs/embed';
import ImageTool from '@editorjs/image';
import Raw from '@editorjs/raw';
import Quote from '@editorjs/quote';
import Delimiter from '@editorjs/delimiter';
import Paragraph from '@editorjs/paragraph';
import SimpleImage from '@editorjs/simple-image';
import FontSize from 'editorjs-inline-font-size-tool';
import AttachesTool from '@editorjs/attaches';
import Warning from '@editorjs/warning';
import Underline from '@editorjs/underline';
import {
	YoutubePlugins,
	NestedList,
	Color,
	SubScriptsPlugin,
	SupScriptsPlugin,
	TextAlign,
} from './customPlugins';

import { api } from 'rest/api';
import { getAccessToken } from 'plugins/token';

const onFileChange = async (file: File) => {
	const data = new FormData();
	if (file) {
		data.append('image', file);

		const response = await api.post('/froala/upload', data, {
			headers: {
				Authorization: `Bearer ${getAccessToken()}`,
			},
		});

		if (response.status === 200) {
			return response;
		}
		return null;
	}
};

export const TOOLS_EDITOR = {
	embed: Embed,
	table: { class: Table, inlineToolbar: true, tunes: ['anyTuneName'] },
	underline: Underline,
	list: { class: NestedList as any, inlineToolbar: true },
	sup: SupScriptsPlugin as any,
	sub: SubScriptsPlugin as any,
	warning: {
		class: Warning,
		inlineToolbar: true,
		config: {
			titlePlaceholder: 'Заголовог',
			messagePlaceholder: 'Сообщение',
		},
	},
	anyTuneName: {
		class: TextAlign as any,
		config: {
			default: 'left',
		},
	},
	color: {
		class: Color as any,
		config: {
			colorCollections: [
				'#9C27B0',
				'#673AB7',
				'#3F51B5',
				'#0070FF',
				'#03A9F4',
				'#00BCD4',
				'#4CAF50',
				'#8BC34A',
				'#CDDC39',
				'#FFFFFF',
				'#000000',
				'#505050',
				'#BBBBBB',
				'#F77F75',
				'#ED760E',
				'#FFBF00',
				'#E32636',
				'#65000B',
			],
			defaultColor: '#FF1300',
			type: 'text',
		},
	},
	marker: {
		class: Color as any,
		config: {
			colorCollections: [
				'#9C27B0',
				'#673AB7',
				'#3F51B5',
				'#0070FF',
				'#03A9F4',
				'#00BCD4',
				'#4CAF50',
				'#8BC34A',
				'#CDDC39',
				'#FFFFFF',
				'#000000',
				'#505050',
				'#BBBBBB',
				'#F77F75',
				'#ED760E',
				'#FFBF00',
				'#E32636',
				'#65000B',
			],
			defaultColor: '#FF1300',
			type: 'marker',
		},
	},
	youtube: YoutubePlugins,
	image: {
		class: ImageTool,
		inlineToolbar: true,
		config: {
			uploader: {
				async uploadByFile(file: File) {
					const data = await onFileChange(file);
					if (data) {
						return {
							success: 1,
							file: {
								url: data.data.link,
							},
						};
					}
				},
			},
		},
	},
	raw: {
		class: Raw,
		config: { placeholder: 'Введите HTML код' },
	},
	header: { class: Header, inlineToolbar: true, tunes: ['anyTuneName'] },
	quote: {
		class: Quote,
		inlineToolbar: true,
		config: {
			quotePlaceholder: 'Введите цитату',
			captionPlaceholder: 'Введите название',
		},
	},
	delimiter: Delimiter,
	simpleImage: SimpleImage,
	paragraph: {
		class: Paragraph,
		inlineToolbar: true,
		tunes: ['anyTuneName'],
	},
	fontSize: {
		class: FontSize,
	},
	attaches: {
		class: AttachesTool,
		config: {
			buttonText: 'Выберите файл',
			uploader: {
				async uploadByFile(file: File) {
					const data = await onFileChange(file);
					if (data) {
						return {
							success: 1,
							file: {
								url: data.data.link,
							},
						};
					}
				},
			},
		},
	},
};

export const SETTING_NAME_TOOLS = {
	messages: {
		ui: {
			blockTunes: {
				toggler: {
					'Click to tune': 'Нажмите, чтобы настроить',
					'or drag to move': 'или перетащите',
				},
			},
			inlineToolbar: {
				converter: {
					'Convert to': 'Конвертировать в',
				},
			},
			toolbar: {
				toolbox: {
					Add: 'Добавить',
				},
			},
			popover: {
				Filter: 'Поиск',
				'Nothing found': 'Ничего не найдено',
			},
		},
		toolNames: {
			Text: 'Параграф',
			Heading: 'Заголовок',
			List: 'Список',
			Checklist: 'Чеклист',
			Quote: 'Цитата',
			Image: 'Вставить изображение',
			Code: 'Код',
			Warning: 'Предупреждение',
			Delimiter: 'Разделитель',
			'Raw HTML': 'HTML-фрагмент',
			Table: 'Таблица',
			Link: 'Ссылка',
			Marker: 'Маркер',
			Bold: 'Полужирный',
			Italic: 'Курсив',
			Sup: 'Верхний индекс',
			Sub: 'Нижний индекс',
			Underline: 'Подчеркнутый',
			InlineCode: 'Моноширинный',
			Color: 'Цвет шрифта',
			'Font Size': 'Размер шрифта',
			Attachment: 'Вставить файл',
		},
		tools: {
			stub: {
				'The block can not be displayed correctly.': 'Блок не может быть отображен',
			},
			image: {
				'Select an Image': 'Выберите изображение',
				Caption: 'Название',
				'With border': 'С рамкой',
				'Stretch image': 'Увеличить изображение',
				'With background': 'С фоном',
			},
			attaches: {
				'File title': 'Название файла',
			},
			table: {
				'Delete column': 'Удалить колонку',
				'Delete row': 'Удалить строку',
				'Add row below': 'Добавить строку снизу',
				'Add row above': 'Добавить строку сверху',
				'Add column to left': 'Добавить колонку слева',
				'Add column to right': 'Добавить колонку справа',
				'With headings': 'С заголовками',
				'Without headings': 'Без заголовков',
			},
			quote: {
				'Align Left': 'Выровнять по левому краю',
				'Align Center': 'Выровнять по центру',
			},
			header: {
				'Heading 1': 'Заголовок 1',
				'Heading 2': 'Заголовок 2',
				'Heading 3': 'Заголовок 3',
				'Heading 4': 'Заголовок 4',
				'Heading 5': 'Заголовок 5',
				'Heading 6': 'Заголовок 6',
			},
		},
		blockTunes: {
			delete: {
				Delete: 'Удалить',
				'Click to delete': 'Кликни для удаления',
			},
			moveUp: {
				'Move up': 'Переместить вверх',
			},
			moveDown: {
				'Move down': 'Переместить вниз',
			},
		},
	},
};
