import React, { useRef, FC, useCallback, useMemo } from 'react';
import { TOOLS_EDITOR, SETTING_NAME_TOOLS } from './setting-editor';
import './style.scss';
import { IsJsonString } from 'plugins/checkJSONFormat';
import { createReactEditorJS } from 'react-editor-js';

interface IEditor {
	getContent?: any;
	content?: any;
	holder?: number;
	autofocus?: boolean;
}

export const Editor: FC<IEditor> = ({ getContent, content = '', holder = 0, autofocus = true }) => {
	const ReactEditorJS = createReactEditorJS();
	const isInstance = useRef<any | null>(null);

	const handleInitialize = useCallback(
		async instance => {
			isInstance.current = instance;

			if (!!content) {
				if (isInstance.current) {
					const editorJS = await isInstance.current._editorJS;
					await editorJS.isReady;
					const savedData = await editorJS.save();

					getContent(JSON.stringify(savedData));
				}
			}
		},
		[content, getContent]
	);

	const handleSave = useCallback(async () => {
		if (isInstance.current) {
			const savedData = await isInstance.current.save();
			getContent(JSON.stringify(savedData));
		}
	}, [getContent]);

	const defaulData = useMemo(() => {
		return content !== '' && IsJsonString(content);
	}, [content]);

	return (
		<ReactEditorJS
			onInitialize={handleInitialize}
			tools={TOOLS_EDITOR}
			i18n={SETTING_NAME_TOOLS}
			defaultValue={defaulData}
			onChange={handleSave}
			autofocus={autofocus}
			holder={`holder${holder}`}
		/>
	);
};
