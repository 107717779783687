import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export const PlusIconButton = props => {
	return (
		<span id={props.id} onClick={props.onClick} className="add-item-btn">
			<svg role="img" className="add-item-btn__svg">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#plus'} />
			</svg>

			<span className="add-item-btn__span">{props.children}</span>
		</span>
	);
};

PlusIconButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	id: PropTypes.string,
};

PlusIconButton.defaultProps = {
	onClick: e => {
		e.preventDefault();
	},
};
