import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TypeButton, Icon } from '@maxi-innovation/ui-kit-frontend';

import { SurveyTextField } from '../fields';
import { IQuestionComponentProps } from '../types';
import {
	updateQuestionTitleAction,
	addQuestionAnswerAction,
	deleteQuestionAnswerAction,
	updateQuestionAnswerAction,
	changeAnswerOtherAction,
} from 'action/survey';
import { CheckBox } from 'core/components';

export const SelectQuestion: FC<IQuestionComponentProps> = ({ questionSchema, questionIndex }) => {
	const dispatch = useDispatch();

	const addVariant = useCallback(() => {
		const newId =
			Math.max(...questionSchema.possibleAnswers.map((item: { id: number }) => item.id)) + 1;
		dispatch(
			addQuestionAnswerAction(questionSchema.questionNumber, {
				id: newId,
				text: '',
			})
		);
	}, [dispatch, questionSchema]);

	const removeVariant = useCallback(
		(removeId: number) => {
			dispatch(deleteQuestionAnswerAction(questionSchema.questionNumber, removeId));
		},
		[dispatch, questionSchema.questionNumber]
	);

	const onChangeAnswerHandler = useCallback(
		(value: string, answerId: number) => {
			dispatch(updateQuestionAnswerAction(questionSchema.questionNumber, answerId, value));
		},
		[dispatch, questionSchema.questionNumber]
	);

	const onChangeTitleHandler = useCallback(
		(value: string) => {
			questionSchema.title = value;
			dispatch(updateQuestionTitleAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	const otherAnswerToggle = useCallback(
		(state: boolean) => {
			dispatch(changeAnswerOtherAction(questionSchema.questionNumber, state));
		},
		[dispatch, questionSchema]
	);

	return (
		<div className="survey-question-body">
			<div className="survey-question-body__wrap">
				<SurveyTextField
					error={!questionSchema.title}
					name={`questions[${questionIndex}].text`}
					label="Вопрос"
					placeholder="Введите текст вопроса"
					onChange={onChangeTitleHandler}
					maxLength={1000}
					defaultValue={questionSchema.title}
				/>
			</div>

			<div className="survey-question-body__wrap">
				{questionSchema.possibleAnswers.map(
					(item: { text: string; id: number; type: string }) => (
						<div className="survey-question-variant" key={item.id}>
							<SurveyTextField
								error={!item.text}
								label="Вариант ответа"
								placeholder="Введите текст ответа"
								onChange={(value: string) => onChangeAnswerHandler(value, item.id)}
								defaultValue={item.text}
							/>

							{questionSchema.possibleAnswers.length > 1 && (
								<Icon
									iconName="X"
									w={15}
									h={15}
									color="mc74"
									className="survey-question-variant__delete"
									onClick={() => removeVariant(item.id)}
								/>
							)}
						</div>
					)
				)}

				{/* @ts-ignore */}
				<CheckBox
					checked={questionSchema.withOwnAnswer}
					disabled={false}
					className="survey-checkbox"
					onChange={() => otherAnswerToggle(!questionSchema.withOwnAnswer)}
					name="survey-checkbox"
					renderLabel={
						<span className="survey-checkbox__label">Добавить вариант "Другое"</span>
					}
				/>

				{questionSchema.possibleAnswers.length < 14 && (
					<Button
						variant={TypeButton.LINKCAPS}
						className="survey-add-variant"
						onClick={addVariant}
					>
						<Icon iconName="Plus" />
						Добавить вариант
					</Button>
				)}
			</div>
		</div>
	);
};
