import {
	RECEIVE_GROUPS_POSTS,
	REQUEST_GROUPS_POSTS,
	CURRENT_PAGE_GROUPS,
	LAST_PAGE_GROUPS,
	CREATE_GROUPS_POST,
	DELETE_GROUPS_POST,
	RECEIVE_GROUPS_SUGGESTED_POSTS,
	REQUEST_GROUPS_SUGGESTED_POSTS,
	CURRENT_PAGE_SUGGESTED_GROUPS,
	LAST_PAGE_SUGGESTED_GROUPS,
	CREATE_GROUPS_SUGGESTED_POST,
	DELETE_GROUPS_SUGGESTED_POST,
	CLEAR_GROUPS_POSTS,
	CLEAR_GROUPS_SUGGESTED_POSTS,
	RECEIVE_GROUPS_DEFERRED_POSTS,
	REQUEST_GROUPS_DEFERRED_POSTS,
	CURRENT_PAGE_DEFERRED_GROUPS,
	LAST_PAGE_DEFERRED_GROUPS,
	CLEAR_GROUPS_DEFERRED_POSTS,
	CREATE_GROUPS_DEFERRED_POST,
	UPDATE_GROUPS_DEFERRED_POST,
	DELETE_GROUPS_DEFERRED_POST,
} from 'constant';

const initialState = {
	common: {},
	suggested: {},
	deferred: {},
	isLoadingPosts: false,
};

const initialGroup = {
	currentPage: 0,
	lastPage: false,
	posts: [],
};

export default function groups(state = initialState, action) {
	let group;
	switch (action.type) {
		case REQUEST_GROUPS_POSTS:
			return {
				...state,
				isLoadingPosts: true,
			};

		case RECEIVE_GROUPS_POSTS:
			group = state.common[action.groupId] || initialGroup;
			return {
				...state,
				common: {
					...state.common,
					[action.groupId]: {
						...group,
						posts: [...group.posts, ...action.posts],
					},
				},
				isLoadingPosts: false,
			};

		case CURRENT_PAGE_GROUPS:
			group = state.common[action.groupId] || initialGroup;
			return {
				...state,
				common: {
					...state.common,
					[action.groupId]: {
						...group,
						currentPage: action.currentPage,
					},
				},
			};

		case LAST_PAGE_GROUPS:
			group = state.common[action.groupId] || initialGroup;
			return {
				...state,
				common: {
					...state.common,
					[action.groupId]: {
						...group,
						lastPage: action.lastPage,
					},
				},
			};

		case CLEAR_GROUPS_POSTS:
			return {
				...state,
				common: {
					...state.common,
					[action.groupId]: initialGroup,
				},
			};

		case CREATE_GROUPS_POST:
			group = state.common[action.groupId] || initialGroup;
			return {
				...state,
				common: {
					...state.common,
					[action.groupId]: {
						...group,
						posts: [action.post, ...group.posts],
					},
				},
			};

		case DELETE_GROUPS_POST:
			group = state.common[action.groupId] || initialGroup;
			return {
				...state,
				common: {
					...state.common,
					[action.groupId]: {
						...group,
						posts: group.posts.filter(post => post.id !== action.id),
					},
				},
			};

		case REQUEST_GROUPS_SUGGESTED_POSTS:
			return {
				...state,
				isLoadingPosts: true,
			};

		case RECEIVE_GROUPS_SUGGESTED_POSTS:
			group = state.suggested[action.groupId] || initialGroup;
			return {
				...state,
				suggested: {
					...state.suggested,
					[action.groupId]: {
						...group,
						posts: [...group.posts, ...action.posts],
					},
				},
				isLoadingPosts: false,
			};

		case CURRENT_PAGE_SUGGESTED_GROUPS:
			group = state.suggested[action.groupId] || initialGroup;
			return {
				...state,
				suggested: {
					...state.suggested,
					[action.groupId]: {
						...group,
						currentPage: action.currentPage,
					},
				},
			};

		case LAST_PAGE_SUGGESTED_GROUPS:
			group = state.suggested[action.groupId] || initialGroup;
			return {
				...state,
				suggested: {
					...state.suggested,
					[action.groupId]: {
						...group,
						lastPage: action.lastPage,
					},
				},
			};

		case CLEAR_GROUPS_SUGGESTED_POSTS:
			return {
				...state,
				suggested: {
					...state.suggested,
					[action.groupId]: initialGroup,
				},
			};

		case CREATE_GROUPS_SUGGESTED_POST:
			group = state.suggested[action.groupId] || initialGroup;
			return {
				...state,
				suggested: {
					...state.suggested,
					[action.groupId]: {
						...group,
						posts: [action.post, ...group.posts],
					},
				},
			};

		case DELETE_GROUPS_SUGGESTED_POST:
			group = state.suggested[action.groupId] || initialGroup;
			return {
				...state,
				suggested: {
					...state.suggested,
					[action.groupId]: {
						...group,
						posts: group.posts.filter(post => post.id !== action.id),
					},
				},
			};

		case REQUEST_GROUPS_DEFERRED_POSTS:
			return {
				...state,
				isLoadingPosts: true,
			};

		case RECEIVE_GROUPS_DEFERRED_POSTS:
			group = state.deferred[action.groupId] || initialGroup;
			return {
				...state,
				deferred: {
					...state.deferred,
					[action.groupId]: {
						...group,
						posts: [...group.posts, ...action.posts],
					},
				},
				isLoadingPosts: false,
			};

		case CURRENT_PAGE_DEFERRED_GROUPS:
			group = state.deferred[action.groupId] || initialGroup;
			return {
				...state,
				deferred: {
					...state.deferred,
					[action.groupId]: {
						...group,
						currentPage: action.currentPage,
					},
				},
			};

		case LAST_PAGE_DEFERRED_GROUPS:
			group = state.deferred[action.groupId] || initialGroup;
			return {
				...state,
				deferred: {
					...state.deferred,
					[action.groupId]: {
						...group,
						lastPage: action.lastPage,
					},
				},
			};

		case CLEAR_GROUPS_DEFERRED_POSTS:
			return {
				...state,
				deferred: {
					...state.deferred,
					[action.groupId]: initialGroup,
				},
			};

		case CREATE_GROUPS_DEFERRED_POST:
			group = state.deferred[action.groupId] || initialGroup;
			return {
				...state,
				deferred: {
					...state.deferred,
					[action.groupId]: {
						...group,
						posts: [...group.posts, action.post],
					},
				},
			};

		case DELETE_GROUPS_DEFERRED_POST:
			group = state.deferred[action.groupId] || initialGroup;
			return {
				...state,
				deferred: {
					...state.deferred,
					[action.groupId]: {
						...group,
						posts: group.posts.filter(post => post.id !== action.id),
					},
				},
			};

		case UPDATE_GROUPS_DEFERRED_POST:
			group = state.deferred[action.groupId] || initialGroup;
			return {
				...state,
				deferred: {
					...state.deferred,
					[action.groupId]: {
						...group,
						posts: group.posts.map(item => {
							if (item.id === action.id) {
								item.text = action.text;
								item.imageList = action.images;
								item.videoList = action.video;
								item.documentList = action.files;
								item.article = action.article;
								item.pollId = action.pollId;
								item.title = action.title;
								item.plannedPublishDate = action.time;
							}
							return item;
						}),
					},
				},
			};

		default:
			return state;
	}
}
