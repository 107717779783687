import { api } from './api';

export const confirmedWelcome = async () => {
	try {
		const response = await api.post('/user/update-need-show-welcome');
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export default confirmedWelcome;
