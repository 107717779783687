import React, { FC } from 'react';

export const ModalContent: FC = () => {
	return (
		<div>
			<h4>Согласие на обработку персональных данных</h4>
			<p>
				Нажимая кнопку «Зарегистрироваться» я даю Роздухову Максиму Евгеньевичу (160009, г.
				Вологда, ул. Зосимовская д.91, кв.72) согласие на обработку (сбор, запись,
				систематизацию, накопление, хранение, уточнение, извлечение, использование,
				уничтожение), а также на включение моих персональных данных (фамилия, имя, отчество,
				дата рождения, личный номер мобильного телефона, рабочий номер телефона (мобильный,
				стационарный), адрес рабочей электронной почты, адрес места работы, должность (в том
				числе дата назначения на должность) и подразделение, положение в
				организационно-управленческой структуре, сведения о кадровых перемещениях, а также
				иных персональных данных, предоставляемых мной лично в процессе редактирования моего
				личного профиля на сайте), иной информации предоставляемой/создаваемой мной на сайте
				вне раздела редактирования профиля (в том числе статусы, записи в микроблоге,
				фотографии, аудиозаписи, видеозаписи, комментарии, записи в обсуждениях групп и
				т.п.) в общедоступные источники (сайт в сети Интернет по адресу{' '}
				<a href="https://maxiportal.ru/" target="_blank" rel="noopener noreferrer">
					https://maxiportal.ru/
				</a>{' '}
				), а также на передачу (поручение обработки (хранение) указанных персональных данных
				и информации для целей хранения третьим лицам, в том числе Обществу с ограниченной
				ответственностью «Регистратор доменных имён РЕГ.РУ» (125252, город Москва, проезд
				Берёзовой рощи, дом 12, этаж 2, комната 4). Настоящее согласие действует до момента
				его письменного отзыва.
			</p>
		</div>
	);
};
