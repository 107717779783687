import {
	SET_INVENTORY_LIST,
	UPDATE_INVENTORY_LIST,
	STEP_INVENTORY_FORM,
	ID_INVENTORY_LIST,
	DOCUMENTS_LIST_INVENTORY,
	ADD_DOCUMENTS_INVENTORY,
	CLEAR_DOCUMENTS_LIST_INVENTORY,
	SET_INVENTORY_CLAIM_LIST,
	UPDATE_INVENTORY_CLAIM_LIST,
	SET_ALL_INVENTORY_LIST,
} from 'constant';

const DefaultState = {
	list: null,
	allList: null,
	step: 'default',
	idsInventory: [],
	items: [],
	claimListInventory: null,
};

export default function inventory(state = DefaultState, action) {
	switch (action.type) {
		case SET_INVENTORY_LIST:
			return { ...state, list: action.data };
		case SET_ALL_INVENTORY_LIST:
			return { ...state, allList: action.data };
		case UPDATE_INVENTORY_LIST:
			return {
				...state,
				list: state.list.map(item =>
					item.id === action.data.id
						? { ...item, location: action.data.location, comment: action.data.comment }
						: item
				),
			};
		case STEP_INVENTORY_FORM:
			return { ...state, step: action.data };
		case ID_INVENTORY_LIST:
			return { ...state, idsInventory: action.data };
		case ADD_DOCUMENTS_INVENTORY:
			return {
				...state,
				items: [...state.items, action.item],
			};
		case CLEAR_DOCUMENTS_LIST_INVENTORY:
			return {
				...state,
				items: [],
			};
		case DOCUMENTS_LIST_INVENTORY:
			return {
				...state,
				items: [
					...state.items.map(item => {
						if (item.inventoryCardId === action.inventoryCardId) {
							item.fileId = action.fileId;
						}
						return item;
					}),
				],
			};
		case SET_INVENTORY_CLAIM_LIST:
			return { ...state, claimListInventory: action.data };
		case UPDATE_INVENTORY_CLAIM_LIST:
			return {
				...state,
				claimListInventory: {
					...state.claimListInventory,
					items: state.claimListInventory.items.map(
						x => action.data.find(({ id }) => id === x.id) || x
					),
				},
			};
		default:
			return state;
	}
}
