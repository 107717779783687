import React, { useState, useLayoutEffect } from 'react';

import { getStatusesList } from 'rest/user';
import { Block } from '../../molecules';

import './style.scss';

const SearchView = ({ result, viewRef }) => {
	const [statuses, setStatuses] = useState(null);

	useLayoutEffect(() => {
		(async () => {
			const { response, responseCode } = await getStatusesList();
			if (!responseCode) setStatuses(response);
		})();
	}, []);

	const filteredResult = result.filter(item => item.total !== 0);

	return filteredResult.length > 0 ? (
		<div className="search-view" ref={viewRef}>
			{filteredResult.map(item => (
				<Block key={item.indexName} item={item} userStatuses={statuses} />
			))}
		</div>
	) : (
		<div className="search-view search-view_noResult">По вашему запросу ничего не найдено</div>
	);
};

export default SearchView;
