import React from 'react';
import { withRouter } from 'react-router-dom';

import { ArticlesItem } from '../../molecules';

import './style.scss';

export const ArticlesList = withRouter(
	({ articlesList, parentId, location, favoritesIds, changeFavoritesIdsList, canEdit }) => {
		return (
			<div className="Guide--view__articles-list">
				{articlesList.length > 0 ? (
					<div className="articles-list__items">
						{articlesList.map((item, index) => {
							return (
								<ArticlesItem
									key={item.id}
									parentId={parentId}
									location={location}
									canEdit={canEdit}
									index={index}
									{...item}
									favorite={favoritesIds && favoritesIds.includes(+item.id)}
									changeFavoritesIdsList={changeFavoritesIdsList}
								/>
							);
						})}
					</div>
				) : (
					<div className="articles-list__no-items">
						<svg role="img">
							<use
								xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#no-items'}
							></use>
						</svg>

						<p>Скоро здесь появятся статьи</p>
					</div>
				)}
			</div>
		);
	}
);
