import React, { FC, useCallback, useState } from 'react';
import { FormStateContext } from '../../model';

import { PhoneForm } from '../PhoneForm';
import { CodeForm } from '../CodeForm';
import { NewPasswordForm } from '../NewPasswordForm';

enum FormState {
	PHONE = 'PHONE',
	CODE = 'CODE',
	NEW_PASS = 'NEW_PASS',
}

const FormStateViewMap = {
	[FormState.PHONE]: <PhoneForm />,
	[FormState.CODE]: <CodeForm />,
	[FormState.NEW_PASS]: <NewPasswordForm />,
};

export const Form: FC = () => {
	const [viewCode, setViewCode] = useState<FormState>(FormState.PHONE);

	const [phone, setPhone] = useState('');
	const [uuid, setUuid] = useState<string | null>(null);
	const [userName, setUserName] = useState<string | null>(null);
	const [deadline, setDeadline] = useState<number>();

	const setPhoneView = useCallback(() => {
		setViewCode(FormState.PHONE);
	}, []);

	const setCodeView = useCallback(() => {
		setViewCode(FormState.CODE);
	}, []);

	const setNewPassView = useCallback(() => {
		setViewCode(FormState.NEW_PASS);
	}, []);

	return (
		<FormStateContext.Provider
			value={{
				phone,
				uuid,
				userName,
				setUuid,
				setPhone,
				setUserName,
				setPhoneView,
				setCodeView,
				setNewPassView,
				deadline,
				setDeadline,
			}}
		>
			{FormStateViewMap[viewCode]}
		</FormStateContext.Provider>
	);
};
