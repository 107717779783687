import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export const Grid = props => {
	return <div className="grid">{props.children}</div>;
};

export const Col = ({ children, sm, md, lg, xs, smorder, mdorder, lgorder, xsorder }) => {
	return (
		<div
			className={`col sm-${sm} md-${md} lg-${lg} ${
				!!xs && `xs-${xs}`
			} sm-order-${smorder} md-order-${mdorder} lg-order-${lgorder} lg-order-${xsorder}`}
		>
			{children}
		</div>
	);
};

Col.propTypes = {
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xs: PropTypes.number,
	smorder: PropTypes.number,
	mdorder: PropTypes.number,
	lgorder: PropTypes.number,
	xsorder: PropTypes.number,
};

Col.defaultProps = {
	sm: 12,
	md: 12,
	lg: 12,
	xs: 0,
	smorder: 0,
	mdorder: 0,
	lgorder: 0,
	xsorder: 0,
};
