import React, { FC, SyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';

import { useFormState, useRecoverNewPassword } from 'feature/auth-recover/model';
import { PasswordTextField } from 'shared/ui/PasswordTextField';

const validationSchema = yup.object().shape({
	password: yup
		.string()
		.min(8, 'Пароль не соответствует требованиям')
		.max(32, 'Пароль не соответствует требованиям')
		.required('Введите пароль'),
	passwordCopy: yup
		.string()
		.required('Введите пароль')
		.oneOf([yup.ref('password')], 'Пароли не совпадают'),
});

export const NewPasswordForm: FC = () => {
	const { register, errors, handleSubmit } = useForm({
		validationSchema,
		reValidateMode: 'onSubmit',
	});

	const { setPhoneView } = useFormState();

	const { isLoading, errorMessage, setNewPassword } = useRecoverNewPassword();

	const onLinkClick = (e: SyntheticEvent) => {
		e.preventDefault();
		setPhoneView();
	};

	const onSubmit = handleSubmit(data => {
		const { password } = data;
		setNewPassword(password);
	});

	return (
		<form className="auth-form" onSubmit={onSubmit}>
			<svg role="img" className="auth-logo">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#entry-red-logo'} />
			</svg>
			{!!errorMessage && (
				<span className="auth-form-error-message" id="auth-recover-new-code-error-message">
					{errorMessage}
				</span>
			)}
			<div className="auth-new-pass-title">
				<span>Код подтверждения принят</span>
			</div>
			<div className="auth-control-block">
				<PasswordTextField
					ref={register}
					name="password"
					id="auth-recover-new-code-password"
					className="auth-control_password"
					label="Придумайте надежный пароль"
					description="Пароль должен содержать от 8 до 32 символов"
					isError={!!errors?.password}
					//@ts-ignore
					errorMessage={errors?.password?.message}
				/>
			</div>
			<div className="auth-control-block auth-new-pass-control-block">
				<PasswordTextField
					ref={register}
					name="passwordCopy"
					id="auth-recover-new-code-password-copy"
					className="auth-control_password"
					label="Подтвердите пароль"
					isError={!!errors?.passwordCopy}
					//@ts-ignore
					errorMessage={errors?.passwordCopy?.message}
				/>
			</div>
			<Button
				variant={TypeButton.PRIMARY}
				type="submit"
				className="auth-action-btn"
				id="auth-recover-new-code-btn"
				disabled={isLoading}
				loading={isLoading}
			>
				Войти
			</Button>
			<div className="auth-form-footer">
				<a
					href="/"
					onClick={onLinkClick}
					className="entryPage__link red-text"
					id="auth-recover-new-code-back-link"
				>
					Вернуться
				</a>
			</div>
		</form>
	);
};
