//Добавить блок
export const MIFORM_ADD = "MIFORM_ADD"

//Удалить блок
export const MIFORM_DELETE = "MIFORM_DELETE"

//Обновить информацию в блоке 
export const MIFORM_UPDATE = "MIFORM_UPDATE"

//DND блоков
export const MIFORM_DND = "MIFORM_DND"

//Очистить
export const MIFORM_CLEAR = "MIFORM_CLEAR"

//Установить
export const MIFORM_SET = "MIFORM_SET"