import { SET_WORKPLACE_CART_COUNT } from 'constant';

const DefaultState = {
	count: 0,
};

export default function workplace(state = DefaultState, action) {
	switch (action.type) {
		case SET_WORKPLACE_CART_COUNT:
			return {
				count: action.count,
			};

		default:
			return state;
	}
}
