import { useEffect, useState } from 'react';

import getYear from 'date-fns/getYear';

export const useCreateListYears = selectedYear => {
	const [list, setList] = useState([]);
	const [startYear, setStartYear] = useState(selectedYear || getYear(new Date()));

	useEffect(() => {
		const listYears = [startYear - 1, startYear];

		let i = 0;
		while (i < 10) {
			i++;
			listYears.push(startYear + i);
		}

		setList(listYears);
	}, [startYear]);

	const nextList = () => {
		setStartYear(prev => prev + 9);
	};

	const prevList = () => {
		setStartYear(prev => prev - 9);
	};

	return { list, nextList, prevList };
};
