import {
    REQUEST_FEED_POSTS,
	RECEIVE_FEED_POSTS,
	RECEIVE_NEXT_FEED_POSTS,
	RECEIVE_PREV_FEED_POSTS,
    CLEAR_FEED_POSTS,
    REQUEST_FEED_POSTS_FAILD,
    SET_FEED_CURRENT_PAGE,
    SET_FEED_LAST_PAGE,
    SET_FEED_IS_LOADING_POSTS,
	SET_FEED_LAST_POST_ID
} from 'constant';

export const requestPosts = () => ({
	type: REQUEST_FEED_POSTS
});

export const receivePosts = payload => ({
	type: RECEIVE_FEED_POSTS,
	payload
});

export const receiveNextPosts = payload => ({
	type: RECEIVE_NEXT_FEED_POSTS,
	payload
});

export const receivePrevPosts = payload => ({
	type: RECEIVE_PREV_FEED_POSTS,
	payload
});

export const clearPosts = () => ({
	type: CLEAR_FEED_POSTS
});

export const requestPostsFaild = () => ({
	type: REQUEST_FEED_POSTS_FAILD
});

export const setCurrentPage = page => ({
	type: SET_FEED_CURRENT_PAGE,
	page
});

export const setLastPage = lastPage => ({
	type: SET_FEED_LAST_PAGE,
	lastPage
});

export const setIsLoadingPosts = isLoadingPosts => ({
	type: SET_FEED_IS_LOADING_POSTS,
	isLoadingPosts
});

export const setLastPostId = lastPostId => ({
	type: SET_FEED_LAST_POST_ID,
	lastPostId
})