const key = 'emilia-banner';

export function getBannerDisplayFlag() {
	const flag = sessionStorage.getItem(key);
	if (flag) {
		return JSON.parse(flag);
	}
	return false;
}

export function setBannerDisplayFlag(isShow: boolean) {
	sessionStorage.setItem(key, JSON.stringify(isShow));
}
