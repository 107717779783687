import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { Button } from 'core/components'
import { bodyFixPosition, bodyUnfixPosition } from 'core/utils'

import {
	suggestContactsCorrectionAction,
	suggestGuideCorrectionAction
} from 'action/suggestCorrection'

import './style.scss'

export class SuggestCorrectionModal extends Component {
	render() {
		return (
			<div className="suggest-correction__modal">
				<div className="suggest-correction__modal-view" ref={(el) => { this.item = el }}>
					<div className="suggest-correction__header">
						<p className="suggest-correction__title">Предложить исправление</p>

						<svg role="img" onClick={this.props.closeModal} className="suggest-correction__close">
							<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#cross'}/>
						</svg>
					</div>

					<textarea
						id="text"
						placeholder="Подробно опишите что вы хотите исправить"
					></textarea>

					<div className="suggest-correction__btns">
						<Button
							type="primary"
							text="Отправить"
							image={null}
							handler={this.sendCorrectionHandler}
						/>

						<Button
							type="secondary"
							text="Отменить"
							image={null}
							handler={this.props.closeModal}
						/>
					</div>
				</div>
			</div>
		)
	}

	sendCorrectionHandler = (e) => {
		e.preventDefault()
		let {
			type,
			closeModal,
			suggestContactsCorrection,
			suggestGuideCorrection,
			sourceURL,
			wikiGuideCategoryId,
			openThanksCorrectionModal
		} = this.props

		if (type === 'contacts') {
			let text = document.getElementById('text')
			if (text.value.replace(/\s/g, '').length > 0 && text.value.length <= 2000) {
				text.classList.remove('error-input')
				closeModal(e)
				suggestContactsCorrection({
					message: text.value,
					sourceURL: sourceURL
				}, openThanksCorrectionModal)
			} else {
				text.classList.add('error-input')	
			}
		} else if (type === 'guide') {
			let text = document.getElementById('text')
			if (text.value.replace(/\s/g, '').length > 0 && text.value.length <= 2000) {
				text.classList.remove('error-input')
				closeModal(e)
				suggestGuideCorrection({
					message: text.value,
					sourceURL: sourceURL,
					wikiGuideCategoryId: wikiGuideCategoryId
				}, openThanksCorrectionModal)
			} else {
				text.classList.add('error-input')	
			}
		}
	}

	keyHandler = (e) => {
        if (e.which === 27 || e.keyCode === 27) {
			this.props.closeModal(e)
        }
	}

	handleClickOutside = (e) => {
        let item = this.item
        const { target } = e;
        if (target !== item && !item.contains(target)) {
            this.props.closeModal(e)
        }
    }

	componentDidMount() {
		window.addEventListener('keyup', this.keyHandler);
		document.addEventListener('click', this.handleClickOutside, true);
		document.getElementById('wrapp').classList.add('no-scroll');
		bodyFixPosition();
    }

    componentWillUnmount() {
		window.removeEventListener('keyup', this.keyHandler);
		document.removeEventListener('click', this.handleClickOutside, true);
		document.getElementById('wrapp').classList.remove('no-scroll');
		bodyUnfixPosition();
    }
}

export class SuggestThanksModal extends Component {
	render() {
		return (
			<div className="suggest-correction__modal">
				<div className="suggest-correction__modal-view suggest-correction__modal-view_thanks" ref={(el) => { this.item = el }}>
					<div className="suggest-thanks">
						<svg role="img" onClick={this.props.closeThanksCorrectionModal} className="suggest-thanks__close">
							<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#cross'}/>
						</svg>

						<svg role="img" className="suggest-thanks__svg">
							<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#help-woman'}/>
						</svg>

						<span className="suggest-thanks__text">Спасибо! Администратор «Максипортала» уже рассматривает ваше исправление</span>
					</div>
				</div>
			</div>
		)
	}

	keyHandler = (e) => {
        if (e.which === 27 || e.keyCode === 27) {
            this.props.closeThanksCorrectionModal(e)
        }
	}

	handleClickOutside = (e) => {
        let item = this.item
        const { target } = e;
        if (target !== item && !item.contains(target)) {
            this.props.closeThanksCorrectionModal(e)
        }
    }

	componentDidMount() {
		window.addEventListener('keyup', this.keyHandler);
		document.addEventListener('click', this.handleClickOutside, true);
		document.getElementById('wrapp').classList.add('no-scroll');
		bodyFixPosition();
    }

    componentWillUnmount() {
		window.removeEventListener('keyup', this.keyHandler);
		document.removeEventListener('click', this.handleClickOutside, true);
		document.getElementById('wrapp').classList.remove('no-scroll');
		bodyUnfixPosition();
    }
}

class SuggestCorrectionBtn extends Component {

	state = {
		isModalCorrection: false,
		isModalThanksCorrection: false
	}

	render() {
		return (
			<Fragment>
				<div className="suggest-correction__btn" onClick={this.openCorrectionModal} title="Вы можете сообщить об ошибке в данных">
					<svg role="img">
						<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#comment-warning'}></use>
					</svg>
			
					<span>Предложить исправления</span>
				</div>

				{
					this.state.isModalCorrection &&
						<SuggestCorrectionModal
							closeModal={this.closeCorrectionModal}
							openThanksCorrectionModal={this.openThanksCorrectionModal}
							sourceURL={this.props.sourceURL}
							type={this.props.type}
							suggestContactsCorrection={this.props.suggestContactsCorrectionAction}
							suggestGuideCorrection={this.props.suggestGuideCorrectionAction}
							wikiGuideCategoryId={this.props.wikiGuideCategoryId}
						/>
				}

				{
					this.state.isModalThanksCorrection &&
						<SuggestThanksModal
							closeThanksCorrectionModal={this.closeThanksCorrectionModal}
						/>
				}
			</Fragment>
		)
	}

	openCorrectionModal = (e) => {
        e.preventDefault()
        this.setState({
            isModalCorrection: true
        })
    }

    closeCorrectionModal = (e) => {
        e.preventDefault()
        this.setState({
            isModalCorrection: false
		})
	}
	
	openThanksCorrectionModal = () => {
        this.setState({
            isModalThanksCorrection: true
        })
	}
	
	closeThanksCorrectionModal = (e) => {
        e.preventDefault()
        this.setState({
            isModalThanksCorrection: false
		})
	}
}

const mapDispatchToProps = (dispatch) => {
    return {
        suggestContactsCorrectionAction: (data, showThanksModal) => {
            dispatch(suggestContactsCorrectionAction(data, showThanksModal));
		},
		suggestGuideCorrectionAction: (data, showThanksModal) => {
            dispatch(suggestGuideCorrectionAction(data, showThanksModal));
		}
    }
}

export default connect(
    null,
    mapDispatchToProps,
)(SuggestCorrectionBtn)