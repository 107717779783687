const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#d9291a" : state.isFocused ? "#d9291a" : "#272727",
        backgroundColor: state.isSelected ? "#f1f1f1 !important" : state.isFocused ? "#f3f4f4 !important" : "#ffffff !important",
        cursor: "pointer"
    }),
    control: (base, state) => ({
        ...base,
        background: "#ffffff",
        borderRadius: "2px",
        borderColor: state.isFocused ? "#beb8b8" : "#beb8b8",
        boxShadow: state.isFocused ? null : null,
        minHeight: "34px",
        height: "34px",
        "&:hover": {
            borderColor: state.isFocused ? "#beb8b8" : "#beb8b8",
		}
    }),
    menu: base => ({
        ...base,
        borderRadius: 0,
        hyphens: "auto",
        marginTop: 0,
        textAlign: "left",
        wordWrap: "break-word",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.1)"
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: "6px"
    }),
    valueContainer: base => ({ 
		...base,
        padding: "0px 8px",
        height: "33px"
    })
}

export default selectStyles