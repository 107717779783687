import React, { useRef, useCallback, useEffect, useState } from 'react'
import { bodyFixPosition, bodyUnfixPosition } from 'core/utils'
import useClickOutside from 'hooks/useClickOutside'

import './style.scss'

const Modal = ({children, onClose, isOpenHandler}) => {

    const modalRef = useRef(null)
    const [animateClass, setAnimateClass] = useState(false)

    const stopPropagationModal = (e) => {
        e.stopPropagation()
    }
    const onCloseHandler = useCallback(
        () => {
            if(onClose) onClose()
        }, [onClose]
    )

	const keyHandler = useCallback((e) => {
		if (e.key === 'Escape') {
            onCloseHandler()
		}
	}, [onCloseHandler])

	useClickOutside(modalRef, () => { onCloseHandler() })

	useEffect(() => {
		window.addEventListener('keyup', keyHandler)
		bodyFixPosition()
		return () => {
			window.removeEventListener('keyup', keyHandler)
			bodyUnfixPosition()
		}
	}, [keyHandler])

    useEffect(
        () => {
            setTimeout(() => {setAnimateClass(true)}, 0)
        }, []
    )

    return (
        <div className={animateClass ? 'bg-wrapper active' : 'bg-wrapper'} onClick={isOpenHandler}>
            <div onClick={stopPropagationModal} ref={modalRef}>
                {children}
            </div>
        </div>
    )        
}

export default Modal