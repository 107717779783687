import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FormLabel } from '../../atoms';
import { FormInputBlock } from '../../molecules';
import { TextAreaHook, Button, MaskInput, TextField, UploaderField } from 'core/components';

import { NotificationHelper } from 'action/notificationHelper';
import { vacanciesReplyInternalUser, vacanciesReply } from 'rest/vacancies';

import './style.scss';

const phoneRegExp = /(\+7|8)[\s(]*\d{3}[)\s]*(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/;
const validationSchemaDefault = yup.object().shape({
	telephone: yup.string().matches(phoneRegExp),
	email: yup.string().email().required(),
	info: yup.string().trim(),
	claimId: yup.number(),
	resumeId: yup.number(),
});
const validationSchemaWithName = yup.object().shape({
	...validationSchemaDefault.fields,
	fullName: yup.string().trim().required(),
});

export const FormItem = ({ isFormSuggest, claimId }) => {
	const [validationSchema, setValidationSchema] = useState(validationSchemaDefault);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [clearFilesTrigger, setClearFilesTrigger] = useState(false);
	const { errors, handleSubmit, setValue, register, unregister } = useForm({
		validationSchema,
	});

	useEffect(() => {
		register('resumeId', { required: false });
		register('claimId', { required: false });
		return () => {
			unregister('resumeId');
			unregister('claimId');
		};
	}, [register, unregister]);

	useEffect(() => {
		setValue('claimId', claimId);
	}, [claimId, setValue]);

	const clearForm = useCallback(() => {
		setClearFilesTrigger(true);
		setValue('fullName', undefined);
		setValue('telephone', undefined);
		setValue('email', undefined);
		setValue('info', undefined);
		setValue('resumeId', undefined);
	}, [setValue]);

	useEffect(() => {
		clearForm();

		if (isFormSuggest) {
			setValidationSchema(validationSchemaWithName);
		} else {
			setValidationSchema(validationSchemaDefault);
		}
	}, [isFormSuggest, clearForm]);

	const setFile = files => {
		const file = files[0];

		if (file.data) {
			if (file.data.id) setValue('resumeId', file.data.id);
		} else if (file.id) {
			setValue('resumeId', file.id);
		}
	};

	const onSubmit = async formData => {
		if (loading) return;
		setLoading(true);

		const { data } = isFormSuggest
			? await vacanciesReply(formData) // Предложить кандидата
			: await vacanciesReplyInternalUser(formData); // Откликнуться самому

		if (data.responseCode === 0) {
			const successText = isFormSuggest
				? 'Вы порекомендовали кандидата'
				: 'Вы откликнулись на вакансию';
			dispatch(NotificationHelper(successText, 0));
			clearForm();
		} else {
			dispatch(NotificationHelper(data.errorMessage, 1));
		}

		setLoading(false);
	};

	return (
		<form className="forms-item">
			{isFormSuggest && (
				<FormInputBlock>
					<TextField
						error={!!errors.fullName}
						name="fullName"
						placeholder="Иванов Иван Иванович"
						label="ФИО"
						fullWidth
						inputRef={register}
						autoComplete="off"
					/>
				</FormInputBlock>
			)}

			<FormInputBlock>
				<FormLabel text="Телефон" />
				<MaskInput
					error={!!errors.telephone}
					name="telephone"
					placeholder="+7 (___) ___-__-__"
					maskPattern="+9 (999) 999-99-99"
					refRegister={register}
					fullWidth
				/>
			</FormInputBlock>

			<FormInputBlock>
				<TextField
					error={!!errors.email}
					name="email"
					placeholder="test@maxi-net.ru"
					label="E-mail"
					fullWidth
					inputRef={register}
					autoComplete="off"
				/>
			</FormInputBlock>

			<FormInputBlock>
				<FormLabel text="Сопроводительное письмо" />
				<TextAreaHook
					valid={!errors.info}
					placeholder={isFormSuggest ? 'Напишите рекомендацию' : 'Напишите о себе'}
					name="info"
					ref={register}
				/>
			</FormInputBlock>

			<FormInputBlock>
				<UploaderField
					onProgress={setFile}
					fileType="VACANCY_RESUME"
					fullWidth
					label={isFormSuggest ? 'Резюме кандидата' : 'Ваше резюме'}
					placeholder="Выбрать файл"
					accept=".pdf, .doc, .docx, .jpeg, .jpg, .png"
					InputProps={{
						clearFilesTrigger,
						setClearFilesTrigger,
					}}
				/>
			</FormInputBlock>

			<Button type="primary" text="Отправить" handler={handleSubmit(onSubmit)} image={null} />
		</form>
	);
};
