import { api } from 'rest/api';
import {
	IChatbotCategoryCreateRest,
	IChatbotCategoryUpdateRest,
	IChatbotChildrenCreateRest,
	ITypeChange,
	ISendMessage,
	IGetDialogListRequest,
	IChatbotUpdateRest,
	IChatList,
	getUnreadCountResponse,
} from './types';

function createError(error: any, defaultValue: any = null) {
	return {
		responseCode: 1,
		errorMessage: typeof error === 'object' ? 'Ошибка сети' : error,
		response: defaultValue,
	};
}

export const getChatbotListRest = async () => {
	try {
		const response = await api.get(`/chatbot/list`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const chatbotCategoryCreateRest = async (data: IChatbotCategoryCreateRest) => {
	try {
		const response = await api.post(`/chatbot/category/create`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const chatbotCategoryUpdateRest = async (data: IChatbotCategoryUpdateRest) => {
	try {
		const response = await api.post(`/chatbot/category/update`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const chatbotChildrenCreateRest = async (data: IChatbotChildrenCreateRest) => {
	try {
		const response = await api.post(`/chatbot/category/create-children`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getChatbotCategoryRest = async (id: number | string) => {
	try {
		const response = await api.get(`/chatbot/category/get`, {
			params: { id },
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const updateChatbotCategorySortRest = async (data: ITypeChange) => {
	try {
		const resp = await api.post(`/chatbot/category/change-sort`, data);
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const deleteCategoryRest = async (id: number) => {
	try {
		const resp = await api.post(`/chatbot/category/delete`, { id });
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const getChatbotUserListRest = async () => {
	try {
		const resp = await api.get(`/chatbot/list-for-user`);
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const getMessengesRest = async (data: IChatList) => {
	try {
		const resp = await api.get(`/messages/list-for-user`, { params: data });
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const sendMessageRest = async (data: ISendMessage) => {
	try {
		const resp = await api.post(`/messages/send`, data);
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const readMessageRest = async (messageId: number) => {
	try {
		const resp = await api.post(`/messages/read`, { messageId });
		return resp.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const getDialogAdminsList = async (data: IGetDialogListRequest) => {
	try {
		const response = await api.get('/dialog/list-for-manager', {
			params: data,
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getExcelMessenger = async (data: IGetDialogListRequest) => {
	try {
		const response = await api.get('/dialog/list-for-manager/export', {
			params: data,
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getChatbotRest = async (id: number | string) => {
	try {
		const response = await api.get(`/chatbot/get`, {
			params: { id },
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getChatbotForUserRest = async (id: number | string) => {
	try {
		const response = await api.get(`/chatbot/get-short-info`, {
			params: { id },
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const chatbotUpdateRest = async (data: IChatbotUpdateRest) => {
	try {
		const response = await api.post(`/chatbot/update`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getDialogRest = async (id: number) => {
	try {
		const response = await api.get(`/dialog/get-for-manager`, {
			params: { id },
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getDialogStatusesRest = async () => {
	try {
		const response = await api.get(`/dialog/status/list`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getDefaultChatRest = async () => {
	try {
		const response = await api.get(`/chatbot/get-default `);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getUnreadCountRest = async (): Promise<getUnreadCountResponse> => {
	try {
		const response = await api.get(`/messages/get-unread-count `);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};
