export function make(tagName, classNames = null, attributes = {}) {
	const el = document.createElement(tagName);
	if (Array.isArray(classNames)) {
		el.classList.add(...classNames);
	} else if (classNames) {
		el.classList.add(classNames);
	}

	for (const attrName in attributes) {
		el[attrName] = attributes[attrName];
	}
	return el;
}

export function fragmentToString(fragment) {
	const div = make('div');
	div.appendChild(fragment);
	return div.innerHTML;
}
export function isEmpty(node) {
	let content;

	if (node.nodeType !== Node.ELEMENT_NODE) {
		content = node.textContent;
	} else {
		content = node.innerHTML;
		content = content.replaceAll('<br>', '');
	}

	return content.trim().length === 0;
}
