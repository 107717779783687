import {
	SET_STRATEGY_BLOCKS,
	ADD_STRATEGY_BLOCK,
	SET_ACTIVE_BLOCK_STRATEGY,
	DELETE_STRATEGY_BLOCK,
	UPDATE_ACTIVE_BLOCK_IMAGE_STRATEGY,
	UPDATE_ACTIVE_BLOCK_TEXT_STRATEGY,
	MOVE_DOWN_STRATEGY_CATEGORY,
	MOVE_UP_STRATEGY_CATEGORY,
	SET_STRATEGY_CATEGORIES,
	DELETE_STRATEGY_CATEGORIES,
	ADD_STRATEGY_CATEGORIES,
	EDIT_STRATEGY_CATEGORIES,
} from '../constant';

const DefaultState = {
	blocks: [],
	categories: [],
	activeBlockId: undefined,
};

export default function strategy(state = DefaultState, action) {
	switch (action.type) {
		case SET_STRATEGY_BLOCKS:
			return {
				...state,
				blocks: action.blocks,
			};

		case ADD_STRATEGY_BLOCK: {
			let temp = [];

			if (!!action.nextBlockId) {
				state.blocks.forEach(item => {
					if (item.id === action.nextBlockId) {
						temp.push(action.block);
					}
					temp.push(item);
				});
			} else {
				temp = [...state.blocks, action.block];
			}
			return {
				...state,
				blocks: temp,
			};
		}

		case DELETE_STRATEGY_BLOCK: {
			const temp = state.blocks.filter(item => item.id !== action.activeBlockId);
			return {
				...state,
				blocks: temp,
			};
		}

		case UPDATE_ACTIVE_BLOCK_IMAGE_STRATEGY: {
			const temp = state.blocks.map(item => {
				if (item.id === action.id) item.image = action.image;
				return item;
			});
			return {
				...state,
				blocks: temp,
			};
		}

		case UPDATE_ACTIVE_BLOCK_TEXT_STRATEGY: {
			const temp = state.blocks.map(item => {
				if (item.id === action.id) item.text = action.text;
				return item;
			});
			return {
				...state,
				blocks: temp,
			};
		}

		case SET_STRATEGY_CATEGORIES:
			return {
				...state,
				categories: action.categories,
			};

		case ADD_STRATEGY_CATEGORIES:
			return {
				...state,
				categories: [...state.categories, action.category],
			};

		case EDIT_STRATEGY_CATEGORIES:
			const temp = state.categories.map(item => {
				if (item.id === action.category.id) return action.category;
				else return item;
			});
			return {
				...state,
				categories: temp,
			};

		case DELETE_STRATEGY_CATEGORIES:
			return {
				...state,
				categories: [...state.categories.filter(item => item.id !== action.id)],
			};

		case MOVE_UP_STRATEGY_CATEGORY: {
			state.categories.some(item => {
				if (item.id === action.currentId) {
					item.sort++;

					state.categories.sort((a, b) => {
						if (b.sort !== a.sort) {
							return b.sort - a.sort;
						} else {
							if (a.id < b.id) {
								return -1;
							} else if (a.id > b.id) {
								return 1;
							} else return 0;
						}
					});
					return true;
				} else return false;
			});

			return {
				...state,
				categories: [...state.categories],
			};
		}

		case MOVE_DOWN_STRATEGY_CATEGORY: {
			state.categories.some(item => {
				if (item.id === action.currentId) {
					item.sort--;

					state.categories.sort((a, b) => {
						if (b.sort !== a.sort) {
							return b.sort - a.sort;
						} else {
							if (a.id < b.id) {
								return -1;
							} else if (a.id > b.id) {
								return 1;
							} else return 0;
						}
					});
					return true;
				} else return false;
			});

			return {
				...state,
				categories: [...state.categories],
			};
		}

		case SET_ACTIVE_BLOCK_STRATEGY:
			return {
				...state,
				activeBlockId: action.activeBlockId,
			};

		default:
			return state;
	}
}
