import {
    MIFORM_ADD,
    MIFORM_DELETE,
    MIFORM_UPDATE,
    MIFORM_DND,
    MIFORM_CLEAR,
    MIFORM_SET
} from '../events'

const DefaultState = []

export default function MiForm(state = DefaultState, action) {

    switch (action.type) {
        case MIFORM_ADD:
            if (state.length > 0) {
                let addBlockState = state.map(
                    item => {
                        item.focus = false
                        return item
                    }
                )
                return [...addBlockState, action.block]
            } else return [...state, action.block]

        case MIFORM_DELETE:
            return state.filter(
                item => {
                    return +item.id !== +action.id
                }
            )

        case MIFORM_UPDATE:
            return state.map(
                item => {
                    if (+item.id === +action.id) {
                        item = Object.assign(item, action.block)
                    }
                    return item
                }
            )

        case MIFORM_SET:
            return action.params

        case MIFORM_DND:
            let dragIndex = state.findIndex(
                item => item.id === + action.idDrag
            )
            let overIndex = state.findIndex(
                item => item.id === + action.idOver
            )
            let dndState = state
            let buffer = dndState[dragIndex]
            dndState[dragIndex] = dndState[overIndex]
            dndState[overIndex] = buffer
            return dndState

        case MIFORM_CLEAR:
            return []

        default:
            return state
    }
}