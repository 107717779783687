import React from 'react';
import { URL } from 'constant';

import { Avatar } from 'core/components';

export const RenderUserItem = ({
	id,
	name,
	surname,
	middlename,
	address,
	workPhone,
	position,
	avatarFile,
	addToListHandler,
	department,
}) => {
	return (
		<div
			key={id}
			className="find-item__search-item"
			data-itemid={id}
			data-itemname={name}
			data-itemsurname={surname}
			data-itemmiddlename={middlename}
			data-itemaddress={address}
			data-itemtelephone={workPhone}
			data-department={department}
			data-position={position}
			onClick={addToListHandler}
			onKeyDown={event => {
				if (event.key === 'Enter') addToListHandler(event);
			}}
			tabIndex="1"
		>
			<Avatar
				size="small"
				type="user"
				image={
					avatarFile
						? avatarFile?.filePath
							? `${URL}${avatarFile.filePath}`
							: `${URL}${avatarFile}`
						: null
				}
			/>
			<div className="find-item__search-item__info">
				<span className="find-item__search-item__name">
					{surname} {name}
				</span>
				<span className="find-item__search-item__position">{position}</span>
			</div>
		</div>
	);
};
