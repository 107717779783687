import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Paperclip, X } from 'react-feather';
import classNames from 'classnames';

import { IFile, IProps } from './types';
import { fileSaveRest } from './api';
import { NotificationHelper } from 'action/notificationHelper';
import { URL } from 'constant';

import './style.scss';

let fileLength: number = 0;
export const FileUploader: FC<IProps> = ({
	accept = 'image/*',
	multiple = true,
	fileType,
	defaultValues,
	max = 10,
	onChange,
	startUploading,
	label,
	error = false,
	removeAllFiles,
	paste = false,
	linkToFile = false,
}) => {
	const dispatch = useDispatch();
	const [files, setFiles] = useState<IFile[]>([]);

	useEffect(() => {
		if (!defaultValues) return;
		setFiles(defaultValues);
	}, [defaultValues]);

	useEffect(() => {
		if (!files?.length) {
			if (removeAllFiles) removeAllFiles();
			return;
		}
		onChange(files);
		fileLength = files.length;
	}, [files, onChange, removeAllFiles]);

	const fileSave = useCallback(
		(uploadFiles: any) => {
			uploadFiles.forEach((file: any) => {
				const reader = new FileReader();
				reader.onload = async () => {
					if (!reader.result) return;
					const uploadImg = reader.result.slice(
						(reader.result as string).indexOf(',') + 1
					);

					const { response, responseCode, errorMessage } = await fileSaveRest({
						originalFileName: file.name,
						base64File: uploadImg,
						fileType,
					});

					if (responseCode) {
						dispatch(NotificationHelper(errorMessage, responseCode));
					} else {
						setFiles(prev => [...(prev ? prev : []), response]);
					}
				};
				reader.readAsDataURL(file);
			});
		},
		[dispatch, fileType]
	);

	const handlePaste = useCallback(
		(event: any) => {
			if (!paste) return;
			if (event.clipboardData.files.length) {
				let uploadFiles = [event.clipboardData.files[0]];

				if (!!max && max <= fileLength) return;

				fileSave(uploadFiles);
			} else {
				return null;
			}
		},
		[fileSave, paste, max]
	);
	useEffect(() => {
		window.addEventListener('paste', (event: any) => handlePaste(event));

		return () => {
			window.removeEventListener('paste', (event: any) => handlePaste(event));
		};
	}, [handlePaste]);

	const uploadFile = (event: any) => {
		if (startUploading) startUploading();
		let uploadFiles = [...event.currentTarget.files];

		if (max) {
			uploadFiles = uploadFiles.slice(0, max - files.length);
		}

		fileSave(uploadFiles);
	};

	const deleteFile = (index: number) => {
		setFiles(prev => prev?.filter((prevItem, prevIndex) => prevIndex !== index));
	};

	return (
		<div className="file-uploader">
			{label && <p className="file-uploader__title">{label}</p>}
			<label className={classNames('file-uploader__wrap', { error })}>
				<input
					type="file"
					className="file-uploader__input"
					accept={accept}
					multiple={multiple}
					onChange={uploadFile}
					disabled={!!files && !!max && files.length >= max}
				/>
				<Paperclip className="file-uploader__icon" />

				<span className="file-uploader__text">Прикрепить файл</span>
			</label>

			<div className="file-uploader-view">
				{!!files?.length &&
					files.map((file, index) => (
						<div className="file-uploader-view__item" key={file.id}>
							<svg className="file-uploader-view__file-icon" role="img">
								<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#file'} />
							</svg>

							<div className="file-uploader-view__wrap">
								{linkToFile ? (
									<a
										href={URL + file.filePath}
										className="file-uploader-view__name"
										rel="noopener noreferrer"
										target="_blank"
									>
										{file.originalFileName}
									</a>
								) : (
									<p className="file-uploader-view__name">
										{file.originalFileName}
									</p>
								)}
								<p className="file-uploader-view__size">{`${(
									file.fileSize /
									1024 /
									1024
								).toFixed(2)} МБ`}</p>
							</div>

							<X
								className="file-uploader-view__delete"
								onClick={() => deleteFile(index)}
							/>
						</div>
					))}
			</div>
		</div>
	);
};
