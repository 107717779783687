import {
    SET_GROUP_INFO,
    UPDATE_GROUP_IMG,
    SUBSCRIBE_GROUP,
    UNSUBSCRIBE_GROUP,
    UPDATE_GROUP_COVER
} from '../constant';

const DefaultState = {};

export default function groupInfo(state = DefaultState, action) {

    switch (action.type) {
        case SET_GROUP_INFO:
            return action.groupInfo

        case UPDATE_GROUP_IMG:
            let updateState = {
                id: state.id,
                name: state.name,
                description: state.description,
                avatarPath: action.img,
                relationType: action.relationType
            }
            return updateState

        case SUBSCRIBE_GROUP:
            let subscribeState = state
            subscribeState.relationType = 'SUBSCRIBER'
            subscribeState.userCount++
            return subscribeState

        case UNSUBSCRIBE_GROUP:
            let unSubscribeState = state
            unSubscribeState.relationType = null
            unSubscribeState.userCount--
            return unSubscribeState

        case UPDATE_GROUP_COVER:
            let updateCoverState = state
            updateCoverState.coverFile = action.coverFile
            return updateCoverState

        default:
            return state
    }

}