import React from 'react';
import { Button, TypeButton, Modal } from '@maxi-innovation/ui-kit-frontend';
import Parser from 'html-react-parser';

import './style.scss';

interface IProps {
	closeModal: () => void;
	callback: () => void;
	text: string;
	title: string;
}

export const ModalConfirmDelete = ({ closeModal, callback, title, text }: IProps) => {
	return (
		<Modal className="confirm-modal" onClose={closeModal}>
			<p className="confirm-modal__title">{title}</p>

			<p className="confirm-modal__text">{Parser(text)}</p>

			<div className="confirm-modal__buttons-group">
				<Button
					className="confirm-modal__buttons-group__button"
					variant={TypeButton.PRIMARY}
					type="button"
					onClick={() => callback()}
				>
					<span>Подтвердить</span>
				</Button>

				<Button
					className="confirm-modal__buttons-group__button"
					variant={TypeButton.SECONDARY}
					type="button"
					onClick={closeModal}
				>
					<span>Отменить</span>
				</Button>
			</div>
		</Modal>
	);
};
