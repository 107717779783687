import { SET_FULL_USER_INFO, UPDATE_FULL_USER_INFO, UPDATE_USER_IMAGE } from '../constant';

const DefaultState = null;

export default function fullUserInfo(state = DefaultState, action) {
	switch (action.type) {
		case SET_FULL_USER_INFO:
			return action.user;

		case UPDATE_FULL_USER_INFO:
			return action.user;

		case UPDATE_USER_IMAGE:
			let updateImage = state;
			updateImage.avatarFile = action.image;
			return updateImage;

		default:
			return state;
	}
}
