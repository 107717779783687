import { ADD_ISPRING_LINK } from '../constant';

const defaultValues = '';

export default function iSpringLink(state = defaultValues, action) {
	switch (action.type) {
		case ADD_ISPRING_LINK:
			return action.value;

		default:
			return state;
	}
}
