import React from 'react'

import './style.scss'

const BackArrow = ({ history, link }) => {
    
    const goBackToList = (e) => {
        e.preventDefault()
        if (link) {
            history.push(link)
        } else {
            history.goBack()
        }
    }

    return (
        <div className="back-btn__wrapper">
            <div className="back-btn" onClick={goBackToList}>
                <svg role="img">
                    <use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
                </svg>
                
                <span>Назад</span>
            </div>
        </div>
    )
}

export default BackArrow