import React, { useState, useCallback, useEffect } from 'react';
import { URL } from 'constant';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { ViewEditor } from 'components/editorjsText/editorHtml';

import Notification from 'modules/notification';
import { NotificationHelper } from 'action/notificationHelper';

import { BreadCrumbs, CrumbItem, Avatar } from 'core/components';

import { getArticle } from 'rest/article';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { HeaderWrapp, GoBack, Title } from '../../atoms/header';

import { ArticleLoader } from '../../organisms/loader';

import { useGetIndexName } from 'hooks/useGetIndexName';

import './style.scss';

export const ArticleGroupView = props => {
	const fromIndex = useGetIndexName('fromIndex');
	const [article, setArticle] = useState(null);

	const dispatch = useDispatch();

	const { id, idArticle } = props.match.params;

	const fetchArticle = useCallback(async () => {
		const res = await getArticle({
			groupId: id,
			articleId: idArticle.split('?')[0],
			...(fromIndex ? { fromIndex: fromIndex } : {}),
		});

		if (res.data.responseCode === 0) {
			let newContent = {
				...res.data.response,
			};

			setArticle(newContent);
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
			props.history.push(`/group/${id}/article`);
		}
	}, [id, idArticle, dispatch, props.history, fromIndex]);

	useEffect(() => {
		fetchArticle();
	}, [fetchArticle]);

	if (article) {
		const { group, name, author, createDate, canEdit, content } = article;

		return (
			<div className="Article-view">
				<Notification />

				<Header>
					<HeaderWrapp>
						{name && <Helmet title={name} />}
						<GoBack to={`/group/${id}/article`} />
						<Title>{`${name}`}</Title>
						<BreadCrumbs>
							<CrumbItem to={`/group/${id}`}>{`${group.name}`}</CrumbItem>
							<CrumbItem to={`/group/${id}/article`}>Статьи</CrumbItem>
							<CrumbItem>{`${name}`}</CrumbItem>
						</BreadCrumbs>
					</HeaderWrapp>
				</Header>
				<Wrapp>
					<div className="Article-author">
						<Link to={`/user/${author.id}`} className="Article-author__link">
							<Avatar
								type="user"
								size="small"
								image={
									author.avatarFile && author.avatarFile.filePath
										? `${URL}${author.avatarFile.filePath}`
										: null
								}
							/>

							<div className="Article-author__link-text">
								<b>{`${author.surname} ${author.name}`}</b>
								<span>{createDate}</span>
							</div>
						</Link>

						{canEdit && (
							<Link
								className="article-edit-link"
								to={`/group/${id}/article/edit/${idArticle}`}
							>
								<svg role="img">
									<use
										xlinkHref={`${process.env.PUBLIC_URL}/img/sprite.svg#pen`}
									/>
								</svg>
								<span>Редактировать</span>
							</Link>
						)}
					</div>

					<div className="view-header">
						<h1>{`${name}`}</h1>
					</div>

					{content && <ViewEditor content={content} />}

					<Link to={`/group/${group.id}/article`} className="Article-linkToAll">
						<span>Читать ещё</span>
						<svg role="img">
							<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
						</svg>
					</Link>
				</Wrapp>
			</div>
		);
	} else {
		return (
			<div className="Article-view">
				<Header>
					<HeaderWrapp />
				</Header>
				<Wrapp>
					<ArticleLoader />
				</Wrapp>
			</div>
		);
	}
};
