import {
	SET_FULL_USER_INFO,
	SET_SHORT_USER_INFO,
	SET_USERS_NOTIFICATIONS,
	UPDATE_FULL_USER_INFO,
	UPDATE_USER_IMAGE,
	GET_USER_STIMULATION_LIST,
} from 'constant';

export const setFullUserInfo = user => {
	return {
		type: SET_FULL_USER_INFO,
		user: user,
	};
};

export const setShortUserInfo = user => {
	return {
		type: SET_SHORT_USER_INFO,
		user: user,
	};
};

export const updateFullUserInfo = user => {
	return {
		type: UPDATE_FULL_USER_INFO,
		user: user,
	};
};

export const updateUserPhoto = image => {
	return {
		type: UPDATE_USER_IMAGE,
		image: image,
	};
};

export const setStimulationList = stimulation => {
	return {
		type: GET_USER_STIMULATION_LIST,
		stimulation: stimulation,
	};
};

export const setUsersNtfcts = count => {
	return {
		type: SET_USERS_NOTIFICATIONS,
		count,
	};
};
