import {
	GET_SECRETARY_TO_DEPARTMENT_LIST,
	ADD_SECRETARY_TO_DEPARTMENT,
	UPDATE_SECRETARY_TO_DEPARTMENT,
	DELETE_SECRETARY_TO_DEPARTMENT
} from 'constant'

const DefaultState = {
	secretaries: null
};

export default function secretaryToDepartment(state = DefaultState, action) {

	switch (action.type) {
		case GET_SECRETARY_TO_DEPARTMENT_LIST:
			return {
				...state,
				secretaries: action.secretaries
			}

		case ADD_SECRETARY_TO_DEPARTMENT:
			return {
				...state,
				secretaries: [action.secretary, ...state.secretaries]
			}

		case UPDATE_SECRETARY_TO_DEPARTMENT:
			return {
				...state,
				secretaries: state.secretaries.map(
					item => {
						if (+item.id === +action.info.id) {
							item = action.info
						}
						return item
					}
				)
			}

		case DELETE_SECRETARY_TO_DEPARTMENT:
			return {
				...state,
				secretaries: state.secretaries.filter(
					item => {
						return +item.id !== +action.id
					}
				)
			}
			
		default:
			return state
	}

}