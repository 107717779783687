import React from 'react';
import Parser from 'html-react-parser';
import * as Icon from 'react-feather';
import classNames from 'classnames';

const Asterisk = () => {
	return (
		<svg
			fill="#000000"
			width="24px"
			height="24px"
			viewBox="-80 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M285 221Q243 246 210 256 243 266 285 291L261 333Q215 305 193 286 200 317 200 368L152 368Q152 317 159 286 137 305 91 333L67 291Q109 266 142 256 109 246 67 221L91 179Q137 207 159 226 152 195 152 144L200 144Q200 195 193 226 215 207 261 179L285 221Z" />
		</svg>
	);
};

export const paragraphParser = (props: any) => {
	return (
		<p className="paragraph" style={{ textAlign: props.tunes?.anyTuneName?.alignment }}>
			{Parser(props.data.text)}
		</p>
	);
};

export const imageParser = ({ data }: any) => {
	return (
		<>
			<img className="image" src={data.file.url} alt="img" />
			{data.caption && <span className="image_description">{Parser(data.caption)}</span>}
		</>
	);
};

export const imageSimpleParser = ({ data }: any) => {
	return (
		<>
			<img className="image" src={data.url} alt="img" />
			{data.caption && <span className="image_description">{Parser(data.caption)}</span>}
		</>
	);
};

export const youtubeParser = ({ data }: any) => {
	if (data.url) {
		return (
			<div className="container_parser">
				<iframe
					title="video"
					src={`https://www.youtube.com/embed/${data.url}`}
					allowFullScreen
				/>
			</div>
		);
	}
};

export const delimiter = () => {
	return (
		<p className="delimiter">
			<Asterisk />
			<Asterisk />
			<Asterisk />
		</p>
	);
};

export const tableParser = ({ data, tunes }: any) => {
	return (
		<table>
			<tbody>
				{data.content.map((item: string[], index: number) => (
					<tr
						key={index}
						className={classNames('tr_base', {
							tr_head: data.withHeadings,
						})}
						style={{ textAlign: tunes?.anyTuneName?.alignment }}
					>
						{item.map((item: string, index: number) => (
							<td key={index}>{Parser(item)}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export const rawParser = ({ data }: any) => {
	return <div className="raw">{data.html}</div>;
};

export const attachesParser = ({ data }: any) => {
	return (
		<a
			className="download_link"
			href={data.file.url}
			target="_blank"
			rel="noopener noreferrer"
			download
		>
			<Icon.Download />
			{data.title}
		</a>
	);
};

export const warningParser = ({ data }: any) => {
	return (
		<div className="warning">
			<div>
				<Icon.AlertCircle />
			</div>
			<div className="content">
				<div className="container">{Parser(data.title)}</div>
				<div className="container">{Parser(data.message)}</div>
			</div>
		</div>
	);
};

const header = (data: any) => {
	switch (data.level) {
		case 1:
			return <h1 dangerouslySetInnerHTML={{ __html: data.text }} />;
		case 2:
			return <h2 dangerouslySetInnerHTML={{ __html: data.text }} />;
		case 3:
			return <h3 dangerouslySetInnerHTML={{ __html: data.text }} />;
		case 4:
			return <h4 dangerouslySetInnerHTML={{ __html: data.text }} />;
		case 5:
			return <h5 dangerouslySetInnerHTML={{ __html: data.text }} />;
		case 6:
			return <h6 dangerouslySetInnerHTML={{ __html: data.text }} />;
	}
};

export const headerParser = ({ data, tunes }: any) => {
	return <div style={{ textAlign: tunes?.anyTuneName?.alignment }}>{header(data)}</div>;
};
