import React from "react";
import { Link, withRouter } from "react-router-dom";

import "./style.scss";

export const GoBack = withRouter(({ to, history }) => {
	const { go, location } = history
	const { state } = location
	return to && !(state && typeof state.back !== undefined) ? (
		<Link className="Aheader-goback" to={to}>
			<svg role="img">
				<use xlinkHref={`${process.env.PUBLIC_URL}/img/sprite.svg#arrow`}/>
			</svg>
			Назад
		</Link>
	) : (
		<button
			className="Aheader-goback"
			type="button"
			onClick={() => go(state.back)}
		>
			<svg role="img">
				<use xlinkHref={`${process.env.PUBLIC_URL}/img/sprite.svg#arrow`}/>
			</svg>
			Назад
		</button>
	)
})