import React from 'react'
import PropTypes from 'prop-types'
import { Eye } from 'react-feather'

import { Roles } from 'core/components'
// import { hasRole } from 'core/utils'

import './style.scss'

export const Views = ({ viewsCount }) => {
	// if (arrayRoles) {
	return (
		<Roles isView={true}>
			<div className="views">
				<Eye className="views__svg" />

				<span className="views__text">{viewsCount}</span>
			</div>
		</Roles>
	)
	// }
}

Views.propsTypes = {
	viewsCount: PropTypes.string.isRequired
}

Views.defaultProps = {
	viewsCount: '0'
}