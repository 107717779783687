import {
    GET_ALL_COMPETENCES,
    COMPETENCES_UPDATE,
    COMPETENCES_DELETE,
    COMPETENCES_ADD
} from '../constant'

const DefaultState = []

export default function Competences(state = DefaultState, action) {

    switch (action.type) {
        case GET_ALL_COMPETENCES:
            return [...action.list]

        case COMPETENCES_ADD:
            return [action.competences, ...state]

        case COMPETENCES_UPDATE:
            let updateCompetences = state
            updateCompetences = updateCompetences.map(
                (item) => {
                    if (+item.id === + action.id) {
                        item.name = action.name
                    }
                    return item
                }
            )
            return updateCompetences

        case COMPETENCES_DELETE:
            let deleteCompetences = state
            deleteCompetences = deleteCompetences.filter(
                (item) => {
                    return +item.id !== + action.id
                }
            )
            return deleteCompetences

        default:
            return state
    }
}