import React from 'react';

function renderEmptyItems(count, Item) {
	const items = [];
	for (let i = 0; i < count; i++) {
		items.push(<Item key={i} />);
	}
	return items;
}

export default renderEmptyItems;