import React from 'react'
import './style.scss'

const TableCell = ({
	variant = 'body',
	...other
}) => {
	const Tag = variant === 'head' ? 'th' : 'td'
	const rootClassName = `mpui-table-cell mpui-table-cell-${Tag}`

	return <Tag className={rootClassName} {...other} />
}

export default TableCell
