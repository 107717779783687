import {
	EDUCATION_SET_ID,
	EDUCATION_SET_LIST,
	EDUCATION_ADD_CATEGORY,
	EDUCATION_UPDATE_CATEGORY,
	EDUCATION_REMOVE_CATEGORY,
	EDUCATION_ADD_CHILD_CATEGORY,
	EDUCATION_UPDATE_CHILD_CATEGORY,
	EDUCATION_REMOVE_CHILD_CATEGORY,
	MOVE_UP_EDUCATION_CATEGORY,
	MOVE_DOWN_EDUCATION_CATEGORY,
	MOVE_UP_EDUCATION_CHILD_CATEGORY,
	MOVE_DOWN_EDUCATION_CHILD_CATEGORY,
} from 'constant';

const DefaultState = {
	list: [],
	educationId: null,
};

export default function education(state = DefaultState, action) {
	switch (action.type) {
		case EDUCATION_SET_ID:
			return {
				...state,
				educationId: action.educationId,
			};

		case EDUCATION_SET_LIST:
			return {
				...state,
				list: action.list,
			};

		case EDUCATION_ADD_CATEGORY:
			return {
				...state,
				list: [...state.list, action.newItem],
			};

		case EDUCATION_ADD_CHILD_CATEGORY:
			const newList = state.list.map(category => {
				if (category.id === +action.parentId) {
					category.childrenList = [
						...(!!category?.childrenList?.length ? category.childrenList : []),
						action.newItem,
					];
				}
				return category;
			});

			return {
				...state,
				list: newList,
			};

		case EDUCATION_UPDATE_CATEGORY:
			const updateCategoryList = state.list.map(category => {
				if (category.id === +action.updateItem.id) {
					category.name = action.updateItem.name;
				}
				return category;
			});

			return {
				...state,
				list: updateCategoryList,
			};

		case EDUCATION_UPDATE_CHILD_CATEGORY:
			const updateChildCategoryList = state.list.map(category => {
				if (category.id === +action.updateItem.parentId) {
					category.childrenList.map(item => {
						if (item.id === +action.updateItem.id) {
							item.name = action.updateItem.name;
							item.description = action.updateItem.description;
						}
						return item;
					});
				}
				return category;
			});

			return {
				...state,
				list: updateChildCategoryList,
			};

		case EDUCATION_REMOVE_CATEGORY:
			const removeList = state.list.filter(category => category.id !== +action.deleteItem);
			return {
				...state,
				list: [...removeList],
			};

		case EDUCATION_REMOVE_CHILD_CATEGORY:
			const removeChildList = state.list.map(category => {
				if (category.id === +action.parentId) {
					category.childrenList = category.childrenList.filter(
						item => +item.id !== +action.deleteItem
					);
				}

				return category;
			});

			return {
				...state,
				list: removeChildList,
			};

		case MOVE_UP_EDUCATION_CATEGORY: {
			state.list[action.changeIndex].sort++;
			state.list = state.list.sort((a, b) => {
				if (b.sort !== a.sort) {
					return b.sort - a.sort;
				} else {
					if (a.id < b.id) {
						return -1;
					} else if (a.id > b.id) {
						return 1;
					} else return 0;
				}
			});

			return {
				...state,
				list: [...state.list],
			};
		}

		case MOVE_DOWN_EDUCATION_CATEGORY: {
			state.list[action.changeIndex].sort--;
			state.list = state.list.sort((a, b) => {
				if (b.sort !== a.sort) {
					return b.sort - a.sort;
				} else {
					if (a.id < b.id) {
						return -1;
					} else if (a.id > b.id) {
						return 1;
					} else return 0;
				}
			});

			return {
				...state,
				list: [...state.list],
			};
		}

		case MOVE_UP_EDUCATION_CHILD_CATEGORY: {
			const categoryList = state.list.map(category => {
				if (category.id === +action.parentId) {
					category.childrenList[action.changeIndex].sort++;
					category.childrenList = category.childrenList.sort((a, b) => {
						if (b.sort !== a.sort) {
							return b.sort - a.sort;
						} else {
							if (a.id < b.id) {
								return -1;
							} else if (a.id > b.id) {
								return 1;
							} else return 0;
						}
					});
				}

				return category;
			});

			return {
				...state,
				list: [...categoryList],
			};
		}

		case MOVE_DOWN_EDUCATION_CHILD_CATEGORY: {
			const changeChildCat = state.list.map(category => {
				if (category.id === +action.parentId) {
					category.childrenList[action.changeIndex].sort--;
					category.childrenList = category.childrenList.sort((a, b) => {
						if (b.sort !== a.sort) {
							return b.sort - a.sort;
						} else {
							if (a.id < b.id) {
								return -1;
							} else if (a.id > b.id) {
								return 1;
							} else return 0;
						}
					});
				}

				return category;
			});

			return {
				...state,
				list: [...changeChildCat],
			};
		}

		default:
			return state;
	}
}
