import {
	SET_ACTIVITY_OPEN,
	SET_ACTIVITY_CLOSE,
	SET_ACTIVITY_VIEW,
	CLEAR_ACTIVITY_VIEW,
	SET_ACTIVITY_USERS,
	CLEAR_ACTIVITY_USERS,
	FETCH_CLOSE_ACTIVITY_STOP,
} from '../constant';

const DefaultState = {
	open: null,
	register: null,
	close: [],
	closePage: 0,
	closeCanLoad: true,
	view: null,
	users: null,
};

export default function activity(state = DefaultState, action) {
	switch (action.type) {
		case SET_ACTIVITY_OPEN:
			return {
				...state,
				open: action.activitys,
			};

		case SET_ACTIVITY_CLOSE:
			return {
				...state,
				closePage: action.pageNumber + 1,
				close: [...state.close, ...action.activityList],
			};

		case FETCH_CLOSE_ACTIVITY_STOP:
			return {
				...state,
				closeCanLoad: false,
			};

		case SET_ACTIVITY_VIEW:
			return {
				...state,
				view: action.activity,
			};

		case CLEAR_ACTIVITY_VIEW:
			return {
				...state,
				view: null,
			};

		case SET_ACTIVITY_USERS:
			return {
				...state,
				users: action.users,
			};

		case CLEAR_ACTIVITY_USERS:
			return {
				...state,
				users: null,
			};

		default:
			return state;
	}
}
