import { api } from 'rest/api';
import { IFileSave, ICreate, IListRequestData, IComplete } from './types';

function createError(error: any, defaultValue: any = null) {
	return {
		responseCode: 1,
		errorMessage: error,
		response: defaultValue,
	};
}

export const fileSave = async (data: IFileSave) => {
	try {
		const response = await api.post(`/file/save`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const groupMessageCreate = async (data: ICreate) => {
	try {
		const response = await api.post(`/services/group/message/create`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const checkPermission = async (groupId: number) => {
	try {
		const response = await api.get(
			`/services/group/message/check/permission?groupId=${groupId}`
		);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const checkAplicationsPermission = async (groupId: number) => {
	try {
		const response = await api.get(
			`/services/group/message/check/admin/permission?groupId=${groupId}`
		);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getMessageList = async (data: IListRequestData) => {
	try {
		const response = await api.get(`/services/group/message/list-for-manager`, {
			params: data,
		});
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getSubjectsList = async (groupId: number) => {
	try {
		const response = await api.get(`/groups/subjects/list?groupId=${groupId}`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const getMessage = async (id: number) => {
	try {
		const response = await api.get(`/user-claims/get?id=${id}`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const messageComplete = async (data: IComplete) => {
	try {
		const response = await api.post(`/services/group/message/complete`, data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const messageToWork = async (claimId: number) => {
	try {
		const response = await api.post(`/services/group/message/to-work`, { claimId });
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const resolutionTypes = async (groupId: number) => {
	try {
		const response = await api.get(
			`/services/group/message/resolution-type/list?groupId=${groupId}`
		);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};
