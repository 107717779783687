import { SET_RME_ID, SET_FAQ_HELP_ID } from 'constant';

const DefaultState = {
	rmeId: null,
};

export default function options(state = DefaultState.rmeId, action) {
	switch (action.type) {
		case SET_RME_ID:
			return {
				...state,
				rmeId: action.id,
			};
		case SET_FAQ_HELP_ID:
			return {
				...state,
				faqHelpId: action.id,
			};
		default:
			return state;
	}
}
