import { SET_THANKS, THANKS_GET_COMPETENCES } from '../constant';

const DefaultState = {
	thanks: null,
	competences: null,
};

const thanks = (state = DefaultState, action) => {
	switch (action.type) {
		case SET_THANKS:
			return {
				...state,
				thanks: action.thanks,
			};

		case THANKS_GET_COMPETENCES:
			return {
				...state,
				competences: action.competences,
			};

		default:
			return state;
	}
};

export default thanks;
