import React from 'react';
import './style.scss';

export const Logo = () => {
	return (
		<a href="/" className="header-logo">
			<svg role="img">
				<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#maxi-logo'}></use>
				{/* <use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#new-year-logo'}></use> */}
			</svg>
		</a>
	);
};
