import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.scss';

export const HeaderLink = ({ url, children, onClick, className = '', id = '' }) => {
	if (url === null) {
		return (
			<span id={id} onClick={onClick} className={`menu-link ${className}`}>
				{children}
			</span>
		);
	} else {
		return (
			<Link id={id} onClick={onClick} className={`menu-link ${className}`} to={`${url}`}>
				{children}
			</Link>
		);
	}
};

HeaderLink.propTypes = {
	url: PropTypes.string,
};

HeaderLink.defaultProps = {
	url: null,
};
