import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { EditModeSwitch } from 'core/components';
import { FixedSidebar } from 'components/fixedSidebar';

import { CitySelect, ProfessionSelect } from '../../molecules';
import { TextFilter } from '../../atoms';

import { useIsMobile } from 'hooks/useIsMobile';

import { updateFilterParams } from 'action/vacancies';

import { Roles } from 'core/components';
import { hasRole } from 'core/utils';

import './style.scss';

const Filters = props => {
	const { updateFilterParams } = props;
	const arrayRoles = props.shortUserInfo.roles;
	const isMobile = useIsMobile();
	const { search: query } = useLocation();
	const params = new URLSearchParams(query);
	const [mine, setMain] = useState(params.get('onlyMy') === 'true');

	const mineHandle = () => {
		setMain(!mine);
		updateFilterParams({
			onlyMy: !mine,
			page: 0,
		});
	};

	const archiveFilter = state => {
		updateFilterParams({
			state: state,
			page: 0,
		});
	};

	const resetFilters = state => {
		updateFilterParams({
			cityId: 1,
			level: '',
			state: state,
		});
	};

	const renderFiltersHelper = () => {
		return (
			<>
				<CitySelect vacanciesCities={props.vacanciesCities} />

				<ProfessionSelect />

				{arrayRoles !== undefined && props.vacanciesListParams !== null && (
					<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_VACANCY_CLAIM')}>
						<TextFilter
							img="archive"
							text={
								props.vacanciesListParams.state === 'ARCHIVE'
									? 'Покинуть архив'
									: 'Архив вакансий'
							}
							id={props.vacanciesListParams.state}
							isActive={props.vacanciesListParams.state === 'ARCHIVE' ? true : false}
							onClickHandler={archiveFilter}
						/>
					</Roles>
				)}

				{!!arrayRoles && (
					<Roles isView={hasRole(arrayRoles, 'ROLE_EDIT_VACANCY_CLAIM')}>
						<EditModeSwitch
							text="Мои вакансии"
							editModeOn={mine}
							switchEditModeHandler={mineHandle}
							name="mine"
						/>
					</Roles>
				)}

				<TextFilter
					img="cross"
					text="Сбросить фильтры"
					className="blur"
					id="reset"
					onClickHandler={resetFilters}
				/>
			</>
		);
	};

	return !isMobile ? (
		<FixedSidebar>{renderFiltersHelper()}</FixedSidebar>
	) : (
		<div className="vacancies-filters">{renderFiltersHelper()}</div>
	);
};

const mapStateToProps = state => {
	return {
		shortUserInfo: state.shortUserInfo,
		vacanciesListParams: state.vacancies.vacanciesListParams,
		vacanciesCities: state.vacancies.vacanciesCities,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateFilterParams: params => {
			dispatch(updateFilterParams(params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Filters);
