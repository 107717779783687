import { SET_APPLICATION, SET_APPLICATIONS } from '../constant';

const defaultValues = {
	application: null,
	applications: null,
};

export default function hotLine(state = defaultValues, action) {
	switch (action.type) {
		case SET_APPLICATION:
			return {
				...state,
				application: action.value,
			};

		case SET_APPLICATIONS:
			return {
				...state,
				applications: action.value,
			};

		default:
			return state;
	}
}
