import React, { FC } from 'react';
import { Button, TypeButton } from '@maxi-innovation/ui-kit-frontend';

import { ModalComponent } from 'core/components';

import './style.scss';

interface IProps {
	close: () => void;
	toArchive: (id: number) => void;
	id: number;
	title: string;
}

export const ModalConfirm: FC<IProps> = ({ close, toArchive, id, title }) => {
	const acceptHandler = () => {
		toArchive(id);
		close();
	};

	return (
		<ModalComponent onClose={close} className="survey-to-archive">
			<p className="survey-to-archive__title">Перенесение опроса в архив</p>
			<p className="survey-to-archive__text">
				Вы уверены, что хотите перенести опрос "{title.trim()}" в архив?
			</p>

			<div className="survey-to-archive__wrap">
				<Button
					className="survey-to-archive__button"
					variant={TypeButton.PRIMARY}
					onClick={acceptHandler}
				>
					Подтвердить
				</Button>
				<Button
					className="survey-to-archive__button"
					variant={TypeButton.SECONDARY}
					onClick={close}
				>
					Отменить
				</Button>
			</div>
		</ModalComponent>
	);
};
