import React from 'react'

import { textColor } from '../../utils'
import './style.scss'

export const GlossaryItem = ({ name, description, fillText }) => {
	return (
		<div className="block-item block-item_glossary">
			<span>{textColor(name, fillText)}</span>
			<span> - {textColor(description, fillText)}</span>
		</div>
	)
}