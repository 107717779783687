import {
	SET_CORPORATE_VALUES_LIST,
	ADD_CORPORATE_VALUES_LINK,
	UPDATE_CORPORATE_VALUES_LINK,
	DELETE_CORPORATE_VALUES_LINK,
} from '../constant';

const defaultValue = [];

export default function corpValuesState(state = defaultValue, action) {
	switch (action.type) {
		case SET_CORPORATE_VALUES_LIST:
			return action.corpValues;

		case ADD_CORPORATE_VALUES_LINK:
			const addLink = state;
			addLink.forEach(item => {
				if (item.id === action.id) item.links.push(action.link);
			});

			return [...addLink];

		case UPDATE_CORPORATE_VALUES_LINK:
			const updateLink = state;
			updateLink.forEach(item => {
				if (item.id === action.id) {
					item.links.forEach(el => {
						if (el.id === action.linkId) {
							if (action.title) el.title = action.title;
							if (action.link) el.link = action.link;
						}
					});
				}
			});
			return [...updateLink];

		case DELETE_CORPORATE_VALUES_LINK:
			const deleteLink = state;
			deleteLink.forEach(item => {
				if (item.id === action.id) {
					item.links = item.links.filter(el => el.id !== action.linkId);
				}
			});
			return [...deleteLink];

		default:
			return state;
	}
}
