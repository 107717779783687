import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { Button } from 'core/components';

import Notification from 'modules/notification';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { Text, HeaderWrapp } from '../../atoms/header';

import { FilesBlock } from 'modules/guide/molecules';
import { EditArticleModal } from 'modules/guide/organisms';

import { createGuideArticle } from 'action/guide';

import { Editor } from 'components/editorjsText';

import { NotificationHelper } from 'action/notificationHelper';

import { FinderFieldMultiple } from 'components/finderFieldMultiple';
import FindItemBlock from 'core/components/findItemBlock';

export const GuideArticleCreate = props => {
	const [name, setName] = useState('');
	const [newContent, setNewContent] = useState('');
	const [articleFilesIds, setArticleFilesIds] = useState([]);
	const [rolesList, setRolesList] = useState([]);
	const [tagsList, setTagsList] = useState([]);
	const [isModalCreateOpened, setIsModalCreateOpened] = useState(false);

	const dispatch = useDispatch();

	const filesArrayHandler = files => {
		const filesIds = [];
		files.forEach(item => {
			filesIds.push(+item.id);
		});
		setArticleFilesIds(filesIds);
	};

	const rolesArrayHandler = useCallback(roles => {
		const rolesIds = [];
		roles.forEach(item => {
			rolesIds.push(+item.id);
		});
		setRolesList(rolesIds);
	}, []);

	const tagsArrayHandler = list => {
		setTagsList(list);
	};

	const nameHandler = e => {
		e.currentTarget.classList.remove('validation-error');
		setName(e.currentTarget.value);
	};

	const toggleEditModalHanler = () => {
		if (rolesList.length > 0) {
			setIsModalCreateOpened(!isModalCreateOpened);
		} else {
			createDocumentHandler(false);
		}
	};

	const createDocumentHandler = flag => {
		const { history, match } = props;
		const idsArray = match.params.id.split('/');
		const categoryId = idsArray[idsArray.length - 1];
		const pathToParent = match.params.id;
		if (newContent) {
			dispatch(
				createGuideArticle(
					{
						name,
						categoryId: categoryId,
						roleIds: rolesList,
						fileIds: articleFilesIds,
						tags: tagsList,
						flagToEmail: flag,
						content: newContent,
					},
					history.push,
					pathToParent
				)
			);
		} else {
			dispatch(NotificationHelper('Необходимо указать содержание и название статьи', 1));
		}
	};

	return (
		<>
			{isModalCreateOpened && (
				<EditArticleModal
					isOpened={isModalCreateOpened}
					closeModal={toggleEditModalHanler}
					title="Создание документа"
					text={`Вы создали документ <b>${name}</b>. Уведомить об этом пользователей?`}
					answerHandler={createDocumentHandler}
				/>
			)}
			<Helmet title="Создание документа" />

			<Notification />

			<div className="Article-create">
				<Header>
					<HeaderWrapp>
						<Text type="bold">Создание документа</Text>
						<div className="Aheader-block">
							<Button
								type="primary"
								text="Опубликовать"
								handler={toggleEditModalHanler}
								image={null}
							/>
							<Link
								to={`/knowledge/guide/${props.match.params.id}`}
								className="Aheader-btn Aheader-btn_special"
							>
								Отменить
							</Link>
						</div>
					</HeaderWrapp>
				</Header>
				<Wrapp>
					<div className="article-form article-form_create">
						<input
							type="text"
							className="article-name"
							onChange={nameHandler}
							placeholder="Название документа"
							value={name}
						/>
					</div>

					<Editor getContent={setNewContent} />

					<FilesBlock arrayHandler={filesArrayHandler} />

					<div className="article-create__find-item role">
						<FinderFieldMultiple
							searchType="WIKI_ROLE"
							label="Роли"
							placeholder="Добавить роль"
							onSelected={rolesArrayHandler}
							description="Выберите роли пользователей, которых нужно уведомлять об изменениях в документе"
						/>
					</div>

					<div className="article-create__find-item">
						<FindItemBlock
							type="tag"
							label="Теги"
							placeholder="Добавить тег"
							arrayHandler={tagsArrayHandler}
							hint="Выберите существующие или введите свои теги для поиска"
						/>
					</div>
				</Wrapp>
			</div>
		</>
	);
};
