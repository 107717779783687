import {
	SET_SHORT_USER_INFO,
	SET_USERS_NOTIFICATIONS,
	VIEW_NOTIFICATION,
	VIEW_ALL_NOTIFICATION,
	UPDATE_USER_IMAGE,
	UPDATE_NTF_COUNT,
} from 'constant';

const DefaultState = {};

export default function shortUserInfo(state = DefaultState, action) {
	switch (action.type) {
		case SET_SHORT_USER_INFO:
			return action.user;

		case SET_USERS_NOTIFICATIONS:
			let setNtfcs = state;
			setNtfcs.countNotifications = action.count;
			return setNtfcs;

		case VIEW_NOTIFICATION:
			let userNtf = state;
			userNtf.countNotifications = userNtf.countNotifications - 1;
			return userNtf;

		case VIEW_ALL_NOTIFICATION:
			let userNtfss = state;
			userNtfss.countNotifications = 0;
			return userNtfss;

		case UPDATE_USER_IMAGE:
			let updateImage = state;
			updateImage.avatarFile = action.image;
			return updateImage;

		case UPDATE_NTF_COUNT:
			let ntfState = state;
			ntfState.countNotifications = action.count;
			return ntfState;

		default:
			return state;
	}
}
