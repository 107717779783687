import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationHelper } from 'action/notificationHelper';
import {
	getSearchResult,
	getSearchResultForPost,
	getSearchResultTag,
} from 'components/finderField/shared/api';
import { IResult, ISearchData } from './types';
import { ISearchPost } from 'components/finderField/shared/api/types';

export const useGetSearchResult = () => {
	const dispatch = useDispatch();
	const [resultList, setResultList] = useState<IResult[]>([]);

	const multiSearchTrigger = async (data: ISearchData) => {
		if (!data.indexNames?.length) return;
		let resultList: IResult[] = [];

		for (var i = 0; i < data.indexNames.length; i++) {
			const { response, responseCode, errorMessage } = await getSearchResult({
				query: data.query,
				quick: data.quick,
				withoutMe: data.withoutMe,
				indexName: data.indexNames[i],
				...(data.limit && { limit: data.limit }),
			});

			if (responseCode === 0) {
				resultList = [...resultList, ...response];
			} else {
				dispatch(NotificationHelper(errorMessage, 1));
			}
		}

		setResultList(resultList);
	};

	const searchTrigger = async (data: ISearchData) => {
		const { response, responseCode, errorMessage } = await getSearchResult({
			query: data.query,
			quick: data.quick,
			withoutMe: data.withoutMe,
			indexName: data.indexName,
			...(data.limit && { limit: data.limit }),
		});

		if (responseCode === 0) {
			setResultList(response);
		} else {
			dispatch(NotificationHelper(errorMessage, 1));
		}
	};

	const searchPostTrigger = async (data: ISearchPost) => {
		const { response, responseCode, errorMessage } = await getSearchResultForPost(data);

		if (responseCode === 0) {
			setResultList([
				{
					indexName: 'POST',
					objects: response.map((item: any) => ({ name: item.title, ...item })),
				},
			]);
		} else {
			dispatch(NotificationHelper(errorMessage, 1));
		}
	};

	const searchTagTrigger = async (data: ISearchData) => {
		const { response, responseCode, errorMessage } = await getSearchResultTag(data);

		if (responseCode === 0) {
			setResultList([
				{
					indexName: 'TAG',
					objects: [...response?.values],
				},
			]);
		} else {
			dispatch(NotificationHelper(errorMessage, 1));
		}
	};

	const clearResultList = () => setResultList([]);

	return {
		searchTrigger,
		resultList,
		clearResultList,
		searchPostTrigger,
		searchTagTrigger,
		multiSearchTrigger,
	};
};
