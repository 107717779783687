import React, { Component } from 'react';
import axios from 'axios';
import { URL } from 'constant';

import { AddFileInput, FileItem } from 'modules/guide/atoms';

import './style.scss';
import { getAccessToken } from 'plugins/token';

export class FilesBlock extends Component {
	state = {
		articleFiles: [],
		fileItem: null,
	};

	api = axios.create({
		baseURL: URL,
		crossDomain: true,
		mode: 'cors',
		withCredentials: true,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getAccessToken()}`,
		},
		onUploadProgress: progressEvent => {
			this.setState({
				max: progressEvent.total,
				value: progressEvent.loaded,
			});
			if (progressEvent.total === progressEvent.loaded) {
				this.setState({ isView: false });
			}
		},
	});

	render() {
		let { articleFiles } = this.state;
		return (
			<div className="files-block">
				<AddFileInput id="documents" onChange={this.filesHandler} />

				{articleFiles.length > 0 &&
					articleFiles.map((item, index) => {
						return (
							<FileItem
								key={index}
								file={item}
								index={index}
								deleteHandler={this.deleteFile}
							/>
						);
					})}
			</div>
		);
	}

	filesHandler = e => {
		e.preventDefault();
		let files = [...e.currentTarget.files];
		files.map(file => {
			let reader = new FileReader();
			let context = this;
			reader.onload = () => {
				this.api
					.post('/file/save/', {
						originalFileName: file.name,
						base64File: reader.result.slice(reader.result.indexOf(',') + 1),
						fileType: 'WIKI_DOCUMENT',
					})
					.then(response => {
						if (response.data.responseCode === 0) {
							context.setState({
								fileItem: response.data.response,
							});
							context.addFileList(response.data.response);
						}
					})
					.catch(error => {
						console.log(error);
					});
			};
			reader.readAsDataURL(file);
			return null;
		});
	};

	deleteFile = e => {
		e.preventDefault();
		let { articleFiles } = this.state;
		let indexFile = e.currentTarget.dataset.index;
		let newDocuments = articleFiles.filter((item, index) => {
			return +index !== +indexFile;
		});
		this.setState(
			{
				articleFiles: [...newDocuments],
			},
			e => this.props.arrayHandler(this.state.articleFiles)
		);
	};

	addFileList = fileItem => {
		this.setState(
			{
				articleFiles: [...this.state.articleFiles, fileItem],
			},
			e => this.props.arrayHandler(this.state.articleFiles)
		);
	};

	componentDidMount() {
		let { filesList, arrayHandler } = this.props;
		if (filesList && arrayHandler) {
			this.setState(
				{
					articleFiles: [...filesList, ...this.state.articleFiles],
				},
				e => arrayHandler(this.state.articleFiles)
			);
		}
	}
}
