import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { URL } from 'constant';
import { Star } from 'react-feather';

import { api } from 'rest/api';
import { NotificationHelper } from 'action/notificationHelper';

import { Roles, Avatar } from 'core/components';
import { hasRole } from 'core/utils';

import Notification from 'modules/notification';

import { default as SuggestCorrectionBtn } from 'components/suggestCorrection';

import { Header } from '../../organisms/header';
import { Wrapp } from '../../atoms/mainWrapp';
import { HeaderWrapp, GoBack, Title } from '../../atoms/header';

import { ArticleLoader } from '../../organisms/loader';

import { FileItem } from 'modules/guide/atoms';

import { allCategoriesList } from 'action/guide';

import { updateFavoriteState, checkIsFavorite } from 'rest/favorites';

import { ViewEditor } from 'components/editorjsText/editorHtml';
import { useIsMobile } from 'hooks/useIsMobile';
import { useGetIndexName } from 'hooks/useGetIndexName';

export const GuideArticleView = props => {
	const fromIndex = useGetIndexName('fromIndex');
	const isMobile = useIsMobile();
	const { roles, categoriesList } = useSelector(state => ({
		roles: state.shortUserInfo?.roles,
		categoriesList: state.guide.categoriesList,
	}));

	const [parentCategoryId, setParentCategoryId] = useState(null);
	const [article, setArticle] = useState(null);
	const [isFavorite, setIsFavorite] = useState(false);

	const dispatch = useDispatch();

	const fetchArticle = useCallback(async () => {
		const articleId = props.match.params.articleId.split('?')[0];

		const { redirect } = props.history.push;
		const parentId = props.match.params.id;
		const res = await api.get(`/wiki/guide/get`, {
			params: {
				id: articleId,
				...(fromIndex ? { fromIndex: fromIndex } : {}),
			},
		});
		if (res.data.responseCode === 0) {
			let newContent = {
				...res.data.response,
			};
			setArticle(newContent);
		} else {
			if (
				!res.data.errorMessage.indexOf(
					`Не найдена запись гида с идентификатором ${articleId}`
				)
			) {
				setArticle(-1);
			} else {
				dispatch(NotificationHelper(res.data.errorMessage, 1));
				redirect(`/knowledge/guide/${parentId}`);
			}
		}
	}, [
		dispatch,
		fromIndex,
		props.match.params.articleId,
		props.history.push,
		props.match.params.id,
	]);

	useEffect(() => {
		fetchArticle();
		window.scrollTo(0, 0);
	}, [fetchArticle]);

	useEffect(() => {
		let idsArray = props.match.params.id.split('/');
		setParentCategoryId(idsArray[idsArray.length - 1]);

		if (!categoriesList) {
			dispatch(allCategoriesList());
		}
	}, [categoriesList, dispatch, props.match.params.id]);

	const changeIsFavoriteStatus = async e => {
		e.preventDefault();
		const res = await updateFavoriteState({
			type: 'WIKI_GUIDE',
			objectId: +e.currentTarget.dataset.id,
			favorite: !isFavorite,
		});
		if (res.data.responseCode === 0) {
			setIsFavorite(!isFavorite);
			dispatch(
				NotificationHelper(
					isFavorite ? 'Удалено из Избранного' : 'Добавлено в Избранное',
					0
				)
			);
		} else {
			dispatch(NotificationHelper(res.data.errorMessage, 1));
		}
	};

	const checkIsFavoriteHandler = useCallback(async () => {
		const articleId = props.match.params.articleId.split('?')[0];

		const res = await checkIsFavorite({
			type: 'WIKI_GUIDE',
			objectId: +articleId,
		});
		if (res.data.responseCode === 0) {
			setIsFavorite(res.data.response);
		} else {
			dispatch(NotificationHelper('Ошибка проверки статуса Избранного', 1));
		}
	}, [props.match.params.articleId, dispatch]);

	useEffect(() => {
		checkIsFavoriteHandler();
	}, [checkIsFavoriteHandler]);

	const getFirstCategory = category => {
		if (category.parent) {
			return getFirstCategory(category.parent);
		}
		return (
			<Link to={`/knowledge/guide/${category.id}`} className="article-breadcrumbs__link">
				{category.name}
			</Link>
		);
	};

	const renderBreadcrumbs = article => {
		return (
			<div className="article-breadcrumbs">
				{getFirstCategory(article.category)}

				{!!article.category?.parent?.parent && (
					<>
						<svg role="img" className="article-breadcrumbs__seporator">
							<use
								xlinkHref={
									process.env.PUBLIC_URL + '/img/sprite.svg#bold-mini-arrow'
								}
							></use>
						</svg>
						<Link
							to={`/knowledge/guide/${article.category.parent.id}`}
							className="article-breadcrumbs__link"
						>
							...
						</Link>
					</>
				)}

				{!!article.category?.parent && (
					<>
						<svg role="img" className="article-breadcrumbs__seporator">
							<use
								xlinkHref={
									process.env.PUBLIC_URL + '/img/sprite.svg#bold-mini-arrow'
								}
							></use>
						</svg>
						<Link
							to={`/knowledge/guide/${article.category.id}`}
							className="article-breadcrumbs__link"
						>
							{article.category.name}
						</Link>
					</>
				)}
			</div>
		);
	};

	if (article) {
		if (article === -1) {
			return (
				<>
					<Header>
						<HeaderWrapp>
							<Link className="Aheader-goback" to="/knowledge/guide">
								<svg role="img">
									<use
										xlinkHref={`${process.env.PUBLIC_URL}/img/sprite.svg#arrow`}
									/>
								</svg>
								В гид по компании
							</Link>
						</HeaderWrapp>
					</Header>
					<div className="Article-view__no-items_wrapper">
						<div className="Article-view__no-items">
							<svg role="img">
								<use
									xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#no-items'}
								/>
							</svg>
							<p>Статья была удалена, либо еще не создана</p>
						</div>
					</div>
				</>
			);
		} else if (article.canRead) {
			return (
				<>
					<Helmet title={`${article.wiki.name}`} />

					<Notification />

					<div className="Article-view">
						<Header>
							<HeaderWrapp>
								<GoBack to={`/knowledge/guide/${props.match.params.id}`} />
								<Title>{article.wiki.name}</Title>
								<p className="article-title">{article.wiki.name}</p>
							</HeaderWrapp>
							{!isMobile && <HeaderWrapp>{renderBreadcrumbs(article)}</HeaderWrapp>}
						</Header>
						<Wrapp>
							<SuggestCorrectionBtn
								type="guide"
								sourceURL={window.location.href}
								wikiGuideCategoryId={parentCategoryId}
							/>

							<div className="Article-author">
								<Link
									to={`/user/${article.wiki.lastUpdateUser.id}`}
									className="Article-author__link"
								>
									<Avatar
										type="user"
										size="small"
										image={
											article?.wiki?.lastUpdateUser?.avatarFile?.filePath
												? `${URL}${article.wiki.lastUpdateUser.avatarFile.filePath}`
												: null
										}
									/>

									<div className="Article-author__link-text">
										<b>{`${article.wiki.lastUpdateUser.surname} ${article.wiki.lastUpdateUser.name}`}</b>
										<span>{article.publishLastDateUpdate}</span>
									</div>
								</Link>

								{Boolean(roles) && (
									<Roles
										isView={
											article.canEdit ||
											hasRole(roles, 'ROLE_UPDATE_WIKI_GUIDE_TOP_CATEGORY')
										}
									>
										<Link
											className="article-edit-link"
											to={`/knowledge/guide/${props.match.params.id}/article/${article.id}/edit`}
										>
											<svg role="img">
												<use
													xlinkHref={`${process.env.PUBLIC_URL}/img/sprite.svg#pen`}
												/>
											</svg>
											<span>Редактировать</span>
										</Link>
									</Roles>
								)}
							</div>

							<div className="view-header">
								<h1>{`${article.wiki.name}`}</h1>

								<Star
									className={
										isFavorite ? 'view-header__svg active' : 'view-header__svg'
									}
									data-id={article.id}
									onClick={changeIsFavoriteStatus}
								/>
							</div>

							{article.content && <ViewEditor content={article.content} />}

							{article.files.length > 0 && (
								<div className="Article-view__files-block">
									{article.files.map((item, index) => {
										return <FileItem key={index} file={item} />;
									})}
								</div>
							)}

							{article.tags.length > 0 && (
								<div className="Article-view__tags-block">
									{article.tags.map((item, index) => {
										return <span key={index}>{`${item}`}</span>;
									})}
								</div>
							)}

							<Link
								to={`/knowledge/guide/${props.match.params.id}`}
								className="Article-linkToAll"
							>
								<span>Читать ещё</span>
								<svg role="img">
									<use
										xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}
									></use>
								</svg>
							</Link>
						</Wrapp>
					</div>
				</>
			);
		} else {
			return (
				<>
					<Notification />

					<div className="Article-view">
						<Header>
							<HeaderWrapp>
								<GoBack to={`/knowledge/guide/${props.match.params.id}`} />
								<Title>{article.wiki.name}</Title>
								<p className="article-title">{article.wiki.name}</p>
							</HeaderWrapp>
						</Header>
						<Wrapp>
							<div>Недостаточно прав на просмотр данной статьи</div>
						</Wrapp>
					</div>
				</>
			);
		}
	} else {
		return (
			<>
				<div className="Article-view">
					<Header>
						<HeaderWrapp />
					</Header>
					<Wrapp>
						<ArticleLoader />
					</Wrapp>
				</div>
			</>
		);
	}
};
