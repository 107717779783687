import * as yup from 'yup';

export const ValidationSchema = yup.object().shape({
	question: yup.string().required(),
	startDate: yup.number().required(),
	endDate: yup.number().required(),
	anonymous: yup.boolean().required(),
	users: yup.array().of(yup.number()),
	positions: yup.array().of(yup.number()),
	departments: yup.array().of(yup.number()),

	// users: yup.array().when(['positions', 'departments'], {
	// 	is: (positions, departments) => !positions?.length && !departments?.length,
	// 	then: yup.array().of(yup.number()).required('Заполните хотя бы одно поле'),
	// 	otherwise: yup.array().of(yup.number()),
	// }),

	// positions: yup.array().when(['users', 'departments'], {
	// 	is: (users, departments) => !users?.length && !departments?.length,
	// 	then: yup.array().of(yup.number()).required('Заполните хотя бы одно поле'),
	// 	otherwise: yup.array().of(yup.number()),
	// }),

	// departments: yup.array().when(['positions', 'users'], {
	// 	is: (positions, users) => !positions?.length && !users?.length,
	// 	then: yup.array().of(yup.number()).required('Заполните хотя бы одно поле'),
	// 	otherwise: yup.array().of(yup.number()),
	// }),
});
