import {
	GUIDE_EDIT_MODE,
	GUIDE_EDIT_MODE_ON,
	GET_TOP_CATEGORIES_LIST,
	GET_CHILD_CATEGORIES_LIST,
	SET_GUIDE_CATEGORY_INFO,
	CLEAR_GUIDE_CATEGORY_INFO,
	DELETE_GUIDE_CATEGORY,
	GET_GUIDE_CATEGORY_CHILDREN,
	CLEAR_GUIDE_CHILDREN_LIST,
	GET_CATEGORY_ARTICLES_LIST,
	GET_GUIDE_ARTICLE_INFO,
	CLEAR_GUIDE_ARTICLE_INFO,
	UPDATE_GUIDE_ARTICLE_INFO,
	SHOW_ACCESS_LIST,
	SORT_ARTICLE_LIST,
	CLEAR_GUIDE_ARTICLE_LIST,
	SET_PARENT_CATEGORY,
} from 'constant';

import { NotificationHelper } from './notificationHelper';

import {
	categoriesList,
	createTopCategory,
	getTopCategory,
	editTopCategory,
	createChildCategory,
	getChildCategory,
	editChildCategory,
	deleteCategory,
	getArticlesList,
	createArticle,
	getArticleInfo,
	editArticle,
	articleToArchive,
	sortArticleList,
	createFirstChildren,
	updateFirstChildren,
	getChildCategoriesList,
	getTopCategoriesList,
} from 'rest/guide';

export const guideEditModeToggle = () => {
	return {
		type: GUIDE_EDIT_MODE,
	};
};

export const guideEditModeOn = () => {
	return {
		type: GUIDE_EDIT_MODE_ON,
	};
};

export const allCategoriesList = () => {
	return dispatch => {
		return categoriesList()
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_TOP_CATEGORIES_LIST,
						list: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка всех разделов', 1));
			});
	};
};

export const createGuideTopCategory = data => {
	return dispatch => {
		return createTopCategory(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Раздел добавлен', 0));
					window.location = `/knowledge/guide/${res.data.response.id}`;
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления раздела', 1));
			});
	};
};

export const getTopCategoryInfo = id => {
	return dispatch => {
		return getTopCategory(id)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SET_GUIDE_CATEGORY_INFO,
						category: res.data.response,
					});
					if (
						res.data.response.departments.length > 0 ||
						res.data.response.users.length > 0 ||
						res.data.response.roles.length > 0
					) {
						dispatch({
							type: SHOW_ACCESS_LIST,
							status: true,
						});
					} else {
						dispatch({
							type: SHOW_ACCESS_LIST,
							status: false,
						});
					}
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(err => {
				dispatch(NotificationHelper(`Ошибка получения информации о разделе - ${err}`, 1));
			});
	};
};

export const clearCategotyInfo = () => {
	return {
		type: CLEAR_GUIDE_CATEGORY_INFO,
	};
};

export const setParentCategory = category => {
	return dispatch => {
		dispatch({
			type: SET_PARENT_CATEGORY,
			category,
		});
	};
};

export const editGuideTopCategory = data => {
	return dispatch => {
		return editTopCategory(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Информация о разделе обновлена', 0));
					window.location = `/knowledge/guide/${data.id}`;
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка обновления информации', 1));
			});
	};
};

export const toggleShowAccessList = status => {
	return {
		type: SHOW_ACCESS_LIST,
		status: status,
	};
};

export const createGuideFirstChildCategory = data => {
	return dispatch => {
		return createFirstChildren(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Подраздел добавлен', 0));
					window.location = `/knowledge/guide/${res.data.response.id}`;
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления подраздела', 1));
			});
	};
};

export const createGuideChildCategory = data => {
	return dispatch => {
		return createChildCategory(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Подраздел добавлен', 0));
					window.location = `/knowledge/guide/${res.data.response.id}`;
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления подраздела', 1));
			});
	};
};

export const getChildCategoryInfo = id => {
	return dispatch => {
		return getChildCategory(id)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SET_GUIDE_CATEGORY_INFO,
						category: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения информации о подразделе', 1));
			});
	};
};

export const editGuideFirstChildCategory = data => {
	return dispatch => {
		return updateFirstChildren(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Информация о подразделе обновлена', 0));
					window.location = `/knowledge/guide/${res.data.response.id}`;
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка обновления информации', 1));
			});
	};
};

export const editGuideChildCategory = data => {
	return dispatch => {
		return editChildCategory(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Информация о подразделе обновлена', 0));
					window.location = `/knowledge/guide/${res.data.response.id}`;
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка обновления информации', 1));
			});
	};
};

export const deleteGuideCategory = data => {
	return dispatch => {
		return deleteCategory(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: DELETE_GUIDE_CATEGORY,
						id: data.id,
					});
					dispatch(NotificationHelper('Раздел удален', 0));
					data.redirectToCategory(data.redirectPath || '/knowledge/guide');
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка удаления раздела', 1));
			});
	};
};

export const getGuideTopCategory = () => {
	return async dispatch => {
		const { response, responseCode, errorMessage } = await getTopCategoriesList();

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, 1));
		} else {
			dispatch({
				type: GET_TOP_CATEGORIES_LIST,
				list: response,
			});
		}
	};
};

export const getGuideCategoryChildren = parentId => {
	return async dispatch => {
		const { response, responseCode, errorMessage } = await getChildCategoriesList(parentId);

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, 1));
		} else {
			dispatch({
				type: GET_GUIDE_CATEGORY_CHILDREN,
				childrenList: response,
			});
		}
	};
};

export const clearChildrenList = () => {
	return {
		type: CLEAR_GUIDE_CHILDREN_LIST,
	};
};

export const getCategoryArticlesList = categoryId => {
	return dispatch => {
		return getArticlesList(categoryId)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_CATEGORY_ARTICLES_LIST,
						articlesList: res.data.response,
					});
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения списка статей раздела', 1));
			});
	};
};

export const sortArticles = data => {
	return dispatch => {
		return sortArticleList({ sortIdList: data.sortIdList })
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: SORT_ARTICLE_LIST,
						dragIndex: data.dragIndex,
						dropIndex: data.dropIndex,
					});
					dispatch(NotificationHelper('Изменения сохранены', 0));
				} else {
					dispatch(NotificationHelper('Ошибка сортировки записей', 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка сортировки записей', 1));
			});
	};
};

export const createGuideArticle = (data, redirect, pathToParent) => {
	return dispatch => {
		return createArticle(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Статья добавлена', 0));
					redirect(`/knowledge/guide/${pathToParent}/article/${res.data.response.id}`);
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка добавления статьи', 1));
			});
	};
};

export const getGuideArticleInfo = (id, redirect, parentId) => {
	return dispatch => {
		return getArticleInfo(id)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: GET_GUIDE_ARTICLE_INFO,
						article: res.data.response,
					});
				} else {
					if (
						!res.data.errorMessage.indexOf(
							`Не найдена запись гида с идентификатором ${id}`
						)
					) {
						dispatch({
							type: GET_GUIDE_ARTICLE_INFO,
							article: -1,
						});
					} else {
						dispatch(NotificationHelper(res.data.errorMessage, 1));
						redirect(`/knowledge/guide/${parentId}`);
					}
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка получения информации о статье', 1));
				redirect(`/knowledge/guide/${parentId}`);
			});
	};
};

export const clearGuideArticleInfo = () => {
	return {
		type: CLEAR_GUIDE_ARTICLE_INFO,
	};
};

export const clearGuideArticleList = () => {
	return {
		type: CLEAR_GUIDE_ARTICLE_LIST,
	};
};

export const editGuideArticle = (data, redirect, parentId) => {
	return dispatch => {
		return editArticle(data)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch({
						type: UPDATE_GUIDE_ARTICLE_INFO,
						article: res.data.response,
					});
					dispatch(NotificationHelper('Информация о статье обновлена', 0));
					redirect(`/knowledge/guide/${parentId}/article/${res.data.response.id}`);
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка обновления информации', 1));
			});
	};
};

export const addGuideArticleToArchive = (id, path, redirect) => {
	return dispatch => {
		return articleToArchive(id)
			.then(res => {
				if (res.data.responseCode === 0) {
					dispatch(NotificationHelper('Статья удалена', 0));
					if (path && redirect) {
						redirect(path);
					}
				} else {
					dispatch(NotificationHelper(res.data.errorMessage, 1));
				}
			})
			.catch(() => {
				dispatch(NotificationHelper('Ошибка перенесения статьи в архив', 1));
			});
	};
};

export const topCategoriesList = list => {
	return {
		type: GET_TOP_CATEGORIES_LIST,
		list: list,
	};
};

export const childCategoriesList = (id, list) => {
	return {
		type: GET_CHILD_CATEGORIES_LIST,
		parentId: id,
		childrenList: list,
	};
};
