import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FormInputBlock, FormLabel, Select } from 'core/components';
import { selectStyles } from 'core/utils';
import { IQuestionComponentProps, IOptions } from '../types';
import { SurveyTextField } from '../fields';
import {
	updateQuestionMaxAction,
	updateQuestionMinAction,
	updateQuestionScaleAction,
	updateQuestionTitleAction,
} from 'action/survey';

const options: IOptions[] = [
	{ label: 4, value: 4 },
	{ label: 5, value: 5 },
	{ label: 6, value: 6 },
	{ label: 7, value: 7 },
	{ label: 8, value: 8 },
	{ label: 9, value: 9 },
	{ label: 10, value: 10 },
];

export const ScaleQuestion: FC<IQuestionComponentProps> = ({ questionSchema, questionIndex }) => {
	const dispatch = useDispatch();
	const [option, setOption] = useState<string>();

	const changeHandle = (value: string) => {
		setOption(value);

		questionSchema.max = +value;
		dispatch(updateQuestionScaleAction(questionSchema));
	};

	const onChangeTitleHandler = useCallback(
		(value: string) => {
			questionSchema.title = value;
			dispatch(updateQuestionTitleAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	const onChangeMinHandler = useCallback(
		(value: string) => {
			questionSchema.minLabel = value;
			dispatch(updateQuestionMinAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	const onChangeMaxHandler = useCallback(
		(value: string) => {
			questionSchema.maxLabel = value;
			dispatch(updateQuestionMaxAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	return (
		<div className="survey-question-body">
			<SurveyTextField
				error={!questionSchema.title}
				name={`questions[${questionIndex}].text`}
				label="Вопрос"
				placeholder="Введите текст вопроса"
				onChange={onChangeTitleHandler}
				maxLength={1000}
				defaultValue={questionSchema.title}
			/>

			<FormInputBlock>
				{/* @ts-ignore */}
				<FormLabel children="Шкала от 1 до" />
				<Select
					error={!questionSchema.max}
					label=""
					name={`questions[${questionIndex}].max`}
					placeholder=""
					options={options}
					onChange={changeHandle}
					styles={selectStyles}
					value={option}
					defaultValue={questionSchema.max}
				/>
			</FormInputBlock>

			<SurveyTextField
				name={`questions[${questionIndex}].minLabel`}
				label="Минимальное значение"
				placeholder="Добавьте описание (необязательно)"
				onChange={onChangeMinHandler}
				maxLength={255}
				defaultValue={questionSchema.minLabel}
			/>

			<SurveyTextField
				name={`questions[${questionIndex}].maxLabel`}
				label="Максимальное значение"
				placeholder="Добавьте описание (необязательно)"
				onChange={onChangeMaxHandler}
				maxLength={255}
				defaultValue={questionSchema.maxLabel}
			/>
		</div>
	);
};
