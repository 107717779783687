import { useEffect } from 'react';
import { parseCookies } from 'nookies';

export const useAuthCheck = () => {
	useEffect(() => {
		const { accessToken } = parseCookies();
		if (accessToken) {
			window.location.href = '/';
		}
	}, []);
};
