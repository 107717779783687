import React from 'react'
import { X } from 'react-feather'
import { Link } from 'react-router-dom'

import { URL } from "constant"
import { Avatar, Loader } from 'core/components'

import ModalWrapper from '../../modal'

const Modal = ({onClose, isLoading, users = []}) => {

    const onCloseHandler = () => {
        onClose()
    }
    
    return (
        <ModalWrapper isOpenHandler={onClose}>
            
            <X size="32"  className="like-modal__close" onClick={onCloseHandler}/>

            <div className="like-modal">
                {
                    (isLoading && users.length === 0) ? <div> <Loader /> </div> : 
                    <>
                        <h3 className="like-modal__title">Оценили</h3>
                        <div className="like-modal__users">
                            {
                                users.map(
                                    user => (
                                        <Link
                                            key={user.id}
                                            to={`/user/${user.id}`} 
                                            title={`${user.surname} ${user.name}`}
                                            onClick={onCloseHandler}
                                            className="like-modal__user"
                                        >
                                            <Avatar 
                                                image={user.avatarFile?.filePath ? `${URL}${user.avatarFile?.filePath}` : null}
                                                type="user"
                                            />
                                            <span className="user__name">
                                                {`${user.surname} ${user.name}`}
                                            </span>
                                        </Link>
                                    )
                                )
                            }
                        </div>
                    </>
                }
            </div>
        </ModalWrapper>
    )
}

export default Modal

