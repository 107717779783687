import React, { forwardRef, useEffect, useRef } from 'react';
import VMasker from 'vanilla-masker';
import PropTypes from 'prop-types';

import { TextField } from 'core/components';

export const MaskInput = forwardRef(({ refRegister, maskPattern, onChange, ...other }, ref) => {
	const maskInputRef = ref ? ref : useRef(null);

	useEffect(() => {
		if (!!maskInputRef.current) VMasker(maskInputRef.current).maskPattern(maskPattern);
	}, [maskInputRef, maskPattern]);

	const focusHandle = event => {
		if (event.currentTarget.value === '') event.currentTarget.value = '+7';
		if (onChange) onChange(event);
	};

	const blurHandle = event => {
		if (event.currentTarget.value === '+' || event.currentTarget.value === '+7')
			event.currentTarget.value = '';
		if (onChange) onChange(event);
	};

	return (
		<TextField
			inputRef={e => {
				if (refRegister) refRegister(e, {});
				maskInputRef.current = e;
			}}
			autoComplete="off"
			onFocus={focusHandle}
			onBlur={blurHandle}
			onChange={onChange}
			{...other}
		/>
	);
});

MaskInput.propTypes = {
	refRegister: PropTypes.func,
	maskPattern: PropTypes.string.isRequired,
	handleChange: PropTypes.func,
};
