import React from 'react'

import './style.scss'

export const HeaderNav = ({ children }) => {
    return (
        <div className="header-nav">
            {children}
        </div>
    )
}