import React from 'react';
import { Check } from 'react-feather';

import './style.scss';

export const CheckBox = ({
	className,
	checked,
	onChange,
	label,
	renderLabel,
	name,
	disabled = false,
	id,
}) => {
	return (
		<div
			className={`checkbox__body ${className} ${disabled ? 'disabled' : ''}`}
			onClick={disabled ? e => e.preventDefault() : onChange}
			name={name}
			data-checked={checked}
			id={id ? id : name}
		>
			<span className={checked ? 'checkbox active' : 'checkbox'}>
				<Check />
			</span>
			{label ? <span>{label}</span> : renderLabel && renderLabel}
		</div>
	);
};
