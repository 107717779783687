import { createContext } from 'react';

interface IFormStateContext {
	setPhoneView: () => void;
	setCodeView: () => void;
	setNewPassView: () => void;

	phone: string;
	setPhone: (phone: string) => void;

	uuid: string | null;
	setUuid: (uuid: string) => void;

	userName: string | null;
	setUserName: (userName: string) => void;

	deadline: number | undefined;
	setDeadline: (uuid: number) => void;
}

export const FormStateContext = createContext<IFormStateContext | null>(null);
