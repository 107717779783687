import { api } from 'rest/api';
import {
	ICongratulationFromCompanyResponse,
	ICongratulationPosterCreateRest,
	ICongratulationPosterGetListResponse,
	ICongratulationPosterResponse,
	ICongratulationPosterUpdateRest,
} from './types';

function createError(error: any, defaultValue: any = null) {
	return {
		responseCode: 1,
		errorMessage: typeof error === 'object' ? 'Ошибка сети' : error,
		response: defaultValue,
	};
}

export const getPostersList = async (type: string) => {
	try {
		const response = await api.get(`/posters/list?type=${type}`);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const createCongratulation = async (data: any) => {
	try {
		const response = await api.post('/congratulations/create', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getAllCongratulation = async (data: any) => {
	try {
		const response = await api.get('/congratulations/get-icons', {
			params: data,
		});
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getCongratulation = async (congratulationId: number) => {
	try {
		const response = await api.get('/congratulations/get', {
			params: {
				congratulationId,
			},
		});
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const setLikeCongratulation = async (congratulationId: number) => {
	try {
		const response = await api.post('/congratulations/like', {
			congratulationId,
		});
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const addCongratulationPoster = async (
	data: ICongratulationPosterCreateRest
): Promise<ICongratulationPosterResponse> => {
	try {
		const response = await api.post('/posters/by-company/add', data);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};

export const updateCongratulationPoster = async (
	data: ICongratulationPosterUpdateRest
): Promise<ICongratulationPosterResponse> => {
	try {
		const response = await api.post('/posters/by-company/update', data);
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getCongratulationPoster = async (): Promise<ICongratulationPosterGetListResponse> => {
	try {
		const response = await api.get('/posters/by-company/list');
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const getCongratulationCompany = async (): Promise<ICongratulationFromCompanyResponse> => {
	try {
		const response = await api.get('/congratulations/get-actual-from-company');
		return response.data;
	} catch (error) {
		return createError(error);
	}
};

export const congratulationShowed = async () => {
	try {
		const response = await api.post('/user/congratulation-from-company/mark-showed');
		return response.data;
	} catch (error) {
		return createError(error);
	}
};
