import React, { Component } from 'react';
import { connect } from 'react-redux';

import Router from './router';

import { getShortUserInfo } from 'rest/user';
import { setShortUserInfo } from 'action/user';

class Articles extends Component {
	render() {
		return <Router />;
	}

	componentDidMount() {
		getShortUserInfo(this);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setShortUserInfo: user => {
			dispatch(setShortUserInfo(user));
		},
	};
};

export default connect(null, mapDispatchToProps)(Articles);
