import React from 'react';
import { useDispatch } from 'react-redux';

import { Filters } from 'modules/vacancies/organisms';

import { Button, ModalComponent } from 'core/components';

import { vacanciesFiltersClose } from 'action/ui';

import './style.scss';

const MobileVacanciesFilters = () => {
	const dispatch = useDispatch();

	const closeMobileFilter = e => {
		e.preventDefault();
		dispatch(vacanciesFiltersClose());
	};

	return (
		<ModalComponent onClose={closeMobileFilter}>
			<p className="mobile-filters__title">Фильтры</p>

			<Filters />

			<Button
				type="primary"
				typeButton="button"
				text="Применить"
				handler={closeMobileFilter}
				image={null}
			/>
		</ModalComponent>
	);
};

export default MobileVacanciesFilters;
