import React, { FC, useState, useEffect } from 'react';

import { Select } from 'core/components';
import { selectStyles } from 'core/utils';
import { IQuestions } from '../types';

const types = [
	{
		value: 'SINGLE',
		label: 'Один ответ',
	},
	{
		value: 'MULTIPLE',
		label: 'Множественный выбор',
	},
	{
		value: 'TEXT',
		label: 'Текст',
	},
	{
		value: 'SCALE',
		label: 'Шкала',
	},
];

interface IProps {
	questionSchema: IQuestions;
	changeType: (type: string, questionNumber: number) => void;
}

export const QuestionTypes: FC<IProps> = ({ changeType, questionSchema }) => {
	const [typeSelect, setType] = useState<string>();

	useEffect(() => {
		if (questionSchema && questionSchema.type !== typeSelect) {
			setType(questionSchema.type);
		}
	}, [questionSchema, typeSelect]);

	const changeTypeHandle = (value: string, label: string) => {
		setType(value);
		changeType(value, questionSchema.questionNumber);
	};

	return (
		<div className="type-survey">
			<p className="type-survey__label">Тип вопроса</p>
			<Select
				placeholder="Выберите тип вопроса"
				options={types}
				onChange={changeTypeHandle}
				styles={selectStyles}
				value={typeSelect}
				defaultValue={questionSchema.type}
			/>
		</div>
	);
};
