import {
	SET_MESSENGER_CATEGORY_LIST,
	MESSENGER_CATEGORY_CHANGE_NAME,
	MESSENGER_CATEGORY_CREATE,
	MESSENGER_CATEGORY_CHILDREN_CREATE,
	MOVE_UP_MESSENGER_CATEGORY,
	MOVE_DOWN_MESSENGER_CATEGORY,
	DELETE_MESSENGER_CATEGORY,
	MESSANGES_COUNT,
	MESSANGES_COUNT_DEC,
} from '../constant';

const DefaultState = {
	chatbotList: [],
	unreadCount: 0,
};

export default function messenger(state = DefaultState, action) {
	switch (action.type) {
		case SET_MESSENGER_CATEGORY_LIST:
			return {
				...state,
				chatbotList: action.response,
			};

		case MESSENGER_CATEGORY_CHANGE_NAME: {
			state.chatbotList[0].categoryList.forEach(item => {
				if (item.id === action.id) {
					item.name = action.name;
				}
			});
			return {
				...state,
				chatbotList: [...state.chatbotList],
			};
		}

		case MESSENGER_CATEGORY_CREATE: {
			state.chatbotList.forEach(item => {
				if (item.id === action.chatBotId) {
					item.categoryList = [...item.categoryList, action.item];
				}
			});
			return {
				...state,
			};
		}

		case MESSENGER_CATEGORY_CHILDREN_CREATE: {
			const findCategory = list => {
				list.forEach(item => {
					if (item.id === action.parentId) {
						item.children = [...(item.children ? item.children : []), action.item];
					} else {
						if (item.children) findCategory(item.children);
					}
				});
			};
			state.chatbotList.forEach(cat => {
				if (cat.id === action.chatBotId) {
					findCategory(cat.categoryList);
				}
			});
			return {
				...state,
			};
		}

		case MOVE_UP_MESSENGER_CATEGORY: {
			const findCategory = list => {
				list.some(item => {
					if (item.id === action.currentId) {
						item.sort++;

						list.sort((a, b) => {
							if (b.sort !== a.sort) {
								return b.sort - a.sort;
							} else {
								if (a.id < b.id) {
									return -1;
								} else if (a.id > b.id) {
									return 1;
								} else return 0;
							}
						});
						return true;
					} else {
						if (item.children) findCategory(item.children);
						return false;
					}
				});
			};

			state.chatbotList.forEach(chatbot => {
				if (chatbot.id === action.chatbotId) {
					findCategory(chatbot.categoryList);
				}
			});

			return {
				...state,
			};
		}

		case MOVE_DOWN_MESSENGER_CATEGORY: {
			const findCategory = list => {
				list.some(item => {
					if (item.id === action.currentId) {
						item.sort--;

						list.sort((a, b) => {
							if (b.sort !== a.sort) {
								return b.sort - a.sort;
							} else {
								if (a.id < b.id) {
									return -1;
								} else if (a.id > b.id) {
									return 1;
								} else return 0;
							}
						});
						return true;
					} else {
						if (item.children) findCategory(item.children);
						return false;
					}
				});
			};

			state.chatbotList.forEach(chatbot => {
				if (chatbot.id === action.chatbotId) {
					findCategory(chatbot.categoryList);
				}
			});

			return {
				...state,
			};
		}

		case DELETE_MESSENGER_CATEGORY: {
			const findCategory = (chatbot, list, parent) => {
				list.some(item => {
					if (item.id === action.deleteId) {
						if (!!parent) {
							parent.children = [
								...parent.children.filter(item => item.id !== action.deleteId),
							];
						} else {
							chatbot.categoryList = [
								...chatbot.categoryList.filter(item => item.id !== action.deleteId),
							];
						}
						return true;
					} else {
						if (item.children) findCategory(chatbot, item.children, item);
						return false;
					}
				});
			};

			state.chatbotList.forEach(chatbot => {
				if (chatbot.id === action.chatbotId) {
					findCategory(chatbot, chatbot.categoryList);
				}
			});

			return {
				...state,
			};
		}

		case MESSANGES_COUNT: {
			return {
				...state,
				unreadCount: action.unreadCount,
			};
		}

		case MESSANGES_COUNT_DEC: {
			return {
				...state,
				unreadCount: --state.unreadCount,
			};
		}

		default:
			return state;
	}
}
