import { api } from 'rest/api';

function createError(error: any, defaultValue: any = null) {
	return {
		responseCode: 1,
		errorMessage: typeof error === 'object' ? 'Ошибка сети' : error,
		response: defaultValue,
	};
}

export const getNavigationMenu = async () => {
	try {
		const response = await api.get(`/nav/list`);
		return response.data;
	} catch (error) {
		return createError(error, []);
	}
};
