export default async function parallelLimit(promiseFactories, limit) {
    let result = []
    let cnt = 0

    function chain(promiseFactories) {
        if(!promiseFactories.length) return
        let i = cnt++
        return promiseFactories.shift()().then((res) => {
            result[i] = res
            return chain(promiseFactories)
        })
    }

    let arrChains = []
    while(limit-- > 0 && promiseFactories.length > 0) {
        arrChains.push(chain(promiseFactories))
    }

    return Promise.all(arrChains).then(() => result)
}
