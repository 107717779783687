import React, { forwardRef, useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import formControlState from '../formControl/formControlState';
import FormControlContext, { useFormControl } from '../formControl/FormControlContext';
import useForkRef from 'core/utils/useForkRef';

import './style.scss';

const InputBase = forwardRef(function InputBase(props, ref) {
	const {
		'aria-describedby': ariaDescribedby,
		autoComplete,
		autoFocus,
		className,
		disabled,
		endAdornment,
		error,
		fullWidth = false,
		id,
		inputProps: inputPropsProp = {},
		inputRef: inputRefProp,
		mask: maskProp,
		maxLength,
		name,
		onBlur,
		onChange,
		onClick,
		onFocus,
		onKeyDown,
		onKeyUp,
		placeholder,
		readOnly,
		renderSuffix,
		startAdornment,
		type = 'text',
		value: valueProp,
		required,
		defaultValue,
		...other
	} = props;

	const value = !!inputPropsProp.value ? inputPropsProp.value : valueProp;
	const mask = !!inputPropsProp.mask ? inputPropsProp.mask : maskProp;

	const inputRef = useRef(null);
	const handleInputRefProp = useForkRef(inputRefProp, inputPropsProp.ref);
	const handleInputRef = useForkRef(inputRef, handleInputRefProp);

	const [focused, setFocused] = useState(false);
	const [filled, setFilled] = useState(false);
	const formControl = useFormControl();

	const fcs = formControlState({
		props,
		formControl,
		states: ['disabled', 'error', 'required'],
	});
	fcs.filled = formControl ? formControl.filled : filled;
	fcs.focused = formControl ? formControl.focused : focused;

	useEffect(() => {
		if (disabled && focused) {
			setFocused(false);
			if (onBlur) {
				onBlur();
			}
		}
	}, [disabled, focused, onBlur]);

	const onFilled = formControl && formControl.onFilled;
	const onEmpty = formControl && formControl.onEmpty;

	const checkFilled = useCallback(() => {
		if (inputRef.current) {
			if (!!inputRef.current.value.trim()) {
				if (onFilled) {
					onFilled();
				} else {
					setFilled(true);
				}
			} else if (onEmpty) {
				onEmpty();
			} else {
				setFilled(false);
			}
		}
	}, [onFilled, onEmpty]);

	useEffect(() => {
		checkFilled();
	}, [checkFilled, value]);

	const handleFocus = event => {
		if (fcs.disabled) {
			event.stopPropagation();
			return;
		}
		if (onFocus) {
			onFocus(event);
		}
		if (inputPropsProp.onFocus) {
			inputPropsProp.onFocus();
		}
		if (formControl && formControl.onFocus) {
			formControl.onFocus(event);
		} else {
			setFocused(true);
		}
	};

	const handleBlur = event => {
		if (onBlur) {
			onBlur(event);
		}
		if (inputPropsProp.onBlur) {
			inputPropsProp.onBlur(event);
		}
		if (formControl && formControl.onBlur) {
			formControl.onBlur(event);
		} else {
			setFocused(false);
		}
	};

	const handleChange = (event, ...args) => {
		checkFilled();
		if (inputPropsProp.onChange) {
			inputPropsProp.onChange(event, ...args);
		}
		if (onChange) {
			onChange(event, ...args);
		}
	};

	const handleClick = event => {
		if (inputRef.current && event.currentTarget === event.target) {
			inputRef.current.focus();
		}
		if (onClick) {
			onClick(event);
		}
	};

	let InputComponent = 'input';
	let inputProps = {
		...inputPropsProp,
		ref: handleInputRef,
	};

	if (mask) {
		InputComponent = InputMask;
		inputProps = {
			...inputProps,
			inputRef: handleInputRef,
			mask,
			ref: null,
		};
	}

	return (
		<div
			className={classNames(
				'input-base',
				{
					'input-base_form-control': formControl,
					'input-base_disabled': fcs.disabled,
					'input-base_error': fcs.error,
					'input-base_full-width': fullWidth,
					'input-base_filled': fcs.filled,
					'input-base_focused': fcs.focused,
				},
				className
			)}
			onClick={handleClick}
			ref={ref}
			{...other}
		>
			{startAdornment}
			<FormControlContext.Provider value={null}>
				<InputComponent
					aria-invalid={fcs.error}
					aria-describedby={ariaDescribedby}
					autoComplete={autoComplete}
					autoFocus={autoFocus}
					defaultValue={defaultValue}
					disabled={fcs.disabled}
					id={id}
					maxLength={maxLength}
					name={name}
					placeholder={placeholder}
					readOnly={readOnly}
					required={fcs.required}
					value={value}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
					type={type}
					{...inputProps}
					className={classNames(
						'input-base-control',
						{
							'input-base-control_disabled': fcs.disabled,
							'input-base-control_error': fcs.error,
							'input-base-control_filled': fcs.filled,
							'input-base-control_focused': fcs.focused,
							'input-base-control_required': fcs.required,
						},
						inputPropsProp.className
					)}
					onBlur={handleBlur}
					onChange={handleChange}
					onFocus={handleFocus}
				/>
			</FormControlContext.Provider>
			{endAdornment}
			{renderSuffix &&
				renderSuffix({
					...fcs,
					startAdornment,
					endAdornment,
				})}
		</div>
	);
});

export default InputBase;
