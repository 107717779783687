import './style.scss';

function make(tagName, classNames = null, attributes = {}) {
	const el = document.createElement(tagName);

	if (Array.isArray(classNames)) {
		el.classList.add(...classNames);
	} else if (classNames) {
		el.classList.add(classNames);
	}

	for (const attrName in attributes) {
		el[attrName] = attributes[attrName];
	}
	return el;
}

export class TextAlign {
	static get DEFAULT_ALIGNMENT() {
		return 'left';
	}

	static get isTune() {
		return true;
	}

	getAlignment() {
		if (this.settings?.blocks) {
			return this.settings.blocks[this.block.name];
		}
		if (this.settings?.default) {
			return this.settings.default;
		}
		return TextAlign.DEFAULT_ALIGNMENT;
	}

	constructor({ api, data, config, block }) {
		this.api = api;
		this.block = block;
		this.settings = config;
		this.data = data || { alignment: this.getAlignment() };
		this.alignmentSettings = [
			{
				name: 'left',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 23h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 45h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>`,
			},
			{
				name: 'center',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 23c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 45c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>`,
			},
			{
				name: 'right',
				icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 19h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 41h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/></svg>`,
			},
			{
				name: 'justify',
				icon: `<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Edit / Text_Align_Justify"><path id="Vector" d="M20 18H4M20 14H4M20 10H4M20 6H4" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>`,
			},
		];
		this._CSS = {
			alignment: {
				left: 'ce-tune-alignment--left',
				center: 'ce-tune-alignment--center',
				right: 'ce-tune-alignment--right',
				justify: 'ce-tune-alignment--justify',
			},
		};
	}

	wrap(blockContent) {
		this.wrapper = make('div');
		this.wrapper.classList.toggle(this._CSS.alignment[this.data.alignment]);
		this.wrapper.append(blockContent);
		return this.wrapper;
	}

	render() {
		const wrapper = make('div');
		this.alignmentSettings
			.map(align => {
				const button = document.createElement('button');
				button.classList.add(this.api.styles.settingsButton);
				button.innerHTML = align.icon;
				button.type = 'button';

				button.classList.toggle(
					this.api.styles.settingsButtonActive,
					align.name === this.data.alignment
				);
				wrapper.appendChild(button);
				return button;
			})
			.forEach((element, index, elements) => {
				element.addEventListener('click', () => {
					this.data = {
						alignment: this.alignmentSettings[index].name,
					};
					elements.forEach((el, i) => {
						const { name } = this.alignmentSettings[i];
						el.classList.toggle(
							this.api.styles.settingsButtonActive,
							name === this.data.alignment
						);
						this.wrapper.classList.toggle(
							this._CSS.alignment[name],
							name === this.data.alignment
						);
					});
				});
			});
		return wrapper;
	}

	save() {
		return this.data;
	}
}
