import {
    WALK_FAME_LIST,
    WALK_FAME_INFO,
    WALK_FAME_USERS,
    WALK_FAME_ADD_USERS,
    WALK_FAME_PHOTO,
    WALK_FAME_PHOTO_ADD,
    WALK_FAME_DELETE_USERS,
    WALK_FAME_PHOTO_DELETE,
    WALK_FAME_PHOTO_UPDATE,
    WALK_FAME_LIST_NO_PUBLICK
} from '../constant'

const DefaultState = {
    walkFameList: [],
    walkFameListNoPublic: [],
    walkFame: {},
    users: [],
    photos: []
};

export default function walkFame(state = DefaultState, action) {

    switch (action.type) {
        case WALK_FAME_LIST:
            let walkFameList = {
                walkFameList: action.list,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: state.users,
                photos: state.photos
            }
            return walkFameList

        case WALK_FAME_LIST_NO_PUBLICK:
            let walkFameListNoPublic = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: action.list,
                walkFame: state.walkFame,
                users: state.users,
                photos: state.photos
            }
            return walkFameListNoPublic

        case WALK_FAME_INFO:
            let walkFameInfo = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: action.walkFame,
                users: state.users,
                photos: state.photos
            }
            return walkFameInfo

        case WALK_FAME_USERS:
            let walkFameUsers = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: action.users,
                photos: state.photos
            }
            return walkFameUsers

        case WALK_FAME_ADD_USERS:
            let walkFameAddUser = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: [action.user, ...state.users],
                photos: state.photos
            }
            return walkFameAddUser

        case WALK_FAME_PHOTO:
            let walkFamePhotos = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: state.users,
                photos: [...action.photos]
            }
            return walkFamePhotos

        case WALK_FAME_PHOTO_ADD:
            let walkFameAddPhoto = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: state.users,
                photos: [action.photo, ...state.photos]
            }
            return walkFameAddPhoto

        case WALK_FAME_DELETE_USERS:
            let walkFameDeleteUsers = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: state.users.filter(
                    (item) => {
                        return +item.id !== +action.id
                    }
                ),
                photos: state.photos
            }
            return walkFameDeleteUsers

        case WALK_FAME_PHOTO_DELETE:
            let walkFameDeletePhoto = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: state.users,
                photos: state.photos.filter(
                    (item) => {
                        return +item.id !== +action.id
                    }
                )
            }
            return walkFameDeletePhoto

        case WALK_FAME_PHOTO_UPDATE:
            let walkFameUpdatePhoto = {
                walkFameList: state.walkFameList,
                walkFameListNoPublic: state.walkFameListNoPublic,
                walkFame: state.walkFame,
                users: state.users,
                photos: state.photos.map(
                    (item) => {
                        if (+item.id === +action.id) {
                            item.description = action.description
                            return item
                        } else return item
                    }
                )
            }
            return walkFameUpdatePhoto

        default:
            return state
    }
}