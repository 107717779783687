import React, { useState, useEffect, FC } from 'react';

import { EditModeSwitch } from 'core/components';

interface IProps {
	defaultValue?: boolean;
	text: string;
	name: string;
	id: string;
	change: (state: boolean) => void;
	disabled?: boolean;
}

export const SwitchField: FC<IProps> = ({
	id,
	name,
	text,
	change,
	defaultValue = false,
	disabled = false,
}) => {
	const [state, setSwitchState] = useState(defaultValue);

	useEffect(() => {
		setSwitchState(defaultValue);
	}, [defaultValue]);

	const onChange = () => {
		setSwitchState(prev => {
			change(!prev);
			return !prev;
		});
	};

	return (
		<EditModeSwitch
			text={text}
			editModeOn={state}
			switchEditModeHandler={onChange}
			name={name}
			id={id}
			disabled={disabled}
		/>
	);
};
