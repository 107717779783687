import React, { useEffect, useState } from 'react';

interface IProps {
	deadline?: number;
	setEnd: React.Dispatch<React.SetStateAction<boolean>>;
}

let interval: ReturnType<typeof setInterval>;

export const Countdown = ({ deadline, setEnd }: IProps) => {
	const [tick, setTick] = useState(false);
	const [isTimeout, setIsTimeout] = useState(false);
	const [[diffM, diffS], setDiff] = useState([0, 0]);

	useEffect(() => {
		if (!deadline) {
			setIsTimeout(true);
			return;
		}

		const dateNow = new Date().getTime();
		const diff = (deadline - dateNow) / 1000;

		if (diff < 0) {
			setIsTimeout(true);
			setEnd(true);
			return;
		}

		setDiff([Math.floor((diff / 60) % 60), Math.floor(diff % 60)]);
	}, [tick, deadline, setEnd]);

	useEffect(() => {
		if (isTimeout) clearInterval(interval);
	}, [isTimeout]);

	useEffect(() => {
		interval = setInterval(() => {
			setTick(!tick);
		}, 1000);
		return () => clearInterval(interval);
	}, [tick]);

	return (
		<span className="countdown">
			{`${diffM.toString().padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}
		</span>
	);
};
