import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TypeButton, Icon } from '@maxi-innovation/ui-kit-frontend';

import { Select } from 'core/components';
import { selectStyles } from 'core/utils';
import { IQuestions, IOptions, ICondition } from '../Form/types';
import {
	updateConditionTypeAction,
	updateQuestionConditionIdAction,
	updateQuestionConditionValueAction,
} from 'action/survey';

interface IProps {
	itemNumber: number;
	questions: IQuestions[];
	deleteCondition: (itemNumber: number) => void;
	questionSchema: IQuestions;
	id: number | undefined;
	currentCondition: ICondition;
}

const questionCondition: IOptions[] = [
	{
		label: 'Равно',
		value: 'E',
	},
	{
		label: 'Больше или равно',
		value: 'GE',
	},
	{
		label: 'Меньше или равно',
		value: 'LE',
	},
];

export const Condition: FC<IProps> = ({
	itemNumber,
	questions,
	deleteCondition,
	questionSchema,
	id,
	currentCondition,
}) => {
	const dispatch = useDispatch();
	const [questionNames, setQuestionNames] = useState<IOptions[]>([]);
	const [questionNameSelect, setQuestionNameSelect] = useState<number>();
	const [defaultName, setDefaultName] = useState<number>();

	const [questionValues, setQuestionValues] = useState<IOptions[]>([]);
	const [questionValueSelect, setQuestionValueSelect] = useState<string>();
	const [defaultValue, setDefaultValue] = useState<string>();
	const [valueKey, setValueKey] = useState(0);

	const [conditionType, setConditionType] = useState<string>();
	const [conditionTypeDefault, setConditionTypeDefault] = useState<string>();

	const [questionConditionSelect, setQuestionConditionSelect] = useState<string>();

	useEffect(() => {
		if (currentCondition.id) {
			setDefaultName(currentCondition.id);
			setQuestionNameSelect(currentCondition.questionNumber);

			setQuestionValueSelect(
				currentCondition.value === 'Другое' ? 'OTHER' : currentCondition.value
			);
			setDefaultValue(currentCondition.value === 'Другое' ? 'OTHER' : currentCondition.value);

			setConditionType(currentCondition.questionType);
			setConditionTypeDefault(currentCondition.type);
			setQuestionConditionSelect(currentCondition.type);
		}
	}, [questionSchema, currentCondition]);

	useEffect(() => {
		const tempArr: IOptions[] = [];

		questions.forEach(item => {
			if (item.questionNumber < questionSchema.questionNumber && item.type !== 'TEXT') {
				tempArr.push({
					label: item.title,
					value: item.id,
					data: {
						type: item.type,
						questionNumber: item.questionNumber,
					},
				});
			}
		});

		setQuestionNames(tempArr);
	}, [questions, questionSchema]);

	useEffect(() => {
		const tempArr: IOptions[] = [];

		questions.forEach(item => {
			if (
				conditionType &&
				questionNameSelect &&
				+item.questionNumber === +questionNameSelect
			) {
				if (conditionType === 'SCALE') {
					if (item.max) {
						for (let index = 1; index <= item.max; index++) {
							tempArr.push({
								label: index,
								value: index.toString(),
							});
						}
					} else {
						item.possibleAnswers?.forEach(item => {
							tempArr.push({ label: item.text, value: item.text });
						});
					}
				} else {
					if (item.possibleAnswers) {
						item.possibleAnswers.forEach(answer => {
							tempArr.push({
								label: answer.text,
								value: answer.text,
							});
						});
						if (item.withOwnAnswer) {
							tempArr.push({
								label: 'Другое',
								value: 'OTHER',
							});
						}
					}
				}
			}
		});

		setQuestionValues(tempArr);
	}, [questions, questionNameSelect, conditionType]);

	useEffect(() => {
		if (!id) return;
		let checkQuestions = false;

		questions.forEach(item => {
			if (item.id === id && item.questionNumber < questionSchema.questionNumber)
				checkQuestions = true;
		});
		if (!checkQuestions) {
			deleteCondition(itemNumber);
		}
	}, [questions, id, questionSchema, deleteCondition, itemNumber]);

	const changeNameHandle = (
		value: number,
		label: string,
		data: { type: string; questionNumber: number; questionType: string }
	) => {
		setQuestionNameSelect(data.questionNumber);

		setConditionTypeDefault('E');
		setConditionType(data.type);

		setDefaultValue(undefined);
		setQuestionValueSelect(undefined);
		setValueKey(prev => prev + 1);

		dispatch(
			updateQuestionConditionIdAction(questionSchema.questionNumber, {
				index: itemNumber,
				id: +value,
				type: 'E',
				questionNumber: data.questionNumber,
				questionType: data.type,
			})
		);
		dispatch(
			updateQuestionConditionValueAction(questionSchema.questionNumber, itemNumber, undefined)
		);
	};

	const changeValueHandle = (value: string) => {
		setQuestionValueSelect(value);

		dispatch(
			updateQuestionConditionValueAction(questionSchema.questionNumber, itemNumber, value)
		);
	};

	const changeConditionHandle = (value: string) => {
		setQuestionConditionSelect(value);

		dispatch(updateConditionTypeAction(questionSchema.questionNumber, itemNumber, value));
	};

	return (
		<div className="survey-condition__item">
			<div className="survey-condition__field">
				<p className="survey-condition__label">Если в вопросе</p>
				<Select
					error={!currentCondition.questionNumber}
					placeholder="Выберите вопрос"
					options={questionNames}
					onChange={changeNameHandle}
					styles={selectStyles}
					value={questionNameSelect}
					defaultValue={defaultName}
				/>
			</div>

			{conditionType === 'SCALE' && !!conditionTypeDefault && (
				<>
					<div className="survey-condition__field">
						<p className="survey-condition__label">Выбрано</p>
						<Select
							error={!currentCondition.type}
							placeholder="Выберите условие"
							options={questionCondition}
							onChange={changeConditionHandle}
							styles={selectStyles}
							value={questionConditionSelect}
							defaultValue={conditionTypeDefault}
						/>
					</div>
				</>
			)}

			<div className="survey-condition__field">
				<p className="survey-condition__label">Выбрано</p>
				<Select
					error={!currentCondition.value}
					placeholder="Выберите ответ"
					options={questionValues}
					onChange={changeValueHandle}
					styles={selectStyles}
					value={questionValueSelect}
					defaultValue={defaultValue}
					key={valueKey}
				/>
			</div>

			<Button
				variant={TypeButton.LINKCAPS}
				className="delete-condition"
				onClick={() => deleteCondition(itemNumber)}
			>
				<Icon iconName="Trash" />
				Удалить
			</Button>
		</div>
	);
};
