import React, { FC } from 'react';

import { IQuestionComponentProps } from '../types';

export const TextQuestion: FC<IQuestionComponentProps> = ({ question, questionNumber }) => {
	return (
		<div className="question-analitic">
			<p className="question-analitic__title">
				{questionNumber}. {question.text}
			</p>

			<div className="analitic-question-answers">
				{question.answers.map((item, index) => (
					<div className="analitic-question-answers__item" key={index}>
						{item.author && (
							<p className="analitic-question-answers__name">{`${
								item.author.surname
							} ${item.author.name.slice(0, 1)}.`}</p>
						)}
						<p className="analitic-question-answers__text">{item.text}</p>
					</div>
				))}
			</div>
		</div>
	);
};
