import { useIsMobile } from 'hooks/useIsMobile';
import React from 'react';
import StickyBox from 'react-sticky-box';

export const FixedSidebar = ({ children }) => {
	const isMobile = useIsMobile();

	return isMobile ? (
		children
	) : (
		<StickyBox offsetTop={119} offsetBottom={20}>
			{children}
		</StickyBox>
	);
};
