import {
	SET_GROUP_POSTS,
	SCROLL_GROUP_POSTS,
	ADD_GROUP_POSTS,
	DELETE_GROUP_POSTS,
	UPDATE_GROUP_POSTS,
	RECOVER_GROUP_POSTS,
	GET_COMMENTS_LIST,
	COMMENTS_COUNT,
	DELETE_COMMENT,
	RECOVER_COMMENT,
	ADD_COMMENT,
	COMMENTS_COUNT_ADD,
	COMMENTS_COUNT_REMOVE,
	POST_LIKE,
	POST_VIEW,
	POST_FAVORITE,
	UPDATE_GROUP_POSTS_NEW,
	UPDATE_COMMENT,
	HIDE_COMMENTS,
	VOTE_IN_POLL,
	ADD_OR_REMOVE_COMMENTS_LIKE,
} from 'constant';

const DefaultState = [];

export default function groupPosts(state = DefaultState, action) {
	switch (action.type) {
		case SET_GROUP_POSTS:
			return [...action.groupPosts];

		case SCROLL_GROUP_POSTS:
			return [...state, ...action.posts];

		case ADD_GROUP_POSTS:
			const firstPost = state[0];
			if (firstPost && firstPost.fixedFlag) {
				return [firstPost, action.post, ...state.slice(1)];
			}
			return [action.post, ...state];

		case DELETE_GROUP_POSTS:
			let posts = state;
			return posts.map(post => {
				if (post.id === action.id) {
					post.isDelete = true;
					return post;
				} else {
					return post;
				}
			});

		case UPDATE_GROUP_POSTS:
			let updatePosts = state;
			return updatePosts.map(post => {
				if (post.id === action.id) {
					post.text = action.text;
					post.imageList = action.images;
					post.videoList = action.video;
					post.documentList = action.files;
					post.article = action.article;
					post.pollId = action.pollId;
					post.title = action.title;
				}
				return post;
			});

		case RECOVER_GROUP_POSTS:
			let recoverPosts = state;
			return recoverPosts.map(post => {
				if (+post.id === +action.post.id) {
					post.isDelete = false;
					return post;
				} else {
					return post;
				}
			});

		case GET_COMMENTS_LIST:
			let getComments = state;
			return getComments.map(post => {
				if (+post.id === +action.id) {
					post.comments = [...action.comments];
				}
				return post;
			});

		case COMMENTS_COUNT:
			let getCommentsCount = state;
			return getCommentsCount.map(post => {
				if (+post.id === +action.id) {
					post.commentCount = action.length;
				}
				return post;
			});

		case ADD_COMMENT:
			let addComment = state;
			return addComment.map(post => {
				if (+post.id === +action.postId) {
					post.comments = [...post.comments, action.comment];
				}
				return post;
			});

		case DELETE_COMMENT:
			let deleteComment = state;
			return deleteComment.map(item => {
				if (+item.id === +action.postId) {
					item.comments = item.comments.filter(comment => {
						if (+comment.id === +action.commentId) {
							comment.isDelete = true;
							return comment;
						} else {
							return comment;
						}
					});
				}
				return item;
			});

		case RECOVER_COMMENT:
			let recoverComments = state;
			return recoverComments.map(post => {
				if (+post.id === +action.postId) {
					post.comments = post.comments.filter(comment => {
						if (+comment.id === +action.commentId) {
							comment.isDelete = false;
							return comment;
						} else {
							return comment;
						}
					});
				}
				return post;
			});

		case UPDATE_COMMENT:
			let updateComment = state;
			return updateComment.map(post => {
				if (+post.id === +action.postId) {
					post.comments = post.comments.map(comment => {
						if (+comment.id === +action.id) {
							comment.note = action.comment.note;
							comment.changeDate = action.comment.changeDate;
						}
						return comment;
					});
				}
				return post;
			});

		case ADD_OR_REMOVE_COMMENTS_LIKE:
			const addOrRemoveCommentsLike = state;
			return addOrRemoveCommentsLike.map(post => {
				if (+post.id === +action.postId) {
					post.comments = post.comments.map(comment => {
						if (+comment.id === +action.id && comment.hasLike !== action.like) {
							comment.hasLike = action.like;
							comment.likeCount = comment.hasLike
								? (comment.likeCount += 1)
								: (comment.likeCount -= 1);
						}
						return comment;
					});
				}
				return post;
			});

		case COMMENTS_COUNT_ADD:
			let commentsCountAdd = state;
			return commentsCountAdd.map(post => {
				if (post.id === action.id) {
					post.commentCount++;
					return post;
				} else {
					return post;
				}
			});

		case COMMENTS_COUNT_REMOVE:
			let commentsCountRemove = state;
			return commentsCountRemove.map(post => {
				if (post.id === action.id) {
					post.commentCount--;
					return post;
				} else {
					return post;
				}
			});

		case HIDE_COMMENTS:
			let hideComments = state;
			return hideComments.map(post => {
				if (+post.id === +action.postId) {
					let hidden = post.comments.splice(-3);
					post.comments = hidden;
				}
				return post;
			});

		case POST_FAVORITE:
			return state.map(item => {
				if (+item.id === +action.id) {
					item.favorite = action.favorite;
				}
				return item;
			});

		case POST_LIKE:
			let postLike = state;
			return postLike.map(item => {
				if (+item.id === +action.id) {
					item.hasLike = action.hasLike;
					if (action.hasLike) {
						item.likeCount++;
					} else {
						item.likeCount--;
					}
					return item;
				} else return item;
			});

		case POST_VIEW:
			let postView = state;
			return postView.map(item => {
				if (+item.id === +action.id) {
					if (!item.view) {
						item.view = true;
						item.viewsCount = item.viewsCount + 1;
					}
				}
				return item;
			});

		case UPDATE_GROUP_POSTS_NEW:
			let updateArticle = state;
			return updateArticle.map(item => {
				if (item.id === action.id) {
					item = action.post;
				}
				return item;
			});

		case VOTE_IN_POLL:
			let voteInPoll = state;
			return voteInPoll.map(post => {
				if (+post.id === +action.postId) {
					post.poll.response = +action.answerId;
					post.poll[`quantity${+action.answerId}`] =
						+post.poll[`quantity${+action.answerId}`] + 1;
				}
				return post;
			});

		default:
			return state;
	}
}
