import React, { useEffect } from 'react';
import * as ReactGA4 from 'react-ga4';
import ReactGA from 'react-ga';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

import Router from './mainRouter';
import register, { unregister } from 'registerServiceWorker';

ReactGA.initialize('UA-126797301-1');
ReactGA4.default.initialize('G-WLNJ67CL6P');

const firebaseConfig = {
	apiKey: 'AIzaSyAf5r1KZrqIhG43bEOfbnCXAsRCzEjSl4o',
	authDomain: 'maxiportal-d7dd0.firebaseapp.com',
	databaseURL: 'https://maxiportal-d7dd0.firebaseio.com',
	projectId: 'maxiportal-d7dd0',
	storageBucket: '',
	messagingSenderId: '168760007380',
	appId: '1:168760007380:web:cfd80d6d13b4ce2a',
};

firebase.initializeApp(firebaseConfig);

const App = () => {
	useEffect(() => {
		if (process.env.REACT_APP_GA) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
		if (process.env.REACT_APP_TITLE) {
			document.title = process.env.REACT_APP_TITLE;
		}

		register();
		return () => {
			unregister();
		};
	}, []);

	return <Router />;
};

export default App;
