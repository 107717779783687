import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Clock } from 'react-feather';
import VMasker from 'vanilla-masker';
import classNames from 'classnames';

import './style.scss';

const timeReg = /^([01]\d|2[0-3]):?([0-5]\d)$/;

const getArrayMinutes = () => {
	const minutes = [];
	for (let i = 0; i < 60; i++) {
		let strMinutes = i < 10 ? `0${i}` : `${i}`;
		minutes.push(strMinutes);
	}
	return minutes;
};

const getArrayHours = () => {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		let strHours = i < 10 ? `0${i}` : `${i}`;
		hours.push(strHours);
	}
	return hours;
};

export const TimePicker = ({
	onChange,
	onInput,
	defaultValue,
	disabled,
	placeholder = 'ЧЧ:ММ',
	maskPattern = '99:99',
	inputRef,
	refRegister,
	name,
	error,
	onClear = false,
	id,
}) => {
	const [openSelect, setOpenSelect] = useState(false);
	const [value, setValue] = useState('');
	const [activeHours, setHoursActive] = useState(null);
	const [activeMinutes, setMinutesActive] = useState(null);
	const minutes = getArrayMinutes();
	const hours = getArrayHours();
	const toolbarRef = useRef(null);

	const refInput = inputRef ? inputRef : React.createRef();

	useEffect(() => {
		if (refInput?.current) VMasker(refInput.current).maskPattern(maskPattern);
	}, [refInput, maskPattern]);

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (onClear) {
			setValue('');
			setMinutesActive(null);
			setHoursActive(null);
		}
	}, [onClear, setValue, setMinutesActive, setHoursActive]);

	const onChangeInputTime = event => {
		const date = event.target.value;
		setValue(date);
	};

	const onBlurTime = event => {
		const date = event.target.value;
		if (date.length !== 0) {
			if (date.match(timeReg)) {
				setValue(date);
			} else {
				const time = date.split(':');
				+time[0] < 24 && +time[1] < 60 ? setValue(date) : setValue('00:00');
			}
		}
	};

	const handleClick = e => {
		if (!toolbarRef.current) return;

		if (!toolbarRef.current.contains(e.target)) {
			setOpenSelect(false);
		}
	};

	const openTimeSelect = () => {
		setOpenSelect(!openSelect);
	};

	useEffect(() => {
		if (!openSelect) return;
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [openSelect, setOpenSelect]);

	useEffect(() => {
		if (onChange && value.match(timeReg)) {
			onChange(value);
		}

		if (onInput && !value.match(timeReg)) onInput(value);
	}, [value, onChange, onInput]);

	const onOptionHoursClicked = useCallback(
		item => () => {
			const minutes = value.split(':')[1];
			setValue(`${item}:${minutes}`);
			setHoursActive(item);
		},
		[value]
	);

	const onOptionMinutesClicked = useCallback(
		item => () => {
			const hours = value.length === 0 ? '00' : value.split(':')[0];
			setValue(`${hours}:${item}`);
			setMinutesActive(item);
		},
		[value]
	);

	const classes = {
		timepicker: classNames('timepicker', {
			error: error,
		}),
	};

	return (
		<div className={classes.timepicker}>
			<div className="timepicker-field">
				<span className="timepicker-btn-clock" onClick={!disabled ? openTimeSelect : null}>
					<Clock />
				</span>
				<input
					ref={e => {
						if (refRegister) refRegister(e, {});
						refInput.current = e;
					}}
					type="text"
					name={name}
					placeholder={placeholder}
					onChange={onChangeInputTime}
					disabled={disabled}
					className="timepicker-input"
					value={value}
					onBlur={onBlurTime}
					id={id}
				/>
			</div>
			{openSelect && (
				<div ref={toolbarRef} className="select-time">
					<div className="select-time-content">
						<span className="select-time-label">Часы:</span>
						<ul className="select-time-hours">
							{hours.map((item, index) => (
								<li
									key={index}
									className={
										item === activeHours
											? 'select-option active'
											: 'select-option'
									}
									onClick={onOptionHoursClicked(item)}
								>
									{item}
								</li>
							))}
						</ul>
					</div>
					<div className="select-time-content">
						<span className="select-time-label">Минуты:</span>
						<ul className="select-time-minutes">
							{minutes.map((item, index) => (
								<li
									key={index}
									className={
										item === activeMinutes
											? 'select-option active'
											: 'select-option'
									}
									onClick={onOptionMinutesClicked(item)}
								>
									{item}
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};
