import React from 'react';
import Helmet from 'react-helmet';

import { RegistrationForm } from 'feature/auth-register';

import '../style.scss';
import { useAuthCheck } from '../useAuthCheck';

const Register = () => {
	useAuthCheck();

	return (
		<>
			<Helmet title="Регистрация" />
			<div className="auth-page">
				<RegistrationForm />
			</div>
		</>
	);
};

export default Register;
