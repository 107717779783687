import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { InputText, Button } from 'core/components';

import { deleteGuideCategory } from 'action/guide';

import './style.scss';

class DeleteModal extends Component {
	state = {
		name: '',
		isValid: true,
	};

	render() {
		let { closeHandler, category } = this.props;
		let { isValid } = this.state;
		return (
			<div className="guide-create__delete-wrap" onClick={closeHandler}>
				<div className="guide-create__delete" onClick={this.bodyClick}>
					<p className="guide-create__delete__title">Удаление раздела</p>

					<p className="guide-create__delete__text">
						Вы собираетесь удалить раздел <b>{category.name}</b>.<br />
						Если Вы точно уверены, что хотите удалить раздел, введите название
						<br />
						<b>{category.name}</b> в поле ниже
					</p>

					<InputText
						id="name"
						placeholder={category.name}
						onChange={this.nameHandler}
						valid={isValid}
						value={this.state.name}
					/>

					<div className="guide-create__delete-toolbar">
						<Button
							text="Подтвердить"
							type="primary"
							handler={this.confirmDelete}
							image={null}
						/>

						<Button
							text="Отменить"
							type="secondary"
							handler={closeHandler}
							image={null}
						/>
					</div>
				</div>
			</div>
		);
	}

	confirmDelete = e => {
		e.stopPropagation();
		e.preventDefault();
		let { name } = this.state;
		if (name === this.props.category.name) {
			this.deleteHandler(e);
			this.setState({ isValid: true });
		} else {
			this.setState({ isValid: false });
		}
	};

	bodyClick = e => {
		e.stopPropagation();
		e.preventDefault();
	};

	nameHandler = e => {
		e.preventDefault();
		this.setState({ isValid: true });
		this.setState({
			name: e.target.value,
		});
	};

	deleteHandler = e => {
		e.preventDefault();
		const { redirectToCategory } = this.props;
		const { id, name, parent } = this.props.category;

		this.props.deleteGuideCategory({
			id: id,
			name: name,
			redirectToCategory: redirectToCategory,
			redirectPath: `/knowledge/guide/${parent ? parent.id : ''}`,
		});
	};

	componentDidMount() {
		document.getElementById('wrapp').classList.add('no-scroll');
	}

	componentWillUnmount() {
		document.getElementById('wrapp').classList.remove('no-scroll');
	}
}

const mapStateToProps = state => {
	return {
		category: state.guide.category,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		deleteGuideCategory: data => {
			dispatch(deleteGuideCategory(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteModal));
