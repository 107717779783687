import { useEffect, useState } from 'react';

export function useResize() {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			global.window.removeEventListener('resize', handleResize);
		};
	}, []);

	return width;
}
