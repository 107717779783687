import React, { forwardRef } from 'react'
import classNames from 'classnames'
import * as Icon from 'react-feather'
import UploaderBase from '../uploaderBase'

import './style.scss'

const FileUploader = forwardRef(function FileUploader(props, ref) {
    const {
        className,
        placeholder = 'Загрузить файл',
        onProgress,
        ...other
    } = props

    const handleProgress = payload => {
        if (onProgress) {
            onProgress(payload)
        }
    }

    return (
        <UploaderBase
            className={classNames('file-uploader', className)}
            onProgress={handleProgress}
            renderSuffix={({
                files,
                focused,
                hovered
            }) => files.length ? files.map(file => (
                <div
                    className={classNames('file-uploader-placeholder', {
                        'file-uploader-placeholder_focused': focused,
                        'file-uploader-placeholder_hovered': hovered
                    })}
                    key={file.originalFileName}
                >
                    <Icon.Paperclip
                        className="file-uploader-placeholder__icon"
                        size="12"
                    />
                    <span className="file-uploader-placeholder__text">
                        {file.originalFileName}
                    </span>
                </div>
            )) : (
                <div
                    className={classNames('file-uploader-placeholder', {
                        'file-uploader-placeholder_focused': focused,
                        'file-uploader-placeholder_hovered': hovered
                    })}
                >
                    <Icon.Paperclip
                        className="file-uploader-placeholder__icon"
                        size="12"
                    />
                    {placeholder}
                </div>
            )}
            {...other}
        />
    )
})

export default FileUploader
