import React, { FC } from 'react';

interface IImageProps {
	src: string;
}

export const Image: FC<IImageProps> = ({ src }) => {
	return (
		<div
			className="emilia__banner"
			style={{
				backgroundImage: `url(${src})`,
			}}
		></div>
	);
};
