import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import { textColor } from '../../utils';

import './style.scss';

export const DocsItem = withRouter(
	({ name, id, themeId = 1, location, lastUpdateDate, fillText, indexName, closeHandle }) => {
		const { state } = location;

		const clickDocItemHandler = () => {
			if (closeHandle) closeHandle();
			ReactGA.event({
				category: 'Переходы по результатам поиска',
				action: 'Переход в "Нормативные документы"',
				label: 'Открытия детального просмотра нормативных документов',
			});
		};

		return (
			<Link
				to={{
					pathname: `/knowledge/documents/view/${themeId}/${id}?fromIndex=${indexName}`,
					state: {
						...state,
						back: state && !!state.back ? state.back - 1 : -1,
					},
				}}
				className="block-item block-item_guide"
				onClick={clickDocItemHandler}
			>
				<svg role="img" className="stroke-svg">
					<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#search-docs'} />
				</svg>
				<div className="block-item-info">
					<span className="block-item-short-text">{textColor(name, fillText)}</span>
					<div className="block-item-wrapper">
						<span className="block-item-folder">
							{textColor(lastUpdateDate, fillText)}
						</span>
					</div>
				</div>
			</Link>
		);
	}
);
