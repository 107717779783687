import { SET_NOTIFICATION } from 'constant'

const DefaultState = {
    isOpen: false,
    status: 0,
    text: ''
}

export default function notification(state = DefaultState, action) {

    switch (action.type) {
        case SET_NOTIFICATION:
            return action.notification

        default:
            return state
    }

}