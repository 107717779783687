const OSTypes = [
    {os: "ANDROID", platform: /Android/},
    {os: "I_PHONE", platform: /iPhone/},
    {os: "I_PAD", platform: /iPad/},
    {os: "SYMBIAN", platform: /Symbian/},
    {os: "WINDOWS_PHONE", platform: /Windows Phone/},
    {os: "TABLET_OS", platform: /Tablet OS/},
    {os: "LINUX", platform: /Linux/},
    {os: "WINDOWS", platform: /Windows NT/},
    {os: "MACINTOSH", platform: /Macintosh/}
]

const getTypeOS = () => {
    const platform = navigator.userAgent

    for (let i in OSTypes) {
        if (OSTypes[i].platform.test(platform)) {
            return OSTypes[i].os;
        }
    }

    return "";
};

export default getTypeOS;
