import React, { SyntheticEvent } from 'react';
import type { BlockTool } from '@editorjs/editorjs';
import ReactDOM from 'react-dom';
import './style.scss';

interface IToolBox {
	title: string;
	icon: string;
}

interface IGetData {
	data: object;
	api: any;
	readOnly: any;
}

export class YoutubePlugins implements BlockTool {
	data: any;
	container: HTMLDivElement;
	api: any;
	readOnly: boolean;
	url: string | null;

	static get toolbox(): IToolBox {
		return {
			title: 'Youtube',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 24.402344 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.402344 16.898438 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.902344 40.5 17.898438 41 24.5 41 C 31.101563 41 37.097656 40.5 40.597656 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.097656 35.5 C 45.5 33 46 29.402344 46.097656 24.902344 C 46.097656 20.402344 45.597656 16.800781 45.097656 14.300781 C 44.699219 12.101563 42.800781 10.5 40.597656 10 C 37.097656 9.5 31 9 24.402344 9 Z M 24.402344 11 C 31.601563 11 37.398438 11.597656 40.199219 12.097656 C 41.699219 12.5 42.898438 13.5 43.097656 14.800781 C 43.699219 18 44.097656 21.402344 44.097656 24.902344 C 44 29.199219 43.5 32.699219 43.097656 35.199219 C 42.800781 37.097656 40.800781 37.699219 40.199219 37.902344 C 36.597656 38.601563 30.597656 39.097656 24.597656 39.097656 C 18.597656 39.097656 12.5 38.699219 9 37.902344 C 7.5 37.5 6.300781 36.5 6.101563 35.199219 C 5.300781 32.398438 5 28.699219 5 25 C 5 20.398438 5.402344 17 5.800781 14.902344 C 6.101563 13 8.199219 12.398438 8.699219 12.199219 C 12 11.5 18.101563 11 24.402344 11 Z M 19 17 L 19 33 L 33 25 Z M 21 20.402344 L 29 25 L 21 29.597656 Z"/></svg>',
		};
	}

	constructor({ data, api, readOnly }: IGetData) {
		this.container = document.createElement('div');
		this.data = data;
		this.api = api;
		this.readOnly = readOnly;
		this.url = null;
	}

	render() {
		this.data?.url ? this.createIframe() : this.renderContainer();
		return this.container;
	}

	save() {
		return {
			url: this.url ? this.url : this.data?.url,
		};
	}

	onChange = (event: SyntheticEvent<HTMLInputElement>) => {
		var p =
			/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
		this.url = event.currentTarget?.value.match(p) ? RegExp.$1 : null;

		this.url && this.createIframe();
	};

	renderContainer() {
		ReactDOM.render(
			<input
				type="text"
				placeholder="Вставьте ссылку на видео..."
				onChange={this.onChange}
				className="input"
			/>,
			this.container
		);
	}

	createIframe() {
		ReactDOM.render(
			<div className="container__editorjs">
				<iframe
					title="video"
					src={`https://www.youtube.com/embed/${
						this.data?.url ? this.data?.url : this.url
					}`}
					allowFullScreen
				/>
			</div>,
			this.container
		);
	}
}
