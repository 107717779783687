import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

export const Text = props => {
  let classStyle = "Aheader-text";
  if (props.type === "bold") {
    classStyle += " Aheader-text_bold";
  }
  if (props.type === "italic") {
    classStyle += " Aheader-text_italic";
  }
  return <span className={classStyle}>{props.children}</span>;
};

Text.propTypes = {
  type: PropTypes.oneOf(["bold", "italic"])
};
