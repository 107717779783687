import React from 'react';
import { withRouter } from 'react-router';
import store from 'store';

import './style.scss';

const ResultLink = ({ total, type, history }) => {
	const onClick = () => {
		let searchValue = document.querySelector('#search-input').value;
		history.push(`/search/${type}/${searchValue}`);
		store.dispatch({ type: 'CLEAR_SEARCH_RESULT' });
		document.querySelector('#search-input').value = '';
	};

	if (total > 10) {
		return (
			<button className="block-resultLink" type="submit" onClick={onClick}>
				{`Все результаты ${type === 'WIKI_GUIDE' ? '' : total}`}
			</button>
		);
	}

	return null;
};

export const BlockResultLink = withRouter(ResultLink);
