import { useState, useEffect } from 'react';

import { KeyboardNav } from 'core/utils';

export const useDropList = (defaultState = false) => {
	const [getDropState, setDropdown] = useState(defaultState);

	const DropToggle = () => setDropdown(prev => !prev);
	const DropDown = () => setDropdown(true);
	const DropUp = () => setDropdown(false);

	return { getDropState, DropToggle, DropDown, DropUp };
};

let keyboardNav = null;
export const useKeyboardNav = (refList, refInput) => {
	const activationKeyboardNav = () => {
		const list = [...refList.current.children];

		if (refInput.current) {
			keyboardNav = new KeyboardNav(list, refInput.current, true);
		}

		if (keyboardNav && refList.current) {
			keyboardNav.activateNav();
		}
	};

	const deactivationKeyboardNav = () => {
		if (keyboardNav) {
			keyboardNav.deactivateNav();
			keyboardNav = null;
		}
	};

	useEffect(() => {
		return () => deactivationKeyboardNav;
	}, []);

	return { activationKeyboardNav, deactivationKeyboardNav };
};

export const useKeyHandler = (ref, callback) => {
	useEffect(() => {
		const keyHandler = event => {
			if (event.key === 'Escape') {
				callback();
				ref.current.blur();
			}
			if (event.key === 'Tab') callback();
		};

		window.addEventListener('keyup', keyHandler);
		return () => {
			window.removeEventListener('keyup', keyHandler);
		};
	}, [callback, ref]);
};
