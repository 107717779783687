import React, {
    forwardRef,
    useState,
    useEffect,
    useLayoutEffect,
    useRef,
    useImperativeHandle,
    useCallback
} from 'react'
import { createPopper } from '@popperjs/core'
import Portal from '../portal'
import setRef from 'core/utils/setRef'
import useForkRef from 'core/utils/useForkRef'
import createChainedFunction from 'core/utils/createChainedFunction'

function getAnchorEl(anchorEl) {
    return typeof anchorEl === 'function' ? anchorEl() : anchorEl
}

function getArrowStyle(data) {
    switch(data.placement) {
        case 'top':
        case 'bottom':
            return {
                left: data.rects.popper.width / 2 -
                    data.modifiersData.preventOverflow.x
            }
        case 'left':
        case 'right':
            return {
                top: data.rects.popper.height / 2 -
                    data.modifiersData.preventOverflow.y
            }
        default:
            return
    }
}

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const defaultPopperOptions = {}

const Popper = forwardRef(function Popper(props, ref) {
    const {
        arrow = true,
        anchorEl,
        children,
        container,
        disablePortal = false,
        keepMounted = false,
        open,
        placement: initialPlacement = 'bottom',
        popperOptions = defaultPopperOptions,
        popperRef: popperRefProp,
        transition = false,
        ...other
    } = props
    const tooltipRef = useRef(null)
    const ownRef = useForkRef(tooltipRef, ref)

    const popperRef = useRef(null)
    const handlePopperRef = useForkRef(popperRef, popperRefProp)
    const handlePopperRefRef = useRef(handlePopperRef)
    useEnhancedEffect(() => {
        handlePopperRefRef.current = handlePopperRef
    }, [handlePopperRef])
    useImperativeHandle(popperRefProp, () => popperRef.current, [])

    const [exited, setExited] = useState(true)
    const [placement, setPlacement] = useState(initialPlacement)
    const [arrowStyle, setArrowStyle] = useState()

    useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update()
        }
    })

    const handleOpen = useCallback(() => {
        if (!tooltipRef.current || !anchorEl || !open) {
            return
        }
    
        if (popperRef.current) {
            popperRef.current.destroy()
            handlePopperRefRef.current(null)
        }
    
        const handlePopperUpdate = data => {
            if (arrow) {
                setArrowStyle(getArrowStyle(data))
            }
            setPlacement(data.placement)
        }

        const popper = createPopper(getAnchorEl(anchorEl), tooltipRef.current, {
            placement: initialPlacement,
            ...popperOptions,
            modifiers: [
                ...popperOptions.modifiers
            ],
            onCreate: createChainedFunction(handlePopperUpdate, popperOptions.onCreate),
            onUpdate: createChainedFunction(handlePopperUpdate, popperOptions.onUpdate),
            onFirstUpdate: createChainedFunction(handlePopperUpdate, popperOptions.onFirstUpdate)
        })
        handlePopperRefRef.current(popper)
    }, [arrow, anchorEl, open, initialPlacement, popperOptions])

    const handleRef = React.useCallback(node => {
        setRef(ownRef, node)
        handleOpen()
    }, [ownRef, handleOpen])

    const handleEnter = () => {
        setExited(false)
    }

    const handleClose = () => {
        if (!popperRef.current) {
            return
        }

        popperRef.current.destroy()
        handlePopperRefRef.current(null)
    }

    const handleExited = () => {
        setExited(true)
        handleClose()
    }

    useEffect(() => {
        handleOpen()
    }, [handleOpen])

    useEffect(() => {
        return () => {
            handleClose()
        }
    }, [])

    useEffect(() => {
        if (!open && !transition) {
            handleClose()
        }
    }, [open, transition])

    if (!keepMounted && !open && (!transition || exited)) {
        return null
    }

    const childProps = {
        placement,
        arrowStyle
    }

    if (transition) {
        childProps.TransitionProps = {
            in: open,
            onEnter: handleEnter,
            onExited: handleExited,
        }
    }

    return (
        <Portal
            disablePortal={disablePortal}
            container={container}
        >
            <div
                ref={handleRef}
                role="tooltip"
                {...other}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    ...other.style
                }}
            >
                {typeof children === 'function' ? children(childProps) : children}
            </div>
        </Portal>
    )
})

export default Popper
