import React, { forwardRef } from 'react';
import classNames from 'classnames';
import * as Icon from 'react-feather';
import UploaderBase from '../uploaderBase';

import './style.scss';

const MultipleFileUploader = forwardRef(function MultipleFileUploader(props, ref) {
	const {
		className,
		placeholder = 'Загрузить файлы',
		onProgress,
		fileType,
		id,
		...other
	} = props;

	const handleProgress = payload => {
		if (onProgress) {
			onProgress(payload);
		}
	};

	const renderSuffix = ({ files, hovered, deleteFile }) => {
		return (
			<div
				className={classNames(
					'file-uploader-placeholder file-uploader-placeholder_multiple',
					{
						'file-uploader-placeholder_hovered': hovered,
					}
				)}
			>
				<label htmlFor={id} className="file-uploader-placeholder__label">
					<Icon.Paperclip className="file-uploader-placeholder__icon" size="12" />
					{placeholder}
				</label>
				{!!files.length && (
					<div className="file-uploader-placeholder__list">
						{files.map((file, index) => {
							return (
								<div
									key={file.originalFileName + index}
									className="file-uploader-placeholder__item"
								>
									<Icon.File
										className="file-uploader-placeholder__icon"
										size="12"
									/>
									<span className="file-uploader-placeholder__text">
										{file.originalFileName}
									</span>
									<Icon.X
										className="file-uploader-placeholder__iconClose"
										size="15"
										onClick={() => {
											deleteFile(index);
										}}
									/>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	};

	return (
		<UploaderBase
			className={classNames('file-uploader', className)}
			onProgress={handleProgress}
			fileType={fileType}
			id={id}
			renderSuffix={renderSuffix}
			{...other}
		/>
	);
});

export default MultipleFileUploader;
