import React, { Component } from 'react'

export default class ArrowGoBack extends Component {
    render() {
		const { children, history } = this.props
		const steps = this.getBackStaps()
        return (
            <div className="header-title">
                {((history.length > 1) || !!steps) && (
					<svg role="img" className="icon icon_back" onClick={this.goBackHandler}>
						<use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}/>
					</svg>
				)}
                {children}
            </div>
        )
	}
	
	getBackStaps() {
		const { state } = this.props.location
		return state && !!state.back ? state.back : 0
	}

    goBackHandler = (e) => {
		e.preventDefault()
		const { history } = this.props
        if (history.length > 1) {
			const steps = this.getBackStaps()
			history.go(!!steps ? steps : -1)
        }
    }
}
