import React from 'react';
import { Link } from 'react-router-dom';
import { Clock } from 'react-feather';

import { Loader } from 'core/components';
import { UserName, UserImage, UserArrow, HeaderNotifications, MessagesLink } from '../../atoms';

import './style.scss';

const statuses = {
	DISTANT: 'Работаю удаленно',
	BUSINESS_TRIP: 'В командировке',
	VACATION: 'В отпуске',
	SICK_LEAVE: 'На больничном',
};

const HeaderUser = props => {
	const { user } = props;

	if (user.surname) {
		return (
			<div className="header-user">
				<MessagesLink />

				<HeaderNotifications countNotifications={user.countNotifications} />

				<Link to={`/user/${user.id}`} id="header_link-to-profile">
					<div className="header-user__wrap">
						<UserName>{`${user.name}`}</UserName>
						{!!statuses[user.userWorkStatus] && (
							<div className="header-user-status">
								<Clock className="header-user-status__icon" />
								<span className="header-user-status__text">
									{statuses[user.userWorkStatus]}
								</span>
							</div>
						)}
					</div>
					<UserImage url={user.avatarFile} />
				</Link>
				<UserArrow />
			</div>
		);
	} else {
		return (
			<div className="header-user header-user_loading">
				<Loader />
			</div>
		);
	}
};

export default HeaderUser;
