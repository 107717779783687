import { api } from './api';

//Получение списка всех уведомлений
//pageNumber integer (int32)
//pageSize integer (int32)
//onlyNotShowed bool
export const getCurrentUserNotifications = (page, pageSize, onlyNotShowed = false) => {
	return api.get('/notifications/list', {
		params: {
			pageNumber: page,
			pageSize,
			onlyNotShowed,
		},
	});
};

//Просмотр уведомления
//id integer (int64)
export const notificationView = id => {
	return api.post(`/notifications/show`, {
		id,
	});
};

//Акцептовать заявку (в соотвествии с типом и текущим состоянием)
//claimId integer (int64) идентификатор заявки
//comment string (255) комментарий
export const userClaimAccept = (claimId, comment) => {
	return api.post('/user-claims/accept', {
		claimId,
		comment,
	});
};

//Отменить заявку (в соотвествии с типом и текущим состоянием)
//claimId integer (int64) идентификатор заявки
//comment string (255) комментарий
export const userClaimReject = (claimId, comment) => {
	return api.post('/user-claims/reject', {
		claimId,
		comment,
	});
};
