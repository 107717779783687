import {
    createContext,
    useContext
} from 'react';

const FormControlContext = createContext();

export function useFormControl() {
  return useContext(FormControlContext);
}

export default FormControlContext
