import {
    SET_USER_FIND,
    CLEAR_USER_FIND
} from '../constant'

const DefaultState = null

const userFind = (state = DefaultState, action) => {
    switch (action.type) {

        case SET_USER_FIND:
            return [...action.users]

        case CLEAR_USER_FIND:
            return null

        default:
            return state
    }
}

export default userFind