import React, { forwardRef, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import useForkRef from 'core/utils/useForkRef';

import FormLabel from '../formLabel';
import FormCaption from '../formCaption';
import FormControl from '../formControl';
import FormHelperText from '../formHelperText';
import OutlinedInput from '../outlinedInput';

import './style.scss';

const variantComponent = {
	outlined: OutlinedInput,
};

const TextField = forwardRef(function TextField(props, ref) {
	const {
		autoComplete,
		autoFocus = false,
		caption,
		children,
		className,
		defaultValue,
		disabled = false,
		readOnly = false,
		endAdornment,
		error = false,
		FormHelperTextProps,
		fullWidth = false,
		helperText,
		id,
		InputLabelProps,
		inputProps = {},
		InputProps: InputPropsProp = {},
		inputRef: inputRefProp,
		label,
		mask,
		maxLength,
		name,
		onBlur,
		onChange,
		onFocus,
		placeholder,
		startAdornment,
		required = false,
		type,
		value: valueProp,
		valueLength,
		variant = 'outlined',
		...other
	} = props;

	const value = !!inputProps.value ? inputProps.value : valueProp;
	const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
	const inputLabelId = label && id ? `${id}-label` : undefined;
	const InputComponent = variantComponent[variant];

	const inputRef = useRef(null);
	const handleInputRefProp = useForkRef(inputRefProp, inputProps.ref);
	const handleInputRef = useForkRef(inputRef, handleInputRefProp);

	const [count, setCount] = useState(value ? value.length : 0);

	useEffect(() => {
		if (inputRef.current) {
			setCount(inputRef.current.value.length);
		}
	}, []);

	useEffect(() => {
		if (valueLength) {
			setCount(valueLength);
		}
	}, [valueLength]);

	useEffect(() => {
		if (inputRef.current && defaultValue && `${defaultValue}`.length) {
			const control = inputRef.current;
			control.value = defaultValue;
		}
	}, [defaultValue]);

	const handleChange = (event, ...args) => {
		if (inputProps.onChange) {
			inputProps.onChange(event, ...args);
		}
		if (onChange) {
			onChange(event, ...args);
		}
		if (inputRef.current && event.currentTarget === event.target) {
			setCount(inputRef.current.value.length);
		}
	};

	const InputProps = {
		...InputPropsProp,
		endAdornment: (
			<>
				{maxLength && (
					<div className="text-field-counter">
						<span className="text-field-counter__current">{count}</span>
						<span className="text-field-counter__separator">/</span>
						<span className="text-field-counter__max">{maxLength}</span>
					</div>
				)}
				{InputPropsProp.endAdornment}
			</>
		),
	};

	return (
		<FormControl
			className={classNames(
				'text-field',
				{
					'text-field_disabled': disabled,
					'text-field_error': error,
					'text-field_full-width': fullWidth,
					'text-field_required': required,
				},
				className
			)}
			disabled={disabled}
			error={error}
			fullWidth={fullWidth}
			ref={ref}
			required={required}
			{...other}
		>
			{label && (
				<FormLabel htmlFor={id} id={inputLabelId} children={label} {...InputLabelProps} />
			)}
			{caption && <FormCaption>{caption}</FormCaption>}
			<div className="text-field-body">
				{startAdornment}
				<InputComponent
					aria-describedby={helperTextId}
					autoComplete={autoComplete}
					autoFocus={autoFocus}
					defaultValue={defaultValue}
					disabled={disabled}
					readOnly={readOnly}
					error={error}
					fullWidth={fullWidth}
					name={name}
					mask={mask}
					maxLength={maxLength}
					type={type}
					value={value}
					id={id}
					inputRef={handleInputRef}
					onBlur={onBlur}
					onChange={handleChange}
					onFocus={onFocus}
					placeholder={placeholder}
					inputProps={inputProps}
					required={required}
					{...InputProps}
				/>
				{endAdornment}
			</div>
			{helperText && (
				<FormHelperText id={helperTextId} {...FormHelperTextProps} children={helperText} />
			)}
		</FormControl>
	);
});

export default TextField;
