import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { X } from 'react-feather';

import { FileUploader } from 'components/fileUploader';
import { questionImageAction } from 'action/survey';

interface IProps {
	questionNumber: number;
	index: number;
	defaultValue?: {
		id: number;
		filePath: string;
		originalFileName: string;
		fileSize: string;
	};
}

export const FileField: FC<IProps> = ({ questionNumber, index, defaultValue }) => {
	const dispatch = useDispatch();
	const [file, setFile] = useState<{ originalFileName: string; fileSize: string }>();

	useEffect(() => {
		if (!defaultValue) return;
		setFile(defaultValue);
	}, [defaultValue, index]);

	const setFileHandler = (files: any) => {
		const file = files[0];
		dispatch(questionImageAction(file, questionNumber));
		setFile(file);
	};

	const deleteFile = () => {
		setFile(undefined);
		dispatch(questionImageAction(undefined, questionNumber));
	};

	return !file ? (
		<FileUploader fileType="SURVEY_QUESTION_IMAGE" onChange={setFileHandler} multiple={false} />
	) : (
		<div className="question-file-preview">
			<div className="question-file-preview__wrap">
				<p className="question-file-preview__name">{file.originalFileName}</p>
				<p className="question-file-preview__size">{`${(
					+file.fileSize /
					1024 /
					1024
				).toFixed(2)} МБ`}</p>
			</div>
			<X className="question-file-preview__delete" onClick={deleteFile} />
		</div>
	);
};
