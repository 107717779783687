import Parser from 'html-react-parser';

import store from 'store';

export const clearStoreSearchResult = () => {
	store.dispatch({
		type: 'CLEAR_SEARCH_RESULT',
	});
	const input = document.querySelector('#search-input');
	if (input) input.value = '';
};

export const textColor = (text, fillText) => {
	let queryArr = [];
	if (fillText) {
		queryArr = fillText.split(' ');
	} else {
		if (getSearchValue()) {
			queryArr = getSearchValue().split(' ');
		} else return text;
	}
	if (text) {
		let highlightedText = text;
		queryArr.forEach(item => {
			if (item !== '' && item !== '-') {
				const regExpEscape = item.replace(/[\\^$*+?.,()|[\]{}]/g, '');
				if (regExpEscape) {
					const regExpLower = new RegExp(regExpEscape, 'gi');
					highlightedText = highlightedText.replace(
						regExpLower,
						`<i class='search-color'>$&</i>`
					);
				}
			}
		});
		return Parser(highlightedText);
	} else return null;
};

const getSearchValue = () => {
	let search = document.querySelector('#search-input');
	if (!search) return;
	else return search.value;
};
