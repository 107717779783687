import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Grid } from 'core/components';

import { HeaderSearch } from '../search/organisms';

import { Logo } from './atoms';
import { User } from './molecules';
import { HeaderMenu, NotificationsMenu } from './organisms';

import Router from './router';

import './style.scss';
import { useResize } from 'hooks/useResize';

const Header = ({ logOut }) => {
	const user = useSelector(state => state.shortUserInfo);
	const width = useResize();

	if (width >= 992) {
		return (
			<header className="header">
				<Grid>
					<Col xs={9} lg={8} md={7}>
						<div className="header__wrapper">
							<Logo />
							<HeaderSearch />
						</div>
					</Col>
					<Col xs={3} lg={4} md={5}>
						<div className="header-userMenuWrapp">
							<NotificationsMenu />
							<User user={user} />
							<HeaderMenu logOut={logOut} />
						</div>
					</Col>
				</Grid>
			</header>
		);
	} else {
		return (
			<header className="header header_mobile">
				<Router />
			</header>
		);
	}
};

export default Header;
