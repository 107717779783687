import React from 'react';

export const RenderPositionItem = ({ id, name, departmentName, addToListHandler }) => {
	return (
		<div
			key={id}
			className="find-item__search-item"
			data-positionid={id}
			data-positionname={name}
			data-positiondepartment={departmentName}
			onClick={addToListHandler}
			onKeyDown={event => {
				if (event.key === 'Enter') addToListHandler(event);
			}}
			tabIndex="1"
		>
			<div className="search-item__position">
				<span>{`${name}`}</span>
				{departmentName && <i>{`${departmentName}`}</i>}
			</div>
		</div>
	);
};
