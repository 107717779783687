import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URL } from 'constant';
import { Link } from 'react-router-dom';
import { Button, TypeButton, Icon } from '@maxi-innovation/ui-kit-frontend';

import { getSurveyListRest, toArchiveRest } from 'shared/api/survey';
import { NotificationHelper } from 'action/notificationHelper';
import { IConfirmModal, ISurveyList } from './types';
import { convertNormalDate } from 'core/utils';
import { useIsMobile } from 'hooks/useIsMobile';
import { ModalConfirm } from './ModalConfirm';

import './style.scss';

export const SurveyList = () => {
	const isMobile = useIsMobile();
	const dispatch = useDispatch();
	const [list, setList] = useState<ISurveyList[] | undefined>(undefined);
	const [confirmModal, setConfirmModal] = useState<IConfirmModal>({
		state: false,
		id: null,
		title: null,
	});

	useEffect(() => {
		(async () => {
			const { response, responseCode, errorMessage } = await getSurveyListRest();

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				setList(response);
			}
		})();
	}, [dispatch]);

	const toArchive = async (id: number) => {
		const { responseCode, errorMessage } = await toArchiveRest(id);

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
		} else {
			dispatch(NotificationHelper('Опрос перемещён в архив', responseCode));
			setList(prev => {
				if (prev) {
					return [...prev.filter(item => item.id !== id)];
				}
			});
		}
	};

	return (
		<div className="survey-list">
			{typeof list !== 'undefined' &&
				(list?.length ? (
					list.map((item, index) => (
						<div className="survey-list-item" key={index}>
							<img
								src={URL + item.cover.filePath}
								alt=""
								className="survey-list-item__img"
							/>
							<div className="survey-list-item__wrap">
								{item.canAdmin && item.canEdit ? (
									<p className="survey-list-item__date">
										{convertNormalDate(item.startDate)} -{' '}
										{convertNormalDate(item.endDate)}
									</p>
								) : (
									<p className="survey-list-item__date">
										Завершение опроса {convertNormalDate(item.endDate)}
									</p>
								)}
								<p className="survey-list-item__title">{item.title}</p>
								{item.alreadyCompleted ? (
									<Button
										variant={TypeButton.QUATERNARY}
										className="survey-list-item__link disabled"
										disabled
									>
										Опрос пройден
									</Button>
								) : item.canAdmin && item.canEdit ? (
									<Link
										to={`/services/survey/take/${item.id}`}
										className="survey-list-item__link"
									>
										<Button
											variant={TypeButton.QUATERNARY}
											className="survey-list-item__link-btn"
										>
											Посмотреть опрос
										</Button>
									</Link>
								) : (
									<Link
										to={`/services/survey/take/${item.id}`}
										className="survey-list-item__link"
									>
										<Button
											variant={TypeButton.QUATERNARY}
											className="survey-list-item__link-btn"
										>
											Пройти опрос
										</Button>
									</Link>
								)}

								{!isMobile && (item.canAdmin || item.canEdit) && (
									<div className="survey-list-admin">
										{item.canEdit ? (
											<Link
												to={`/services/survey/edit/${item.id}`}
												className="survey-list-admin__link"
											>
												<Button variant={TypeButton.LINKCAPS}>
													<Icon
														iconName="Edit2"
														w={15}
														h={15}
														className="survey-list-admin__icon"
													/>
													Изменить
												</Button>
											</Link>
										) : (
											item.canAdmin && (
												<div
													className="survey-list-admin__link"
													onClick={() =>
														setConfirmModal({
															state: true,
															id: item.id,
															title: item.title,
														})
													}
												>
													<Button variant={TypeButton.LINKCAPS}>
														<Icon
															iconName="Archive"
															w={15}
															h={15}
															className="survey-list-admin__icon"
														/>
														В архив
													</Button>
												</div>
											)
										)}

										{item.canAdmin && (
											<Link
												to={`/services/survey/analitics/${item.id}`}
												className="survey-list-admin__link left"
											>
												<Button variant={TypeButton.LINKCAPS}>
													<Icon
														iconName="PieChart"
														w={15}
														h={15}
														className="survey-list-admin__icon"
													/>
													Аналитика
												</Button>
											</Link>
										)}
									</div>
								)}
							</div>
						</div>
					))
				) : (
					<div className="survey-list-empty">У вас нет доступных опросов</div>
				))}

			{confirmModal.state && confirmModal.id && confirmModal.title && (
				<ModalConfirm
					close={() =>
						setConfirmModal({
							state: false,
							id: null,
							title: null,
						})
					}
					toArchive={toArchive}
					id={confirmModal.id}
					title={confirmModal.title}
				/>
			)}
		</div>
	);
};
