import React from 'react';
import { Link } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

import { FixedSidebar } from 'components/fixedSidebar';

import './style.scss';

const AsideRight = () => {
	const asideRenderHelper = () => {
		return (
			<div className="vacancies-aside fixed-sidebar__wrap">
				<p className="vacancies-aside__title">Информация</p>

				<p className="vacancies-aside__text">
					Если вы заинтересовались вакансией и считаете, что можете проявить свои
					способности и быть полезным на данной должности, Вы можете откликнуться на
					вакансию. Ваша кандидатура будет рассмотрена в течение 5 календарных дней с
					момента отправления заявки.
				</p>
				<p className="vacancies-aside__text">
					Если вы хотите порекомендовать нам специалиста, то заполните анкету в отношении
					своих родственников, знакомых, которые заинтересованы в предлагаемой работе, и
					подходят по предъявляемым к кандидатам требованиям.
				</p>
				<p className="vacancies-aside__text">
					Предварительно, пожалуйста, ознакомьтесь с{' '}
					<Link to="/vacancies/rules" className="inline-link">
						правилами участия
					</Link>{' '}
					в конкурсе на вакантные должности компании. Мы обязательно с Вами свяжемся,
					чтобы сообщить результат!
				</p>
			</div>
		);
	};

	return useIsMobile() ? asideRenderHelper() : <FixedSidebar>{asideRenderHelper()}</FixedSidebar>;
};

export default AsideRight;
