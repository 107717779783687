import {
	SET_MAIN_PAGE_POSTS,
	POST_LIKE,
	COMMENTS_COUNT_ADD,
	COMMENTS_COUNT_REMOVE,
	POST_VIEW,
} from 'constant';

const initialState = {
	list: [],
};

export default function mainPagePosts(state = initialState, action) {
	switch (action.type) {
		case SET_MAIN_PAGE_POSTS:
			return {
				...state,
				list: [...action.mainPagePosts],
			};

		case POST_LIKE:
			return {
				...state,
				list: state.list.map(item => {
					if (+item.id === +action.id) {
						item.hasLike = action.hasLike;
						if (action.hasLike) {
							item.likeCount++;
						} else {
							item.likeCount--;
						}
						return item;
					} else return item;
				}),
			};

		case POST_VIEW:
			return {
				...state,
				list: state.list.map(item => {
					if (+item.id === +action.id) {
						if (!item.view) {
							item.view = true;
							item.viewsCount = item.viewsCount + 1;
						}
					}
					return item;
				}),
			};

		case COMMENTS_COUNT_ADD:
			return {
				...state,
				list: state.list.map(post => {
					if (post.id === action.id) {
						post.commentCount++;
						return post;
					} else {
						return post;
					}
				}),
			};

		case COMMENTS_COUNT_REMOVE:
			return {
				...state,
				list: state.list.map(post => {
					if (post.id === action.id) {
						post.commentCount--;
						return post;
					} else {
						return post;
					}
				}),
			};

		default:
			return state;
	}
}
