import { getHours, getMinutes, getYear, getMonth, getDate } from 'date-fns';

export const convertNormalDateWithTime = (unixTime, pretext) => {
	let year = getYear(unixTime);
	let month = getMonth(unixTime) + 1;
	let day = getDate(unixTime);
	let hours = getHours(unixTime);
	let minutes = getMinutes(unixTime);

	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;
	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;

	return `${day}.${month}.${year} ${!!pretext ? pretext + ' ' : ''}${hours}:${minutes}`;
};

export const convertDateWithTimeForFile = unixTime => {
	let year = getYear(unixTime);
	let month = getMonth(unixTime) + 1;
	let day = getDate(unixTime);
	let hours = getHours(unixTime);
	let minutes = getMinutes(unixTime);

	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;
	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;

	return `${day}-${month}-${year} ${hours}-${minutes}`;
};
