import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './style.scss'

export const BackToBase = ({ className }) => {
    return (
        <Link to="/university" className={`back-to-base ${className}`}>
            <svg role="img">
                <use xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#arrow'}></use>
            </svg>
            Назад к Обучению
        </Link>
    )
}

BackToBase.propTypes = {
    className: PropTypes.string
}

BackToBase.defaultProps = {
    className: ''
}