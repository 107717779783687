import React, { forwardRef } from 'react'
import classNames from 'classnames'
import './style.scss'

const Paper = forwardRef(function Paper(props, ref) {
    const {
        className,
        ...other
    } = props
    return (
        <div
            className={classNames('paper', className)}
            ref={ref}
            {...other}
        />
    )
})

export default Paper
