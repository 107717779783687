import React from 'react'

import './style.scss'

const Loader = ({ type }) => {
    return (
        <div className={classNameHelper(type)}></div>
    )
}

const classNameHelper = (type) => {
    switch (type) {
        case 'dark':
            return 'nb-spinner'
        case 'white':
            return 'nb-spinner white'
        default:
            return 'nb-spinner'
    }
}

export default Loader