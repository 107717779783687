import React, { useEffect, useState, FC, useRef } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import * as yup from 'yup';
import { Button, TypeButton, Icon } from '@maxi-innovation/ui-kit-frontend';

import { Datepicker } from 'core/components';
import { IFiltersData } from './types';

const validationSchema = yup.object().shape({
	startPeriod: yup.number(),
	endPeriod: yup.number(),
});

const registerFields = Object.keys(validationSchema.fields);

interface IProps {
	filterHandle: (data: IFiltersData) => void;
	clearFiltersHandle: () => void;
}

const millisecondsInDay = 86399000;
export const Filters: FC<IProps> = ({ filterHandle, clearFiltersHandle }) => {
	const formRef = useRef<HTMLFormElement>(null);
	const [clear, setClear] = useState(false);

	const methods = useForm<IFiltersData>({
		reValidateMode: 'onSubmit',
		validationSchema,
	});
	const { handleSubmit, register, unregister, setValue } = methods;

	useEffect(() => {
		registerFields.forEach(name => register(name));

		return () => unregister(registerFields);
	}, [register, unregister]);

	const startPeriodHandler = (date: number) => {
		setValue('startPeriod', date ? date : undefined);
	};

	const endPeriodHandler = (date: number) => {
		setValue('endPeriod', date ? date + millisecondsInDay : undefined);
	};

	const clearFilters = () => {
		setClear(true);
		clearFiltersHandle();
	};

	const onSubmit = async (data: IFiltersData) => {
		filterHandle(data);
	};

	return (
		<FormContext {...methods}>
			<form className="pulse-survey-filters" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
				<div className="pulse-survey-filters__wrap">
					<p className="pulse-survey-filters__label">Дата начала</p>
					{/* @ts-ignore */}
					<Datepicker
						clearDate={clear}
						setClearDate={setClear}
						onChange={startPeriodHandler}
						placeholder="Выберите дату"
						id="pulse-survey_filters_start-date"
					/>
				</div>

				<div className="pulse-survey-filters__wrap">
					<p className="pulse-survey-filters__label">Дата окончания</p>
					{/* @ts-ignore */}
					<Datepicker
						clearDate={clear}
						setClearDate={setClear}
						onChange={endPeriodHandler}
						placeholder="Выберите дату"
						id="pulse-survey_filters_end-date"
					/>
				</div>

				<div className="pulse-survey-filters__button-group">
					<Button
						variant={TypeButton.QUATERNARY}
						type="submit"
						className="pulse-survey-filters__button"
						title="Применить"
						id="pulse-survey_filters_search"
					>
						<Icon iconName="Search" w={18} h={18} />
					</Button>

					<Button
						variant={TypeButton.TRETIARY}
						onClick={clearFilters}
						type="button"
						className="pulse-survey-filters__button"
						title="Сбросить"
						id="pulse-survey_filters_clear"
					>
						<Icon iconName="X" w={18} h={18} />
					</Button>
				</div>
			</form>
		</FormContext>
	);
};
