import { api } from './api';

//Получить опцию по имени
//name string Обязат.
export const getOptions = name => {
	return api.get(`/options/get?name=${name}`);
};

//Получить опцию по имени
//name string Обязат.
export const getOptionsNull = name => {
	return api.get(`/options/get-null?name=${name}`);
};
